import React, { useEffect, useMemo, useState } from "react";
import { formPagesList } from "../../../../services/appService";
import Select from "react-select";
import { toast } from "react-toastify";

const filterFormPositions = [
  {
    label: "top",
    value: "top",
  },
  {
    label: "left",
    value: "left",
  },
  {
    label: "right",
    value: "right",
  },
];

const DisplayBuilderFilterForm = (props) => {
  const [displayChanges, setDisplayChanges] = useState(
    props?.displayFilterFormObj
  );
  const [selectedFilterForm, setSelectedFilterForm] = useState([]);
  const [filterFormListOptions, setFilterFormListOptions] = useState([]);
  const initialFormPosition = props?.displayFilterFormObj?.formPosition
    ? filterFormPositions.find(
        (form) =>
          form.value === props?.displayFilterFormObj?.formPosition && form
      )
    : null;
  const [formPosition, setFormPosition] = useState(initialFormPosition);
  const [formPositionOptions] = useState(filterFormPositions);

  useMemo(() => {
    getAllFormList();
    setDisplayChanges(displayChanges);
  }, []);

  useEffect(() => {
    props.onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const checkBoxChangeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.checked;
    data[name] = value;
    setDisplayChanges(data);
  };

  const handleSelectChange = (name, selectedOpt) => {
    if (name === "filterForm") {
      setSelectedFilterForm(selectedOpt);
    }
    if (name === "formPosition") {
      setFormPosition(selectedOpt);
    }
    changeValue({ target: { name, value: selectedOpt?.value } });
  };

  function getAllFormList() {
    let bodyObj = {
      FilterCriteria: [
        {
          filter_type: "text",
          type: "equal",
          filter: "form_component",
          filter_field: "page_type",
        },
      ],
      export: true,
    };
    formPagesList(bodyObj)
      .then((response) => {
        if (response?.success) {
          const filterFormList = response?.data.map((form) => {
            return {
              label: form.function_name,
              value: form._id.$oid,
            };
          });
          setFilterFormListOptions(filterFormList);
          setSelectedFilterForm(
            filterFormList.find((form) => {
              return form.value === displayChanges?.filterForm && form;
            })
          );
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  }

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2" htmlFor="filterForm">
          Select Filter Form
        </label>
        <div>
          <Select
            options={filterFormListOptions}
            isClearable
            inputId="filterForm"
            name="filterForm"
            value={selectedFilterForm}
            onChange={(e) => {
              handleSelectChange("filterForm", e);
            }}
            menuPosition="fixed"
            classNames={{
              control: (state) => 'searchableDropdownControl',
              menuPortal: () => 'searchableDropdownMenuPortal',
              singleValue: () => 'searchableDropdownValue',
              multiValue: () => 'searchableDropdownValue',
              menuList: () => 'searchableDropdownMenuList'
            }}
          />
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Filter Header
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.filterFormHeader?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter value"
            name="filterFormHeader"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.filterFormHeader}
          ></input>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2" htmlFor="formPosition">
          Select Filter Form Position
        </label>
        <div>
          <Select
            options={formPositionOptions}
            isClearable
            inputId="formPosition"
            name="formPosition"
            value={formPosition}
            onChange={(e) => {
              handleSelectChange("formPosition", e);
            }}
            menuPosition="fixed"
            classNames={{
              control: (state) => 'searchableDropdownControl',
              menuPortal: () => 'searchableDropdownMenuPortal',
              singleValue: () => 'searchableDropdownValue',
              multiValue: () => 'searchableDropdownValue',
              menuList: () => 'searchableDropdownMenuList'
            }}
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <div className="d-flex" >
          <input
            className="me-2"
            type="checkbox"
            onChange={(e) => {
              checkBoxChangeValue(e);
            }}
            name="isCacheEnabled"
            checked={displayChanges?.isCacheEnabled}
          />
          <label className="form-label p-0 mt-2">Enable Cache</label>
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderFilterForm;
