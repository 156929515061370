import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getRecordDataById } from "../../../services/tableConfigService";
import { inputTypeKey } from "../../common/model/Model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormContext } from "../contexts/FormContext";
import "./inlineTableForm.scss";
import GetEditField from "./GetEditField";
import GetDisplayField from "./fieldsComponent/GetDisplayField";
import { getFieldDefaultValue } from "./fieldsDisplayComponent/getDefaultValue.js";
import CalculatedFieldTable from "./fieldsComponent/CalculatedField/CalculatedFieldTable.js";
import { v4 as uuidv4 } from "uuid";
import { getLastNodeData, executeLogicByName, executeAppForTempStorage } from "../../../services/API.js";
import { getAllDropdownList } from "../../../services/appService.js";

const InLineTableForm = ({
  fieldData,
  title,
  appSessionId,
  upsertMode = true,
  isEdit = true,
  setInLineTableFormData,
  inLineTableFormData = [],
  mainFormData,
  setmainFormData,
}) => {
  const [apiCallDataBackup, setApiCallDataBackup] = useState({});
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [pageFields, setPageFields] = useState([]);
  const [editableIndex, setEditableIndex] = useState(-1);
  const [inLineTableFormDataSummary, setInLineTableFormDataSummary] = useState([]);
  const [isSummaryDataLoading, setIsSummaryDataLoading] = useState(false);

  useEffect(() => {
    if (fieldData?.addFormData) {
      getFormPageField();
    }
  }, []);

  useEffect(() => {
    if (pageFields.length) {
      getAllApiDataDependentDropDown();
    }
  }, [pageFields]);

  const getAllApiDataDependentDropDown = () => {
    let fields =
      pageFields?.filter(
        (dataField) =>
          dataField.data_type === inputTypeKey.dynamicDependentDropdown &&
          dataField.show_key_to_table !== "no" &&
          dataField.key_to_send &&
          dataField.key_to_show &&
          dataField.key_to_send !== dataField.key_to_show
      ) || [];
    fields.forEach((dataField) => {
      let bodyObj = {
        body: {
          FilterCriteria: [],
          export: true,
        },
        datasetName:  dataField.dependentDataset,
      };
      getAllDropdownList(bodyObj).then((response) => {
        setApiCallDataBackup((prev) => ({
          ...prev,
          [dataField.name]: response.data,
        }));
      });
    });
  };

  const getFormPageField = () => {
    let objData = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      dataset_name: "fc_app_pages",
      id: fieldData?.addFormData,
    };
    setIsFormLoading(true);
    getRecordDataById(objData)
      .then((response) => {
        if (response.success === true) {
          setPageFields(
            response?.data.page_fields?.filter(
              (data) => data?.data_type !== inputTypeKey.inlineTableForm
            )
          );
        }
      })
      .catch((err) => toast.error(err.response?.data?.message || err.response?.message || err.message))
      .finally(() => setIsFormLoading(false));
  };

  const inLineTableFormChange = (value, key, index) => {
    let tableFormData = [...inLineTableFormData];
    if (key) {
      tableFormData[index] = { ...tableFormData[index], [key]: value };
    } else {
      tableFormData[index] = { ...tableFormData[index], ...value };
    }
    setInLineTableFormData(tableFormData);
  };

  const addNewInlineFormData = () => {
    let initialFormData = {};
    pageFields.forEach((field) => {
      initialFormData = {
        ...initialFormData,
        [field.name]: getFieldDefaultValue(field),
      };
    });
    setEditableIndex(inLineTableFormData.length);
    setInLineTableFormData([...inLineTableFormData, initialFormData]);
  };

  const removeInlineFormData = (index) => {
    setInLineTableFormData(
      inLineTableFormData.filter((_, idx) => idx !== index)
    );
    setInLineTableFormData(
      inLineTableFormData.filter((_, idx) => idx !== index)
    );
  };

  const loadCalculatedTableSummaryData = () => {
    setIsSummaryDataLoading(true);
    const calcField = pageFields?.filter(
      (item) => item?.data_type === inputTypeKey?.calculatedField
    )[0];
    if (!calcField) {
      return;
    }
    const summaryInputReferenceName = calcField.summaryInputRefName;
    const summaryOutputReferenceName = calcField.summaryOutputRefName;
    let logic = calcField.summary_logic_to_call;
    if (!logic) {
      return;
    }
    let payload = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      data: { "payload": inLineTableFormData },
      reference_name: summaryInputReferenceName,
      app_session_id: appSessionId,
    };

    executeAppForTempStorage(payload)
      .then((res) => {
        if (res) {
          let obj = {
            app_session_id: appSessionId,
            logic_name: logic,
            reference_name: summaryOutputReferenceName,
          };
          executeLogicByName(obj)
            .then((response) => {
              if (response) {
                let [data, _] = response.data.value.data || [];
                if (data) {
                  var finalSummaryData = {};
                  Object.entries(data).forEach(
                    ([key, value], i) => {
                      key = key.replace("_", " ");
                      key = key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
                      finalSummaryData[key] = value;
                    });
                  setInLineTableFormDataSummary(finalSummaryData);
                  let mainFormDataWithSummary = { ...mainFormData, ...data };
                  setmainFormData(mainFormDataWithSummary);
                }
              }
            })
            .catch((err) => {
              toast.error(err.response?.data?.message || err.response?.message || err.message);
            })
            .finally(() => {
              setIsSummaryDataLoading(false);
            });
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => {
        
      });
  };


  return (
    <div className="rounded mb-2 border bg-white">
      <div className="d-help border-bottom p-2 flex-wrap">
        <p className="m-0 px-2 py-1">
          <span className="content">{title}</span>
          <span className="content ms-1">{inLineTableFormData?.length > 0 && `(${inLineTableFormData?.length})`}</span>
          
          
        </p>
        {inLineTableFormData?.length > 0 && (
          <FontAwesomeIcon
            icon={"fa fa-plus-circle"}
            title={`Add New ${title}`}
            className="pointer ms-0 me-1 text-secondary"
            aria-hidden="true"
            onClick={addNewInlineFormData}
            size="lg"
          />
        )}
      </div>

      {isFormLoading ? (
        <div className="set_height d-flex justify-content-center align-items-center">
          <div className="loader-circle-sm" />
        </div>
      ) : (
        <>
          {inLineTableFormData?.length > 0 ? (
            <div className="inlineTableForm table-responsive m-2">
              <table className="w-100 table table-sm mb-0">
                <thead>
                  <tr>
                    {pageFields.map((element, index) => {
                      return (
                        <th className="p-2 text-nowrap" key={index}>
                          
                          <span className="content">{element?.display_name}</span>
                        </th>
                      );
                    })}
                    <th action="true" className="p-1 px-3">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {inLineTableFormData?.map((data, index) => {
                    return (
                      <CustomFormTableRow
                        key={index}
                        index={index}
                        pageFields={pageFields}
                        onRowDataChange={(value, key) => {
                          inLineTableFormChange(value, key, index);
                        }}
                        data={data}
                        appSessionId={appSessionId}
                        upsertMode={upsertMode}
                        isEdit={isEdit}
                        apiCallDataBackup={apiCallDataBackup}
                        setApiCallDataBackup={setApiCallDataBackup}
                        removeInlineFormData={removeInlineFormData}
                        isEditableIndex={editableIndex === index}
                        setEditableIndex={setEditableIndex}
                        mainFormData={mainFormData}
                        fieldData={fieldData}
                        setmainFormData={(data) => {
                          setmainFormData(data);
                          loadCalculatedTableSummaryData();
                        }}
                      />
                    );
                  })}
                </tbody>
                </table>
                <div className="row px-3 inline-table-form-summary-section">
                  {Object.entries(inLineTableFormDataSummary).map(
                    ([key, value], i) => (
                      <div
                        key={i}
                        className="col p-2 text-left inline-table-form-summary-section-bg"
                      >
                        <label className="control-label text-left">
                          {key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:
                        </label>
                        <span className="ms-3">{value}</span>
                      </div>
                    )
                  )}
                  {isSummaryDataLoading ? (
                      <>
                        <span className="spinner-border spinner-border-sm text-light me-2 "></span>
                        Loading..
                      </>
                    ) : (
                      ""
                    )}
                </div>
            </div>
          ) : (
            <div className="set_height d-flex align-items-center justify-content-center m-2">
              <div className="text-center">
                <div className="mb-2">No Record Added</div>
                <FontAwesomeIcon
                  icon={"fa fa-plus-circle"}
                  title={`Add New ${title}`}
                  className="pointer ms-0 me-1 text-secondary"
                  aria-hidden="true"
                  onClick={addNewInlineFormData}
                  size="lg"
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const CustomFormTableRow = ({
  pageFields,
  appSessionId,
  isEdit,
  data,
  onRowDataChange,
  apiCallDataBackup,
  setApiCallDataBackup,
  removeInlineFormData,
  index,
  setEditableIndex,
  isEditableIndex,
  mainFormData,
  fieldData,
  setmainFormData,
}) => {
  const [formData, setFormData] = useState({});
  const [autoFillData, setAutoFillData] = useState("");
  const [showCalcRow, setCalcRow] = useState(false);
  const [uuidKeyName, setuuidKeyName] = useState("");

  useEffect(() => {
    if (pageFields.length) {
      let fields =
        pageFields?.filter(
          (dataField) =>
            dataField.data_type === inputTypeKey.searchableDropdown &&
            dataField.show_key_to_table !== "no" &&
            dataField.key_to_send &&
            dataField.key_to_show &&
            dataField.key_to_send !== dataField.key_to_show
        ) || [];
      fields.forEach((dataField) => {
        getLastNodeData(appSessionId, dataField.logic_to_call).then((res) => {
          let { data } = res?.data?.value || {};
          if (data) {
            setApiCallDataBackup((prev) => ({
              ...prev,
              [dataField.name]: data.data,
            }));
          }
        });
      });
    }
  }, [pageFields]);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  useEffect(() => {
    if (autoFillData) {
      setFormData({ ...formData, ...autoFillData });
      onRowDataChange({ ...formData, ...autoFillData });
    }
  }, [autoFillData]);

  const calcField = pageFields?.filter(
    (item) => item?.data_type === inputTypeKey?.calculatedField
  )[0];

  return (
    <FormContext.Provider
      value={{
        formData,
        setFormData,
        autoFillData,
        setAutoFillData,
        apiCallDataBackup,
        setApiCallDataBackup,
        appSessionId,
        isEdit,
        isEditableIndex,
        uuidKeyName,
        setuuidKeyName,
        showCalcRow,
        setCalcRow,
      }}
    >
      <tr
        className="inlineTableFormEdit"
        onDoubleClick={() => setEditableIndex(index)}
      >
        {pageFields?.map((field, i) => {
          return (
            <CustomFormTableTd
              key={i}
              field={field}
              onFieldChange={(value, key) => {
                setuuidKeyName(uuidv4() + key);
                onRowDataChange(value, key);
              }}
              rowData={data}
              appSessionId={appSessionId}
              mainFormData={mainFormData}
              fieldData={fieldData}
              setmainFormData={setmainFormData}
              index={index}
              setCalcRow={setCalcRow}
            />
          );
        })}
        {/* All Action Button Placed Here in This Column */}
        <td action="true" className="border-top align-center p-0">
          <div className="border-start fieldMinimumHeight p-2 d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center editIconContainer">
              <div
                className="border rounded d-flex justify-content-center align-items-center p-1 me-1 editIcon"
                onClick={() => {
                  setEditableIndex(index);
                }}
              >
                <FontAwesomeIcon
                  title="Edit"
                  icon={"fa fa-pencil"}
                  className="pointer"
                  size="sm"
                />
              </div>
              <div
                className="border rounded d-flex justify-content-center align-items-center p-1 editIcon"
                onClick={() => {
                  removeInlineFormData(index);
                }}
              >
                <FontAwesomeIcon
                  title="Remove"
                  icon={"fa fa-times"}
                  className="pointer"
                />
              </div>
            </div>
          </div>
        </td>
      </tr>
      {calcField?.data_type === inputTypeKey.calculatedField &&
        isEditableIndex && (
          <tr>
            <td className="border-0" colSpan={pageFields?.length}>
              <CalculatedFieldTable
                field={calcField}
                onFieldChange={onRowDataChange}
                rowData={data}
                appSessionId={appSessionId}
                mainFormData={mainFormData}
                fieldData={fieldData}
                setmainFormData={(data) => {
                  setmainFormData(data);
                }}
                index={index}
                isEdit={isEdit}
            ></CalculatedFieldTable>
            </td>
          </tr>
        )}
    </FormContext.Provider>
  );
};

const CustomFormTableTd = ({
  index,
  field,
  rowData,
  onFieldChange,
  appSessionId,
  mainFormData,
  fieldData,
  setmainFormData,
  setCalcRow,
}) => {
  const { isEdit, isEditableIndex } = useContext(FormContext);
  let componentUi = <></>;
  let disabled = field?.initialDisable === "yes";

  if (isEdit === true && field.edit_mode === "no") {
    disabled = true;
  }

  if (isEditableIndex) {
    componentUi = (
      <GetEditField
        field={field}
        value={rowData[field?.name]}
        onChange={(e) => onFieldChange(e, field?.name)}
        disabled={disabled}
        appSessionId={appSessionId}
        mainFormData={mainFormData}
        fieldData={fieldData}
        setmainFormData={setmainFormData}
        index={index}
        isEdit={isEdit}
        setCalcRow={setCalcRow}
      />
    );
  } else {
    componentUi = (
      <GetDisplayField field={field} value={rowData[field?.name]} />
    );
  }

  return (
    <td className="border-top align-top p-2 fieldMinimumHeight">
      {componentUi}
    </td>
  );
};

export default InLineTableForm;
