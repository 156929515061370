import React, { Component } from "react";
import { getRecordsByTenantId } from "../../../../services/datasetService";
import "./Form.scss";
import { withRouter } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataset_objectId: "",
      field: {
        name: "",
        description: "",
        author: "",
        folder_name: "Default",
      },
      readOnly: false,
      errors: "",
      editMode: false,
    };
    this.changedValue = this.changedValue.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  componentWillMount() {
    this.getCurrentDataSetRecord();
    this.props.onRef(this);
  }

  getCurrentDataSetRecord() {
    let datasetId = this.props.match.params.id;
    if (datasetId === undefined) {
    } else {
      this.setState(
        {
          dataset_objectId: datasetId,
        },
        () => {
          let getData = {
            dataset_id: this.state.dataset_objectId
              ? this.state.dataset_objectId
              : "",
            is_parent: "True",
            offset: 0,
            limit: 10,
          };
          if (datasetId !== undefined) {
            getRecordsByTenantId(getData).then((response) => {
              if(response.data?.name){
                this.state.editMode = true;
              }
              this.setState({
                field: {
                  name: response.data.name,
                  description: response.data.description,
                  author: response.data.author,
                  folder_name: response.data.folder_name,
                },
                readOnly: true,
              });
            });
          }
        }
      );
    }
  }

  validateForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;

    var namePattern = new RegExp(/^[A-Za-z0-9_\s]*$/);
    if (!fields["name"]) {
      formIsValid = false;
      if (this.props.match.path === "/add_app_page/:appId") {
        errors["name"] = "*Page Name cannot be empty";
      } else {
        errors["name"] = "*Dataset Name cannot be empty";
      }
    } else if (!namePattern.test(fields["name"])) {
      formIsValid = false;
      if (this.props.match.path === "/add_app_page/:appId") {
        errors["name"] = "*Page Name should contains only alphabets, numbers and _";
      } else {
        errors["name"] = "*Dataset Name should contains only alphabets, numbers and _";
      }
    }

    if (!fields["description"]) {
      formIsValid = false;
      errors["description"] = "*Description cannot be empty";
    }

    if (this.props.match.path !== "/add_app_page/:appId") {
      if (!fields["author"]) {
        formIsValid = false;
        errors["author"] = "*Author cannot be empty";
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  changedValue(event) {
    let fields = this.state.field;
    fields[event.target.name] = event.target.value;
    if (event.target.name === "name") {
      if (event.target.value.includes(" ")) {
        fields[event.target.name] = event.target.value.slice(0, -1);
      }
    }
    this.setState({
      fields,
    });
  }

  submitData(event) {
    event.preventDefault();
    if (this.validateForm()) {
      let datasetObj = {
        dataset_name: this.state.field.name,
        dataset_object_id: this.state.dataset_objectId,
        description: this.state.field.description,
        author: this.state.field.author,
        folder_name: this.state.field.folder_name,
      };
      this.sendData(datasetObj);
    }
  }

  sendData = (datasetObj) => {
    this.props.returnObjDataToParent(datasetObj);
  };

  render() {
    return (
      <div>
        <div className="p-2 border bg-white mb-2">
          <form
            className="form-label-left input_mask"
            onSubmit={this.submitData}
          >
            <div className="form-group row">
              {this.props.match.path === "/add_app_page/:appId" ? (
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Page Name
                </label>
              ) : (
                <label className="col-form-label col-md-3 col-sm-3 required">
                  Dataset Name
                </label>
              )}
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  name="name"
                  disabled={this.state.readOnly}
                  value={this.state.field.name}
                  onChange={(e) => {
                    this.changedValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.name}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Description
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  name="description"
                  value={this.state.field.description}
                  onChange={(e) => {
                    this.changedValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.description}
                </div>
              </div>
            </div>
            {this.props.match.path !== "/add_app_page/:appId" ? (
              <>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3">
                    Folder Name
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Folder Name"
                      name="folder_name"
                      value={this.state.field.folder_name}
                      onChange={(e) => {
                        this.changedValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.name}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-3 col-sm-3 required">
                    Author
                  </label>
                  <div className="col-md-9 col-sm-9 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Author"
                      name="author"
                      value={this.state.field.author}
                      onChange={(e) => {
                        this.changedValue(e);
                      }}
                    />
                    <div className="errorMsg text-danger">
                      {this.state.errors.author}
                    </div>
                  </div>
                </div>
                {this.state.editMode && (
                  <div className="form-group row">
                    <div className="col-8">
                        &nbsp;
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                      <button
                        className="btn btn-sm btn-success"
                        onClick={this.submitData}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-floppy-disk" />&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(Form);
