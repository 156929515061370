import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState, useEffect, useContext } from "react";
import { Col } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import "./scheduleListView.scss";
import Loader from "../../dataset/Loader/Loader";
import { reactAppExecutionVP } from "../../../services/API";
import { toast } from "react-toastify";
import AppLoader from "../../dataset/Loader/AppLoader";
import { RoutesContext } from "../../../RoutesContext";

const ScheduleListView = ({
  displayData,
  appSessionId,
  filterApply,
  objData,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
}) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [collapse, setCollapse] = useState(true);
  const [scheduleListData, setScheduleListData] = useState([]);
  const [scheduleListLoading, setScheduleListLoading] = useState(false);
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const { component_id, isCacheEnabled,autoReloadComponent,autoReloadDelay } = displayData;

  useEffect(() => {
    if (
      cachedData.hasOwnProperty(component_id) &&
      !tempStorageCall &&
      isCacheEnabled
    ) {
      setScheduleListData(cachedData?.[component_id]);
      return;
    }
    getScheduleListData();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (filterApply) {
      getScheduleListData();
    }
  }, [filterApply]);

  useEffect(() => {
    if (autoReloadComponent && autoReloadDelay !== "") {
      const intervalId = setInterval(
        () => getScheduleListData(),
        autoReloadDelay
      );
      return () => clearInterval(intervalId);
    }
  }, []);

  const getScheduleListData = () => {
    let executeAppObject = {
      data: {
        logic:
          objData?.logic?.length > 0
            ? objData?.logic
            : [displayData?.componentLogic],
      },
      referenceValue: [{ refValue: displayData?.refValue }],
      app_session_id: appSessionId,
    };

    setScheduleListLoading(true);
    reactAppExecutionVP(executeAppObject, signal)
      .then((response) => {
        if (response.data?.length) {
          const componentData = response?.data.find((data) => {
            return Object.keys(data)?.[0] === displayData?.refValue;
          })?.[displayData.refValue];
          setScheduleListData(componentData);
          let data = cachedData;
          data[component_id] = componentData;
          setCacheData(data);
          setTempStorageCall(false);
        }
        clearFilterApply();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
        setScheduleListLoading(false);
      })
      .finally(() => {
        setScheduleListLoading(false);
      });
  };

  return (
    <Col md={displayData?.columnWidthTable || "12"} className="my-1 px-0">
      <div
        className={`${
          collapse && "h-100"
        } position-relative  main-content bg-white mx-1 mb-2`}
      >
        <div className="py-1 px-1 ">
          <div className="borderBottom">
            <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
              <div className="text-capitalize">
                <h5 className="m-0 table-heading">
                  {displayData?.scheduleTitle}
                </h5>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title="Refresh"
                  onClick={getScheduleListData}
                  aria-controls="chart-toggle"
                >
                  <FontAwesomeIcon icon={"refresh"} className={`arrow-icon`} />
                </button>
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title={collapse ? "Collapse" : "Expand"}
                  onClick={() => setCollapse(!collapse)}
                  aria-controls="schedule-toggle"
                  aria-expanded="true"
                >
                  <FontAwesomeIcon
                    icon={"fa fa-angle-down"}
                    className={`arrow-icon ${
                      collapse ? "arrow-icon-up" : "arrow-icon-down"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
          {scheduleListLoading && <AppLoader />}
          <Collapse in={collapse}>
            <div id="schedule-toggle">
              <div className={`scheduleContainer scheduleContainerHeight`}>
                {scheduleListData?.value?.data?.length ? (
                  scheduleListData?.value?.data?.map((scheduleViewData) => {
                    return (
                      <ScheduleView
                        scheduleViewData={scheduleViewData}
                        displayKeys={displayData}
                      ></ScheduleView>
                    );
                  })
                ) : (
                  <div
                    className="text-center no-data "
                    style={{ minHeight: "80px" }}
                  >
                    {!scheduleListLoading && "No data to display"}
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </Col>
  );
};
export default ScheduleListView;

const ScheduleView = ({ scheduleViewData, displayKeys }) => {
  let date = scheduleViewData?.[displayKeys?.scheduleDateKey]?.substring(0, 10);
  const dateFormate = new Date(date);
  const month_MMM = dateFormate.toLocaleString("default", {
    month: "short",
  });
  const dayOfMonth = dateFormate.getDate();
  const isValidDate = (dateString) => {
    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
    return regex.test(dateString);
  };

  return (
    <div className="d-flex mb-1 schedule">
      <div
        className="scheduleDateArea text-white text-center"
        style={{ background: displayKeys?.DatePanelColor }}
      >
        {!isValidDate(date) ? (
          <span style={{ fontSize: "14px" }}>Invalid Date</span>
        ) : (
          <>
            <div className="month">{month_MMM}</div>
            <div className="day">{dayOfMonth}</div>
          </>
        )}
      </div>
      <div className="flex-grow-1 scheduleTextData">
        <div className="title">
          {scheduleViewData?.[displayKeys?.scheduleTitleKey] || (
            <span className="text-danger">
              Invalid&nbsp;
              <span className="font-italic">
                {displayKeys?.scheduleTitleKey}
              </span>
              &nbsp; Key
            </span>
          )}
        </div>
        <div className="description">
          {scheduleViewData?.[displayKeys?.scheduleDescriptionKey] || (
            <span className="text-danger">
              Invalid&nbsp;
              <span className="font-italic">
                {displayKeys?.scheduleDescriptionKey}
              </span>
              &nbsp; Key
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
