import React, { useContext, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Store.scss";
import ReactFileReader from "react-file-reader";
import {
  addStoreDetails,
  cartConfigUpdate,
  fetchInstallAppData,
  getAllStoreComponentPages,
  getCartConfigDetails,
  getLogicsByAppId,
  getStoreDetails,
  updateStoreDetails,
} from "../../../services/appService";
import Loader from "../../dataset/Loader/Loader";
import { toast } from "react-toastify";
import defaultImage from "../../../images/default.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { IconDropdown } from "../../dataset_editor/dataset_structure/display_builder/IconDropdown";
import { RoutesContext } from "../../../RoutesContext";
import { v4 as uuId } from "uuid";

function Store(props) {
  const storeDetails = { store_name: "", storeCode: "", storeDescription: "" };
  const headerData = [
    "Sr.No.",
    "Store Enable",
    "Store Name",
    "Store Code",
    "Store Description",
    "Store Logo",
    "Actions",
  ];
  const commonInputStyle = "col-form-label col-md-4 col-sm-4 required";
  const [showModal, setShowModal] = useState(false);
  const [inputField, setInputField] = useState(storeDetails);
  const [sendFileImage, setFileImage] = useState("");
  const [fileUploadView, setFileUploadView] = useState("");
  const [tableData, setTableData] = useState();
  const [currentRecordId, setCurrentRecordId] = useState("");
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(true);
  const [enableProduct, setEnableProduct] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [totalStoreCount, setTotalStoreCount] = useState(0);
  const [storeComponentPageData, setStoreComponentPageData] = useState([]);

  //Cart Configuration
  const [appOptionList, setAppOptionList] = useState([]);
  const [appOptionLoading, setAppOptionLoading] = useState(false);
  const [logicOptionList, setLogicOptionList] = useState([]);
  const [logicOptionLoading, setLogicOptionLoading] = useState(false);
  const [cartConfig, setCartConfig] = useState({});
  const [cartConfigResponseLoading, setCartConfigResponseLoading] =
    useState(false);
  const { routesData, setRoutesData } = useContext(RoutesContext);

  // call store List Api
  useEffect(() => {
    fetchStoreListingData();
    getStoreComponentList();
    getInstallApps();
    getCartConfigData();
  }, []);

  const handleClose = () => {
    setErrors({});
    setShowModal(false);
  };

  const routeCategoryListing = (id) => {
    props.history.push(`/store/${id}`);
  };

  let count = totalStoreCount - totalCount;
  let message =
    count > 0 ? `You can add ${count} store` : "Please upgrade your plan";

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {message}
    </Tooltip>
  );

  const addNewStore = (e) => {
    if (totalCount < totalStoreCount) {
      e.preventDefault();
      setShowModal(true);
      setInputField(storeDetails);
      setFileImage("");
      setFileUploadView("");
    } else {
      toast.error("Please upgrade your plan");
    }
  };

  const getStoreComponentList = () => {
    let savedStoreApiBody = {
      FilterCriteria: [
        {
          filter_type: "text",
          type: "equal",
          filter: "store_component",
          filter_field: "page_type",
        },
      ],
    };
    getAllStoreComponentPages(savedStoreApiBody).then((res) => {
      if (res.success) {
        setStoreComponentPageData(res.data);
      }
    });
  };

  const fetchStoreListingData = () => {
    setLoader(true);
    getStoreDetails()
      .then((response) => {
        if (response?.data) {
          setTableData(response.data);
          setTotalStoreCount(response?.number_of_store);
          setTotalCount(response?.total_count || 0);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // upload logo functionality
  const uploadLogo = (logoValue) => {
    if (logoValue.fileList[0].size >= 11000000) {
      toast.error("Store Logo Cannot Be More Than 10MB");
    } else {
      setFileImage(`name=${logoValue.fileList[0].name};${logoValue.base64}`);
      setFileUploadView(URL.createObjectURL(logoValue.fileList[0]));
    }
  };

  // on edit pass data to model
  const editDetails = (data) => {
    setCurrentRecordId(data._id.$oid);
    setFileUploadView(data.file[0]);
    setFileImage(data.file[0]);
    setShowModal(true);
    setInputField(data);
    setEnableProduct(data.store_status);
  };

  // input handler on change of field
  const onFormFieldChange = (e) => {
    setInputField((inputField) => ({
      ...inputField,
      [e.target.name]: e.target.value,
    }));
  };

  // form Validation
  const validateFieldForm = () => {
    let fields = inputField;
    let errors = {};
    let formIsValid = true;

    if (!fields.store_name) {
      formIsValid = false;
      errors["store_name"] = "*Please Enter Store Name.";
    }

    if (!fields.storeCode) {
      formIsValid = false;
      errors["storeCode"] = "*Please Enter Store Code.";
    }

    if (!fields.storeDescription) {
      formIsValid = false;
      errors["storeDescription"] = "*Please Enter Store Description.";
    }

    if (fileUploadView === "" || sendFileImage.length <= 0) {
      formIsValid = false;
      errors["logo"] = "*Please upload  a logo";
    }

    setErrors(errors);
    return formIsValid;
  };

  // on checkbox change
  const changeStatus = (data, e) => {
    let checkboxValue = e.target.checked;
    data["store_status"] = checkboxValue;
    let bodyData = {
      fields: data,
      object_id: data._id.$oid,
    };
    delete data["_id"];
    updateStoreDetailsValue(bodyData);
  };

  // on submit pass data to store into db
  const submitDataToDisplay = () => {
    if (validateFieldForm()) {
      setInputField(storeDetails);
      storeDetailsData();
    }
  };

  // api to store data to db
  const storeDetailsData = () => {
    let data = {
      fields: {
        store_name: inputField.store_name,
        storeCode: inputField.storeCode,
        storeDescription: inputField.storeDescription,
        file: [sendFileImage],
        store_status: enableProduct,
        page_id: inputField.page_id,
      },
    };

    if (
      currentRecordId !== "" &&
      currentRecordId !== null &&
      currentRecordId !== undefined &&
      currentRecordId.length
    ) {
      data["object_id"] = currentRecordId;
      updateStoreDetailsValue(data);
    } else {
      addStoreDetails(data)
        .then((response) => {
          if (response) {
            setShowModal(false);
            toast.success("Data Saved Successfully");
            fetchStoreListingData();
          }
        })
        .catch((err) => {
          if (err.response || err.message) {
            toast.error(err.response?.data?.message || err.response?.message || err.message);
            setShowModal(false);
          }
        });
    }
  };

  // update store Details
  const updateStoreDetailsValue = (data) => {
    updateStoreDetails(data)
      .then((response) => {
        if (response) {
          setShowModal(false);
          toast.success("Data Updated Successfully");
          fetchStoreListingData();
          setCurrentRecordId("");
        }
      })
      .catch((err) => {
        if (err.response || err.message) {
          toast.error(err.response?.data?.message || err.response?.message || err.message);
          setShowModal(false);
          setCurrentRecordId("");
        }
      });
  };

  const selectedStorePageHandle = (obj) => {
    setInputField((inputField) => ({
      ...inputField,
      page_id: obj?.value || "",
    }));
  };

  // rendered table heading data
  const renderedHeaderData = headerData.map((header, index) => (
    <th className="table_head text-nowrap" key={index}>
      {header}
    </th>
  ));

  // rendered table body data
  const renderedTableData = tableData?.map((data, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>
        <input
          className="table-checkbox"
          type="checkbox"
          checked={data?.store_status}
          onChange={(e) => {
            changeStatus(data, e);
          }}
        />
      </td>
      <td>{data?.store_name}</td>
      <td>{data?.storeCode}</td>
      <td>{data?.storeDescription}</td>
      <td>
        <div className="store-img-wrapper">
          {data?.file.length ? (
            <img src={data?.file[0]} alt="store-image"></img>
          ) : (
            <img src={defaultImage} alt="store-image" s></img>
          )}
        </div>
      </td>
      <td>
        <div className="d-flex">
          <button
            className="btn btn-md btn-outline-secondary me-3"
            onClick={() => {
              routeCategoryListing(data._id.$oid);
            }}
          >
            Enter Store
          </button>
          <FontAwesomeIcon
            icon={"fa fa-pen-to-square"}
            className="mt-1"
            onClick={() => editDetails(data)}
            title="Edit Store"
            size="xl"
            role="button"
          />
        </div>
      </td>
    </tr>
  ));

  const storePageData = storeComponentPageData?.map((page) => {
    return {
      label: page.function_name,
      value: page._id.$oid,
    };
  });

  const getCartConfigData = () => {
    setLoader(true);
    getCartConfigDetails()
      .then((response) => {
        let [cartDetails] = response?.data || [];
        if (cartDetails) {
          let object_id = cartDetails?._id?.$oid;
          delete cartDetails?._id;
          setCartConfig({ ...cartDetails, object_id });
          getAppLogic(cartDetails?.selectedApp);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getInstallApps = () => {
    setAppOptionLoading(true);
    fetchInstallAppData()
      .then((response) => {
        if (response.success === true) {
          setAppOptionList(
            response.data?.map((app) => {
              return {
                label: app?.app_name,
                value: app?._id?.$oid,
              };
            })
          );
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => {
        setAppOptionLoading(false);
      });
  };

  const changeValue = (e) => {
    let obj = { ...cartConfig };
    obj[e.target?.name] = e.target.value;
    setCartConfig(obj);
  };

  const handleSearchableDropdown = (option, action) => {
    let obj = { ...cartConfig };
    obj[action?.name] = option || "";
    if (action?.name === "selectedApp") {
      if (option) {
        getAppLogic(option);
      } else {
        obj["cartLogic"] = "";
        setLogicOptionList([]);
      }
    }
    setCartConfig(obj);
  };

  const handleToggleAction = (status) => {
    let obj = { ...cartConfig };
    if (status === "yes") {
      obj["hidePriceInformation"] = "";
    } else {
      obj["hidePriceInformation"] = "yes";
    }
    setCartConfig(obj);
  };

  const getAppLogic = (app) => {
    setLogicOptionLoading(true);
    getLogicsByAppId(app?.value)
      .then((response) => {
        if (response.success === true) {
          setLogicOptionList(
            response.data?.map((logic) => {
              return {
                label: logic?.function_name,
                value: logic?._id?.$oid,
              };
            })
          );
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => {
        setLogicOptionLoading(false);
      });
  };

  const updateDetails = () => {
    let objectBody = {
      data: {
        ...cartConfig,
      },
    };
    setCartConfigResponseLoading(true);
    cartConfigUpdate(objectBody)
      .then((response) => {
        toast.success(response?.message);
        setRoutesData({ ...routesData, cartChange: uuId() });
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      })
      .finally(() => {
        setCartConfigResponseLoading(false);
      });
  };

  return (
    <>
      <div className="button-wrapper w-100 d-help py-2">
        <h5 className="mb-0">Stores</h5>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <button
            type="button"
            className="custom-btn m-0 "
            onClick={addNewStore}
          >
            <FontAwesomeIcon icon="fa-solid fa-plus" />
            <span className="ms-1">Add Store</span>
          </button>
        </OverlayTrigger>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div className="table-responsive">
          <table className="table bulk_action sm-tableLayoutInitial bg-white w-100 mb-0">
            <thead>
              <tr className="head-row">{renderedHeaderData}</tr>
            </thead>
            <tbody>{renderedTableData}</tbody>
          </table>
          {tableData?.length ? null : (
            <p className="no_table_records">No Store Available</p>
          )}
        </div>
      )}

      <div className="mt-4 button-wrapper w-100 d-help py-2">
        <h5 className="mb-0">Cart Checkout Settings</h5>
      </div>
      <div className="bg-white p-2 my-2">
        <div className="row">
          <div className="col-sm-6 col-md-4 form-group">
            <div className="">
              <label className="mb-1">Select an App</label>
            </div>
            <div className="">
              <Select
                classNames={{
                  control: (state) => "searchableDropdownControl",
                  menuPortal: () => "searchableDropdownMenuPortal",
                  singleValue: () => "searchableDropdownValue",
                  multiValue: () => "searchableDropdownValue",
                  menuList: () => "searchableDropdownMenuList",
                }}
                isLoading={appOptionLoading}
                name="selectedApp"
                placeholder="Select.."
                value={cartConfig?.selectedApp}
                options={appOptionList}
                isClearable
                onChange={handleSearchableDropdown}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <div className="">
              <label className="mb-1">Select a Logic to run on checkout</label>
            </div>
            <div className="">
              <Select
                classNames={{
                  control: (state) => "searchableDropdownControl",
                  menuPortal: () => "searchableDropdownMenuPortal",
                  singleValue: () => "searchableDropdownValue",
                  multiValue: () => "searchableDropdownValue",
                  menuList: () => "searchableDropdownMenuList",
                }}
                isLoading={logicOptionLoading}
                name="cartLogic"
                placeholder="Select.."
                value={cartConfig?.cartLogic}
                options={logicOptionList}
                isClearable
                onChange={handleSearchableDropdown}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <div>
              <label className="mb-1">Logic Reference <small>(variable name from logic)</small></label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Reference"
                name="logicReference"
                value={cartConfig?.logicReference}
                onChange={changeValue}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <div>
              <label className="mb-1">Checkout Button Name</label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                name="buttonName"
                value={cartConfig?.buttonName}
                onChange={changeValue}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <div>
              <label className="mb-1">Checkout Button Icon</label>
            </div>
            <div>
              <IconDropdown
                name="buttonIcon"
                onChange={handleSearchableDropdown}
                value={cartConfig?.buttonIcon}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <div>
              <label className="mb-1">Hide Pricing Information</label>
            </div>
            <div className="mt-1">
              <FontAwesomeIcon
                size="2xl"
                role="button"
                className={`stauspointer toggleBtn toggleFormBtnSize ms-0 ${
                  cartConfig?.hidePriceInformation === "yes"
                    ? "text-success"
                    : "text-danger"
                }`}
                icon={
                  cartConfig?.hidePriceInformation === "yes"
                    ? "fa fa-toggle-on"
                    : "fa fa-toggle-off"
                }
                aria-hidden="true"
                onClick={() =>
                  handleToggleAction(cartConfig?.hidePriceInformation)
                }
              />
            </div>
          </div>
        </div>
        <div className="text-end">
          <button
            disabled={cartConfigResponseLoading}
            className={`custom-btn m-0 text-nowrap`}
            onClick={updateDetails}
          >
            {cartConfigResponseLoading && (
              <div className="spinner-border spinner-border-sm me-1" />
            )}&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />&nbsp;&nbsp;
            Submit&nbsp;&nbsp;
          </button>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="m-0 fw-bold">Create Store</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="form-group row">
              <label className={commonInputStyle} name="enable_category">
                Store Status
              </label>
              <div className="col-md-8 col-sm-8">
                <FontAwesomeIcon
                  icon={
                    enableProduct === true
                      ? "fa fa-toggle-on"
                      : "fa fa-toggle-off"
                  }
                  role="button"
                  disabled={true}
                  className={`stauspointer toggleFormBtnSize togglebtn ${
                    enableProduct ? "text-success" : "text-danger"
                  }`}
                  aria-hidden="true"
                  name="enable_category"
                  onClick={(e) => setEnableProduct(!enableProduct)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className={commonInputStyle}>Enter Store Name</label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Store Name"
                  name="store_name"
                  value={inputField.store_name}
                  onChange={onFormFieldChange}
                />
                <span className=" text-danger">{errors.store_name}</span>
              </div>
            </div>
            <div className="form-group row">
              <label className={commonInputStyle}>Enter Store Code</label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Store Code"
                  name="storeCode"
                  value={inputField.storeCode}
                  onChange={onFormFieldChange}
                />
                <span className="text-danger">{errors.storeCode}</span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-4 col-sm-4">
                Select Page
              </label>
              <div className="col-md-8 col-sm-8">
                <Select
                  defaultValue={storePageData?.find(
                    (page) => page.value === inputField.page_id
                  )}
                  options={storePageData}
                  isClearable
                  onChange={(e) => {
                    selectedStorePageHandle(e);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className={commonInputStyle}>Store Description</label>
              <div className="col-md-8 col-sm-8">
                <textarea
                  className="form-control"
                  placeholder="Enter Description"
                  name="storeDescription"
                  value={inputField.storeDescription}
                  onChange={onFormFieldChange}
                />
                <span className="text-danger">{errors.storeDescription}</span>
              </div>
            </div>

            <div className="form-group row">
              <label className={commonInputStyle}>Store Logo</label>
              <div className="col-md-8 col-sm-8">
                <div className="d-inline-block">
                  <ReactFileReader
                    handleFiles={uploadLogo}
                    multipleFiles={false}
                    base64={true}
                  >
                    <>
                      <button className="chooseFile-custom-btn">
                        <FontAwesomeIcon icon="fa-solid fa-upload" />
                        <span className="ms-1">Upload Store Logo</span>
                      </button>
                      {fileUploadView ? (
                        <span>
                          <img
                            src={fileUploadView}
                            alt="logo-img"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginLeft: "20px",
                            }}
                          />
                        </span>
                      ) : null}
                    </>
                  </ReactFileReader>
                  <span className="mt-2 text-danger">{errors.logo}</span>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="custom-btn mt-3"
                onClick={submitDataToDisplay}
              >
                &nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />
                <span className="ms-1 ">&nbsp;&nbsp;Submit&nbsp;&nbsp;</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Store;
