import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";

const CustomTextArea = (props) => {
  const { value, onChange, readonly, elementData } = props;
  
  return (
    <InputGroup>
      <FormControl
        as="textarea"
        rows={4}  // Number of rows
        placeholder={elementData.helpText || "Enter text here.."}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        disabled={readonly}
      />
    </InputGroup>
  );
};

export default CustomTextArea;
