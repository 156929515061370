import React, { useContext, useState } from "react";
import { executeAppForTempStorage } from "../../../../services/API";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { inputTypeKey, transition } from "../../../common/model/Model";
import TransitionDisplay from "./TransitionDisplay";
import { TableRowContext } from "../../contexts/TableRowContext";
import { formatCurrency } from "../../../../services/utils";

const LinkingWrap = ({ children, linkData, appSessionId, rowKeyData }) => {
  const { reloadComponentData } = useContext(TableRowContext);
  const history = useHistory();
  const [linkTransitionState, setLinkTransitionState] = useState(false);

  const hideLinkTransition = () => {
    reloadComponentData();
    setLinkTransitionState(false);
  };

  const onLinkingClick = () => {
    let objBody = {
      app_session_id: appSessionId,
      reference_name: linkData.linkingReference || "",
      data: rowKeyData,
    };

    let link;
    if (linkData?.linkAppId && linkData?.linkPageId) {
      link = `/app_ui/${linkData?.linkAppId}/${linkData?.linkPageId}`;
    } else {
      link = `/not-configured`;
    }
    //open the sidebar/popup before calling the logic
    if ([transition.popUp, transition.sideBar].includes(linkData.linkingType)) {
      setLinkTransitionState(true);
    }

    if (
      [transition.anotherApp, transition.anotherPage].includes(
        linkData.linkingType
      )
    ) {
      if (linkData.linkingReference) {
        executeAppForTempStorage(objBody)
          .then((response) => {
            if (response) {
              history.push(link);
            }
          })
          .catch((err) => {
            toast.error(err.response?.data?.message || err.response?.message || err.message);
          });
      } else {
        history.push(link);
      }
    }

    if ([transition.popUp, transition.sideBar].includes(linkData.linkingType)) {
      if (linkData.linkingReference) {
        executeAppForTempStorage(objBody)
          .then((response) => {})
          .catch((err) => {
            toast.error(err.response?.data?.message || err.response?.message || err.message);
          });
      }
    }
  };

  const getInlineStyle = (name) => {
    const matchedTag = linkData?.tags?.find((data) => data.name === name);
    const textColor =
      linkData?.textColor === "Dark" ? "text-secondary" : "text-white";
    if (matchedTag) {
      return (
        <span
          className={textColor}
          style={{
            backgroundColor: matchedTag.color,
            textShadow: "1px 0px 2px #000000",
            padding: "6px",
            borderRadius: "10px",
          }}
        >
          {name}
        </span>
      );
    } else {
      if (linkData.data_type === inputTypeKey.numberTextBox) {
        return linkData?.displayCurrencyFormat
          ? formatCurrency(name, linkData?.displayCurrencyFormat)
          : (name ? name : "0");
      } else {
        return name;
      }
    }
  };

  return (
    <>
      {linkData.linkingType ? (
        children || children?.length ? (
          <span role="button" className="link-primary text-decoration-underline" onClick={onLinkingClick}>
            {getInlineStyle(children)}
          </span>
        ) : (
          ""
        )
      ) : (
        getInlineStyle(children) || "-"
      )}

      {linkData.linkingType && (
        <TransitionDisplay
          appSessionId={appSessionId}
          displayType={linkData.linkingType}
          appId={linkData?.linkAppId}
          flowId={linkData?.linkPageId}
          show={linkTransitionState}
          hideLinkTransition={hideLinkTransition}
        />
      )}
    </>
  );
};

export default LinkingWrap;
