import React, { Component } from "react";
import Loader from "../../dataset/Loader/Loader";
import "./InstalledApp.scss";
import { Link } from "react-router-dom";
import { fetchInstallAppData } from "../../../services/appService";
import { Pagination } from "../../dataset/pagination/Pagination";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class InstalledApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getApplicationHeader: [
        "Sr No",
        "App Name",
        "Version",
        "Created By",
        "Updated By",
        "Logo",
      ],

      getAppsRecord: [],
      totalRecordCount: 0,
      currentPage: 1,
      dataPerPage: 10,
      loading: true,
      errorMsg: false,
    };
  }

  componentWillMount() {
    this.getInstallData();
  }

  receiveCurrentPageNumber = (langValue) => {
    this.setState({ currentPage: langValue });
  };

  getInstallData() {
    this.setState({ loading: true });
    fetchInstallAppData()
      .then((response) => {
        if (response.success === true) {
          this.setState({
            getAppsRecord: response.data,
            totalRecordCount: response.total_count,
            loading: false,
          });
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        });
      });
  }

  render() {
    // Pagination
    const { currentPage, dataPerPage } = this.state;
    const indexOfLastRecord = currentPage * dataPerPage;
    const indexOfFirstRecord = indexOfLastRecord - dataPerPage;
    const currentData = this.state.getAppsRecord.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    const setAppsHeaderData = this.state.getApplicationHeader.map(
      (header, index) => (
        <th className="text-nowrap" key={index}>
          {header}
        </th>
      )
    );

    const setAppsRecordsData = currentData.map((data, index) => (
      <tr key={index}>
        <td>{data.srno}</td>
        <td>{data.app_name}</td>
        <td>{data.app_version}</td>
        <td>{data.created_by}</td>
        <td>{data.updated_by}</td>
        <td>
          {data.data_file ? (
            <div className="appLogo">
              <img className="img-fluid" alt="logo" src={data.data_file}></img>
            </div>
          ) : null}
        </td>
      </tr>
    ));
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div className="col-md-12 col-sm-12 bg-white application_table">
        <div className="x_panel  mb-0 pb-0 border-0">
          <div className="x_content">
            <div className="row">
              <div className="col-md-12 col-sm-12 d-help mb-2">
                <h5 className="m-0 fw-bold">Installed Apps</h5>
                <div className="searchbar_wrapper">
                  <div className="input-wrapper">
                    <FontAwesomeIcon icon={"fa fa-search"} />
                    <input
                      type="text"
                      className="application_search"
                      placeholder="Search"
                    />
                  </div>
                  <Link to={"app-list"}>
                    <button
                      type="button"
                      className="btn btn-success btn-md m-0 me-2"
                    >&nbsp;&nbsp;
                      <FontAwesomeIcon icon="fa-solid fa-cloud-arrow-down" />
                      <span className="ms-1">&nbsp;&nbsp;Install New App&nbsp;&nbsp;</span>
                    </button>
                  </Link>
                  <Link to={"create-app"}>
                    <button
                      type="button"
                      className="btn btn-success btn-md m-0"
                    >
                      &nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-plus" />
                      <span className="ms-1">&nbsp;&nbsp;Create New App&nbsp;&nbsp;</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="card-box">
                  <div
                    id="datatable-checkbox_wrapper"
                    className="dataTables_wrapper container-fluid dt-bootstrap no-footer p-0"
                  >
                    <div className="row">
                      <div className="col-md-12 col-sm-12 set_height table-responsive table-hover">
                        <table
                          
                          className="table  table-bordered bulk_action appsTable no-footer w-100 m-0"
                        >
                          <thead>
                            <tr className="head-row">{setAppsHeaderData}</tr>
                          </thead>

                          <tbody>{setAppsRecordsData}</tbody>
                        </table>
                        {this.state.errorMsg === true ? (
                          <p className="no_table_records">Internal issue ...</p>
                        ) : this.state.errorMsg === false &&
                          this.state.totalRecordCount === 0 ? (
                          <p className="no_table_records">
                            No matching records found
                          </p>
                        ) : null}
                      </div>
                    </div>
                    {this.state.totalRecordCount >= 1 ? (
                      <Pagination
                        sendRowDataLength={this.state.getAppsRecord.length}
                        onSelectLanguage={this.receiveCurrentPageNumber}
                      ></Pagination>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InstalledApp;
