import {
  executeAppForTempStorage,
  executeLogicByName,
} from "../../../services/API";
import { toast } from "react-toastify";

export const handleAutoFillData = (
  searchData,
  readAutoFillReference,
  appSessionId,
  autoFillLogic,
  autoFillReference,
  setAutoFillData,
  setAutoFillLoading
) => {
  const getAutoFillData = () => {
    let obj = {
      app_session_id: appSessionId,
      logic_name: autoFillLogic || "",
      reference_name: autoFillReference || "",
    };
    if (autoFillLogic && autoFillReference) {
      setAutoFillLoading(true);
      executeLogicByName(obj)
        .then((response) => {
          if (response) {
            let [data, _] = response.data.value.data || [];
            if (data) {
              setAutoFillData(data);
            }
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        })
        .finally(() => {
          setAutoFillLoading(false);
        });
    }
  };

  let data = {
    tenant_id: JSON.parse(localStorage.getItem("tenantId")),
    user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
    role_name: JSON.parse(localStorage.getItem("role")),
    sessionid: JSON.parse(localStorage.getItem("session_id")),
    data: searchData,
    reference_name: readAutoFillReference,
    app_session_id: appSessionId,
  };

  if (readAutoFillReference) {
    setAutoFillLoading(true);
    executeAppForTempStorage(data)
      .then((response) => {
        if (response) {
          getAutoFillData();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  } else {
    getAutoFillData();
  }
};
