import { downloadFile } from "./utils";

export async function initWorkflow(model, logicId) {
  const options = {
    method: "POST",
    body: JSON.stringify({
      id: model.options.id,
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
    }),
  };

  return fetchWrapper("/workflow/new", options, logicId);
}
// end

/**
 * Sends request to server via fetch API and handles error cases
 * @param {string} endpoint - server endpoint
 * @param {Object} options - options parameter for `fetch` call
 * @returns {Promise<Object>} - server response or error
 */

export async function getNode(nodeId, logicId) {
  return fetchWrapper(`/node/${nodeId}`, {}, logicId);
}

function fetchWrapper(endpoint, options = {}, logicId = "") {
  return new Promise((resolve, reject) => {
    let new_endpoint = process.env.REACT_APP_VP_URL + endpoint;
    fetch(new_endpoint + "?logic_id=" + logicId, options)
      .then(async (resp) => {
        const data = await resp.json();
        if (resp.ok) {
          return resolve(data);
        } else {
          return reject(data);
        }
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          return reject(err);
        }
      });
  });
}

/**
 * Retrieve node info from server side workflow
 * @param {string} nodeId - ID of node to retrieve
 * @returns {Promise<Object>} - server response (node info and flow variables)
 */

/**
 * Add node to server-side workflow
 * @param {CustomNodeModel} node - JS node to add
 * @returns {Promise<Object>} - server response
 */
export async function addNode(node, logicId) {
  const payload = { ...node.options, options: node.config };
  const options = {
    method: "POST",
    body: JSON.stringify(payload),
  };
  return fetchWrapper("/node/", options, logicId);
}

/**
 * Delete node from server-side workflow
 * @param {CustomNodeModel} node - JS node to remove
 * @returns {Promise<Object>} - server response
 */
export async function deleteNode(node, logicId) {
  const id = node.options.id;
  const options = {
    method: "DELETE",
  };
  const endpoint = node.options.is_global ? "node/global" : "node";
  return fetchWrapper(`/${endpoint}/${id}`, options, logicId);
}

/**
 * Update configuration of node in server-side workflow
 * @param {CustomNodeModel} node - JS node to remove
 * @param {Object} config - configuration from options form
 * @param {Object} flowConfig - flow variable configuration options
 * @returns {Promise<Object>} - server response (serialized node)
 */
export async function updateNode(node, config, flowConfig, logicId) {
  node.config = config;
  node.options.option_replace = flowConfig;
  const payload = { ...node.options, options: node.config };
  const options = {
    method: "POST",
    body: JSON.stringify(payload),
  };
  const endpoint = node.options.is_global ? "node/global" : "node";
  return fetchWrapper(`/${endpoint}/${node.options.id}`, options, logicId);
}

export async function saveNodeData(endpoint, options = {}, logicId = "") {
  return new Promise((resolve, reject) => {
    let new_endpoint = process.env.REACT_APP_VP_URL + endpoint;
    fetch(new_endpoint + "?logic_id=" + logicId, options)
      .then(async (resp) => {
        const data = await resp.json();
        if (resp.ok) {
          return resolve(data);
        } else {
          return reject(data);
        }
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

/**
 * Get available nodes for node menu
 * @returns {Promise<Object>} - server response (node menu items)
 */
export async function getNodes(logicId) {
  const userId = JSON.parse(localStorage.getItem("userid"));
  const sessionId = JSON.parse(localStorage.getItem("session_id"));
  return fetchWrapper(`/workflow/nodes/${userId}/${sessionId}`, {}, logicId);
}

/**
 * Get global flow variables for workflow
 * @returns {Promise<Object>} - server response (global flow variables)
 */
export async function getGlobalVars(logicId) {
  return fetchWrapper("/workflow/globals", {}, logicId);
}

/**
 * Start a new workflow on the server
 * @param {DiagramModel} model - Diagram model
 * @returns {Promise<Object>} - server response
 */

/**
 * Uploads JSON workflow file to server
 * @param {FormData} formData - form with key `file` and value of type `File`
 * @returns {Promise<Object>} - server response (full serialized workflow)
 */
export async function uploadWorkflow(formData, logicId) {
  formData.append("tenant_id", JSON.parse(localStorage.getItem("tenantId")));
  formData.append("user_id", JSON.parse(localStorage.getItem("userid")));
  formData.append("role_name", JSON.parse(localStorage.getItem("role")));
  formData.append("sessionid", JSON.parse(localStorage.getItem("session_id")));
  const options = {
    method: "POST",
    body: formData,
  };
  return fetchWrapper("/workflow/open", options, logicId);
}

/**
 * Execute python code on the server
 * @param {FormData} formData - form with key `file` and value of type `File`
 * @returns {Promise<Object>} - server response (full serialized workflow)
 */
 export async function executeCode(code, logicId) {
  let existingObj = localStorage.getItem("test_session_id");
  if(!existingObj){
    existingObj = {}
  }else{
    existingObj=JSON.parse(existingObj);
  }
  const options = {
    method: "POST",
    body: JSON.stringify({
      code: code,
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      app_session_id: existingObj[logicId] || "",
      logic_id: logicId
    }),
  };

  return fetchWrapper("/workflow/execute_code", options, logicId);
}

async function handleEdge(link, method, logicId) {
  const sourceId = link.getSourcePort().getNode().options.id;
  const targetId = link.getTargetPort().getNode().options.id;

  let endpoint;

  if (link.getSourcePort().options.in) {
    // If edge goes from IN port -> OUT port, reverse the ports
    endpoint = `/node/edge/${targetId}/${sourceId}`;
  } else {
    // Otherwise, keep source -> target edge
    endpoint = `/node/edge/${sourceId}/${targetId}`;
  }

  return fetchWrapper(endpoint, { method: method }, logicId);
}

// RP#01: Custom function
export async function handleEdgeDelete(sourceId, targetId, logicId) {
  const endpoint = `/node/edge/${sourceId}/${targetId}`;
  return fetchWrapper(endpoint, { method: "DELETE" }, logicId);
}

// RP#01: Custom function
export async function handleEdgeAdd(sourceId, targetId, logicId) {
  const endpoint = `/node/edge/${sourceId}/${targetId}`;
  return fetchWrapper(endpoint, { method: "POST" }, logicId);
}

/**
 * Add edge to server-side workflow
 * @param {VPLinkModel} link - JS edge to create
 * @returns {Promise<Object>} - server response
 */
export async function addEdge(link, logicId) {
  return handleEdge(link, "POST", logicId);
}

/**
 * Delete edge from server-side workflow
 * @param {VPLinkModel} link - JS edge to delete
 * @returns {Promise<Object>} - server response
 */
export async function deleteEdge(link, logicId) {
  return handleEdge(link, "DELETE", logicId);
}

/**
 * Upload a data file to be stored on the server
 * @param {FormData} formData - FormData with file and nodeId
 * @returns {Promise<Object>} - server response
 */
export async function uploadDataFile(formData, logicId) {
  const options = {
    method: "POST",
    body: formData,
  };
  return fetchWrapper("/workflow/upload", options, logicId);
}

/**
 * Download file by name from server
 * @param {CustomNodeModel} node - node containing file to download
 * @returns {Promise<void>}
 */
export async function downloadDataFile(node, logicId) {
  // TODO: make this not a giant security problem
  let contentType;

  const payload = { ...node.options, options: node.config };

  // can't use fetchWrapper because it assumes JSON response
  fetch(`/workflow/download?logic_id=${logicId}`, {
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject(await resp.json());
      contentType = resp.headers.get("content-type");
      let filename = resp.headers.get("Content-Disposition");

      if (contentType.startsWith("text")) {
        resp.text().then((data) => {
          downloadFile(data, contentType, filename);
        });
      }
    })
    .catch((err) => { });
}

/**
 * Get execution order of nodes in graph
 * @returns {Promise<Object>} - server response (array of node IDs)
 */
export async function executionOrder(headerData, logicId) {
  let endpoint = "/workflow/execute";
  const options = {
    method: "POST",
    body: JSON.stringify(headerData),
  };
  return fetchWrapper(endpoint, options, logicId);
}

/**
 * RP#01: Custom function
 * @returns All edges in a workflow/graph
 */
export async function getAllWorkflowEdges(logicId) {
  const endpoint = `/node/edge/all`;
  return fetchWrapper(endpoint, { method: "GET" }, logicId);
}

/**
 * RP#01: Custom function
 * @returns All nodes in a workflow/graph
 */
export async function getAllWorkflowNodes(logicId) {
  const endpoint = `/node/node/all`;
  return fetchWrapper(endpoint, { method: "GET" }, logicId);
}

/**
 * Execute given node on server
 * @param {CustomNodeModel }node - node to execute
 * @returns {Promise<Object>} - server response
 */
export async function execute(body, logicId) {
  const id = body.node.options.id;
  const test_session_id = body.test_session_id;
  const user_id = JSON.parse(localStorage.getItem("userid"));
  const session_id = JSON.parse(localStorage.getItem("session_id"));
  return fetchWrapper(
    `/node/${id}/${test_session_id}/${user_id}/${session_id}/execute`, {}, logicId
  );
}

/**
 * Retrieves the data at the state of the specified node
 * @param {string }nodeId - node identifier for an execution state
 * @returns {Promise<Object>} - json respnse with the data at specified state
 */
export async function retrieveData(nodeId, logicId) {
  return fetchWrapper(`/node/${nodeId}/retrieve_data`, {}, logicId);
}

export async function reactAppExecutionVP(headerData, signal, logicId) {
  let object = {
    ...headerData,
    tenant_id: JSON.parse(localStorage.getItem("tenantId")),
    user_id: JSON.parse(localStorage.getItem("userid")),
    sessionid: JSON.parse(localStorage.getItem("session_id")),
    role_name: JSON.parse(localStorage.getItem("role")),
  };
  let endpoint = "/executeapp/";
  const options = {
    method: "POST",
    body: JSON.stringify(object),
    signal: signal,
  };
  return fetchWrapper(endpoint, options, logicId);
}

export async function reactAppFormExecutionVP(headerData, logicId) {
  let object = {
    ...headerData,
    tenant_id: JSON.parse(localStorage.getItem("tenantId")),
    user_id: JSON.parse(localStorage.getItem("userid")),
    sessionid: JSON.parse(localStorage.getItem("session_id")),
    role_name: JSON.parse(localStorage.getItem("role")),
  };
  let endpoint = "/submit_form/";
  const options = {
    method: "POST",
    body: JSON.stringify(object),
  };
  return fetchWrapper(endpoint, options, logicId);
}

export async function callVPApiRedis(headerData, logicId) {
  let endpoint = "/workflow/upload_to_redis";
  const options = {
    method: "POST",
    body: JSON.stringify(headerData),
  };
  return fetchWrapper(endpoint, options, logicId);
}

export async function executeAppForTempStorage(headerData, logicId) {
  let object = {
    ...headerData,
    tenant_id: JSON.parse(localStorage.getItem("tenantId")),
    user_id: JSON.parse(localStorage.getItem("userid")),
    sessionid: JSON.parse(localStorage.getItem("session_id")),
    role_name: JSON.parse(localStorage.getItem("role")),
  };
  let endpoint = "/executeappfortemporarystorage/";
  const options = {
    method: "POST",
    body: JSON.stringify(object),
  };
  return fetchWrapper(endpoint, options, logicId);
}

export async function executeAppForActions(headerData, logicId) {
  let object = {
    ...headerData,
    tenant_id: JSON.parse(localStorage.getItem("tenantId")),
    user_id: JSON.parse(localStorage.getItem("userid")),
    sessionid: JSON.parse(localStorage.getItem("session_id")),
    role_name: JSON.parse(localStorage.getItem("role")),
  };
  let endpoint = "/executeappforactions/";
  const options = {
    method: "POST",
    body: JSON.stringify(object),
  };
  return fetchWrapper(endpoint, options, logicId);
}

export async function importDataForTable(data, logicId) {
  let endpoint = "/import_data/";
  const options = {
    method: "POST",
    body: data,
  };
  return fetchWrapper(endpoint, options, logicId);
}

export async function getRedisKeys(logicId) {
  let endpoint = "/workflow/get_redis_keys";
  const options = {
    method: "POST",
    body: JSON.stringify({
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
    }),
  };
  return fetchWrapper(endpoint, options, logicId);
}

export async function uploadAppSession(body, logicId) {
  let endpoint = "/workflow/upload_to_app_session";
  const options = {
    method: "POST",
    body: JSON.stringify(body),
  };
  return fetchWrapper(endpoint, options, logicId);
}

export async function deleteAppSessionId(body, logicId) {
  let endpoint = "/workflow/delete_app_session";
  const options = {
    method: "POST",
    body: JSON.stringify(body),
  };
  return fetchWrapper(endpoint, options, logicId);
}

export async function subMitFormData(logicId) {
  let endpoint = "/submit_form/";
  const options = {
    method: "POST",
    body: JSON.stringify({
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
    }),
  };
  return fetchWrapper(endpoint, options, logicId);
}

export async function tablePagination(option) {
  let endpoints = "/workflow/pagination";
  let options = {
    method: "POST",
    body: JSON.stringify(option),
  };
  return fetchWrapper(endpoints, options);
}

export async function sendNodeResponseData(body) {
  let endpoint = "/get_logic_json/";
  const options = {
    method: "POST",
    body: JSON.stringify(body),
  };
  return fetchWrapper(endpoint, options);
}

export async function clearFlowVars(logic_id) {
  let endpoint = "/workflow/clear_flow_vars";
  const options = {
    method: "POST",
    body: JSON.stringify({
      logic_id: logic_id,
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
    }),
  };
  return fetchWrapper(endpoint, options, logic_id);
}

export async function getFlowRecordDataById(data, logic_id) {
  let endpoint = `/workflow/fetch_app_logic`;
  if (data.id) {
    let urlName = data.dataset_name;
    const options = {
      method: "POST",
      body: JSON.stringify({
        id: data?.id,
        dataset_name: urlName,
        tenant_id: JSON.parse(localStorage.getItem("tenantId")),
        user_id: JSON.parse(localStorage.getItem("userid")),
        sessionid: JSON.parse(localStorage.getItem("session_id")),
      }),
    };
    return fetchWrapper(endpoint, options, logic_id);
  } else {
    const options = {
      method: "POST",
      body: JSON.stringify({
        id: data,
        tenant_id: JSON.parse(localStorage.getItem("tenantId")),
        user_id: JSON.parse(localStorage.getItem("userid")),
        sessionid: JSON.parse(localStorage.getItem("session_id")),
      }),
    };

    return fetchWrapper(endpoint, options);
  }
}

export async function getLastNodeData(appSessionId, logicName, logicId) {
  let endpoint = `/fetch_last_node_data/`;
  const options = {
    method: "POST",
    body: JSON.stringify({
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      role_name: JSON.parse(localStorage.getItem("role")),
      app_session_id: appSessionId,
      logic_name: logicName,
    }),
  };
  return fetchWrapper(endpoint, options, logicId);
}

export async function downloadXmlData(appSessionId, logicName, sysFilter) {
  let endpoint = `/fetch_last_node_data/`;
  const options = {
    method: "POST",
    body: JSON.stringify({
      sysFilter: sysFilter,
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      role_name: JSON.parse(localStorage.getItem("role")),
      app_session_id: appSessionId,
      logic_name: logicName,
    }),
  };
  return fetchWrapper(endpoint, options);
}

export async function executeLogicByName(payload) {
  let endpoint = `/fetch_data_by_ref_key/`;
  const options = {
    method: "POST",
    body: JSON.stringify({
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      role_name: JSON.parse(localStorage.getItem("role")),
      app_session_id: payload.app_session_id,
      logic_name: payload.logic_name,
      reference_name: payload.reference_name,
    }),
  };
  return fetchWrapper(endpoint, options);
}
