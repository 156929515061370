import React, { useEffect, useState, useContext } from "react";
import {
  executeAppForTempStorage,
  executeLogicByName,
  getLastNodeData,
} from "../../../../../services/API";
import { FormContext } from "../../../contexts/FormContext";
import { toast } from "react-toastify";
import "./CalculatedField.scss";
import AppLoader from "../../../../dataset/Loader/AppLoader";

const CalculatedField = (props) => {
  const { formData, setFormData, uuidKeyName, setuuidKeyName } =
    useContext(FormContext);
  const [detailData, setDetailData] = useState([]);
  const [isDataLoading, setDataLoading] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);

  let payloadForTempStorage = {
    tenant_id: JSON.parse(localStorage.getItem("tenantId")),
    user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
    role_name: JSON.parse(localStorage.getItem("role")),
    sessionid: JSON.parse(localStorage.getItem("session_id")),
    data: {},
    reference_name: props?.field?.dependentReference,
    app_session_id: props?.appSessionId,
  };

  const dependentFields = props?.field?.depends_on_multiple;

  useEffect(() => {
    let data = { ...props?.mainFormData };
    const detailData =
      data[props?.fieldData?.name][props.index][props?.field?.name];
    setDetailData(detailData);
  }, []);

  useEffect(() => {
    let timeoutId;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      let dependentFieldsKeys = dependentFields.map((field) => field?.value);
      if (
        dependentFieldsKeys.some((keyname) => uuidKeyName.includes(keyname)) &&
        checkForValues(dependentFieldsKeys)
      ) {
        getCalculatedFieldData();
      }
    }, 1200);
    return () => clearTimeout(timeoutId);
  }, [formData]);

  const checkForValues = (dependentFieldsKeys) =>
    dependentFieldsKeys?.every((field) => {
      const value = formData[field];
      if (typeof value === "string") {
        return value.trim() !== "";
      } else if (typeof value === "number") {
        return !isNaN(value);
      } else if (typeof value === "object" && value !== null) {
        return Object.keys(value).length > 0;
      } else if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== undefined && value !== null;
    });

  const setDataInParentState = (detailData) => {
    let thisFormData = {
      ...formData,
      [props?.field?.name]: detailData,
    };
    setFormData(thisFormData);
    let data = { ...props?.mainFormData };
    data[props?.fieldData?.name][props.index] = thisFormData;
    props?.setmainFormData(data);
  };

  const getCalculatedFieldData = () => {
    let data = props?.mainFormData;
    data = { ...data, [props?.fieldData?.name]: formData };
    payloadForTempStorage.data = data;
    setDataLoading(true);
    executeAppForTempStorage(payloadForTempStorage)
      .then((res) => {
        if (res) {
          let obj = {
            app_session_id: props?.appSessionId,
            logic_name: props?.field?.logic_to_call || "",
            reference_name: props?.field?.calculationReference,
          };
          executeLogicByName(obj)
            .then((res) => {
              if(res)
              {
                let [data, _] = res.data.value.data || [];
                setDetailData(data);
                setDataInParentState(data);
              }
            })
            .catch((err) => {
              toast.error(err.response?.data?.message || err.response?.message || err.message);
            })
            .finally(() => {
              setDataLoading(false);
            });
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => {
        setuuidKeyName("");
      });
  };

  const editCalculatedFieldData = () => {
    let data = props?.mainFormData;
    data = { ...data, [props?.fieldData?.name]: formData };
    payloadForTempStorage.data = data;
    payloadForTempStorage.reference_name =
      props?.field?.calculationEditReference;
    let logic = props?.field?.logic_for_calculation;
    let app_session_id = props?.appSessionId;

    setDataLoading(true);
    setIsSavingData(true);
    executeAppForTempStorage(payloadForTempStorage)
      .then((res) => {
        if (res) {
          getLastNodeData(app_session_id, logic)
            .then((res) => {
              setDetailData(res?.data?.value[0]);
              setDataInParentState(res?.data?.value[0]);
            })
            .catch((err) => {
              toast.error(err.response?.data?.message || err.response?.message || err.message);
            });
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => {
        setIsSavingData(false);
        setDataLoading(false);
        setuuidKeyName("");
      });
  };

  const handleDetails = (index, key, value) => {
    const newData = [...detailData];
    newData[index][key] = value;
    setDetailData(newData);
  };

  const saveDetails = () => {
    let thisFormData = { ...formData, [props?.field?.name]: detailData };
    setFormData(thisFormData);
    let data = { ...props?.mainFormData };
    data[props?.fieldData?.name][props?.index] = thisFormData;
    props?.setmainFormData(data);
    editCalculatedFieldData();
  };

  return (
    <div className="position-relative rounded">
      <div className="details bg-white">
        {isDataLoading && <AppLoader />}
        {detailData?.length > 0 ? (
          <>
            <table className="table rounded border-bottom p-0 border">
              <thead>
                <tr>
                  {Object.keys(detailData[0]).map((key, i) => (
                    <th className="" key={i}>
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {detailData?.map((item, index) => (
                  <tr key={index}>
                    {Object.entries(item).map(
                      ([key, value], i) =>
                        index !== detailData.length - 1 && (
                          <td key={i}>
                            {i === 0 ? (
                              <span>{value}</span>
                            ) : (
                              <input
                                type="text"
                                className="form-control form-control-sm details_table_input"
                                id={key}
                                value={value}
                                onChange={(e) =>
                                  handleDetails(index, key, e.target.value)
                                }
                              />
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="row px-3 ">
              {Object.entries(detailData[detailData.length - 1]).map(
                ([key, value], i) => (
                  <div
                    key={i}
                    className="col-sm-6 col-md-2 p-2 text-left"
                  >
                    <label className="control-label text-left">
                      {key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}:
                    </label>
                    <span className="ms-3">{value}</span>
                  </div>
                )
              )}
              {detailData.length > 0 &&
                props?.field?.calculationEditReference &&
                props?.field?.logic_for_calculation && (
                  <div className="border-top mt-3">
                    <button
                      className="btn btn-md btn-success px-3 float-end mt-2 mb-2 mx-0"
                      onClick={saveDetails}
                    >
                      {isSavingData ? (
                        <>
                          <span className="spinner-border spinner-border-sm text-light me-2 "></span>
                          Saving
                        </>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                )}
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center set_height border ">
            {!isDataLoading ? "No data" : ""}
          </div>
        )}
      </div>
    </div>
  );
};

export default CalculatedField;
