import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import CustomNodeModel from './CustomNodeModel';
import CustomNodeWidget from './CustomNodeWidget';

export default class CustomNodeFactory extends AbstractReactFactory {
    constructor(logic_id) {
        super('custom-node');
        this.logic_id = logic_id;
    }

    generateModel(event) {
        return new CustomNodeModel(event.initialConfig.options, event.initialConfig.config);
    }

    generateReactWidget(event) {
        return <CustomNodeWidget engine={this.engine} node={event.model} logic_id={this.logic_id} />;
    }
}
