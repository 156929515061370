import React from "react";
import { Modal } from "react-bootstrap";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import {
  saveModalData,
  getRecordDataById,
} from "../../../services/tableConfigService";
import "./ModalForm.scss";
import { toast } from "react-toastify";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ModalForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      schema: {},
      uiSchema: {},
      show: false,
      isResponseLoading: false,
      errorMessage: "",
      widgets: {},
    };
  }

  handleShow = async () => {
    await this.props.itemId;
    this.setState({ show: true });
    this.getRecordById();
    this.createJsonForm();
  };

  textareaHandler = (e, key) => {
    let state = this.state.formData;
    state[key] = e;
    this.setState({ formData: state });
  };

  createJsonForm() {
    let formJsonArr = {};
    let formUISchemaArr = {};
    let uijsonSchema = {};
    let requiredFields = [];
    let objtoAdd1 = {};
    let widgets = {};
    let widget = {};
    this.props.sendHeaderData.forEach((element) => {
      if (element.data_type === "string" || element.data_type === "number") {
        objtoAdd1 = {
          [element.name]: {
            type: element.data_type,
            title: element.display_name,
            minimum: 0,
          },
        };
      }
      if (element.data_type === "largetext") {
        const modules = {
          toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["link", "image", "video"],
            ["clean"],
          ],
        };
        let name = element.name;
        objtoAdd1 = {
          [element.name]: {
            type: "string",
            title: element.display_name,
          },
        };

        widget = {
          [name]: (props) => {
            return (
              <ReactQuill
                modules={modules}
                theme="snow"
                value={this.state.formData[element.name]}
                onChange={(e) => this.textareaHandler(e, element.name)}
              />
            );
          },
        };

        uijsonSchema = {
          [element.name]: {
            "ui:widget": name,
          },
        };
      }

      if (element.data_type === "boolean") {
        let boolEnum = [];
        let mainData = element.enter_values;
        let boolData = mainData.split(",");
        boolData.forEach(function (obj) {
          boolEnum.push(obj);
        });
        objtoAdd1 = {
          [element.name]: {
            type: "array",
            title: element.display_name,
            items: {
              type: "string",
              enum: boolEnum,
            },
            uniqueItems: true,
          },
        };

        uijsonSchema = {
          [element.name]: {
            "ui:widget": "checkboxes",
            "ui:options": {
              inline: true,
            },
          },
        };
      }

      if (element.data_type === "string[]") {
        let globArr = [];
        let arr =
          element.enter_values && element.enter_values
            ? element.enter_values
            : [];
        let finalArrData = arr && arr.length ? arr.split(",") : [];
        finalArrData.forEach(function (obj) {
          globArr.push(obj);
        });
        objtoAdd1 = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            enum: finalArrData,
          },
        };

        uijsonSchema = {
          [element.name]: {
            "ui:widget": "select",
          },
        };
      }

      if (element.data_type === "dateTime") {
        objtoAdd1 = {
          [element.name]: {
            title: element.display_name,
            type: "string",
            format: "date",
          },
        };
      }

      if (element.data_type === "radio_button") {
        let boolEnum = [];
        let mainData = element.enter_values;
        let boolData = mainData.split(",");
        boolData.forEach(function (obj) {
          boolEnum.push(obj);
        });
        objtoAdd1 = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            enum: boolEnum,
            default: element.default_value || "",
          },
        };

        uijsonSchema = {
          [element.name]: {
            "ui:widget": "radio",
            "ui:options": {
              inline: true,
            },
          },
        };
      }

      if (element.is_required === true || element.is_required === "true") {
        requiredFields.push(element.name);
      }
      formUISchemaArr = { ...formUISchemaArr, ...uijsonSchema };
      formJsonArr = { ...formJsonArr, ...objtoAdd1 };
      widgets = { ...widgets, ...widget };
    });
    this.setState({
      schema: {
        type: "object",
        properties: formJsonArr,
        required: requiredFields,
      },
      uiSchema: formUISchemaArr,
      widgets: widgets,
    });
  }

  getRecordById() {
    this.setState({
      isResponseLoading: true,
    });
    if (this.props.itemId === "new_modal") {
      this.setState({
        formData: [],
        isResponseLoading: false,
      });
    } else {
      getRecordDataById(this.props.itemId, this.props.modalDatasetId).then(
        (response) => {
          if (Object.keys(response.data).length) {
            this.props.sendHeaderData.map((element) => {
              if (element.data_type === "dateTime") {
                response.data[element.name] = moment(
                  response.data[element.name]
                ).format("YYYY-MM-DD");
              }
            });
          }
          this.setState({
            formData: response.data,
            initialFormData: response.data,
            isResponseLoading: false,
          });
        }
      );
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  //reset form handler
  formResetHandle = () => {
    this.setState({ formData: [] });
  };

  setFormData = (data) => {
    this.setState((this.state.formData = data));
  };

  saveData = () => {
    const { initialFormData, formData } = this.state;
  
    // Compare formData with initialFormData and find changed fields
    const changedFields = Object.keys(formData).reduce((acc, key) => {
      if (JSON.stringify(formData[key]) !== JSON.stringify(initialFormData[key])) {
        acc[key] = formData[key]; // Store only changed values
      }
      return acc;
    }, {});
  
    if (Object.keys(changedFields).length === 0) {
      toast.info("No changes detected");
      return;
    }
  
    let Obj = {
      data: [{ ...changedFields, _id: this.props.itemId !== "new_modal" ? this.props.itemId : undefined }]
    };
  
    this.setState({ isResponseLoading: true });
  
    saveModalData(Obj)
      .then((response) => {
        if (!response.success) {
          this.setState({ errorMessage: response.message });
          toast.error(response?.data?.message || response?.message);
        } else {
          this.setState({ formData: { ...formData }, initialFormData: { ...formData } }); // Update initial formData after save
          this.props.onSelectLanguage();
          toast.success("Data Saved Successfully");
        }
      })
      .catch((err) => {
        this.setState({ errorMessage: err.response?.data?.message || err.response?.message || err.message });
        if (this.state.errorMessage) {
          toast.error(this.state.errorMessage);
        }
      })
      .finally(() => {
        this.setState({ isResponseLoading: false });
      });
  
    this.handleClose();
  };
  render() {
    return (
      <div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemId === "new_modal"
                ? "Add Details"
                : "Edit Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              validator={validator}
              showErrorList={false}
              schema={this.state.schema}
              uiSchema={this.state.uiSchema}
              formData={this.state.formData}
              onChange={(e) => this.setFormData(e.formData)}
              onSubmit={this.saveData}
              widgets={this.state.widgets}
            >
              <div className="text-end">
                <button
                  className="custom-btn mx-1"
                  type="submit"
                  disabled={this.state.isResponseLoading}
                >
                  <div className="modal-form">
                    {this.state.isResponseLoading ? (
                      <div className="spinner-border text-light" />
                    ) : (
                      <FontAwesomeIcon icon="fa-solid fa-check-double" />
                    )}
                    <span className="ms-1">
                      {this.props.itemId === "new_modal" ? "Submit" : "Update"}
                    </span>
                  </div>
                </button>

                <button
                  className="cancel-btn"
                  type="button"
                  data-dismiss="modal"
                  onClick={this.handleClose}
                >
                  <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                  <span className="ms-1">Cancel</span>
                </button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default ModalForm;
