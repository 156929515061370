import Workspace from "./Workspace";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import { saveAppResonseByJson } from "../../../../services/datasetService";
import { checkSameValueData } from "../../../../services/appService";
import { getRecordDataById } from "../../../../services/tableConfigService";

import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class visualProgramming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: {
        name: "",
        description: "",
        hideFlow: false,
      },
      visual_id: "",
      errors: {},
      appId: "",
      errorMessage: "",
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
      isSavingLogic: false,
    };
  }

  componentWillMount() {
    this.getPropsData();
  }

  getPropsData() {
    if (this.props.match.params.id !== undefined) {
      this.setState(
        {
          visual_id: this.props.match.params.id,
        },
        () => {
          if (
            this.props.match.path === "/app_logic/:id" ||
            this.props.match.path === "/app_flow/:id"
          ) {
            this.loadEditData();
          }
        }
      );
    }
    if (
      this.props.match.path === "/add_app_logic/:appId" ||
      this.props.match.path === "/add_app_flow/:appId"
    ) {
      this.setState({
        appId: this.props.match.params.appId,
      });
    }
  }

  loadEditData() {
    let objData = {
      tenant_id: this.state.tenant_Id,
      dataset_name:
        this.props.match.path === "/app_logic/:id"
          ? "fc_app_logics"
          : "fc_app_flows",
      id: this.state.visual_id,
    };
    getRecordDataById(objData)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            field: {
              name: response.data.function_name,
              description: response.data.description,
              hideFlow:
                response.data?.hideFlow === undefined
                  ? false
                  : response.data?.hideFlow,
            },
            appId: response.data.app_id,
            // getJsonValue: JSON.parse(response.data.function_json),
          });
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  }

  changeValue(event) {
    let fields = this.state.field;
    fields[event.target.name] = event.target.value;
    this.setState({
      fields,
    });
  }
  // validate form date
  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "*Please enter name.";
    }
    if (!fields["description"]) {
      formIsValid = false;
      errors["description"] = "*Please enter description";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  submitAddDetails(event) {
    event.preventDefault();
    if (this.validateFieldForm()) {
      this.setState({ isSavingLogic: true });
      let objData = {
        dataset_name:
          this.props.match.path === "/add_app_logic/:appId" ||
          this.props.match.path === "/app_logic/:id"
            ? "fc_app_logics"
            : "fc_app_flows",
        fields: {
          function_key: "function_name",
          function_value: this.state.field.name,
          app_id: this.state.appId,
          object_id: this.state.visual_id,
        },
      };

      checkSameValueData(objData)
        .then((response) => {
          if (response.data) {
            let errorMessage =
              this.props.match.path === "/add_app_logic/:appId" ||
              this.props.match.path === "/app_logic/:id"
                ? "Logic"
                : "Flow";
            toast.error(`${errorMessage} name already exist.`);
          } else {
            let obj = {
              tenant_id: this.state.tenant_Id,
              dataset_name:
                this.props.match.path === "/add_app_logic/:appId" ||
                this.props.match.path === "/app_logic/:id"
                  ? "fc_app_logics"
                  : "fc_app_flows",
              object_id:
                this.state.visual_id === "" ? "" : this.state.visual_id,
              fields: {
                function_name: this.state.field.name,
                description: this.state.field.description,
                app_id: this.state.appId,
              },
            };
            if (
              this.props.match.path === "/add_app_flow/:appId" ||
              this.props.match.path === "/app_flow/:id"
            ) {
              obj.fields["hideFlow"] = this.state.field.hideFlow;
            }
            saveAppResonseByJson(obj)
              .then((response) => {
                if (response.success === true) {
                  this.setState({
                    objId: response.object_id,
                    isSavingLogic: false,
                  });
                  if (
                    this.props.match.path === "/add_app_logic/:appId" ||
                    this.props.match.path === "/app_logic/:id"
                  ) {
                    this.setState({visual_id:response.object_id},()=>{
                      this.props.history.push(
                        `/app_logic/logic_editor/${response.object_id}`
                      );
                    })
                  }
                  if (
                    this.props.match.path === "/add_app_flow/:appId" ||
                    this.props.match.path === "/app_flow/:id"
                  ) {
                    this.setState({visual_id:response.object_id},()=>{
                      this.props.history.push(
                        `/app_flow/flow_builder/${response.object_id}`
                      );
                   })
                  }
                }
              })
              .catch((err) => {
                this.setState({
                  errorMessage: err.response?.data?.message || err.response?.message || err.message,
                  isSavingLogic: false,
                });
                if (this.state.errorMessage) {
                  toast.error(this.state.errorMessage);
                }
              });
          }
        })
        .catch((err) => {});
    }
  }

  render() {
    return (
      <div>
        {this.props.match.path === "/app_logic/logic_editor/:id" ||
        this.props.match.path === "/app_flow/flow_builder/:id" ? (
          <Workspace
            app_Data={this.props.match.path}
            objId={this.state.visual_id}
          />
        ) : (
          <div>
            <div className="d-help py-2">
              {this.props.match.path === "/add_app_logic/:appId" ||
              this.props.match.path === "/app_logic/:id" ? (
                <h5>
                  {this.props.match.path === "/app_logic/:id"
                    ? "Edit Logic"
                    : "Add Logic"}
                </h5>
              ) : (
                <h5>
                  {this.props.match.path === "/app_flow/:id"
                    ? "Edit Flow"
                    : "Add Flow"}
                </h5>
              )}

              <button
                type="button"
                className="btn btn-secondary btn-sm m-0"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <FontAwesomeIcon icon="fa fa-arrow-left" />
                <span className="ms-1">Back</span>
              </button>
            </div>
            <div className="x_panel p-2">
              <div>
                <div className="x_content">
                  <div className="form-group row">
                    <label className="col-form-label col-md-3 col-sm-3 required">
                      Name
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        name="name"
                        value={this.state.field.name}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      />
                      <div className="errorMsg text-danger">
                        {this.state.errors.name}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-3 col-sm-3 required">
                      Description
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Description"
                        name="description"
                        value={this.state.field.description}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      />
                      <div className="errorMsg text-danger">
                        {this.state.errors.description}
                      </div>
                    </div>
                  </div>
                  {this.props.match.path === "/add_app_flow/:appId" ||
                  this.props.match.path === "/app_flow/:id" ? (
                    <div className="form-group row">
                      <label className="col-form-label col-md-3 col-sm-3 col-3">
                        Hide Flow
                      </label>
                      <div className="col-md-9 col-sm-9 col-9">
                        <div className="d-flex align-items-center checkboxtContainer">
                          <input
                            type="checkbox"
                            className="checkboxHeight"
                            name="hideFlow"
                            checked={this.state.field?.hideFlow}
                            onChange={(e) => {
                              this.setState({
                                field: {
                                  ...this.state.field,
                                  hideFlow: e.target.checked,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="float-end">
                    <button
                      onClick={(event) => this.submitAddDetails(event)}
                      type="submit"
                      className="custom-btn m-0"
                    >
                      <div className="vpFormSubmitSpinner">
                        {this.state.isSavingLogic && (
                          <div className="spinner-border text-light"></div>
                        )}&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />&nbsp;&nbsp;
                        <span className="ms-1">&nbsp;&nbsp;Save & Next&nbsp;&nbsp;</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default visualProgramming;
