import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import dummyImage from "../../images/dummy-image.jpg";
import {
  addToCart,
  addToCartList,
  clearAddToCartList,
  getCartConfigDetails,
} from "../../services/appService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "../dataset/Loader/Loader";
import "../viewCart/viewCart.scss";
import { Modal } from "react-bootstrap";
import { RoutesContext } from "../../RoutesContext";
import { v4 as uuId } from "uuid";
import { executeAppForActions, uploadAppSession } from "../../services/API";

const ViewCart = () => {
  const history = useHistory();
  const { routesData, setRoutesData } = useContext(RoutesContext);
  const [viewCartList, setViewCartList] = useState({});
  const [isCartLoading, setIsCartLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [cartConfig, setCartConfig] = useState({});
  const [isResponseLoading, setIsResponseLoading] = useState(false);

  useEffect(() => {
    setIsCartLoading(true);
    getAllCartItems();
    getCartConfigData();
  }, []);

  const getAllCartItems = () => {
    addToCartList()
      .then((response) => {
        if (response?.success) {
          let cartData = response?.data;
          setViewCartList(cartData);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      })
      .finally(() => {
        setIsCartLoading("");
      });
  };

  const cartClick = (singleCart, quantity, status) => {
    if (quantity === 0) {
      setDeleteModalState(true);
      setSelectedProduct(singleCart);
    } else {
      cartClickHandle(singleCart, quantity, status);
    }
  };

  const cartClickHandle = (singleCart, quantity, status) => {
    let body = {
      productData: [
        {
          product_id: singleCart?._id?.$oid,
          quantity: Number(quantity),
        },
      ],
    };
    setIsCartLoading(singleCart?._id?.$oid);
    addToCart(body)
      .then((response) => {
        toast.success(response?.message);
        getAllCartItems();
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
        setIsCartLoading("");
      })
      .finally(() => {
        handleModalClose();
        setRoutesData({ ...routesData, cartChange: uuId() });
      });
  };

  const handleModalClose = () => {
    setDeleteModalState(false);
    setSelectedProduct({});
  };

  const getCartConfigData = () => {
    getCartConfigDetails()
      .then((response) => {
        let [cartDetails] = response?.data || [];
        if (cartDetails) {
          let object_id = cartDetails?._id?.$oid;
          delete cartDetails?._id;
          setCartConfig({ ...cartDetails, object_id });
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      })
      .finally(() => {
        // setLoader(false);
      });
  };

  const cartButtonClick = () => {
    let body = {
      app_id: cartConfig?.selectedApp?.value,
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
    };
    setIsResponseLoading(true);
    uploadAppSession(body)
      .then((response) => {
        executeButtonAction(response?.data);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  };

  const executeButtonAction = (appSessionId) => {
    let objectBody = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      app_session_id: appSessionId,
      logic_name: cartConfig?.cartLogic?.label,
      reference_name: cartConfig?.logicReference,
      data: [
        {
          total_amount: viewCartList?.total,
          sub_total: viewCartList?.sub_total,
          tax_amount: viewCartList?.tax_amount,
          user_id: JSON.parse(localStorage.getItem("userid")),
          particular: viewCartList?.product_data?.map((productData) => {
            return {
              item_code: productData?.product?.sku_id,
              item: productData?.product?.product_name,
              description: productData?.product?.product_description,
              quantity: productData?.quantity,
              amount: productData?.product?.price,
              totalCount: productData?.total_amount,
            };
          }),
        },
      ],
    };
    executeAppForActions(objectBody)
      .then((response) => {
        setIsCartLoading(true);
        clearCartData();
        toast.success(response?.message);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      })
      .finally(() => {
        setIsResponseLoading(false);
      });
  };

  const clearCartData = () => {
    clearAddToCartList()
      .then((response) => {
        if (response?.success) {
          setRoutesData({ ...routesData, cartChange: uuId() });
          getAllCartItems();
        }
      })
      .catch((error) => {
        setIsCartLoading("");
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      });
  };

  return (
    <div>
      {isCartLoading === true ? (
        <Loader />
      ) : (
        <div>
          <div className="text-end pt-2">
            <button
              type="button"
              className="btn btn-secondary btn-sm m-0"
              onClick={(e) => {
                e.preventDefault();
                history?.goBack();
              }}
            >
              <FontAwesomeIcon icon="fa fa-arrow-left" />
              <span className="ms-1">Back</span>
            </button>
          </div>

          {viewCartList?.product_data?.length ? (
            <div className="row mx-1">
              <div className="col-md-8 p-0">
                <div className="py-2">
                  <h5>Cart</h5>
                </div>
                <div className="tableTheme1 mx-1 mb-3">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th colSpan={2}>Product</th>
                          <th className="text-nowrap">Quantity</th>
                          {cartConfig?.hidePriceInformation === "yes" ? null : (
                            <>
                              <th className="text-nowrap">Price</th>
                              <th className="text-nowrap">Subtotal</th>
                              <th className="text-nowrap">Tax</th>
                              <th className="text-nowrap">Total</th>
                            </>
                          )}

                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewCartList?.product_data?.map((cart, index) => {
                          return (
                            <SingleCartProduct
                              key={index}
                              cartDetails={cart}
                              cartClick={cartClick}
                              isCartLoading={isCartLoading}
                              hidePriceInformation={
                                cartConfig?.hidePriceInformation === "yes"
                              }
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-0">
                <div className="mx-1">
                  <div className="py-2">
                    <h5
                      className={
                        cartConfig?.hidePriceInformation === "yes"
                          ? "invisible"
                          : ""
                      }
                    >
                      Cart Total
                    </h5>
                  </div>
                  <table className="table mb-0">
                    <tbody>
                      {cartConfig?.hidePriceInformation === "yes" ? null : (
                        <>
                          {" "}
                          <tr>
                            <th className="border-top">Subtotal</th>
                            <td>
                              <span>
                                <bdi>
                                  {viewCartList?.sub_total?.toLocaleString(
                                    "en-IN"
                                  ) || 0}
                                </bdi>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th className="border-top">Tax</th>
                            <td>
                              <span>
                                <bdi>
                                  {viewCartList?.tax_amount?.toLocaleString(
                                    "en-IN"
                                  ) || 0}
                                </bdi>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <h6>
                                <strong>
                                  Total (
                                  <FontAwesomeIcon icon={"indian-rupee-sign"} />
                                  )
                                </strong>
                              </h6>
                            </th>
                            <td>
                              <h6>
                                <strong>
                                  {viewCartList?.total?.toLocaleString("en-IN")}
                                </strong>
                              </h6>
                            </td>
                          </tr>
                        </>
                      )}

                      <tr>
                        <td colSpan={2} className="text-end">
                          {cartConfig?.buttonName && (
                            <button
                              className="btn btn-primary"
                              onClick={cartButtonClick}
                            >
                              {isResponseLoading ? (
                                <div className="spinner-border spinner-border-sm text-light" />
                              ) : (
                                <FontAwesomeIcon
                                  icon={cartConfig?.buttonIcon?.value}
                                />
                              )}{" "}
                              {cartConfig?.buttonName}
                            </button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <Modal
                centered
                show={deleteModalState}
                onHide={handleModalClose}
                backdrop={isCartLoading ? "static" : true}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Remove Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h6>Are you sure you want to remove this item?</h6>
                  <div className="text-end">
                    <button
                      className="btn btn-secondary btn-md me-1 "
                      type="button"
                      onClick={handleModalClose}
                    >
                      <span className="ms-1">No</span>
                    </button>
                    <button
                      disabled={isCartLoading}
                      className="btn btn-danger btn-md"
                      type="button"
                      onClick={() => cartClickHandle(selectedProduct, 0)}
                    >
                      {isCartLoading && (
                        <div className="spinner-border spinner-border-sm text-light" />
                      )}
                      <span className="ms-1">Yes</span>
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          ) : (
            <div className="set_height mt-5">
              <div className="text-center opacity-5 mb-3">
                <FontAwesomeIcon
                  icon={"shopping-cart"}
                  size="5x"
                  aria-hidden="true"
                />
              </div>
              <div className="text-center">No items in your cart</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewCart;

export const SingleCartProduct = ({
  cartDetails,
  cartClick,
  isCartLoading,
  hidePriceInformation,
}) => {
  let productData = cartDetails?.product;
  let total_amount = cartDetails?.total_amount;
  let tax_amount = cartDetails?.tax_amount;
  let sub_total = cartDetails?.sub_total;

  const [quantity, setQuantity] = useState(Number(cartDetails?.quantity));

  const counterChangeHandler = (e) => {
    let value = e.target.value;

    if (!isCartLoading) {
      setQuantity(value);
    }
  };

  useEffect(() => {
    setQuantity(cartDetails?.quantity);
  }, [cartDetails?.quantity]);

  useEffect(() => {
    let timeoutId;

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      if (cartDetails?.quantity !== quantity && quantity > 0) {
        cartClick(productData, quantity);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [quantity]);

  return (
    <tr className="align-middle singleCart">
      <td colSpan={2}>
        <div>
          <Link
            className="cartView d-flex"
            to={"/app_product_view/" + productData?._id?.$oid}
          >
            <div>
              <img
                height={50}
                width={50}
                src={
                  productData?.product_images?.length
                    ? productData.product_images[0]
                    : dummyImage
                }
              />
            </div>
            <span className="text-wrap ms-2 d-flex align-items-center">
              {productData?.product_name}
            </span>
          </Link>
        </div>
      </td>
      <td>
        <div className="quantity-wrapper">
          <div className="d-flex">
            <div
              role="button"
              className="border counter flex-grow-1 d-flex align-items-center justify-content-center"
              onClick={() => {
                if (!isCartLoading) {
                  cartClick(productData, Number(cartDetails?.quantity - 1));
                }
              }}
            >
              {cartDetails?.quantity > 1 ? (
                <span>-</span>
              ) : (
                <FontAwesomeIcon
                  role="button"
                  icon={"trash"}
                  size="sm"
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="border flex-grow-1 fs-6 d-flex align-items-center justify-content-center">
              <input
                type="number"
                className="counterInput"
                value={quantity}
                onChange={counterChangeHandler}
                min={0}
              />
            </div>
            <div
              role="button"
              className="border counter flex-grow-1 d-flex align-items-center justify-content-center"
              onClick={() => {
                if (!isCartLoading) {
                  cartClick(productData, Number(cartDetails?.quantity + 1));
                }
              }}
            >
              <span>+</span>
            </div>
          </div>
          {productData?._id?.$oid === isCartLoading && (
            <div className="progressIndeterminateContainer">
              <div className="progressBar"></div>
            </div>
          )}
        </div>
      </td>
      {hidePriceInformation ? null : (
        <>
          <td>{productData?.price}</td>
          <td>{sub_total?.toLocaleString("en-IN") || 0}</td>
          <td>{tax_amount?.toLocaleString("en-IN") || 0}</td>
          <td>{total_amount?.toLocaleString("en-IN") || 0}</td>
        </>
      )}

      <td className="px-2">
        <div
          title="Remove"
          className="counter border d-flex align-items-center justify-content-center"
          onClick={() => cartClick(productData, 0)}
          role="button"
        >
          <FontAwesomeIcon icon={"remove"} size="sm" aria-hidden="true" />
        </div>
      </td>
    </tr>
  );
};
