import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const DisplayBuilderHeader = ({ 
  displayBuilder, 
  columnWidthList, 
  onChange,
}) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);
  useEffect(() => {
    setDisplayChanges((prev) => {
      const updatedState = { ...prev, ...displayBuilder };
      updatedState.refValue = updatedState.refValue || "response"; 
      return JSON.stringify(updatedState) !== JSON.stringify(prev) ? updatedState : prev;
    });
  }, []);
  
  useEffect(() => {
    onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };
  return (
    <>
      <div className="col-md-6 form-group">
        <label className="form-label col-md-4 col-sm-4 p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div className="col-md-8 col-sm-8">
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control`}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          ></input>
        </div>
      </div>

      <div className="col-md-6 form-group">
      <label className="form-label col-md-4 col-sm-4 p-0 mt-2">
          Column Width
        </label>
        <div className="col-md-8 col-sm-8">
          <Form.Select
            className="form-control"
            value={displayChanges?.columenWidth}
            onChange={(e) => {
              changeValue(e);
            }}
            name="columenWidth"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-6 form-group">
      <label className="form-label col-md-4 col-sm-4 p-0 mt-2">
          Header Title
        </label>
        <div className="col-md-8 col-sm-8">
          <input
            type="text"
            className={`${
              displayChanges?.headerTitle?.length <= 0 ? "border-red" : ""
            } form-control`}
            placeholder="Enter Title"
            name="headerTitle"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.headerTitle}
            ></input>
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderHeader;
