import { React, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";
import * as $ from "jquery";
import dummyImg from "../../../images/dummy-header-img.png";
import { addToCartList, logOut } from "../../../services/appService";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, NavDropdown } from "react-bootstrap";
import { useState } from "react";
import { RoutesContext } from "../../../RoutesContext";
import { filterThemeData, upsertSingleRecord } from "../../../services/appService";

import SearchBar from "../../search/SearchBar";

function Header() {
  const { routesData } = useContext(RoutesContext);
  const [viewCartList, setViewCartList] = useState([]);
  const user = JSON.parse(localStorage.getItem("user-info"))
    ? JSON.parse(localStorage.getItem("user-info"))
    : "";
  const query_data = JSON.parse(localStorage.getItem("query_param"));
  const isAdmin = sessionStorage.getItem("admin-info");
  const history = useHistory();
  const currentTheme = localStorage.getItem("currentTheme");
  const setCurrentTheme = (themeName) => {
    let html = document.getElementsByTagName("html")[0];
    if (
      themeName &&
      themeName !== "" &&
      typeof themeName != "undefined" &&
      themeName !== "undefined"
    ) {
      html.className = themeName;
      localStorage.setItem("currentTheme", themeName);
    }
  };

  const switchTheme = () => {
    let html = document.getElementsByTagName("html")[0];
    if (html.className === "default") {
      setCurrentTheme("dark");
    } else {
      setCurrentTheme("default");
    }
  };
  setCurrentTheme(currentTheme);

  useEffect(() => {
    $("#menu_toggle").on("click", function () {
      $("#main").toggleClass("nav-md nav-sm");
    });
    getAllCartItems();
  }, [routesData?.cartChange]);

  const getAllCartItems = () => {
    addToCartList()
      .then((response) => {
        if (response?.success) {
          let cartData = response?.data;
          setViewCartList(cartData?.product_data || []);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      });
  };

  const loggingOut = (e) => {
    logOut()
      .then((response) => {
        if (response.success === true) {
          if (!isAdmin) {
            localStorage.clear();
            history.push(`/login?tenant_name=${query_data}`);
          } else {
            sessionStorage.clear();
            localStorage.clear();
            history.push("/dmadmin?tenant_name=flashcorp");
          }
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: true, loading: false }, () => {
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        });
      });
  };

  let userName = user?.Name?.split(" ");
  let firstnameInitial = userName?.[0].charAt(0).toUpperCase();
  let lastName = userName?.[1];
  let lastnameInitial = lastName?.charAt(0).toUpperCase();

  const [isDarkMode, setIsDarkMode] = useState(false);
    const [loading, setLoading] = useState(true);
  
    const defaultColors = {
      primaryColor: "#0d6efd",
      secondaryColor: "#607D8B",
      backgroundColor: "#f6f7fb",
      textColor: "#000",
      submitButtonColor: "#198754",
      chooseFileButtonColor: "#0d6efd",
      importButtonColor: "#0dcaf0",
      exportButtonColor: "#0d6efd",
      backButtonColor: "#6c757d",
      addButtonColor: "#6c757d",
      cancelButtonColor: "#dc3545",
      testLogicButtonColor: "#0d6efd",
      uninstallButtonColor: "#dc3545",
      installButtonColor: "#198754",
      boxBackgroundColor : "#ffffff",
      navMenuBackgroundColor : "#ffffff",
      logoBackgroundColor : "#ffffff",
      addToCartButtonColor : "#0d6efd",
      viewWishListButtonColor : "#9e9e9e",
      updateFormTitleBackgroundColor : "#f6f7fb",
      closeButtonBackgroundColor : "#f6f7fb",
      activeTabBackgroundColor : "#f6f7fb",
      activeTabBorderBottomColor : "#0d6efd",
      iconColor : "#616161",
      userLogoBackgroundColor : "#5f7d8c",
      formBackgroundColor : "#ffffff",
      formLabelColor : "#9E9E9E",
      formBorderColor : "#9E9E9E",
      formHeaderColor : "#9E9E9E",
      inputFieldBackgroundColor: "#ffffff",
      inputFieldTextColor: "#607D8B",
      inputFieldBorderColor: "#e9ecef",
      tableBackgroundColor : "#ffffff",
      dropdownTextColor : "#607D8B",
      dropdownBorderColor : "#e9ecef",
      dropdownBackgroundColor : "#ffffff",
      dropdownListHoverColor : "#0d6efd",
      tableHeaderBackgroundColor : "#ffffff",
      tableHeaderTextColor : "#607D8B",
      tableRowBackgroundColor : "#ffffff",
      tableRowTextColor : "#607D8B",
      tableBorderColor : "#dfe5e8",
      boxTextColor : "#474747",
      searchButtonColor : "#6c757d",
      formSliderColor : "#0d6efd",
      formSliderShifterColor : "#0d6efd",
      headerTextColor : "#000",
      activeTabTextColor : "#000",
    };
  
    const darkModeColors = {
      primaryColor: "#2a3042",
      secondaryColor: "#2d2d2d",
      backgroundColor: "#212529",
      textColor: "#ffffff",
      submitButtonColor: "#0a84ff",
      chooseFileButtonColor: "#0a84ff",
      importButtonColor: "#0a84ff",
      exportButtonColor: "#0a84ff",
      backButtonColor: "#444444",
      addButtonColor: "#444444",
      cancelButtonColor: "#ff453a",
      testLogicButtonColor: "#0a84ff",
      uninstallButtonColor: "#ff453a",
      installButtonColor: "#0a84ff",
      boxBackgroundColor : "#333547",
      navMenuBackgroundColor : "#333547",
      logoBackgroundColor : "#333547",
      addToCartButtonColor : "#0a84ff",
      viewWishListButtonColor : "#aaaaaa",
      updateFormTitleBackgroundColor : "#2d2d2d",
      closeButtonBackgroundColor : "#2d2d2d",
      activeTabBackgroundColor : "#333333",
      activeTabBorderBottomColor : "#0a84ff",
      iconColor : "#ebebed",
      userLogoBackgroundColor : "#2d2d2d",
      formBackgroundColor : "#1e1e1e",
      formLabelColor : "#aaaaaa",
      formBorderColor : "#333333",
      formHeaderColor : "#cccccc",
      inputFieldBackgroundColor: "#2d2d2d",
      inputFieldTextColor: "#ffffff",
      inputFieldBorderColor: "#444444",
      tableBackgroundColor : "#1e1e1e",
      dropdownTextColor : "#ffffff",
      dropdownBorderColor : "#444444",
      dropdownBackgroundColor : "#2d2d2d",
      dropdownListHoverColor : "#0a84ff",
      tableHeaderBackgroundColor : "#2d2d2d",
      tableHeaderTextColor : "#ffffff",
      tableRowBackgroundColor : "#1e1e1e",
      tableRowTextColor : "#ffffff",
      tableBorderColor : "#333333",
      boxTextColor : "#ebebed",
      searchButtonColor : "#444444",
      formSliderColor : "#0a84ff",
      formSliderShifterColor : "#0a84ff",
      headerTextColor : "#ebebed",
      activeTabTextColor : "#ebebed",
    };
  
    const [colors, setColors] = useState(defaultColors);
  
    useEffect(() => {
      const savedColors = JSON.parse(localStorage.getItem("colors"));
      if (savedColors) {
        setColors(savedColors);
        setIsDarkMode(savedColors.backgroundColor === darkModeColors.backgroundColor);
      } else {
        const getThemingData = async () => {
          try {
            const response = await filterThemeData({ FilterCriteria: [], SortCriteria: [], PaginationCriteria: { limit: 10, skip: 0 } });
            if (response?.data?.length > 0) {
              setColors(response.data[0]);
              setIsDarkMode(response.data[0].backgroundColor === darkModeColors.backgroundColor);
            }
          } catch (err) {
            toast.error(err.response?.data?.message || err.response?.message || err.message);
          } finally {
            setLoading(false);
          }
        };
        getThemingData();
      }
    }, []);
  
    const toggleTheme = () => {
      const newColors = isDarkMode ? defaultColors : darkModeColors;
      setColors(newColors);
      setIsDarkMode(!isDarkMode);
      localStorage.setItem("colors", JSON.stringify(newColors));
      submitTheme(newColors);
    };
  
    const submitTheme = async (themeColors) => {
      try {
        const tenantId = JSON.parse(localStorage.getItem("tenantId")) || null;
        const userId = JSON.parse(localStorage.getItem("userid")) || null;
        if (!tenantId || !userId) {
          toast.error("User or tenant information is missing.");
          return;
        }
        const obj = {
          tenant_id: tenantId,
          dataset_name: "fc_theme",
          user_id: userId,
          fields: { ...themeColors },
        };
        await upsertSingleRecord(obj);
        window.location.reload();
      } catch (err) {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      }
    };

  return (
    <div className="top_nav header">
      <div className="nav_menu m-0 d-flex justify-content-between">
        <div className="nav header-icons-container toggle align-items-center ms-2">
          <Link to="#" id="menu_toggle">
            <FontAwesomeIcon icon={"bars"} />
          </Link>
        </div>
        <div className="d-flex align-items-center me-3 header-dropdown">
          <SearchBar />

          <Link
            to={"/cartlist"}
            className="companyProfileHeaderLink mx-2 position-relative p-1"
            title="Cart List"
          >
            <FontAwesomeIcon
              icon={"shopping-cart"}
              className="companyProfileHeaderIcon"
            />
            {viewCartList?.length ? (
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {viewCartList?.length}
              </span>
            ) : null}
          </Link>
          <Button
            onClick={toggleTheme}
            className="switchThemeHeaderLink p-1"
            variant="link"
            size="lg"
            title="Switch Theme"
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-circle-half-stroke"}
              className="switchThemeHeaderIcon"
            />
          </Button>
          {user?.role_name?.toLowerCase() !== "admin" ? null : (
            <>
            <Link
              to={"/companyprofile"}
              className="companyProfileHeaderLink"
              title="Company Profile"
            >
              <FontAwesomeIcon
                icon={"fa fa-gear"}
                className="companyProfileHeaderIcon"
              />
            </Link>
            <a
              href="https://datamoulds.atlassian.net/wiki/spaces/FKB/pages" target="_blank" rel="noopener noreferrer"
              className="companyProfileHeaderLink mx-2"
              title="Documentation"
            >
              <FontAwesomeIcon
                icon={"fa fa-book"}
                className="companyProfileHeaderIcon"
              />
            </a>
            </>
          )}
          <NavDropdown
            className="me-2 userProfileHeaderDropdown"
            title={
              user.profile_image ? (
                <img
                  title={`${userName?.[0]} ${userName?.[1]}`}
                  src={
                    user.profile_image === undefined ||
                    user.profile_image === ""
                      ? dummyImg
                      : user.profile_image
                  }
                  alt="Profile"
                  className="user_logo"
                />
              ) : (
                <div
                  className="user_logo"
                  title={`${userName?.[0]} ${userName?.[1]}`}
                >
                  <div className="user_logo_text">
                    {firstnameInitial}
                    {lastnameInitial}
                  </div>
                </div>
              )
            }
            id="nav-dropdown"
          >
            <NavDropdown.Item eventKey="4.1">
              <Link className="dropdown-item" to={`/user_profile`}>
                <FontAwesomeIcon icon={"fa fa-user"} />
                <span className="ms-2">User Profile</span>
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item href="https://datamoulds.atlassian.net/wiki/spaces/FKB/pages" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={"fa fa-book"} />
              <span className="ms-2">Documentation</span>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="4.3">
              <Link className="dropdown-item" to={"/help_page"}>
                <FontAwesomeIcon icon={"fa fa-handshake"} />
                <span className="ms-1">Support</span>
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="4.4" onClick={loggingOut}>
              <button className="dropdown-item">
                <FontAwesomeIcon icon={"fa fa-sign-out"} />
                <span className="ms-2">Log Out</span>
              </button>
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>
    </div>
  );
}

export default Header;
