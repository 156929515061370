import React, { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { toast } from "react-toastify";
import FilterComponent from "../../common/filterComponent/FilterComponent";
import { Link } from "react-router-dom";
import Split from "react-split";
import RowLineEdit from "./inLineEdit/RowLineEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Collapse from "react-bootstrap/Collapse";
import "./SubTable.scss";
import { actionTypes } from "../../common/model/Model";
import TablePagination from "./TablePagination";
import ApproveModal from "./ApproveModal";
import LineItemModal from "./LineItemModal";
import { useHistory, useParams } from "react-router-dom";
import TableHeaderButton from "./TableHeaderButton";
import {
  executeAppForActions,
  getLastNodeData,
  reactAppExecutionVP,
} from "../../../services/API";
import ViewUpdateAdd from "./view update/ViewUpdateAdd";
import { RoutesContext } from "../../../RoutesContext";
import TableSummary from "./TableSummary";
import CheckListModal from "./CheckListModal";
import InlineFormModal from "./InlineFormTable";
import SortAndFilter from "./SortAndFilter";
import Outside from "../../dataset/table/Outside";
import { TableRowContext } from "../contexts/TableRowContext";

const SubTable = ({
  appSessionId,
  objData,
  filterApply,
  displayData,
  reloadComponents,
  setReloadComponents,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
  // refreshKey 
}) => {
  let initialFilter = {
    FilterCriteria: [],
    SortCriteria: [],
    PaginationCriteria: {
      limit: 20,
      skip: 0,
    },
  };

  const history = useHistory();
  const params = useParams();
  const abortController = new AbortController();
  const signal = abortController.signal;
  const userInfo = JSON.parse(localStorage.getItem("user-info"));
  const [privilegeButtons, setPrivilegeButtons] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [sysFilter, setSysFilter] = useState(initialFilter);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [multipleActions, setMultipleActions] = useState([]);
  const [currentActionDetails, setCurrentActionDetails] = useState({});
  const [lineItemsModalState, setLineItemsModalState] = useState(false);
  const [checkListModalState, setCheckListModalState] = useState(false);
  const [checkListHeaderData, setCheckListHeaderdata] = useState();
  const [lineItemData, setLineItemData] = useState([]);
  const [checkListData, setCheckListData] = useState([]);
  const [lineItemHeaderFieldData, setLineItemHeaderFieldData] = useState("");
  const [componentData, setComponentData] = useState({});
  const [componentDataLoading, setComponentDataLoading] = useState(false);
  const [tableButtonLoading, setTableButtonLoading] = useState("");
  const [toggleFilter, setToggleFilter] = useState(false);
  const [toggleTableView, setToggleTableView] = useState(true);
  const [viewUpdateAddData, setViewUpdateAddData] = useState({});
  const [viewUpdateAddState, setViewUpdateAddState] = useState("");
  const [viewWidthState, setViewWidthState] = useState([100, 0]);
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const { component_id, isCacheEnabled, autoReloadComponent, autoReloadDelay } =
    displayData;
  const [inlineFormModalData, setInlineFormModalData] = useState({});
  const [activeColumnFilter, setActiveColumnFilter] = useState("");
  const [fieldApiBackUpObject, setFieldApiBackUpObject] = useState({});

  let executeAppObject = {
    data: {
      logic:
        objData?.logic?.length > 0
          ? objData?.logic
          : [displayData?.componentLogic],
    },
    referenceValue: [{ refValue: displayData?.refValue }],
    app_session_id: appSessionId,
    sys_filter: sysFilter,
  };

  useEffect(() => {
    if (autoReloadComponent && autoReloadDelay !== "") {
      const intervalId = setInterval(
        () => appExecutionEngine(executeAppObject),
        autoReloadDelay
      );
      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  useEffect(() => {
    setActivePage(1);
    setSysFilter(initialFilter);
    return () => {
      abortController.abort();
    };
  }, [params?.flowId]);

  useEffect(() => {
    getPrivileges();
    if (
      cachedData.hasOwnProperty(component_id) &&
      !tempStorageCall &&
      isCacheEnabled
    ) {
      setComponentData(cachedData?.[component_id]);
      if (cachedData?.[component_id]?.activePage) {
        setActivePage(cachedData?.[component_id]?.activePage);
      }
      return;
    }
    setComponentDataLoading(true);
    appExecutionEngine(executeAppObject);
    fieldApiBackUp();

    // clearing previous State Data
    setViewUpdateAddState("");
    setComponentData([]);
    setViewWidthState([100, 0]);
    setViewUpdateAddData({});
  }, [objData]);

  useEffect(() => {
    if (filterApply) {
      setComponentDataLoading(true);
      appExecutionEngine(executeAppObject);
    }
  }, [filterApply]);

  useEffect(() => {
    if (reloadComponents.includes(displayData?.refValue)) {
      setComponentDataLoading(true);
      appExecutionEngine(executeAppObject);
    }
  }, [reloadComponents]);

  const getPrivileges = () => {
    if (userInfo?.privileges) {
      const { app_previleges } = userInfo?.privileges;
      app_previleges?.forEach((data) => {
        const { _id, displayName, ...restData } = data;
        let app_id = "";
        Object.keys(restData).map((key) => {
          app_id = key;
        });
        if (app_id === params?.id) {
          data?.[app_id]?.pageList?.forEach((page) => {
            let buttonLists = [];
            if (page?.page_id === objData?.page?.page_id) {
              page.componentList.map((component) => {
                component.componentButtonList.map((buttonList) => {
                  buttonLists.push(buttonList);
                });
              });
              setPrivilegeButtons(buttonLists);
            }
          });
        }
      });
    }
  };

  const paginationData = (pageNumber) => {
    let sys_filter = { ...sysFilter };
    sys_filter.PaginationCriteria = {
      limit: 20,
      skip: (pageNumber - 1) * 20,
    };
    setSysFilter(sys_filter);
    setActivePage(pageNumber);
    setFilter(sys_filter);
    let data = { ...cachedData };
    data[component_id]["activePage"] = pageNumber;
    setCacheData({ ...cachedData, ...data });
  };

  const handleModalClose = () => {
    setShowApproveModal(false);
    setViewUpdateAddData({});
    setCurrentActionDetails({});
  };

  const addComponentToReloadArray = (onActionReloadComponent) => {
    let components = onActionReloadComponent?.split(",") || [];
    if (components?.length) {
      setReloadComponents(components);
    }
  };

  const executeAppAction = (rowData, actionData) => {
    let objBody = {
      app_session_id: appSessionId,
      logic_name: actionData.logicNameValue || "",
      reference_name: actionData.referenceValue || "",
      data: [rowData],
    };

    executeAppForActions(objBody)
      .then((response) => {
        if (response) {
          if (response?.message) {
            toast.success(response?.message);
            routePageToOneStepBack();
            addComponentToReloadArray(actionData?.onActionReloadComponent);
            let link;
            if (actionData?.transitionAppId && actionData?.transitionPageId) {
              link = `/app_ui/${actionData?.transitionAppId}/${actionData?.transitionPageId}`;
              history.push(link);
            }
          }
        }
      })
      .catch((error) => toast.error(error.response?.data?.message || error.response?.message || error.message))
      .finally(() => setTableButtonLoading(""));
  };

  const openModelData = (fieldData, data) => {
    setLineItemsModalState(true);
    setLineItemData(data);
    setLineItemHeaderFieldData(fieldData);
  };

  const openCheckListModal = (fieldData, data) => {
    setCheckListModalState(true);
    setCheckListData(data);
    setCheckListHeaderdata(fieldData);
  };

  const openInlineFormTableModal = (fieldData, data) => {
    setInlineFormModalData({ fieldData, data });
  };

  const selectActionsList = (event, recordId) => {
    if (event.target.checked) {
      setMultipleActions([...multipleActions, recordId]);
    } else {
      setMultipleActions(
        multipleActions.filter((data_id) => data_id !== recordId)
      );
    }
  };

  const selectAllActionsList = (event, allData) => {
    if (event.target.checked) {
      setMultipleActions(allData.map((data) => data._id?.$oid));
    } else {
      setMultipleActions([]);
    }
  };

  const setToggleFilterHandle = () => {
    if (toggleTableView) {
      setToggleFilter(!toggleFilter);
    } else {
      setToggleTableView(true);
      setToggleFilter(true);
    }
  };

  const setResetFilter = () => {
    setSysFilter(initialFilter);
    setActivePage(1);
    setFilter(initialFilter);
  };

  const getFilterCriteria = (filter_criteria) => {
    let sys_filter = { ...sysFilter };
    sys_filter.FilterCriteria = filter_criteria;
    sys_filter.PaginationCriteria = {
      limit: 10,
      skip: 0,
    };
    setSysFilter(sys_filter);
    setActivePage(1);
    setFilter(sys_filter);
  };

  const setFilterSortCriteria = (filter) => {
    let sys_filter = { ...sysFilter };
    sys_filter.SortCriteria = filter;

    setSysFilter(sys_filter);
    setFilter(sys_filter);
  };

  const setFilter = (filter) => {
    setSysFilter(filter);
    let bodyObject = {
      ...executeAppObject,
      sys_filter: filter,
    };
    appExecutionEngine(bodyObject);
  };

  const appExecutionEngine = (bodyObject) => {
    setComponentDataLoading(true);
    reactAppExecutionVP(bodyObject, signal)
      .then((response) => {
        response?.data?.map((refData) => {
          if (refData[displayData?.refValue]) {
            setComponentData(refData[displayData?.refValue]);
            let data = cachedData;
            data[component_id] = {
              ...data[component_id],
              ...refData[displayData?.refValue],
            };
            setCacheData(data);
            setMultipleActions([]);
            setTempStorageCall(false);
          } else {
            setComponentData([]);
          }
        });
        clearFilterApply();
      })
      .catch((err) => toast.error(err.response?.data?.message || err.response?.message || err.message))
      .finally(() => setComponentDataLoading(false));
  };

  const reloadComponentData = () => {
    setComponentDataLoading(true);
    appExecutionEngine(executeAppObject);
  };

  // useEffect(() => {
  //   reloadComponentData();
  // }, [refreshKey]);

  const onButtonActionClickHandler = (actionData, rowData, index) => {
    actionData.actionName = getActionButtonText(actionData,rowData);
    setCurrentActionDetails(actionData);

    if (actionData.actionName === "Update") {
      setViewUpdateAddData(rowData);
      setViewUpdateAddState(actionData.actionName);
      setViewWidthState([25, 75]);
    } else if (actionData.actionName === "Clone") {
      let cloneRowData = { ...rowData };
      delete cloneRowData["srno"]
      delete cloneRowData["_id"]
      delete cloneRowData["created_date"]
      delete cloneRowData["created_date_label"]
      delete cloneRowData["created_date_millis"]
      delete cloneRowData["updated_date"]
      delete cloneRowData["updated_date_label"]
      delete cloneRowData["updated_date_millis"]
      delete cloneRowData["user_id"]
      setViewUpdateAddData(cloneRowData);
      setViewUpdateAddState(actionData.actionName);
      setViewWidthState([25, 75]);
    } else if (actionData.actionName === "View") {
      setViewUpdateAddData(rowData);
      setViewUpdateAddState(actionData.actionName);
      setViewWidthState([25, 75]);
    } else if (actionData.actionType === actionTypes.timeLineView) {
      setViewUpdateAddData(rowData);
      setViewUpdateAddState(actionData.actionName);
      setViewWidthState([25, 75]);
    } else if (
      actionData?.actionType === actionTypes.popUp ||
      actionData?.actionType === undefined
    ) {
      setViewUpdateAddData(rowData);
      setShowApproveModal(true);
    } else if (actionData.actionType === actionTypes.button) {
      if(window.confirm("Please confirm if you want to perform " + (actionData.actionName || "this") + " action?")){
        setTableButtonLoading(JSON.stringify(rowData) + actionData.action_id);
        executeAppAction(rowData, actionData);
      }
    }
  };

  const getActionButtonText = (action, rowData) => {
    if(!action || !rowData){
      return "";
    }
    let actionText = action.actionName;
    if(action.actionName !== "Update" && action.actionName !== "View" && action.buttonTextKey && action.buttonTextKey.length > 0){
      if(rowData[action.buttonTextKey] && rowData[action.buttonTextKey].length > 0){
        actionText = rowData[action.buttonTextKey];
      }
    }
    return actionText;
  }

  const routePageToOneStepBack = () => {
    if (componentData?.value?.data.length < 2 && activePage > 1) {
      paginationData(activePage - 1);
    } else {
      reloadComponentData();
    }
  };

  let pageFieldsList = [];
  if (displayData?.page_field?.length > 0) {
    pageFieldsList = displayData?.page_field;
  } else {
    pageFieldsList = componentData?.value?.fields;
  }
  let formFieldList = pageFieldsList;
  let pageFieldsListFiltered = pageFieldsList?.filter(
    (field) => !field?.show_key_to_table || field?.show_key_to_table !== "no"
  );
  /*
  pageFieldsList = pageFieldsList?.filter(
    (field) => !field?.show_key_to_table || field?.show_key_to_table !== "no"
  );
  */

  const fieldApiBackUp = () => {
    if (pageFieldsList) {
      let fields =
        pageFieldsList.filter(
          (dataField) =>
            dataField.show_key_to_table !== "no" &&
            dataField.key_to_send &&
            dataField.key_to_show &&
            dataField.key_to_send !== dataField.key_to_show
        ) || [];
      fields.forEach((dataField) => {
        getLastNodeData(appSessionId, dataField.logic_to_call).then((res) => {
          let { data } = res.data.value || {};
          if (data) {
            setFieldApiBackUpObject((fieldApiBackUpObject) => ({
              ...fieldApiBackUpObject,
              [dataField.name]: data.data.map((apiData) => ({
                label: apiData[dataField.key_to_show],
                value: apiData[dataField.key_to_send],
              })),
            }));
          }
        });
      });
    }
  };

  const tableHeaderData = pageFieldsListFiltered?.map((columnHeader, index) => {
    return (
      <th key={index} className={`text-nowrap`}>
        <div className="d-flex">
          <span> {columnHeader.display_name}</span>
          <SortAndFilter
            setActiveColumnFilter={setActiveColumnFilter}
            showFilter={activeColumnFilter === columnHeader.name}
            column={columnHeader}
            sysFilter={sysFilter}
            setFilterSortCriteria={setFilterSortCriteria}
            setFilterCriteria={getFilterCriteria}
          />
        </div>
      </th>
    );
  });

  const newData = componentData?.value?.data || [];

  const updateParentRowData = (index, updateRowData) => {
    newData[index] = updateRowData;
  }

  let savedFilterListApiBody = {
    FilterCriteria: [
      {
        filter_type: "text",
        type: "equal",
        filter: JSON.parse(localStorage.getItem("userid")),
        filter_field: "user_id",
      },
      {
        filter_type: "text",
        type: "equal",
        filter: objData.page.page_id,
        filter_field: "page_id",
      },
    ],
    export: true,
  };

  let updateAction = displayData?.actionDetails.filter(
      (action) => action?.buttonPlacement !== "Header" && action?.actionName === "Update" 
    ) || {};
  if(updateAction && updateAction.length > 0){
    updateAction = updateAction[0]
  }

  let viewAction = displayData?.actionDetails.filter(
      (action) => action?.buttonPlacement !== "Header" && action?.actionName === "View" 
    ) || {};
  if(viewAction && viewAction.length > 0){
    viewAction = viewAction[0]
  }
  
  let cloneAction = displayData?.actionDetails.filter(
      (action) => action?.buttonPlacement !== "Header" && action?.actionName === "Clone" 
    ) || {};
  if(cloneAction && cloneAction.length > 0){
    cloneAction = cloneAction[0]
  }
  
  const allActionList =
    displayData?.actionDetails.filter(
      (action) => action?.buttonPlacement !== "Header" && action?.actionName !== "Update"  && action?.actionName !== "View"  && action?.actionName !== "Clone" 
    ) || [];

  let actionButtonsList =
    allActionList?.filter(
      (action) =>
        privilegeButtons?.includes(action?.action_id) ||
        userInfo.role_name.toLowerCase() === "admin"
    ) || [];

  const actionColumnState =
    actionButtonsList?.length > 0 ||
    (updateAction && updateAction.updateOnTable === "Yes" &&
      (privilegeButtons?.includes(updateAction?.action_id) ||
        userInfo.role_name.toLowerCase() === "admin")) ||
    (viewAction && viewAction.viewOnTable === "Yes" &&
      (privilegeButtons?.includes(viewAction?.action_id) ||
        userInfo.role_name.toLowerCase() === "admin")) ||
    (cloneAction && cloneAction.updateOnTable === "Yes" &&
      (privilegeButtons?.includes(cloneAction?.action_id) ||
        userInfo.role_name.toLowerCase() === "admin"));
  return (
    <Col md={displayData?.columnWidthTable || "12"} className={"px-0"}>
      <div className={`h-100 mx-1`}>
        <div className="main-content bg-white py-1 px-1 mb-2">
            <div className="d-flex justify-content-between align-items-center pb-2 mt-1 border-bottom">
              <h5 className="table-heading fw-bold">{displayData?.tableName}</h5>
              <div className="d-flex align-items-center">
                <TableHeaderButton
                  executeAppObject={executeAppObject}
                  objData={objData}
                  formConfig={objData?.page?.form_config}
                  formFieldsList={formFieldList}
                  pageFieldsList={pageFieldsListFiltered}
                  actionButtonList={displayData?.actionDetails}
                  appSessionId={appSessionId}
                  tableName={displayData?.tableName}
                  pageId={displayData?.formId}
                  sysFilter={sysFilter}
                  currentTableData={componentData?.value?.data}
                  routePageToOneStepBack={routePageToOneStepBack}
                  multipleActions={multipleActions}
                  setMultipleActions={setMultipleActions}
                  reloadComponentData={reloadComponentData}
                  addComponentToReloadArray={addComponentToReloadArray}
                />
                <div className="d-flex align-items-center">
                  {displayData?.transitionAppId &&
                    displayData?.transitionPageId ? (
                    <div className="d-flex align-items-center transition-header-icon">
                      <Link
                        to={`/app_ui/${displayData?.transitionAppId}/${displayData?.transitionPageId}`}
                      >
                        <button
                          className="header-icon-button icon-hover-effect"
                          title={displayData?.transitionActionTitle ?? ""}
                        >
                          <FontAwesomeIcon
                            icon={
                              displayData?.transitionSelectedIcon?.value ??
                              "fa fa-external-link"
                            }
                            size="lg"
                            color="#73879c"
                          />
                        </button>
                      </Link>
                    </div>
                  ) : null}

                  {displayData?.showFilter === undefined ||
                    displayData?.showFilter ? (
                    <button
                      onClick={setToggleFilterHandle}
                      className="header-icon-button icon-hover-effect"
                      title="Filter"
                    >
                      <FontAwesomeIcon
                        icon="fa fa-filter"
                        size="lg"
                        color="#73879c"
                      />
                    </button>
                  ) : null}
                  <button
                    className="arrowDownContainer header-icon-button icon-hover-effect"
                    title="Refresh"
                    onClick={() => reloadComponentData()}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      color="#73879c"
                      icon={"refresh"}
                    />
                  </button>
                  <button
                    className="arrowDownContainer header-icon-button icon-hover-effect"
                    title={toggleTableView ? "Collapse Table" : "Expand Table"}
                    onClick={() => setToggleTableView(!toggleTableView)}
                    aria-controls="table-toggle"
                    aria-expanded={toggleTableView}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      color="#73879c"
                      icon={"fa fa-angle-down"}
                      className={`${toggleTableView ? "arrow-icon-up" : "arrow-icon-down"
                        }`}
                    />
                  </button>
                </div>
              </div>
            </div>
            
          <Collapse in={toggleTableView}>
            <div id="table-toggle">
                
            {displayData?.tableSummaryConfiguration?.summaryFields?.length > 0 &&
              <TableSummary
                  summaryData={displayData?.tableSummaryConfiguration}
                  executeAppObject={executeAppObject}
                  objData={objData}
                  formConfig={objData?.page?.form_config}
                  formFieldsList={formFieldList}
                  pageFieldsList={pageFieldsListFiltered}
                  actionButtonList={displayData?.actionDetails}
                  appSessionId={appSessionId}
                  tableName={displayData?.tableName}
                  pageId={displayData?.formId}
                  sysFilter={sysFilter}
                  currentTableData={componentData?.value?.data}
                  routePageToOneStepBack={routePageToOneStepBack}
                  multipleActions={multipleActions}
                  setMultipleActions={setMultipleActions}
                  reloadComponentData={reloadComponentData}
                  addComponentToReloadArray={addComponentToReloadArray}
                  component_id={component_id}
                  isCacheEnabled={isCacheEnabled}
                  tempStorageCall={tempStorageCall}
                />
              }          

              <FilterComponent
                show={toggleFilter}
                setResetFilter={setResetFilter}
                onChange={getFilterCriteria}
                filterCriteriaList={sysFilter.FilterCriteria}
                columnList={
                  pageFieldsListFiltered?.map((field) => {
                    return {
                      label: field.display_name,
                      value: field.name,
                      dataType: field.data_type,
                    };
                  }) || []
                }
                savefilterId={objData?.page?.page_id}
                datasetName="sys_app_data_filter"
                savedFilterApiBody={savedFilterListApiBody}
              />

              {displayData?.disclaimerNote && toggleTableView && (
                <div>
                  <h6 className="text-danger disclaimerText">
                    *Note:- {displayData?.disclaimerNote}
                  </h6>
                </div>
              )}

              <Split
                onDragEnd={(e) => {
                  setViewWidthState(e);
                }}
                className="split"
                sizes={viewWidthState}
                gutterSize={viewUpdateAddState ? 6 : 0}
                minSize={viewUpdateAddState ? 300 : 0}
              >
                <div
                  className={`formViewWidth viewHeight ${viewUpdateAddState ? "tableClose" : ""
                    }`}
                >
                  <div className={`${viewUpdateAddState ? "me-md-2" : ""}`}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="position-relative">
                          <div
                            className={`tableTheme1 table-responsive set_height ${displayData?.tableTheme || ""
                              }`}
                          >
                            <table className="table mb-0">
                              <Outside
                                setIndex={() => setActiveColumnFilter("")}
                                isTable={true}
                              >
                                <tr>
                                  {displayData?.actionDetails?.find(
                                    (action) =>
                                      action.buttonPlacement === "Header" &&
                                      (action?.actionType ===
                                        actionTypes.button ||
                                        !action?.actionType)
                                  ) &&
                                    newData.length !== 0 && (
                                      <th
                                        className="width-auto actionsColumn"
                                        checkBox="true"
                                      >
                                        <input
                                          className="mx-auto"
                                          type="checkbox"
                                          onChange={(e) =>
                                            selectAllActionsList(e, newData)
                                          }
                                          checked={
                                            newData.length ===
                                            multipleActions.length
                                          }
                                        />
                                      </th>
                                    )}
                                  {tableHeaderData}
                                  {actionColumnState && (
                                    <th
                                      action="true"
                                      className="width-auto actionsColumn text-center"
                                    >
                                      Actions
                                    </th>
                                  )}
                                </tr>
                              </Outside>

                              {newData?.length > 0 && (
                                <tbody>
                                  {newData?.map((serviceData, index) => (
                                    <tr
                                      key={index}
                                      className={`${multipleActions.includes(
                                        serviceData._id?.$oid
                                      ) && "checkedRow"
                                        } ${viewUpdateAddData._id?.$oid &&
                                          viewUpdateAddData._id?.$oid ===
                                          serviceData._id?.$oid
                                          ? "checkedViewRow"
                                          : ""
                                        }`}
                                    >
                                      {displayData?.actionDetails?.find(
                                        (action) =>
                                          action.buttonPlacement === "Header" &&
                                          (action?.actionType ===
                                            actionTypes.button ||
                                            !action?.actionType)
                                      ) && (
                                          <td checkBox="true">
                                            <input
                                              className="mx-auto"
                                              type="checkbox"
                                              onChange={(e) =>
                                                selectActionsList(
                                                  e,
                                                  serviceData._id?.$oid
                                                )
                                              }
                                              checked={multipleActions.includes(
                                                serviceData._id?.$oid
                                              )}
                                            />
                                          </td>
                                        )}
                                      <TableRowContext.Provider
                                        value={{
                                          reloadComponentData,
                                          fieldApiBackUpObject,
                                        }}
                                      >
                                        <RowLineEdit
                                          tableButtonLoading={
                                            tableButtonLoading
                                          }
                                          openModelData={openModelData}
                                          openCheckListModal={
                                            openCheckListModal
                                          }
                                          openInlineFormTableModal={
                                            openInlineFormTableModal
                                          }
                                          actionColumnState={actionColumnState}
                                          actionButtonsList={actionButtonsList}
                                          updateAction={updateAction}
                                          viewAction={viewAction}
                                          cloneAction={cloneAction}
                                          serviceData={serviceData}
                                          pageFieldsList={pageFieldsListFiltered}
                                          appSessionId={appSessionId}
                                          privilegeButtons={privilegeButtons}
                                          onButtonActionClick={
                                            onButtonActionClickHandler
                                          }
                                          setTempStorageCall={
                                            setTempStorageCall
                                          }
                                          updateParentRowData={updateParentRowData}
                                          parentRowIndex={index}
                                        />
                                      </TableRowContext.Provider>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            </table>
                          </div>
                          {componentDataLoading && (
                            <div className="tableSpinner">
                              <div className="loader-circle-sm" />
                            </div>
                          )}

                          {newData?.length === 0 && !componentDataLoading && (
                            <div className="tableSpinner">
                              No data to display
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <TablePagination
                      displayData={displayData}
                      totalCount={componentData?.total_count}
                      activePage={activePage}
                      paginationData={(pageNumber) => {
                        if (
                          !componentDataLoading &&
                          activePage !== pageNumber
                        ) {
                          paginationData(pageNumber);
                        }
                      }}
                    />
                  </div>
                </div>

                <div
                  className={`formViewWidth ${viewUpdateAddState ? "formViewOpen" : ""
                    }`}
                >
                  <div>
                    <ViewUpdateAdd
                      formConfig={objData?.page?.form_config}
                      reloadComponentData={reloadComponentData}
                      appSessionId={appSessionId}
                      viewUpdateAddState={viewUpdateAddState}
                      actions={displayData}
                      viewUpdateAddData={viewUpdateAddData}
                      viewFieldsList={pageFieldsList}
                      formFieldsList={formFieldList}
                      changeViewPageState={() => {
                        setViewUpdateAddState("");
                        setViewWidthState([100, 0]);
                        setViewUpdateAddData({});
                      }}
                      datasetName={componentData?.value?.table_name}
                      viewWidthState={viewWidthState}
                      relationData={displayData?.relations}
                      currentActionDetails={currentActionDetails}
                      openModelData={openModelData}
                      openInlineFormTableModal={openInlineFormTableModal}
                      openCheckListModal={openCheckListModal}
                    />
                  </div>
                </div>
              </Split>
            </div>
          </Collapse>

          <ApproveModal
            serviceRowData={viewUpdateAddData}
            showApproveModal={showApproveModal}
            handleModalClose={handleModalClose}
            currentActionDetails={currentActionDetails}
            appSessionId={appSessionId}
            routePageToOneStepBack={routePageToOneStepBack}
            pageFields={formFieldList}
            addComponentToReloadArray={addComponentToReloadArray}
          />
          <LineItemModal
            lineItemsModalState={lineItemsModalState}
            setLineItemsModalState={setLineItemsModalState}
            lineItemHeaderFieldData={lineItemHeaderFieldData}
            showLineItemData={lineItemData}
          />
          <CheckListModal
            checkListModalState={checkListModalState}
            checkListHeaderData={checkListHeaderData}
            setCheckListModalState={setCheckListModalState}
            checkListData={checkListData}
          />
          <InlineFormModal
            appSessionId={appSessionId}
            inlineFormModalData={inlineFormModalData}
            handleModal={() => setInlineFormModalData({})}
          />
        </div>
      </div>
    </Col>
  );
};

export default SubTable;
