import React, { useContext, useMemo, useState, useEffect } from "react";
import "./Kanban.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Spinner, Form, Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import KanbanLane from "./KanbanLane";
import { DragDropContext } from "react-beautiful-dnd";
import { FilterFormContext } from "../contexts/FilterFormContext";
import { executeAppForActions, reactAppExecutionVP } from "../../../services/API";
import { toast } from "react-toastify";
import { filterFormDataGetter } from "./kanbanCardUtils";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import Badge from "react-bootstrap/Badge";

const Kanban = ({
  appSessionId,
  componentData,
  displayData,
  objData,
  submitFormPayload,

}) => {

  const { filterFormData } = useContext(FilterFormContext);
  const [toggleBoardView, setToggleBoardView] = useState(true);
  const [KanbanData, setKanbanData] = useState(null);
  const [cardSpacing, setCardSpacing] = useState(3);
  const [searchTerm, setSearchTerm] = useState(""); // Free text search term
  const [fieldSearchTerm, setFieldSearchTerm] = useState(""); // Field-level search term
  const [selectedField, setSelectedField] = useState(null); // Selected field for field-level search
  const [filteredKanbanData, setFilteredKanbanData] = useState(null); // Filtered data based on search
  const [boardData, setBoardData] = useState({
    pageName: objData?.page?.function_name,
    boardId: displayData?.component_id,
    lanes: displayData?.lanes.reduce(
      (obj, item) => Object.assign(obj, { [item.id]: { ...item, cardIds: [] } }),
      {}
    ),
    laneOrder: displayData?.lanes.map((lane) => lane.id),
  });
  const [isLoading, setIsLoading] = useState(true);
  const [srno, setSrno] = useState(0);
 
  // Fetch fields for dropdown from displayData.displayFields
  const fieldOptions = displayData?.displayFields?.filter(
    (field) => field.is_hidden === false
  ) || [];

  // added this code
  let displayFieldArray = objData?.page?.display_fields;
  let componentLogic = displayFieldArray[0]?.componentLogic;
  let refValue = displayFieldArray[0]?.refValue;
  let executeAppObject = {
    data: {
      logic: [componentLogic],
    },
    referenceValue: [{ refValue: refValue }],
    app_session_id: appSessionId,
    sys_filter: {FilterCriteria: [], SortCriteria: []},
  };

  // Fetch board data
  const fetchBoardData = async () => {
    try {
      const response = await reactAppExecutionVP(executeAppObject);
      const kanbanData = response?.data?.[0]?.response?.value?.data;

      setKanbanData(kanbanData);
      setFilteredKanbanData(kanbanData); // Initialize filtered data

      if (!Array.isArray(kanbanData) || kanbanData.length === 0) {
        setSrno(0);
        return;
      }

      // Filter out deleted cards
      const activeKanbanData = kanbanData.filter(item => item.is_Deleted!=="yes");

      setSrno(Math.max(...activeKanbanData.map((item) => item.srno || 0), 0));

      const updatedBoardData = {
        pageName: objData?.page?.function_name,
        boardId: displayData?.component_id,
        lanes: displayData?.lanes.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: { ...item, cardIds: [] } }),
          {}
        ),
        laneOrder: displayData?.lanes.map((lane) => lane.id),
        cards: {},
      };

      // Group cards by lane (status) and sort by sys_priority
      const cardsByLane = {};
      activeKanbanData.forEach((item) => {
        const cardKey = item.srno.toString();
        const status = item[displayData?.statusFieldName]?.trim(); // Use dynamic status field

        if (!cardsByLane[status]) {
          cardsByLane[status] = [];
        }
        cardsByLane[status].push({
          ...item,
          cardKey,
        });
      });

      // Sort cards within each lane by sys_priority (1-based)
      Object.keys(cardsByLane).forEach((status) => {
        cardsByLane[status].sort((a, b) => (a.sys_priority ?? Infinity) - (b.sys_priority ?? Infinity));
      });

      // Populate lanes with sorted card IDs and update cards
      activeKanbanData.forEach((item) => {
        const cardKey = item.srno.toString();
        const status = item[displayData?.statusFieldName]?.trim();
        const lane = Object.values(updatedBoardData.lanes).find(
          (l) => l.title === status
        );

        if (lane) {
          // Add card to lane in the correct order
          if (!lane.cardIds.includes(cardKey)) {
            lane.cardIds = cardsByLane[status].map((card) => card.cardKey);
          }

          const { kanban_structure, card_data, ...cleanedCard } = item;
          updatedBoardData.cards[cardKey] = {
            ...cleanedCard,
            srno: item.srno,
            [displayData?.statusFieldName]: status,
            sys_priority: item.sys_priority ?? lane.cardIds.length, // Fallback to 1-based index if sys_priority is not set
          };
        } else {
          console.warn(`No lane found for ${displayData?.statusFieldName}: ${status}`);
        }
      });

      setBoardData(updatedBoardData);
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to fetch Kanban data");
      setIsLoading(false);
    }
  };

  // Run fetchBoardData inside useEffect
  useEffect(() => {
    fetchBoardData();
  }, []);

  // Search functionality (both free text and field-level)
  useEffect(() => {
    if (!KanbanData) {
      setFilteredKanbanData(null);
      return;
    }

    let filtered = KanbanData;

    // Apply free text search
    if (searchTerm) {
      filtered = filtered.filter((card) =>
        Object.values(card).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Apply field-level search
    if (selectedField && fieldSearchTerm) {
      filtered = filtered.filter((card) =>
        String(card[selectedField.value])
          .toLowerCase()
          .includes(fieldSearchTerm.toLowerCase())
      );
    }

    setFilteredKanbanData(filtered);

    // Update boardData based on filtered data
    const updatedBoardData = {
      ...boardData,
      lanes: displayData?.lanes.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: { ...item, cardIds: [] } }),
        {}
      ),
      cards: {},
    };

    filtered
      .filter((item) => !item.is_Deleted)
      .forEach((item) => {
        const cardKey = item.srno.toString();
        const status = item[displayData?.statusFieldName]?.trim();
        const lane = Object.values(updatedBoardData.lanes).find(
          (l) => l.title === status
        );

        if (lane) {
          if (!lane.cardIds.includes(cardKey)) {
            lane.cardIds.push(cardKey);
          }

          const { kanban_structure, card_data, ...cleanedCard } = item;
          updatedBoardData.cards[cardKey] = {
            ...cleanedCard,
            srno: item.srno,
            [displayData?.statusFieldName]: status,
            sys_priority: item.sys_priority ?? lane.cardIds.length, // Fallback to 1-based index if sys_priority is not set
          };
        }
      });

    // Sort cardIds in each lane by sys_priority after filtering (1-based)
    Object.values(updatedBoardData.lanes).forEach((lane) => {
      const laneCards = lane.cardIds
        .map((cardId) => updatedBoardData.cards[cardId])
        .sort((a, b) => (a.sys_priority ?? Infinity) - (b.sys_priority ?? Infinity));
      lane.cardIds = laneCards.map((card) => card.srno.toString());
    });

    setBoardData(updatedBoardData);
  }, [searchTerm, fieldSearchTerm, selectedField, KanbanData]);

  useMemo(() => {
    if (componentData?.kanban_structure?.cards) {
      setBoardData((prev) => ({
        ...prev,
        ...componentData.kanban_structure,
      }));
    } else if (Object.values(filterFormData || {}).every((value) => value === "")) {
      setBoardData((prev) => ({
        ...prev,
        cards: {},
        lanes: displayData?.lanes.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: { ...item, cardIds: [] } }),
          {}
        ),
      }));
    }
  }, [componentData, filterFormData]);

  useMemo(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, [componentData]);

  const handleOnDragEnd = async (result) => {
    try {
      const { destination, source, draggableId } = result;
  
      if (
        !destination ||
        (destination.droppableId === source.droppableId && destination.index === source.index)
      ) {
        return;
      }
  
      const start = boardData.lanes[source.droppableId];
      const finish = boardData.lanes[destination.droppableId];
      const draggedCard = boardData.cards[draggableId];
      const cardSrno = draggedCard.srno;
  
      if (!cardSrno) {
        console.error("Card srno is missing!");
        return;
      }
  
      const newStatus = finish.title;
      const startLaneName = start.title;
      const endLaneName = finish.title;
  
      // Remove the dragged card from the source lane
      const newStartCardIds = start.cardIds.filter((id) => id !== draggableId);
  
      // Create the new card IDs array for the destination lane
      const newFinishCardIds = [...finish.cardIds.filter((id) => id !== draggableId)]; // Avoid duplicates
      newFinishCardIds.splice(destination.index, 0, draggableId);
  
      // Update sys_priority for all cards in the destination lane (1-based)
      const updatedCards = { ...boardData.cards };
      newFinishCardIds.forEach((cardId, index) => {
        updatedCards[cardId] = {
          ...updatedCards[cardId],
          [displayData?.statusFieldName]: newStatus,
          sys_priority: index + 1, // 1-based indexing
        };
      });
  
      // Update sys_priority for remaining cards in the source lane (if different lane)
      if (start.id !== finish.id) {
        newStartCardIds.forEach((cardId, index) => {
          updatedCards[cardId] = {
            ...updatedCards[cardId],
            sys_priority: index + 1, // 1-based indexing
          };
        });
      }
  
      // Update boardData
      const updatedBoardData = {
        ...boardData,
        lanes: {
          ...boardData.lanes,
          [start.id]: { ...start, cardIds: newStartCardIds },
          [finish.id]: { ...finish, cardIds: newFinishCardIds },
        },
        cards: updatedCards,
      };
      setBoardData(updatedBoardData);
  
      // Update KanbanData
      const updatedKanbanData = KanbanData.map((card) => {
        const cardId = card.srno.toString();
        if (newFinishCardIds.includes(cardId)) {
          return {
            ...card,
            [displayData?.statusFieldName]: newStatus,
            sys_priority: updatedCards[cardId].sys_priority,
          };
        } else if (newStartCardIds.includes(cardId)) {
          return {
            ...card,
            sys_priority: updatedCards[cardId].sys_priority,
          };
        }
        return card;
      });
      setKanbanData(updatedKanbanData);
      setFilteredKanbanData(updatedKanbanData);
  
      // Prepare payload with all cards in the destination lane
      const payloadCards = newFinishCardIds.map((cardId, index) => ({
        srno: updatedCards[cardId].srno,
        sys_priority: index + 1, // 1-based indexing
        tracking_status: newStatus,
        start_lane_name: startLaneName,
        end_lane_name: endLaneName,
      }));
  
      // Construct the payload for executeAppForActions
      const actionPayload = {
        app_session_id: appSessionId,
        logic_name: objData?.page?.data?.updateLogic?.label,
        reference_name: objData?.page?.data?.form_output_key,
        data: payloadCards, 
      };
  
      // Send API request using executeAppForActions
      const res = await executeAppForActions(actionPayload);
  
      
      if (res?.message) {
        // toast.success(res.message);
      } else {
        throw new Error("No success message returned from the server");
      }
  
      // toast.success(`Card "${draggedCard.name}" moved to "${newStatus}"`);
    } catch (error) {
      console.error("Error in handleOnDragEnd:", error);
      toast.error(error.response?.data?.message || error.response?.message || error.message);
      setBoardData(boardData); // Revert UI on failure
    }
  };

  let boardStyle = {
    backgroundColor: displayData?.boardBgColor || "#f6f7fb",
  };

  return (
    <div className="overflow-auto kanban-board-container mb-2 p-1">
      <div className="main-content1 bg-white py-1 px-1 mb-2">
        <div className="borderBottom">
          <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
            <div className="text-capitalize d-flex align-items-center">
              <h5 className="m-0 kanban-board-heading me-3">
                {displayData?.boardName || "Board"}  
                <Badge bg="primary mx-1">{
                  (filteredKanbanData?.length === KanbanData?.length) ? (
                    <>{KanbanData?.length || 0}</>
                  ) : (
                    <>{filteredKanbanData?.length || 0} of {KanbanData?.length || 0}</>
                  )
                }
                &nbsp;cards</Badge>
              </h5>
              {/* Free Text Search */}
              <Form.Group controlId="freeTextSearch" className="me-3">
                <Form.Control
                  type="text"
                  placeholder="Search cards..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: "200px" }}
                />
              </Form.Group>
              {/* Field-Level Search */}
              <div className="d-flex align-items-center">
                <DropdownButton
                  id="field-select-dropdown"
                  title={<FontAwesomeIcon icon="fa fa-filter" />}
                  variant="light"
                  className="me-2"
                >
                  {fieldOptions.map((field, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => setSelectedField(field)}
                    >
                      {field.label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <Form.Group controlId="fieldLevelSearch">
                  <Form.Control
                    type="text"
                    placeholder={
                      selectedField
                        ? `Search by ${selectedField.label}`
                        : "Select a field to search"
                    }
                    value={fieldSearchTerm}
                    onChange={(e) => setFieldSearchTerm(e.target.value)}
                    disabled={!selectedField}
                    style={{ width: "200px" }}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {displayData?.transitionAppId && displayData?.transitionPageId ? (
                <div className="d-flex align-items-center transition-header-icon">
                  <Link
                    to={`/app_ui/${displayData?.transitionAppId}/${displayData?.transitionPageId}`}
                  >
                    <button
                      className="header-icon-button icon-hover-effect"
                      title={displayData?.transitionActionTitle ?? ""}
                    >
                      <FontAwesomeIcon
                        icon={
                          displayData?.transitionSelectedIcon?.value ??
                          "fa fa-circle-question"
                        }
                        size="md"
                      />
                    </button>
                  </Link>
                </div>
              ) : null}
              <button
                className="btn btn-sm"
                title="Extremely Compressed Layout"
                onClick={() => setCardSpacing(1)}
              >
                <FontAwesomeIcon icon="fa-solid fa-down-left-and-up-right-to-center" />
              </button>
              <button
                className="btn btn-sm"
                title="Compressed Layout"
                onClick={() => setCardSpacing(2)}
              >
                <FontAwesomeIcon icon="fa-solid fa-arrows-left-right-to-line" />
              </button>
              <button
                className="btn btn-sm"
                title="Normal Layout"
                onClick={() => setCardSpacing(3)}
              >
                <FontAwesomeIcon icon="fa-solid fa-left-right" />
              </button>
              <button
                className="btn btn-sm"
                title="Reload Data"
                onClick={() => {
                  fetchBoardData();
                  setSearchTerm(""); // Reset free text search
                  setFieldSearchTerm(""); // Reset field-level search
                  setSelectedField(null); // Reset selected field
                }}
              >
                <FontAwesomeIcon icon={"fa fa-refresh"} />
              </button>
              <button
                className="arrowDownContainer header-icon-button icon-hover-effect"
                title={toggleBoardView ? "Collapse Board" : "Expand Board"}
                onClick={() => setToggleBoardView(!toggleBoardView)}
                aria-controls="board-toggle"
                aria-expanded={toggleBoardView}
              >
                <FontAwesomeIcon
                  icon={"fa fa-angle-down"}
                  className={`arrow-icon ${
                    toggleBoardView ? "arrow-icon-up" : "arrow-icon-down"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
        <Collapse in={toggleBoardView}>
          <div id="board-toggle" className="kanban-board-container">
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : (
              <div style={boardStyle}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <div className="kanban-board">
                    <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row flex-xxl-row">
                      {boardData.laneOrder?.map((laneId) => {
                        const lane = boardData.lanes?.[laneId];
                        const filteredCards = Object.values(boardData.cards)
                          .filter(
                            (card) =>
                              card[displayData?.statusFieldName]?.trim() === lane.title &&
                              !card.is_Deleted
                          )
                          .sort((a, b) => (a.sys_priority ?? Infinity) - (b.sys_priority ?? Infinity)); // Sort cards by sys_priority (1-based)

                        return (
                          <KanbanLane
                            srno={srno}
                            fetchBoardData={fetchBoardData}
                            key={lane.id}
                            boardData={boardData}
                            setBoardData={setBoardData}
                            lane={lane}
                            cards={filteredCards}
                            displayData={displayData}
                            objData={objData}
                            appSessionId={appSessionId}
                            submitFormPayload={submitFormPayload}
                            cardSpacing={cardSpacing}
                          />
                        );
                      })}
                    </div>
                  </div>
                </DragDropContext>
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default Kanban;