import { Modal } from "react-bootstrap";

const CustomModal = ({
  modalState,
  modalTitle,
  modalDiscription,
  hideModal,
  onDeleteClick,
  isLoading,
  delText,
  cancelText,
}) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        show={modalState}
        onHide={hideModal}
        backdrop={isLoading ? "static" : true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalDiscription}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-md btn-danger"
            onClick={(e) => {
              e.stopPropagation(); // Stop propagation here
              onDeleteClick();
            }}
            disabled={isLoading}
          >
            {delText || "Delete"}
          </button>
          <button className="btn btn-md btn-secondary" onClick={hideModal}>
            {cancelText || "Cancel"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomModal;
