import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState, useEffect, useContext } from "react";
import { Col } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import "./progressList.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { reactAppExecutionVP } from "../../../services/API";
import { toast } from "react-toastify";
import AppLoader from "../../dataset/Loader/AppLoader";
import { RoutesContext } from "../../../RoutesContext";

const ProgressList = ({
  displayData,
  appSessionId,
  filterApply,
  objData,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
}) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [collapse, setCollapse] = useState(true);
  let [progress, exceed, remains] =
    displayData?.displayProgressLabel?.split(",") || [];
  const [progressData, setProgressData] = useState([]);
  const [progressLoading, setProgressLoading] = useState(false);
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const { component_id, isCacheEnabled, autoReloadComponent, autoReloadDelay } = displayData;

  useEffect(() => {
    if (
      cachedData.hasOwnProperty(component_id) &&
      !tempStorageCall &&
      isCacheEnabled
    ) {
      setProgressData(cachedData?.[component_id]);
      return;
    }
    getProgressBarData();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (filterApply) {
      getProgressBarData();
    }
  }, [filterApply]);

  useEffect(() => {
    if (autoReloadComponent && autoReloadDelay !== "") {
      const intervalId = setInterval(
        () => getProgressBarData(),
        autoReloadDelay
      );
      return () => clearInterval(intervalId);
    }
  }, []);

  const getProgressBarData = () => {
    let executeAppObject = {
      data: {
        logic:
          objData?.logic?.length > 0
            ? objData?.logic
            : [displayData?.componentLogic],
      },
      referenceValue: [{ refValue: displayData?.refValue }],
      app_session_id: appSessionId,
    };

    setProgressLoading(true);
    reactAppExecutionVP(executeAppObject, signal)
      .then((response) => {
        if (response.data?.length) {
          const componentData = response?.data.find((data) => {
            return Object.keys(data)?.[0] === displayData?.refValue;
          })?.[displayData.refValue];
          setProgressData(componentData);
          let data = cachedData;
          data[component_id] = componentData;
          setCacheData(data);
        }
        clearFilterApply();
        setTempStorageCall(false);
      })
      .catch((err) => toast.error(err.response?.data?.message || err.response?.message || err.message))
      .finally(() => setProgressLoading(false));
  };

  return (
    <Col md={displayData?.columnWidthTable || "12"} className="my-1 px-0">
      <div
        className={`${
          collapse && "h-100"
        } position-relative  mx-1 main-content bg-white  mb-2`}
      >
        <div className="py-1 px-1">
          <div className="borderBottom">
            <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
              <div className="text-capitalize">
                <h5 className="m-0 table-heading">
                  {displayData?.progressListTitle}
                </h5>
              </div>
              <div className="d-flex align-items-center">
                {displayData?.transitionAppId &&
                displayData?.transitionPageId ? (
                  <div className="d-flex align-items-center transition-header-icon">
                    <Link
                      to={`/app_ui/${displayData?.transitionAppId}/${displayData?.transitionPageId}`}
                    >
                      <button
                        className="header-icon-button icon-hover-effect"
                        title={displayData?.transitionActionTitle ?? ""}
                      >
                        <FontAwesomeIcon
                          icon={
                            displayData?.transitionSelectedIcon?.value ??
                            "external-link"
                          }
                          size="md"
                        />
                      </button>
                    </Link>
                  </div>
                ) : null}
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title="Refresh"
                  onClick={getProgressBarData}
                  aria-controls="chart-toggle"
                >
                  <FontAwesomeIcon icon={"refresh"} className={`arrow-icon`} />
                </button>
                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title={collapse ? "Collapse" : "Expand"}
                  onClick={() => setCollapse(!collapse)}
                  aria-controls="table-toggle"
                  aria-expanded="true"
                >
                  <FontAwesomeIcon
                    icon={"fa fa-angle-down"}
                    className={`arrow-icon ${
                      collapse ? "arrow-icon-up" : "arrow-icon-down"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
          {progressLoading && <AppLoader />}
          <Collapse in={collapse}>
            <div id="progress-toggle">
              {progressData?.value?.length > 0 ? (
                <div className="d-flex flex-wrap justify-content-end mb-2">
                  <div className="d-flex me-2 mt-1 align-items-center">
                    <div
                      className="me-1 progreeIndicator"
                      style={{ background: displayData?.progressColor }}
                    ></div>
                    <span className="fw-bold">{progress || "Used"}</span>
                  </div>
                  <div className="d-flex me-2 mt-1 align-items-center">
                    <div
                      className="me-1 progreeIndicator"
                      style={{
                        background:
                          displayData?.progressExceedColor || "#e44d42",
                      }}
                    ></div>
                    <span className="fw-bold">{exceed || "Exceed"}</span>
                  </div>
                  <div className="d-flex mt-1 align-items-center">
                    <div
                      className="me-1 progreeIndicator"
                      style={{ background: "#B0BEC5" }}
                    ></div>
                    <span className="fw-bold">{remains || "Remains"}</span>
                  </div>
                </div>
              ) : null}

              <div className="maxDisplayToggle pe-1">
                {progressData?.value?.length ? (
                  progressData?.value?.map((data, index) => {
                    return (
                      <div
                        className="maxDisplayToggle d-flex align-items-center mb-2 progressContainer"
                        key={index}
                      >
                        <span
                          className="me-2 textContainer"
                          title={data?.[displayData?.progressTitleKey]}
                        >
                          {data?.[displayData?.progressTitleKey]}
                        </span>
                        <CustomProgressBar
                          actual={data?.[displayData?.progressKey] || 0}
                          progressColor={displayData?.progressColor}
                          total={data?.[displayData?.progressCompareKey]}
                          progressExceedColor={displayData?.progressExceedColor}
                          className="progreessBar"
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="text-center no-data "
                    style={{ minHeight: "80px" }}
                  >
                    {!progressLoading && "No data to display"}
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </Col>
  );
};
export default ProgressList;

const CustomProgressBar = ({
  actual,
  progressColor,
  progressExceedColor,
  className,
  total,
}) => {
  return (
    <div
      className={`progress flex-grow-1 ${className}`}
      style={{ height: "24px" }}
    >
      {actual > total ? (
        <>
          <OverlayTrigger
            flip
            delay={{ show: 250, hide: 250 }}
            overlay={
              <ProgressTooltip count={Math.abs(total)} percentage={"100.00"} />
            }
          >
            <div
              className="progress-bar progress-bar-striped pointer"
              role="customprogressbar"
              style={{
                width: `${100}%`,
                backgroundColor: progressColor,
              }}
              aria-valuenow={100}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span style={{ fontSize: "13px" }}>{100}%</span>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            flip
            delay={{ show: 250, hide: 250 }}
            overlay={
              <ProgressTooltip
                count={Math.abs(total - actual)}
                percentage={((Math.abs(total - actual) / total) * 100).toFixed(
                  2
                )}
              />
            }
          >
            <div
              className="progress-bar progress-bar-striped pointer"
              style={{
                width: `${(Math.abs(total - actual) / total) * 100}%`,
                backgroundColor: progressExceedColor || "#e44d42",
              }}
            >
              <span style={{ fontSize: "13px" }}>
                {((Math.abs(total - actual) / total) * 100) % 1 === 0
                  ? ((Math.abs(total - actual) / total) * 100).toFixed(1)
                  : ((Math.abs(total - actual) / total) * 100).toFixed(2)}
                %
              </span>
            </div>
          </OverlayTrigger>
        </>
      ) : (
        <>
          <OverlayTrigger
            flip
            delay={{ show: 250, hide: 250 }}
            overlay={
              <ProgressTooltip
                count={Math.abs(actual)}
                percentage={((actual / (total || 100)) * 100).toFixed(2)}
              />
            }
          >
            <div
              className="progress-bar progress-bar-striped pointer"
              role="customprogressbar"
              style={{
                width: `${(actual / (total || 100)) * 100}%`,
                backgroundColor: progressColor,
              }}
              aria-valuenow={100}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span style={{ fontSize: "13px" }}>
                {(Math.abs((actual / (total || 100)) * 100) * 100) % 1 === 0
                  ? Math.abs((actual / (total || 100)) * 100).toFixed(1)
                  : Math.abs((actual / (total || 100)) * 100).toFixed(2)}
                %
              </span>
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            flip
            delay={{ show: 250, hide: 250 }}
            overlay={
              <ProgressTooltip
                count={Math.abs(total - actual)}
                percentage={(100 - (actual / (total || 100)) * 100).toFixed(2)}
              />
            }
          >
            <div
              className="progress-bar pointer"
              role="customprogressbar"
              style={{
                width: `${(100 - (actual / (total || 100)) * 100).toFixed(2)}%`,
                backgroundColor: "#B0BEC5",
              }}
              aria-valuenow={100}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span style={{ fontSize: "13px" }}>
                {(100 - (actual / (total || 100)) * 100).toFixed(2)}%
              </span>
            </div>
          </OverlayTrigger>
        </>
      )}
    </div>
  );
};

// Overlay with props has to use ref
const ProgressTooltip = React.forwardRef((props, ref) => {
  return (
    <Tooltip {...props} ref={ref}>
      <div className="text-center">
        <p className="mb-0">{props?.count}</p>
        <span style={{ fontSize: "13px" }}>{props?.percentage || 0}%</span>
      </div>
    </Tooltip>
  );
});
