import React, { Component } from "react";
import { toast } from "react-toastify";
import Loader from "../../dataset/Loader/Loader";
import {
  deleteScheduleRecord,
  fetchAllSchedules,
  runSchedule,
} from "../../../services/appService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import "./schedule.scss";
import Pagination from "react-js-pagination";

export class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      isWrite: false,
      scheduleData: [],
      filterCriteriaData: [],
      loading: true,
      currentPage: 1,
      totalRecordCount: 0,
      dataPerPage: 20, // Set to 10 records per page
      errorMsg: "",
      headers: [
        "Sr No.",
        "Schedule Name",
        "Schedule",
        "Activation Status",
        "Current Status",
        "Last Status",
        "Last Error",
        "Last Run Date",
        "Last Duration",
        "App",
        "Logic",
        "Parameter (JSON)",
        "Next Run Datetime",
        "No. Of Retries",
        "Max Runtime",
        "Action",
        "Run Now",
      ],
      runningState: false,
      runIndex: -1,
      searchText: "",
    };
  }
  componentDidMount() {
    this.getPrivilages();
    this.getScheduleListData();
    this.mounted = true;
  }

  getPrivilages = () => {
    if (this.state.userInfo.privileges) {
      const { standard_privileges } = this.state.userInfo.privileges;
      standard_privileges.forEach((data) => {
        if (data["Schedules"]) {
          this.setState({ isWrite: data["Schedules"]?.write });
        }
      });
    } else {
      this.setState({ isWrite: true });
    }
  };

  runSingleSchedule(scheduleId, index) {
    confirmAlert({
      message:
        "Please confirm, you want to run this schedule immediately. The job will run in the background?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ runningState: true, runIndex: index });
            runSchedule(scheduleId)
              .then((response) => {
                this.setState({ runningState: false });
                toast.success(response.data || response.message);
              })
              .catch((err) => {
                this.setState({ runningState: false });
                toast.error(err.message || err.data);
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteSchedule(obj_id) {
    confirmAlert({
      message: "Are you sure you want to delete this schedule permanently?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let objData = { data: [obj_id] };
            deleteScheduleRecord(objData)
              .then((response) => {
                toast.success(response.message);
                this.getScheduleListData();
              })
              .catch((err) => {
                this.setState({ loading: false, errorMsg: true }, () => {
                  toast.error(err.response?.data?.message || err.response?.message || err.message);
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  getScheduleListData() {
    this.setState({ loading: true, scheduleData: [] }); // Clear previous data
    const requestBody = {
      FilterCriteria: this.state.filterCriteriaData,
      SortCriteria: [],
      PaginationCriteria: {
        limit: this.state.dataPerPage,
        skip: (this.state.currentPage - 1) * this.state.dataPerPage,
      },
    };
  
    fetchAllSchedules(requestBody)
      .then((response) => {
        if (this.mounted && response.success) {
          this.setState({
            scheduleData: response?.data || [],
            totalRecordCount: response?.totalcount || 0,
          });
        } else if (!response.success) {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        if (this.mounted) {
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  fetchAllDetails = () => {
    const { searchText } = this.state;
  
    let filterCriteriaData = [];
    if (searchText) {
      filterCriteriaData.push({
        filter_type: "text",
        type: "contains",
        filter: searchText,
        filter_field: "schedule_name",
      });
    }
  
    this.setState(
      {
        filterCriteriaData,
        currentPage: 1, // Reset to the first page for new search
      },
      () => {
        this.getScheduleListData();
      }
    );
  };
  
  

  receiveCurrentPageNumber = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getScheduleListData(); // Fetch data for the new page
    });
  };
  

  componentDidUpdate(prevProps, prevState) {
    // Fetch new data if currentPage changes
    if (prevState.currentPage !== this.state.currentPage) {
      this.getScheduleListData();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const setHeaderData = this.state.isWrite
      ? this.state.headers.map((header, index) => (
          <th className="text-nowrap" key={index}>
            {header}
          </th>
        ))
      : this.state.headers
          .filter((header) => header !== "Action")
          .map((header, index) => (
            <th className="text-nowrap" key={index}>
              {header}
            </th>
          ));

          const setTableData = this.state.scheduleData.map((tableData, index) => (
            <tr key={tableData._id.$oid}> {/* Use a unique key for each row */}
              <td>
                {(this.state.currentPage - 1) * this.state.dataPerPage + index + 1}
              </td>
              <td className="max_width" title={tableData.schedule_name}>
                {tableData.schedule_name}
              </td>
              <td className="max_width">{tableData.cron_string}</td>
              <td className="max_width">
                {tableData.activation_status ? "Active" : "Inactive"}
              </td>
              <td className="max_width">
                {tableData.status}&nbsp;&nbsp;&nbsp;
                {tableData.status === "Running" ? (
                  <FontAwesomeIcon icon="fa-solid fa-spinner" spinPulse />
                ) : null}
              </td>
              <td className="max_width" title={tableData.last_error}>
                {tableData.last_status}&nbsp;&nbsp;&nbsp;
                {tableData.last_status === "ERROR" ? (
                  <FontAwesomeIcon
                    icon="fa-solid fa-triangle-exclamation"
                    style={{ color: "#e64561" }}
                  />
                ) : null}
              </td>
              <td className="max_width" title={tableData.last_error}>
                {tableData.last_error}
              </td>
              <td className="max_width">{tableData.last_run_time}</td>
              <td className="max_width">{tableData.duration}</td>
              <td className="max_width">{tableData.app_name}</td>
              <td className="max_width" title={tableData.logic_name}>
                {tableData.logic_name}
              </td>
              <td className="max_width" title={tableData.parameters}>
                {tableData.parameters}
              </td>
              <td className="max_width">{tableData.next_run_time}</td>
              <td className="max_width">{tableData.no_of_retries}</td>
              <td className="max_width">{tableData.max_run_time}</td>
              {this.state.isWrite ? (
                <td className="text-nowrap">
                  <div className="d-flex text-center m-0 justify-content-center">
                    <div className="action-icons text-center">
                      <Link to={`update-schedule/${tableData._id.$oid}`}>
                        <FontAwesomeIcon
                          icon={"fa fa-pen-to-square"}
                          className="me-2"
                        />
                      </Link>
                      &nbsp;&nbsp;
                      <FontAwesomeIcon
                        role="button"
                        icon={"fa fa-trash"}
                        className="me-2"
                        onClick={() => this.deleteSchedule(tableData._id.$oid)}
                      />
                    </div>
                  </div>
                </td>
              ) : null}
              <td>
                {this.state.runningState && this.state.runIndex === index ? (
                  <div className="spinner-border spinner-border-sm" />
                ) : (
                  <div className="action-icons text-center">
                    <FontAwesomeIcon
                      role="button"
                      icon={"fa fa-play"}
                      className="me-2 fa-xl"
                      title="Run scheduled job in background"
                      onClick={() =>
                        this.runSingleSchedule(tableData._id.$oid, index)
                      }
                    />
                  </div>
                )}
              </td>
            </tr>
          ));
          

    return (
      <div>
        <div className="d-help py-2 flex-wrap">
          <h5>Schedules</h5>
          <div className="d-flex">
            <FontAwesomeIcon
              role="button"
              icon={"fa fa-solid fa-rotate-right"}
              className="me-2 fa-xl mt-2"
              title="Reload Schedules"
              onClick={() => this.getScheduleListData()}
            />
            &nbsp;&nbsp;
            <div className="input-group input-group-sm mb-0">
              <span className="input-group-text" id="basic-addon1">
                <FontAwesomeIcon icon={"fa fa-search"} onClick={this.fetchAllDetails} />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Search Schedule"
                value={this.state.searchText}
                onChange={(e) => this.setState({ searchText: e.target.value })}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.fetchAllDetails();
                  }
                }}
              />
            </div>
            {this.state.isWrite ? (
              <Link to={"/create-schedule"}>
                <button
                  type="button"
                  className="add-btn mx-1 text-nowrap "
                >
                  &nbsp;&nbsp;
                  <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                  &nbsp;&nbsp;Create Schedule&nbsp;&nbsp;
                </button>
              </Link>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="table-responsive table-hover">
              {this.state.loading ? (
                <Loader />
              ) : (
                <table className="table  table-bordered mb-0">
                  <thead>
                    <tr className="head-row">{setHeaderData}</tr>
                  </thead>
                  <tbody>
                    {this.state.totalRecordCount !== 0 ? (
                      setTableData
                    ) : (
                      <tr>
                        <td
                          colSpan={this.state.headers.length}
                          className="no_table_records"
                        >
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <div className="dynamic_pagination justify-content-between">
          {this.state.totalRecordCount > 0 && (
            <div className="my-2">
              Showing{" "}
              {(this.state.currentPage - 1) * this.state.dataPerPage + 1} to{" "}
              {Math.min(
                this.state.currentPage * this.state.dataPerPage,
                this.state.totalRecordCount
              )}{" "}
              of {this.state.totalRecordCount} entries
            </div>
          )}
          {this.state.totalRecordCount > this.state.dataPerPage && !this.state.loading ? (
            <div className="dynamic_pagination my-2">
              <Pagination
  pageRangeDisplayed={3}
  activePage={this.state.currentPage}
  itemsCountPerPage={this.state.dataPerPage}
  totalItemsCount={this.state.totalRecordCount}
  onChange={this.receiveCurrentPageNumber}
/>

            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Schedule;
