import React, { useEffect, useState } from "react";
import {
  fetchproductByCategory,
  sendEmailToSubAdmin,
} from "../../../../services/appService";
import dummyImage from "../../../../images/dummy-image.jpg";
import "./ProductList.scss";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import {
  cancelRequest,
  getCancelTokenRef,
  isRequestCancel,
} from "../../../../services/axiosApp";
import { AddToCart } from "./AddToCart";
import FilterComponent from "../../../common/filterComponent/FilterComponent";
import { productDefaultColumnHeaderData } from "../../../common/model/ProductModel";

function ProductList({ category_id, storeId, activeHierarchyObject }) {
  const headerData = productDefaultColumnHeaderData;
  const history = useHistory();
  const [productList, setProductList] = useState([]);
  const [hidePriceInformation, setHidePriceInformation] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getQuoteModal, setGetQuoteModal] = useState(false);
  const [details, setDetails] = useState({ name: "", number: "" });
  const [productId, setProductId] = useState("");
  const [error, setError] = useState({});
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [toggleFilter, setToggleFilter] = useState(true);

  useEffect(() => {
    setFilterCriteria([]);
    setToggleFilter(!toggleFilter);
  }, [category_id]);

  useEffect(() => {
    let gen = getCancelTokenRef();
    setProductList([]);
    setLoadMoreLoader(false);
    setActivePage(1);
    setLimit(10);
    setTotalRecord(0);
    if (category_id) {
      setIsLoading(true);
      fetchProductList(gen.token, []);
    }

    return () => {
      cancelRequest(gen);
    };
  }, [category_id, storeId, filterCriteria]);

  useEffect(() => {
    let gen = getCancelTokenRef();
    if (category_id) {
      setLoadMoreLoader(true);
      fetchProductList(gen.token, productList);
    } else {
      setProductList([]);
    }

    return () => {
      cancelRequest(gen);
    };
  }, [limit, activePage]);

  // fetch Product List
  const fetchProductList = (token, previousProduct) => {
    let bodyData = {
      category_id: [category_id],
      FilterCriteria: filterCriteria,
      SortCriteria: [],
      PaginationCriteria: {
        limit: limit,
        skip: (activePage - 1) * limit,
      },
    };

    fetchproductByCategory(bodyData, storeId, token)
      .then((response) => {
        setIsLoading(false);
        setLoadMoreLoader(false);
        setHidePriceInformation(response?.hidePriceInformation === "yes");
        let data = response?.data || [];
        if (data?.length > 0) {
          setProductList([...previousProduct, ...data]);
          setTotalRecord(response?.totalcount);
        }
      })
      .catch((error) => {
        if (!isRequestCancel(error)) {
          setLoadMoreLoader(false);
          setIsLoading(false);
        }
      });
  };
  //   route To Product Details
  const routeToProductDetails = (data) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history.push(`/app_product_view/${data._id.$oid}`);
  };

  const handleGetQuote = (product_id) => {
    setGetQuoteModal(true);
    setProductId(product_id);
  };

  const validateFormField = () => {
    let formIsValid = true;
    let error = {};
    if (details.number === "") {
      formIsValid = false;
      error["numberError"] = "Please Enter Your Number";
    }

    if (details.name === "") {
      formIsValid = false;
      error["nameError"] = "Please Enter Your Name";
    }
    setError(error);
    return formIsValid;
  };

  const sendEmail = () => {
    if (validateFormField()) {
      let sendEmailBody = {
        user_detail: {
          name: details.name,
          contact: details.number,
          email: details.email || "",
        },
      };
      sendEmailToSubAdmin(sendEmailBody, productId)
        .then((res) => {
          if (res.success) {
            toast.success(res.message);
            setGetQuoteModal(false);
            setDetails({ name: "", number: "" });
          }
        })
        .catch((err) => {
          setDetails({ name: "", number: "" });
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        });
    }
  };

  const handleDetails = (e) => {
    const data = { ...details };
    data[e.target.name] = e.target.value;
    setDetails(data);
  };

  const hideModal = () => {
    setGetQuoteModal(false);
    setError({});
    setDetails({ name: "", number: "" });
  };

  let savedFilterListApiBody = {
    FilterCriteria: [
      {
        filter_type: "text",
        type: "equal",
        filter: JSON.parse(localStorage.getItem("userid")),
        filter_field: "user_id",
      },
    ],
    export: true,
  };

  return (
    <div className="p-2 border-top">
      <div className="d-help">
        <h6 className="pt-2">{activeHierarchyObject?.category_name}</h6>
        <div>
          <button
            onClick={() => setToggleFilter(!toggleFilter)}
            className="header-icon-button icon-hover-effect"
            title="Filter"
          >
            <FontAwesomeIcon icon="fa fa-filter" size="lg" color="#73879c" />
          </button>
        </div>
      </div>

      <div className="container p-0">
        <FilterComponent
          show={toggleFilter}
          setResetFilter={() => setFilterCriteria([])}
          onChange={setFilterCriteria}
          filterCriteriaList={filterCriteria}
          columnList={headerData}
          datasetName="product_filter"
          savedFilterApiBody={savedFilterListApiBody}
        />

        {productList.length > 0
          ? productList?.map((data, index) =>
              data.product_status === true ? (
                <div
                  role="button"
                  className="product-wrapper my-2 p-2"
                  onClick={() => routeToProductDetails(data)}
                  key={index}
                >
                  <div className="row">
                    <div className="col-md-3 col-sm-12 col-12 dynamic-border">
                      <div className="img-wrapper mb-sm-2 mb-md-0">
                        <img
                          src={
                            data.product_images?.length
                              ? data.product_images[0]
                              : dummyImage
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 col-12">
                      <div className="content-wrapper text-capitalize">
                        <div className="top-content">
                          <h6 className="fw-bold responsive-header">
                            {data.product_name}
                          </h6>
                          <p>
                            {data.product_short_description.length > 92
                              ? data.product_short_description.slice(0, 95) +
                                "..."
                              : data.product_short_description}
                          </p>
                        </div>
                        <div className="middle-content my-2 d-flex flex-wrap">
                          {data.product_tag?.length > 0 ? (
                            data.product_tag.map((tags, index) => (
                              <div key={index} className="custom-tag mb-2">
                                <FontAwesomeIcon
                                  icon="fa fa-tag"
                                  className="me-2"
                                />
                                <span>{tags.text}</span>
                              </div>
                            ))
                          ) : (
                            <>
                              <div className="custom-tag">
                                <FontAwesomeIcon
                                  icon="fa fa-tag"
                                  className="me-2"
                                />
                                <span>No Tags</span>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="d-help">
                          <div className="price text-dark 46 d-help">
                            {/* <p className="text-warning m-0 me-4 text-sm">
                      25% off
                    </p> */}
                            <p className="fw-bold m-0">
                              {data.get_quote ? (
                                <button
                                  className="btn btn-light border btn-md fw-bold"
                                  onClick={() => handleGetQuote(data._id.$oid)}
                                >
                                  <FontAwesomeIcon
                                    icon="fa fa-envelope-o"
                                    className="me-2"
                                    aria-hidden="true"
                                  />
                                  Get Quote
                                </button>
                              ) : (
                                <span>
                                  {!hidePriceInformation && (
                                    <>
                                      &#x20b9;&nbsp;
                                      {data?.price?.toLocaleString("en-IN")}
                                    </>
                                  )}
                                </span>
                              )}
                            </p>
                          </div>
                          <div onClick={(e) => e.stopPropagation()}>
                            <AddToCart productData={data} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            )
          : !isLoading &&
            category_id && (
              <div className="d-flex align-items-center justify-content-center set_height ps-2 font-weigh-bold font-14 mb-0 border rounded">
                <div className="text-center text-secondary">
                  <div className="fs-3">
                    <FontAwesomeIcon
                      icon={"fa fa-file-image"}
                      aria-hidden="true"
                    />
                  </div>
                  <span>No Products Found</span>
                </div>
              </div>
            )}

        {isLoading && (
          <div className="d-flex align-items-center justify-content-center set_height border rounded">
            <div className="loader-circle me-1" />
          </div>
        )}

        {totalRecord > 0 && (
          <div className="text-center">
            {totalRecord !== productList.length && (
              <button
                className="btn btn-outline-primary mb-2"
                type="button"
                onClick={() => setActivePage(activePage + 1)}
              >
                {loadMoreLoader ? (
                  <>
                    <div className="spinner-border spinner-border-sm me-1" />
                    Loading...
                  </>
                ) : (
                  "Load More"
                )}
              </button>
            )}
          </div>
        )}
      </div>

      <Modal show={getQuoteModal} onHide={() => hideModal()} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Enter Details</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3 required">
              Name
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
                name="name"
                value={details?.name}
                onChange={handleDetails}
              />
              {error.nameError && (
                <div className="errorMsg text-danger">{error.nameError}</div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3 required">
              Phone No.
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="number"
                className="form-control"
                placeholder="Enter Your Phone Number"
                name="number"
                value={details?.phoneNumber}
                onChange={handleDetails}
              />
              <div className="errorMsg text-danger">{error.numberError}</div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3">Email</label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="email"
                className="form-control"
                placeholder="Enter Your Email"
                name="email"
                value={details?.email}
                onChange={handleDetails}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button className="btn btn-primary btn-md me-0" onClick={sendEmail}>
              Submit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProductList;
