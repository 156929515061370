import * as echarts from "echarts";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Col } from "react-bootstrap";
import { RoutesContext } from "../../RoutesContext";
import { reactAppExecutionVP } from "../../services/API";
import AppLoader from "../dataset/Loader/AppLoader";
const StackedHorizontalBarGraph = ({
  componentData,
  displayData,
  objData,
  appSessionId,
  filterApply,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
}) => {
  const chartRef = useRef(null);
  const [toggleChartView, setToggleChartView] = useState(true);
  const [graphLoading, setGraphLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const { component_id, isCacheEnabled } = displayData;
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const [chartType, setChartType] = useState("");

  useEffect(() => {
    setChartType(displayData.echartType);
  }, [displayData.echartType]);
  useEffect(() => {
    if (!graphData) {
      return;
    }
    const filteredData = graphData?.series_data?.filter(
      (item) => item[graphData.x_axis_category_key]
    );

    let uniqueDates = Array.from(
      new Set(filteredData?.map((item) => item[graphData.x_axis_category_key]))
    );

    const filteredLegend = graphData?.series_data?.filter(
      (item) => item.status
    );
    const legends = Array.from(
      new Set(filteredLegend?.map((item) => item.status))
    );

    const xAxis = uniqueDates.filter((date) => date !== null);

    let seriesY;

    seriesY = graphData?.series_data?.reduce((result, item) => {
      const dateIndex = xAxis?.indexOf(item[graphData.x_axis_category_key]);

      if (dateIndex !== -1) {
        const statusIndex = result?.findIndex(
          (series) => series.name === item[graphData.categorization_key]
        );
        if (statusIndex === -1) {
          result.push({
            name: item[graphData?.categorization_key],
            type: "bar",
            data: Array(xAxis.length).fill(null),
          });
          result[result.length - 1].data[dateIndex] = item.value;
        } else {
          result[statusIndex].data[dateIndex] = item.value;
        }
      }

      return result;
    }, []);

    const myCharts = echarts.init(chartRef.current);

    let options = {
      animation: true,
      animationDuration: 2000,
      animationEasing: "linear",
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {},
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
        name: graphData?.x_axis_category_key || "X-Axis", // Label for X-Axis
        nameLocation: "middle",
        nameTextStyle: {
          fontSize: 14,
          padding: 5,
        },
      },
      yAxis: {
        type: "category",
        data: uniqueDates,
        name: graphData?.categorization_key || "Y-Axis", // Label for Y-Axis
        nameLocation: "middle",
        nameTextStyle: {
          fontSize: 14,
          padding: 46,
        },
      },
      series: seriesY,
    };

    myCharts.setOption(options);

    return () => {
      myCharts.dispose();
    };
  }, [chartType, graphData]);

  const abortController = new AbortController();
  const signal = abortController.signal;
  useEffect(() => {
    if (
      cachedData.hasOwnProperty(component_id) &&
      !tempStorageCall &&
      isCacheEnabled
    ) {
      setGraphData(cachedData?.[component_id]);
      return;
    }
    getGraphData();
    return () => {
      abortController.abort();
    };
  }, []);

  const getGraphData = async () => {
    let executeAppObject = {
      data: {
        logic:
          objData?.logic?.length > 0
            ? objData?.logic
            : [displayData?.componentLogic],
      },
      referenceValue: [{ refValue: displayData?.refValue }],
      app_session_id: appSessionId,
    };

    setGraphLoading(true);
    reactAppExecutionVP(executeAppObject, signal)
      .then((response) => {
        if (response.data?.length) {
          const componentData = response?.data.find((data) => {
            return Object.keys(data)?.[0] === displayData?.refValue;
          })?.[displayData.refValue];
          setGraphData(componentData?.value?.data);
          let data = cachedData;
          data[component_id] = componentData?.value?.data;
          setCacheData(data);
          setTempStorageCall(false);
        }
        clearFilterApply();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
        setGraphLoading(false);
      })
      .finally(() => {
        setGraphLoading(false);
      });
  };

  return (
    <Col md={displayData.columenWidth} className="my-1 px-0">
      <div className={`h-100 position-relative  mx-1 main-content bg-white mb-2`}>
        <div className="py-1 px-1">
          <div className="borderBottom">
            <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
              <div className="text-capitalize">
                <h5 className="m-0 table-heading">
                  {displayData?.echartTitle}
                </h5>
              </div>
              <div className="d-flex align-items-center">
                {displayData?.transitionAppId &&
                displayData?.transitionPageId ? (
                  <div className="d-flex align-items-center transition-header-icon">
                    <Link
                      to={`/app_ui/${displayData?.transitionAppId}/${displayData?.transitionPageId}`}
                    >
                      <button
                        className="header-icon-button icon-hover-effect"
                        title={displayData?.transitionActionTitle ?? ""}
                      >
                        <FontAwesomeIcon
                          icon={
                            displayData?.transitionSelectedIcon?.value ??
                            "external-link"
                          }
                          size="md"
                        />
                      </button>
                    </Link>
                  </div>
                ) : null}

                <button
                  className="arrowDownContainer header-icon-button icon-hover-effect"
                  title={toggleChartView ? "Collapse Chart" : "Expand Chart"}
                  onClick={() => setToggleChartView(!toggleChartView)}
                  aria-controls="chart-toggle"
                  aria-expanded={toggleChartView}
                >
                  <FontAwesomeIcon
                    icon={"fa fa-angle-down"}
                    className={`arrow-icon ${
                      toggleChartView ? "arrow-icon-up" : "arrow-icon-down"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {graphLoading && <AppLoader />}
        <Collapse in={toggleChartView}>
          <div
            ref={chartRef}
            style={{
              height: "400px",
            }}
          />
        </Collapse>
      </div>
    </Col>
  );
};

export default StackedHorizontalBarGraph;
