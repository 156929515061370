import React, { useEffect, useState } from "react";
import { getStoreDetailsById } from "../../../services/appService";
import "./Category.scss";
import { toast } from "react-toastify";
import Loader from "../../dataset/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

function Category(props) {
  const [totalCount, setTotalCount] = useState({});
  const [storeName, setStoreName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const param = useParams();

  const routeToSubCategory = (category) => {
    props.history.push(`/store/${param?.store_id}/${category}`);
  };

  useEffect(() => {
    setIsLoading(true);
    getStoreDetailsById(param?.store_id)
      .then((response) => {
        setStoreName(response.data[0].store_name);
        setTotalCount(response.store_data_count);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        <>
          <div className="button-wrapper w-100 d-help py-2">
            <div className="d-help flex-wrap py-1">
              <div className="bread-crum d-help me-2">
                <p
                  className="m-0 me-2 text-underline font-13"
                  onClick={props.history?.goBack}
                >
                  All Stores
                </p>
                <span>/</span>
                <h6 className="m-0 ms-2 text-capitalize font-13">
                  {storeName}
                </h6>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-sm btn-secondary m-0 "
              onClick={props.history?.goBack}
            >
              <FontAwesomeIcon icon="fa fa-arrow-left" />
              <span className="ms-1">Back</span>
            </button>
          </div>
          <div className="main-category col-md-12">
            <div className="row px-2">
              <div className="col-md-3 col-lg-3 col-sm-6 p-0">
                <div className="p-1">
                  <div
                    className="store-category py-1 shadow-sm bg-white position-relative"
                    onClick={() => routeToSubCategory("categories")}
                  >
                    <div className="add-wrapper">
                      <FontAwesomeIcon icon={`fa fa-sitemap`} color="grey" />
                    </div>
                    <p className="m-0 text-center text-nowrap">Categories</p>
                    {totalCount?.categories_count > 0 && (
                      <div className="countBadge">
                        <span>
                          {totalCount?.categories_count > 99
                            ? "99+"
                            : totalCount?.categories_count}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-lg-3 col-sm-6 p-0">
                <div className="p-1">
                  <div
                    className="store-category py-1 shadow-sm bg-white position-relative"
                    onClick={() => routeToSubCategory("attributes_set")}
                  >
                    <div className="add-wrapper">
                      <FontAwesomeIcon
                        icon={`fa-regular fa-rectangle-list`}
                        color="grey"
                      />
                    </div>
                    <p className="m-0 text-center text-nowrap">
                      Attribute Sets
                    </p>
                    {totalCount?.attributes_count > 0 && (
                      <div className="countBadge">
                        <span>
                          {totalCount?.attributes_count > 99
                            ? "99+"
                            : totalCount?.attributes_count}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-sm-6 p-0">
                <div className="p-1">
                  <div
                    className="store-category py-1 shadow-sm bg-white position-relative"
                    onClick={() => routeToSubCategory("product_category")}
                  >
                    <div className="add-wrapper">
                      <FontAwesomeIcon icon={`fa fa-archive`} color="grey" />
                    </div>
                    <p className="m-0 text-center text-nowrap">Products</p>
                    {totalCount?.products_count > 0 && (
                      <div className="countBadge">
                        <span>
                          {totalCount?.products_count > 99
                            ? "99+"
                            : totalCount?.products_count}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Category;
