	import React, { useState } from "react";
  import * as _ from "lodash";
  import { OverlayTrigger, Tooltip } from "react-bootstrap";
  import CustomNodeUpload from "./CustomNodeUpload";
  import Accordion from "react-bootstrap/Accordion";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  
  export default function NodeMenu(props) {
   
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [openSections, setOpenSections] = useState({});

    // Handle search and track open sections
    function handleSearch(event) {
      const value = event.target.value?.toLowerCase();
      setSearchTerm(value);
  
      const newOpenSections = {};
      _.forEach(props.nodes, (items, section) => {
        if (
          items.some((item) =>
            item?.name?.toLowerCase().includes(value)
          )
        ) {
          newOpenSections[section] = true; // Auto-open sections with matches
        }
      });
      setOpenSections(newOpenSections);
    }
  
    function toggle() {
      setIsOpen((isOpen) => !isOpen);
    }

    function handleSectionToggle(section) {

      setOpenSections((prev) => ({
        ...prev,
        [section]: !prev[section], // Toggle state
      }));
    }
  
    if (window.location.pathname.includes("/app_logic/logic_editor")) {
      delete props.nodes["Add Page"];
    }
  
    // construct menu from JSON of node types
    return (
      <div className="NodeMenu m-0 p-2">
         <div className="d-flex align-items-center">
          {/* <h5>Node Menu</h5> */}
          <input
            type="text"
            placeholder="Search Nodes..."
            className="form-control"
            value={searchTerm}  
            onChange={handleSearch}
            onFocus={() => setIsOpen(true)}
            
          />
          {/* <div className="ml-2" onClick={props.onToggle}>
            {!props.isOpen ? (
              <FontAwesomeIcon icon="fa fa-solid fa-caret-down" />
            ) : (
              <FontAwesomeIcon icon="fa fa-solid fa-caret-up" />
            )}
          </div> */}
        </div>
        {isOpen && (
          <>
            <div className="node-submenu px-1">
             
                <Accordion activeKey={Object.keys(openSections)}>
                {_.map(props.nodes, (items, section) => {
                  // Filter items based on search
                  const filteredItems = items.filter((item) =>
                    item.name?.toLowerCase().includes(searchTerm)
                  );
  
                  if (filteredItems.length === 0) return null; // Hide sections with no matches
  
                  return (
                    <Accordion.Item
                      className="mb-1"
                      eventKey={section}
                      key={`node-menu-${section}`}
                    >
                      <Accordion.Header className="m-0 p-0" onClick={() => handleSectionToggle(section)}>
                        <h6 className="mb-0">{section}</h6>
                      </Accordion.Header>
                      <Accordion.Body className="p-0" hidden={!openSections[section]}>
                        <ul>
                          {filteredItems.map((item, index) => (
                            <NodeMenuItem
                              key={item.node_key + index}
                              nodeInfo={item}
                            />
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </>
        )}
        <CustomNodeUpload onUpload={props.onUpload} />
      </div>
    );
  }
  
  /**
   * Format docstring with newlines into tooltip content
   * @param string - node docstring
   * @returns {array} - array of strings and HTML elements
   */
  function formatTooltip(string) {
    const split = string.split("\n");
    const out = [];
    split.forEach((line, i) => {
      out.push(line);
      out.push(<br key={i} />);
    });
    out.pop();
    return out;
  }
  
  function NodeMenuItem(props) {
    if (!props.nodeInfo.missing_packages) {
      const tooltip = props.nodeInfo.doc
        ? formatTooltip(props.nodeInfo.doc)
        : "This node has no documentation.";
      return (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={<NodeTooltip message={tooltip} />}
        >
          <li
            className="NodeMenuItem"
            draggable={true}
            onDragStart={(event) => {
              event.dataTransfer.setData(
                "storm-diagram-node",
                JSON.stringify(props)
              );
            }}
            style={{ color: props.nodeInfo.color }}
          >
            {props.nodeInfo.name}
          </li>
        </OverlayTrigger>
      );
    } else {
      let tooltip =
        "These Python modules could not be imported:\n\n" +
        props.nodeInfo.missing_packages.join("\n");
      tooltip = formatTooltip(tooltip);
      return (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 250 }}
          overlay={<NodeTooltip message={tooltip} />}
        >
          <li className="NodeMenuItem invalid">{props.nodeInfo.filename}</li>
        </OverlayTrigger>
      );
    }
  }
  
  // Overlay with props has to use ref forwarding
  const NodeTooltip = React.forwardRef((props, ref) => {
    return (
      <Tooltip {...props} ref={ref}>
        <div style={{ textAlign: "left" }}>{props.message}</div>
      </Tooltip>
    );
  });
  