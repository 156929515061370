import React,{useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import config from "../../../../config.js";


const CustomPageBuilder = (props) => {

  const [displayChanges, setDisplayChanges] = useState(
    props?.displayFilterFormObj
  );


  const baseUrl = config.env("REACT_APP_PAGE_BUILDER_URL");
  const currentUrl = window.location.pathname;
  const pageId = currentUrl.split("/").pop(); // Gets the last segment of URL
  const tenantId = localStorage.getItem("tenantId").replace(/['"]+/g, '');

  // URLs
  const htmlPageEditorUrl = `${baseUrl}editor.php?tenant_id=${tenantId}&page_id=${pageId}`;
  const htmlPageUrl = `${baseUrl}${tenantId}/${pageId}.html`;
  
  useEffect(() => {
    if (pageId && tenantId) {
      setDisplayChanges((prevState) => ({
        ...prevState,
        htmlPageEditorUrl,
        htmlPageUrl,
      }));
    }
  }, [pageId, tenantId]);


    const handleEditorButtonClick = async () => {

      const saveApiUrl = `${baseUrl}save.php`;  
      
      try {
        const formData = new FormData();
        formData.append("startTemplateUrl", "new-page-blank-template.html");
        formData.append("title", tenantId);
        formData.append("file", `${tenantId}/${pageId}.html`);
        formData.append("folder", tenantId);
        formData.append("name", tenantId);
        formData.append("url", `${tenantId}/${pageId}.html`);
  
        const response = await fetch(saveApiUrl, {
          method: "POST",
          body: formData,
        });
  
        if (response.ok) {
          window.open(displayChanges?.htmlPageEditorUrl, "_blank", "noopener,noreferrer");
        } else {
          console.error("Failed to save data before opening the editor");
        }
      } catch (error) {
        console.error("Error making API call:", error);
      }
    };
  

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group align-self-center justify-content-center d-flex">
        <button
          className="btn btn-primary btn-sm"
          onClick={handleEditorButtonClick}
          >
          &nbsp;&nbsp;<FontAwesomeIcon icon={faArrowUpRightFromSquare} />&nbsp;&nbsp;Open Page Editor&nbsp;&nbsp;&nbsp;
        </button>&nbsp;&nbsp;
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => window.open(displayChanges?.htmlPageUrl, "_blank", "noopener,noreferrer")}
          >
          &nbsp;&nbsp;<FontAwesomeIcon icon={faArrowUpRightFromSquare} />&nbsp;&nbsp;Preview Page&nbsp;&nbsp;&nbsp;
        </button>
      </div>
    </>
  );
};

export default CustomPageBuilder;
