import React, { Component } from "react";
import {
  fetchAllDataSets,
  searchDatasetByName,
} from "../../../services/datasetService";
import "./DatasetTable.scss";
import Loader from "../../dataset/Loader/Loader";
import { Link } from "react-router-dom";
import moment from "moment";
import { Pagination } from "../../dataset/pagination/Pagination";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export class DatasetTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      getDataSetHeader: [
        "Dataset Name",
        "Description",
        "Author",
        "Created Date",
        "Last Updated Date",
        "Actions",
      ],
      getDataSetRecord: [],
      getTotalRecordCount: 0,
      searchValue: "",
      currentPage: 1,
      dataPerPage: 20,
      totalPageNumber: 0,
      pageNumbers: [],
      loading: true,
      errorMsg: false,
      urlValue: "",
      isWrite: false,
      isDatasetsWrite: false,
      selectedFolder: null,
      folders: [],
      folderGroups: {},
    };
  }
  componentDidMount() {
    this.getPrivilages();
  }

  getPrivilages = () => {
    if (this.state.userInfo.privileges) {
      const { standard_privileges } = this.state.userInfo.privileges;
      standard_privileges.forEach((data) => {
        if (data["Dataset  Editor"] && this.state.urlValue === "/datasets") {
          this.setState({ isWrite: data["Dataset  Editor"]?.write });
        } else if (
          data["Datasets"] &&
           this.state.urlValue === "/dataset_list"
          ) {
          this.setState({ isDatasetsWrite: data["Datasets"]?.write });
        }
      });
    } else {
      this.setState({ isWrite: true, isDatasetsWrite: true });
    }
  };
  componentWillMount() {
    this.mounted = true;
    this.setState({
      urlValue: this.props.match.url,
    });
    this.fetchAllData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  receiveCurrentPageNumber = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  sortFolders = (a, b) => {
    if (a === "Default") return -1;
    if (b === "Default") return 1;
    return a.localeCompare(b);
  };

  fetchAllData() {
    this.setState({ loading: true });
    let obj = {
      SortCriteria: [
        {
          sort_field: "name",
          sort_type: "asc",
        },
      ],
    };
    fetchAllDataSets(obj)
      .then((response) => {
        if (this.mounted) {
          if (response.success === true) {
            const folderGroups = {};
            response.data.forEach((dataset) => {
              const folderName = dataset.folder_name || "Default";
              if (!folderGroups[folderName]) {
                folderGroups[folderName] = [];
              }
              folderGroups[folderName].push(dataset);
            });

            const folderArray = Object.keys(folderGroups).sort(this.sortFolders);

            this.setState({
              getDataSetRecord: response.data,
              getTotalRecordCount: response.total_count,
              folders: folderArray,
              folderGroups: folderGroups,
              loading: false,
              error: false,
            });
          } else {
            this.setState({ errorMsg: true, loading: false }, () => {
              toast.error(response?.data?.message || response?.message);
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        });
      });
  }

  handleFolderClick = (folderName) => {
    this.setState({ 
      selectedFolder: folderName,
      currentPage: 1 
    });
  };

  handleBackToFolders = () => {
    this.setState({ selectedFolder: null });
  };

  getFilteredDatasets = () => {
    const { selectedFolder, folderGroups, getDataSetRecord } = this.state;
    if (selectedFolder === "Default") {
      return getDataSetRecord.filter(dataset => (!dataset.folder_name || dataset.folder_name === "Default" || dataset.folder_name===""));
    } else if (selectedFolder) {
      return folderGroups[selectedFolder] || [];
    }
    return getDataSetRecord;
  };

  getEnteredDataSet = (event) => {
    const searchValue = event.target.value;
    this.setState({ searchValue }, () => {
      if (searchValue.length) {
        searchDatasetByName(searchValue)
          .then((response) => {
            if (response) {
              const { selectedFolder } = this.state;
              let filteredData = response.data;
              
              if (selectedFolder) {
                filteredData = selectedFolder === "Default"
                  ? response.data.filter(dataset => (!dataset.folder_name || dataset.folder_name === "Default" || dataset.folder_name===""))
                  : response.data.filter(d => d.folder_name === selectedFolder);
              }

              this.setState({
                getDataSetRecord: response.data,
                filteredDatasets: filteredData,
                getTotalRecordCount: filteredData.length,
                errorMsg: false,
              });
            }
          })
          .catch((err) => {
            toast.error(err.response?.data?.message || err.response?.message || err.message);
          });
      } else {
        this.fetchAllData();
      }
    });
  };

  renderFolderCard = (folder) => {
    const count = folder === "Default" 
      ? this.state.getDataSetRecord.filter(dataset => (!dataset.folder_name || dataset.folder_name === "Default" || dataset.folder_name==="")).length 
      : this.state.getDataSetRecord.filter(d => d.folder_name === folder).length;
    
    return (
      <div key={folder} className="col-md-2 mb-4">
        <div 
          className="folder-card p-3 text-center cursor-pointer"
          onClick={() => this.handleFolderClick(folder)}
        >
          <FontAwesomeIcon 
            icon={"fa fa-folder"} 
            size="3x" 
            className="text-warning mb-2"
          />
          <div className="folder-name"><h5>{folder}</h5></div>
          <div className="folder-count text-muted small">{count} items</div>
        </div>
      </div>
    );
  };

  renderTableRow = (records, index) => {
    const { isDatasetsWrite, isWrite, urlValue } = this.state;
    const rowNumber = (this.state.currentPage - 1) * this.state.dataPerPage + index + 1;

    return (
      <tr key={index}>
        <td>{records.name}</td>
        <td>{records.description}</td>
        <td>{records.author}</td>
        <td>
          {records.created_date
            ? moment(records.created_date).format("L")
            : null}
        </td>
        <td>
          {records.updated_date
            ? moment(records.updated_date).format("L")
            : null}
        </td>
        {(isDatasetsWrite && urlValue === "/dataset_list") ||
        (isWrite && urlValue === "/datasets") ? (
          <td>
            <div className="action-icons text-start">
              <Link
                to={
                  urlValue === "/dataset_list"
                    ? `/datatable/${records._id.$oid}`
                    : `dataset/${records._id.$oid}`
                }
              >
                <FontAwesomeIcon
                  icon={"fa fa-pen-to-square"}
                  className="me-2"
                  title="Edit"
                  size="lg"
                />
              </Link>
            </div>
          </td>
        ) : null}
      </tr>
    );
  };

  render() {
    const {
      loading,
      errorMsg,
      getTotalRecordCount,
      urlValue,
      isDatasetsWrite,
      isWrite,
      selectedFolder,
      folders,
      getDataSetHeader,
      currentPage,
      dataPerPage,
      searchValue,
    } = this.state;

    if (loading) {
      return <Loader />;
    }

    const filteredDatasets = searchValue.length > 0
      ? this.state.filteredDatasets || []
      : (selectedFolder 
          ? this.getFilteredDatasets() 
          : this.state.getDataSetRecord);

    const indexOfLastRecord = currentPage * dataPerPage;
    const indexOfFirstRecord = indexOfLastRecord - dataPerPage;
    const currentData = filteredDatasets.slice(indexOfFirstRecord, indexOfLastRecord);

    const setHeaderData =
      (isDatasetsWrite && urlValue === "/dataset_list") ||
      (isWrite && urlValue === "/datasets")
        ? getDataSetHeader.map((header, index) => (
            <th className="text-nowrap" key={index}>
              {header}
            </th>
          ))
        : getDataSetHeader
            .filter((header) => header !== "Actions")
            .map((header, index) => (
              <th className="text-nowrap" key={index}>
                {header}
              </th>
            ));

    return (
      <div>
        <div className="d-help py-2 flex-wrap">
          <h5>
            {urlValue === "/datasets"
              ? "Dataset Field Editor"
              : "Datasets"}
          </h5>

          <div className="d-flex">
          {!selectedFolder && urlValue === "/dataset_list" && isDatasetsWrite && (
            <Link to={`dataset`}>
              <button
                type="button"
                className="custom-btn ms-2 my-auto text-nowrap me-0"
              >
                &nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-plus" />
                <span className="ms-1">&nbsp;&nbsp;Add Dataset&nbsp;&nbsp;</span>
              </button>
            </Link>
          )}
            {selectedFolder && urlValue !== "/datasets" && isDatasetsWrite && (
                <>
                <div className="input-group input-group-sm mb-0">
                <span className="input-group-text" id="basic-addon1">
                    <FontAwesomeIcon icon={"fa fa-search"} />
                </span>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search Dataset By Name"
                    onChange={this.getEnteredDataSet}
                    value={this.state.searchValue}
                />
                </div>
                </>
              )}
          </div>
        </div>

        {!selectedFolder ? (
          <div className="folder-view">
            {folders.length === 0 ? (
              <div className="text-center py-5">
                <FontAwesomeIcon icon="fa fa-folder-open" size="3x" className="text-muted mb-3" />
                <h5>No folders available</h5>
                <p>Create a dataset to get started</p>
              </div>
            ) : (
              <div className="row">
                {folders.map(this.renderFolderCard)}
              </div>
            )}
          </div>
        ) : (
          <div className="card-box">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>
                <button 
                  onClick={this.handleBackToFolders}
                  className="btn btn-sm btn-outline-secondary me-2"
                >
                  <FontAwesomeIcon icon={"fa fa-arrow-left"} />
                </button>
                Datasets in {selectedFolder}
              </h5>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered mb-0">
                <thead>
                  <tr className="head-row">{setHeaderData}</tr>
                </thead>
                <tbody>{currentData.map(this.renderTableRow)}</tbody>
              </table>
              {errorMsg === true ? (
                <p className="no_table_records">Internal issue ...</p>
              ) : errorMsg === false && filteredDatasets.length === 0 ? (
                <p className="no_table_records">No Dataset found</p>
              ) : null}
            </div>

            {filteredDatasets.length >= dataPerPage && (
              <div className="dataTables_wrapper mt-2">
                <Pagination
                  sendRowDataLength={filteredDatasets.length}
                  onSelectLanguage={this.receiveCurrentPageNumber}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default DatasetTable;
