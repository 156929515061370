import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactSlider from "react-slider";
import "./customSlider.scss"

const CustomSlider = ({ field, onChange, value = [], disabled}) => {
  const [count, setCount] = useState(field?.numberSlider?.count || 0);

  useEffect(() => {
    if (value !== undefined) {
      setCount(value);
    }
  }, [value]);

  const handleChangeSlider = (val) => {
    setCount(val);
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <div>
      <div className="slider-container">
        <div className="slider-row">
          <span>{field?.numberSlider?.minValue || 0}</span>
          <span>{count}</span>
          <span>{field?.numberSlider?.maxValue || 100}</span>
        </div>

        <ReactSlider
          className="horizontal-slider"
          thumbClassName="thumb"
          trackClassName="track"
          value={count}
          onChange={handleChangeSlider}
          min={field?.numberSlider?.minValue || 0}
          max={field?.numberSlider?.maxValue || 100}
          step={field?.numberSlider?.incrementValue || 1}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default CustomSlider;
