import React, { useEffect, useState } from "react";
import {
  exportProductList,
  fetchproductByCategory,
  getDataById,
} from "../../../services/appService";
import Loader from "../../dataset/Loader/Loader";
import { toast } from "react-toastify";
import ImportProductExcel from "./ImportProductExcel";
import Pagination from "react-js-pagination";
import FilterComponent from "../../common/filterComponent/FilterComponent";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ProductListing.scss";
import { productDefaultColumnHeaderData } from "../../common/model/ProductModel";

function ProductListing(props) {
  const headerData = productDefaultColumnHeaderData;
  const [productTableData, setProductTableData] = useState();
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [categoryName, setcategoryName] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [sortCriteria, setSortCriteria] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [filterObjId, setFilterObjId] = useState("");
  const storeId = props.match.params.store_id;
  const limit = 10;
  const category_id = props.location.pathname.split("/")[3];
  const [isProductWrite, setisProductWrite] = useState(false);
  const [userInfo] = useState(JSON.parse(localStorage.getItem("user-info")));
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [totalInsertedProduct, setTotalInsertedProduct] = useState(0);
  const [customAttributHeader, setCustomAttributHeader] = useState([]);
  const query = props?.location?.search;
  const categoryListType = new URLSearchParams(query).get("category_list_type");

  // call product List Api
  useEffect(() => {
    fetchProductList();
    getCategoryData();
    checkIsProductWrite();
  }, []);

  const getCategoryData = () => {
    getDataById(category_id)
      .then((response) => {
        setcategoryName(response.data.category_name);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      });
  };

  // fetch Product List
  const fetchProductList = () => {
    setLoader(true);
    let bodyData = {
      category_id: [category_id],
      FilterCriteria: filterCriteria,
      SortCriteria: sortCriteria,
      PaginationCriteria: {
        limit: limit,
        skip: (currentPage - 1) * limit,
      },
      object_id: filterObjId,
      filter_name: filterName,
    };
    fetchproductByCategory(bodyData, storeId)
      .then((response) => {
        setLoader(false);
        if (response.data) {
          setProductTableData(response.data);
          setTotalRecords(response?.totalcount);
          setTotalProductCount(response?.total_product_count);
          setTotalInsertedProduct(response?.total_inserted_product);
          setCustomAttributHeader(response?.headers);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      });
  };

  // on add button click route to page
  const routeToAddProduct = () => {
    if (totalInsertedProduct < totalProductCount) {
      props.history.push(
        `/product/add-product/${storeId}/${category_id}${props?.location?.search}`
      );
    } else {
      toast.error("Please upgrade your plan");
    }
  };

  let count = totalProductCount - totalInsertedProduct;
  let message =
    count > 0 ? `You can add ${count} Products` : "Please upgrade your plan";

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {message}
    </Tooltip>
  );

  // on edit button click route to page
  const routeToAddUpdateProductPage = (product_id) => {
    props.history.push(
      `/product/product_details/${storeId}/${category_id}/${product_id}${props?.location?.search}`
    );
  };

  // back to category listing
  const backToCategoryList = () => {
    props.history.push(`/store/${storeId}`);
  };

  // back to store
  const backToStore = () => {
    props.history.push("/stores");
  };

  // back button
  const backToCategoryListing = () => {
    props.history.push(`/store/${storeId}/product_category`);
  };

  // render table header data
  const renderedHeader = headerData.map((header, index) =>
    categoryListType === "list_type" ? (
      header?.label !== "Latitude" &&
      header?.label !== "Longitude" && (
        <th className="table_head" key={index}>
          {header.label}
          <FontAwesomeIcon
            icon={"fa fa-sort"}
            className="ms-2 cursor-pointer"
            onClick={() => {
              sortHeaderData(header.value, index);
            }}
          />
        </th>
      )
    ) : (
      <th className="table_head" key={index}>
        {header.label}
        <FontAwesomeIcon
          icon={"fa fa-sort"}
          className="ms-2 cursor-pointer"
          onClick={() => {
            sortHeaderData(header.value, index);
          }}
        />
      </th>
    )
  );

  const checkIsProductWrite = () => {
    let isWrite = false;
    if (userInfo?.privileges?.store_previleges) {
      let { manage_all_product, manage_own_product } =
        userInfo?.privileges?.store_previleges;
      isWrite =
        manage_all_product.write?.includes(storeId) ||
        manage_own_product.write?.includes(storeId);
    }

    setisProductWrite(isWrite);
  };

  // render table body data
  const renderedTableData = productTableData?.map((data, index) => (
    <tr key={index}>
      <td>{index + 1 + currentPage * limit - limit}</td>
      {headerData.map((headerData, subIndex) =>
        categoryListType === "list_type" ? (
          headerData?.label !== "Latitude" &&
          headerData?.label !== "Longitude" && (
            <td key={subIndex}>
              {data[headerData.value]?.toString()
                ? data[headerData.value]?.toString()
                : "-"}
            </td>
          )
        ) : (
          <td key={subIndex}>
            {data[headerData.value]?.toString()
              ? data[headerData.value]?.toString().slice(0, 30)
              : "-"}
          </td>
        )
      )}

      {customAttributHeader?.map((header) => {
        return <td>{data?.[header?.name] || "-"}</td>;
      })}

      {isProductWrite ||
      userInfo.role_name.toLowerCase() === "admin" ||
      userInfo.role_name.toLowerCase() === "subadmin" ? (
        <td action="true">
          <FontAwesomeIcon
            icon="fa fa-pen-to-square"
            onClick={() => routeToAddUpdateProductPage(data._id.$oid)}
            size="lg"
            role="button"
          />
        </td>
      ) : null}
    </tr>
  ));

  const exportProductFile = () => {
    let obj = {
      store_id: storeId,
      category_id: category_id,
    };

    exportProductList(obj)
      .then((response) => {
        var FileSaver = require("file-saver");
        var blob = new Blob([response], {
          type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(blob, categoryName + ".csv");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  };

  // sort functionality on columns
  const sortHeaderData = (header, index) => {
    let header_lowCase = header;
    let sortObj = {
      sort_field: header_lowCase,
      sort_type: "asc",
    };
    let sortFlag = true;
    if (sortCriteria.length > 0) {
      let resortedData = [...sortCriteria];
      resortedData.filter((data) => {
        if (data.sort_field === header_lowCase) {
          sortFlag = false;
          let order = data.sort_type;
          data.sort_type = order === "asc" ? (data.sort_type = "desc") : "asc";
        }
      });
      setSortCriteria(resortedData);
      if (sortFlag) {
        setSortCriteria((oldArray) => [...oldArray, sortObj]);
      }
    } else {
      setSortCriteria((oldArray) => [...oldArray, sortObj]);
    }
  };

  // pagination data
  const receiveCurrentPageNumber = (currentPage) => {
    setCurrentPage(currentPage);
  };

  // current page change call Product Listing UseEffect
  useEffect(() => {
    fetchProductList();
  }, [sortCriteria, currentPage, filterName]);

  useEffect(() => {
    setCurrentPage(1);
    fetchProductList();
  }, [filterCriteria]);

  const resetValue = () => {
    setFilterCriteria([]);
    setSortCriteria([]);
    setCurrentPage(1);
    setFilterName("");
    setFilterObjId("");
  };

  // receive filtered data from child
  const getFilterCriteria = (filter_criteria) => {
    setFilterCriteria(filter_criteria);
  };

  let savedFilterListApiBody = {
    FilterCriteria: [
      {
        filter_type: "text",
        type: "equal",
        filter: JSON.parse(localStorage.getItem("userid")),
        filter_field: "user_id",
      },
    ],
    export: true,
  };

  return (
    <div className="col-md-12 col-sm-12 production_listing px-2">
      <div className="x_panel bg-transparent mb-0 pb-0 border-0">
        <div className="button-wrapper w-100 d-help my-3">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row py-2 align-items-center product-responsive-heading">
                  <div className="bread-crum d-flex align-items-center gap-2 product-list">
                    <p
                      className="m-0 me-1 text-underline font-13"
                      onClick={backToStore}
                    >
                      Stores
                    </p>
                    <span>/</span>
                    <p
                      className="m-0 ms-1 me-1 text-underline font-13"
                      onClick={backToCategoryList}
                    >
                      Store
                    </p>
                    <span>/</span>
                    <p
                      className="m-0 ms-1 me-1 text-underline font-13"
                      onClick={backToCategoryListing}
                    >
                      Product Catalog
                    </p>
                    <span>/</span>
                    <h6 className="m-0 ms-1 font-13">Items</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xs-12 responsive-button">
                <div className="d-flex justify-content-end align-items-center">
                  <button
                    type="button"
                    className="btn btn-md btn-secondary me-2 d-flex align-items-center"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-filter" />
                    <span className="ms-1">Filter</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-md btn-success me-2 d-flex align-items-center"
                    onClick={exportProductFile}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-file-export" />
                    <span className="ms-1">Export</span>
                  </button>

                  {isProductWrite ||
                  userInfo.role_name.toLowerCase() === "admin" ||
                  userInfo.role_name.toLowerCase() === "subadmin" ? (
                    <>
                      <ImportProductExcel
                        store_id={storeId}
                        category_id={category_id}
                        category_name={categoryName}
                        total_inserted_product={totalInsertedProduct}
                        total_product_count={totalProductCount}
                        getTableDataImport={fetchProductList}
                      ></ImportProductExcel>
                      {loader ? (
                        <button
                          type="button"
                          className="btn btn-md btn-success me-2 d-flex align-items-center"
                          onClick={routeToAddProduct}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-plus" />
                          <span className="ms-1 w-100">Add Product</span>
                        </button>
                      ) : (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <button
                            type="button"
                            className="btn btn-md btn-success me-2 d-flex align-items-center"
                            onClick={routeToAddProduct}
                          >
                            <FontAwesomeIcon icon="fa-solid fa-plus" />
                            <span className="ms-1">Add Product</span>
                          </button>
                        </OverlayTrigger>
                      )}
                    </>
                  ) : null}

                  <button
                    type="button"
                    className="btn btn-sm btn-secondary me-0"
                    onClick={backToCategoryListing}
                  >
                    <FontAwesomeIcon icon="fa fa-arrow-left" />
                    <span className="ms-1">Back</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <FilterComponent
            show={showFilter}
            setResetFilter={resetValue}
            onChange={getFilterCriteria}
            filterCriteriaList={filterCriteria}
            columnList={headerData}
            datasetName="product_filter"
            savedFilterApiBody={savedFilterListApiBody}
          ></FilterComponent>
          <div
            className={`${
              loader
                ? "onLoadingOverflowHidden w-100"
                : "tableTheme1 table-responsive"
            }`}
          >
            <table className="table product-table  no-footer w-100 main-table-scroll table-bordered mb-0">
              <thead>
                <tr className="head-row text-nowrap">
                  <th className="table_head">Srno</th>
                  {renderedHeader}
                  {customAttributHeader?.map((header) => {
                    return <th>{header?.display_name}</th>;
                  })}
                  {isProductWrite ||
                  userInfo.role_name.toLowerCase() === "admin" ? (
                    <th action="true" className="table_head">
                      Actions
                    </th>
                  ) : null}
                </tr>
              </thead>
              {!loader ? (
                <>
                  <tbody>
                    {productTableData?.length ? (
                      renderedTableData
                    ) : (
                      <tr className="no_table_records">
                        <td colSpan={headerData.length + 2}>
                          No Product Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              ) : null}
            </table>
          </div>
          {loader && <Loader />}
          {totalRecords > 10 && !loader ? (
            <div className="dynamic_pagination my-2 col-md-12">
              <Pagination
                pageRangeDisplayed={3}
                activePage={currentPage}
                itemsCountPerPage={10}
                totalItemsCount={totalRecords}
                onChange={receiveCurrentPageNumber}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ProductListing;
