import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

const DisplayBuilderGoogleMap = ({
  logicList,
  displayBuilder,
  columnWidthList,
  onChange,
}) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);
  const markerType = ["Car", "Truck", "Pin"];
  const markers = markerType.map((data, index) => (
    <option key={index} value={data}>
      {data}
    </option>
  ));

  useEffect(() => {
    setDisplayChanges((prev) => {
      const updatedState = { ...prev, ...displayBuilder };
      updatedState.refValue = updatedState.refValue || "response"; 
      return JSON.stringify(updatedState) !== JSON.stringify(prev) ? updatedState : prev;
    });
  }, []);

  useEffect(() => {
    onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const searchableValueChange = (event, key) => {
    const data = { ...displayChanges };
    let value = event?.label;
    data[key] = value || "";
    setDisplayChanges(data);
  };

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Map Type</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges.mapType}
            onChange={(e) => {
              changeValue(e);
            }}
            name="mapType"
          >
            <option value="">Please select</option>
            <option id="trip_map" value={"trip_map"}>
              Trip Map
            </option>
            <option id="cs_map" value={"cs_map"}>
              Current Status Map
            </option>
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Map Title</label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Map Title"
            name="mapTitle"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.mapTitle}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Marker Type</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges.markerType}
            onChange={(e) => {
              changeValue(e);
            }}
            name="markerType"
          >
            <option value="">Please select</option>
            {markers}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Marker Animation</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges.markerAnimation}
            onChange={(e) => {
              changeValue(e);
            }}
            name="markerAnimation"
          >
            <option value="">Please select</option>
            <option id="drop" value={"drop"}>
              Drop
            </option>
            <option id="bounce" value={"bounce"}>
              Bounce
            </option>
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Directions</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges.mapDirection}
            onChange={(e) => {
              changeValue(e);
            }}
            name="mapDirection"
          >
            <option value="">Please select</option>
            <option id="yes" value={"yes"}>
              Yes
            </option>
            <option id="no" value={"no"}>
              No
            </option>
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12">
        <label className="form-label p-0 mt-2">Component Logic</label>
        <div>
          <Select
            classNamePrefix={"react-select"}
            placeholder="Select.."
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === displayChanges?.componentLogic
            )}
            onChange={(e) => searchableValueChange(e, "componentLogic")}
            isClearable
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Column Width</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.columenWidth}
            onChange={(e) => {
              changeValue(e);
            }}
            name="columenWidth"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderGoogleMap;
