import React, { useContext, useEffect, useState } from "react";
import CustomModalForm from "../components/CustomModalForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterFormContext } from "../contexts/FilterFormContext";
import { filterFormDataGetter } from "./kanbanCardUtils";
import { reactAppFormExecutionVP } from "../../../services/API";
import { toast } from "react-toastify";

const AddUpdateKanbanCard = ({
  displayData,
  fetchBoardData,
  lane,
  boardData,
  setBoardData,
  setIsAddingCard,
  setIsUpdatingCard,
  setIsCloningCard,
  isCloningCard,
  card,
  objData,
  appSessionId,
  submitFormPayload,
}) => {
  const [modalShow, setModalShow] = useState(true);
  const { filterFormData } = useContext(FilterFormContext);
  const [cardData, setCardData] = useState(card ?? {});

  useEffect(() => {
    if (card) {
      setCardData(card);
    } else {
      setCardData({
        [displayData?.statusFieldName]: lane.title, // Dynamic status field
        ...filterFormData,
      });
    }
  }, [card, filterFormData, lane.title, displayData?.statusFieldName]);

  const handleAddUpdateKanbanCard = async () => {
    try {
      if (card?.srno) {
        setIsUpdatingCard(false);
      } else {
        setIsAddingCard(false);
      }

      if (!card?.srno || isCloningCard) {
        cardData.srno = 0;
      }

      setIsCloningCard(false);
  
      const cardKey = cardData.srno.toString();
      const laneCards = [...(boardData.lanes?.[lane.id]?.cardIds || [])];
  
      if (!laneCards.includes(cardKey)) {
        laneCards.push(cardKey);
      }
  
      const newBoardData = {
        ...boardData,
        lanes: {
          ...boardData.lanes,
          [lane.id]: {
            ...boardData.lanes[lane.id],
            cardIds: [...laneCards],
          },
        },
        cards: {
          ...boardData.cards,
          [cardKey]: { ...cardData },
        },
      };
  
      const filterformDataToSend = filterFormDataGetter(filterFormData, cardData);
  
      const newSubmitFormPayload = {
        ...submitFormPayload,
        data: {
          ...submitFormPayload.data,
          formData: [
            {
              ...submitFormPayload.data.formData?.[0],
              board_id: newBoardData.boardId,
              srno: isCloningCard ? 0 : cardData.srno,
              [displayData?.statusFieldName]: cardData[displayData?.statusFieldName],
              ...filterformDataToSend,
              ...cardData, // Include all card fields at top level
            },
          ],
        },
      };
  
      const res = await reactAppFormExecutionVP(newSubmitFormPayload, "from AddUpdateKanbanCard.");
      if (res.status === 200) {
        setBoardData(newBoardData);
        fetchBoardData();
        toast.success(card?.srno ? "Card updated successfully" : "Card added successfully");
      } else {
        throw new Error("Failed to save card");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.response?.message || error.message);
    }
  };

  const handleModalOnHide = () => {
    setModalShow(false);
    if (card?.srno) {
      setIsUpdatingCard(false);
    } else {
      setIsAddingCard(false);
    }
    setIsCloningCard(false);
  };

  return (
    <CustomModalForm
      show={modalShow}
      onHide={handleModalOnHide}
      modalTitle={card?.srno ? "Edit" : "Add"}
      pageFields={objData?.page?.page_fields?.[0]}
      formData={cardData}
      setFormData={setCardData}
      onSubmitClick={handleAddUpdateKanbanCard}
      submitButtonIcon={
        card?.srno ? (
          <FontAwesomeIcon icon={"fa fa-pencil"} />
        ) : (
          <FontAwesomeIcon icon={"fa fa-add"} />
        )
      }
      submitButtonText={isCloningCard ? "Copy & Create New" : (card?.srno ? "Update" : "Add")}
      appSessionId={appSessionId}
    />
  );
};

export default AddUpdateKanbanCard;