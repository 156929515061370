import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import KanbanCardPreview from "./KanbanCardPreview";
import { getRecordDataById } from "../../../../../services/tableConfigService";
import Select from "react-select";

function DisplayBuilderKanbanCardKeyMapping({
  displayChanges,
  setDisplayChanges,
}) {
  const [showKanbanCardPreview, setShowKanbanCardPreview] = useState(false);
  const [kanbanCardPageFields, setKanbanCardPageFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState({});

  useMemo(() => {
    setPageFields();
  }, [displayChanges.displayFormId]);

  function setPageFields() {
    const payloadForFetchingPageFields = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      dataset_name: "fc_app_pages",
      id: displayChanges?.displayFormId,
    };
    getRecordDataById(payloadForFetchingPageFields).then((res) => {
      const pageFieldsName = res.data.page_fields.map((field) => {
        return { label: field.name, value: field.name };
      });
      setKanbanCardPageFields(pageFieldsName);
    });
  }

  const handleSelectChange = (key, value) => {
    if(key && value){
      setSelectedFields({ ...selectedFields, [key]: { label: value, value } });
      const data = { ...displayChanges };
      data["key_mapping"] = {
        ...data?.["key_mapping"],
        [key]: value,
      };
      setDisplayChanges(data);
    }
  };

  return (
    <>
      <div className="col-md-6 form-group">
        <div className="kanban-card-display-builder mb-2 rounded">
          <div className="py-2 m-2">
            <div className="border-bottom border-secondary border-opacity-25">
              <div className="d-flex justify-content-between align-items-center">
                <div className="col-md-4 col-lg-4 col-6">
                  <div className="input-group">
                    <Select
                      closeMenuOnSelect={true}
                      isClearable
                      menuPosition="fixed"
                      classNames={{
                        control: (state) => 'searchableDropdownControl',
                        menuPortal: () => 'searchableDropdownMenuPortal',
                        singleValue: () => 'searchableDropdownValue',
                        multiValue: () => 'searchableDropdownValue',
                        menuList: () => 'searchableDropdownMenuList'
                      }}
                      options={kanbanCardPageFields}
                      onChange={(e) => {
                        handleSelectChange("label", e?.value);
                      }}
                      value={selectedFields?.["label"]}
                    />
                    <label className="text-sm float-left">{displayChanges.key_mapping?.label || "(Card header)"}</label>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={"fa fa-eye"}
                    className="me-2"
                    color="#696969"
                    role="button"
                    title="Preview Card"
                    onClick={() => setShowKanbanCardPreview(true)}
                  />
                </div>
              </div>
            </div>

            <div className="my-2">
              <Select
                closeMenuOnSelect={true}
                isClearable
                menuPosition="fixed"
                classNames={{
                  control: (state) => 'searchableDropdownControl',
                  menuPortal: () => 'searchableDropdownMenuPortal',
                  singleValue: () => 'searchableDropdownValue',
                  multiValue: () => 'searchableDropdownValue',
                  menuList: () => 'searchableDropdownMenuList'
                }}
                options={kanbanCardPageFields}
                onChange={(e) => {
                  handleSelectChange("title", e?.value);
                }}
                value={selectedFields?.["title"]}
              />
              <label className="text-sm float-left">{displayChanges.key_mapping?.title || "(Sub title)"}</label>
            </div>

            <div className="my-2">
              <Select
                closeMenuOnSelect={true}
                isClearable
                menuPosition="fixed"
                classNames={{
                  control: (state) => 'searchableDropdownControl',
                  menuPortal: () => 'searchableDropdownMenuPortal',
                  singleValue: () => 'searchableDropdownValue',
                  multiValue: () => 'searchableDropdownValue',
                  menuList: () => 'searchableDropdownMenuList'
                }}
                options={kanbanCardPageFields}
                onChange={(e) => {
                  handleSelectChange("description", e?.value);
                }}
                value={selectedFields?.["description"]}
              />
              <label className="text-sm float-left">{displayChanges.key_mapping?.description || "(Description)"}</label>
            </div>

            <div className="d-flex justify-content-between">
              <div className="col-6 col-md-4 col-lg-4">
                <div className="my-2">
                  <Select
                    closeMenuOnSelect={true}
                    isClearable
                    menuPosition="fixed"
                    classNames={{
                      control: (state) => 'searchableDropdownControl',
                      menuPortal: () => 'searchableDropdownMenuPortal',
                      singleValue: () => 'searchableDropdownValue',
                      multiValue: () => 'searchableDropdownValue',
                      menuList: () => 'searchableDropdownMenuList'
                    }}
                    options={kanbanCardPageFields}
                    onChange={(e) => {
                      handleSelectChange("from_section", e?.value);
                    }}
                    value={selectedFields?.["from_section"]}
                  />
                  <label className="text-sm float-left">{displayChanges.key_mapping?.from_section || "(Status#1)"}</label>
                </div>
              </div>

              <div className="col-6 col-md-4 col-lg-4">
                <div className="my-2">
                  <Select
                    closeMenuOnSelect={true}
                    isClearable
                    menuPosition="fixed"
                    classNames={{
                      control: (state) => 'searchableDropdownControl',
                      menuPortal: () => 'searchableDropdownMenuPortal',
                      singleValue: () => 'searchableDropdownValue',
                      multiValue: () => 'searchableDropdownValue',
                      menuList: () => 'searchableDropdownMenuList'
                    }}
                    options={kanbanCardPageFields}
                    onChange={(e) => {
                      handleSelectChange("to_section", e?.value);
                    }}
                    value={selectedFields?.["to_section"]}
                  />
                  <label className="text-sm float-left">{displayChanges.key_mapping?.to_section || "(Status#2)"}</label>
                </div>
              </div>
            </div>

            <div className="my-2">
              <Select
                closeMenuOnSelect={true}
                isClearable
                menuPosition="fixed"
                classNames={{
                  control: (state) => 'searchableDropdownControl',
                  menuPortal: () => 'searchableDropdownMenuPortal',
                  singleValue: () => 'searchableDropdownValue',
                  multiValue: () => 'searchableDropdownValue',
                  menuList: () => 'searchableDropdownMenuList'
                }}
                options={kanbanCardPageFields}
                onChange={(e) => {
                  handleSelectChange("tags", e?.value);
                }}
                value={selectedFields["tags"]}
              />
              <label className="text-sm float-left">{displayChanges.key_mapping?.tags || "(Success Tags)"}</label>
            </div>

            <div className="my-2">
              <Select
                closeMenuOnSelect={true}
                isClearable
                menuPosition="fixed"
                classNames={{
                  control: (state) => 'searchableDropdownControl',
                  menuPortal: () => 'searchableDropdownMenuPortal',
                  singleValue: () => 'searchableDropdownValue',
                  multiValue: () => 'searchableDropdownValue',
                  menuList: () => 'searchableDropdownMenuList'
                }}
                options={kanbanCardPageFields}
                onChange={(e) => {
                  handleSelectChange("errorTags", e?.value);
                }}
                value={selectedFields["errorTags"]}
              />
              <label className="text-sm float-left">{displayChanges.key_mapping?.errorTags || "(Error/Warning Tags)"}</label>
            </div>

            <div className="my-2">
              <Select
                closeMenuOnSelect={true}
                isClearable
                menuPosition="fixed"
                classNames={{
                  control: (state) => 'searchableDropdownControl',
                  menuPortal: () => 'searchableDropdownMenuPortal',
                  singleValue: () => 'searchableDropdownValue',
                  multiValue: () => 'searchableDropdownValue',
                  menuList: () => 'searchableDropdownMenuList'
                }}
                options={kanbanCardPageFields}
                onChange={(e) => {
                  handleSelectChange("moreInfo", e?.value);
                }}
                value={selectedFields?.["moreInfo"]}
              />
              <label className="text-sm float-left">{displayChanges.key_mapping?.moreInfo || "(More Information in html form)"}</label>
            </div>

            <div className="border-top border-secondary border-opacity-25 pt-2 mt-2 d-flex justify-content-end">
              <div className="col-4">
                <Select
                  closeMenuOnSelect={true}
                  isClearable
                  menuPosition="fixed"
                  classNames={{
                    control: (state) => 'searchableDropdownControl',
                    menuPortal: () => 'searchableDropdownMenuPortal',
                    singleValue: () => 'searchableDropdownValue',
                    multiValue: () => 'searchableDropdownValue',
                    menuList: () => 'searchableDropdownMenuList'
                  }}
                  options={kanbanCardPageFields}
                  onChange={(e) => {
                    handleSelectChange("idField", e?.value);
                  }}
                  value={selectedFields?.["idField"]}
                />
                <label className="text-sm float-left">{displayChanges.key_mapping?.idField || "(ID Field- numeric preferred)"}</label>
              </div>
              <div className="col-4">&nbsp;</div>
              <div className="col-4">
                <Select
                  closeMenuOnSelect={true}
                  isClearable
                  menuPosition="fixed"
                  classNames={{
                    control: (state) => 'searchableDropdownControl',
                    menuPortal: () => 'searchableDropdownMenuPortal',
                    singleValue: () => 'searchableDropdownValue',
                    multiValue: () => 'searchableDropdownValue',
                    menuList: () => 'searchableDropdownMenuList'
                  }}
                  options={kanbanCardPageFields}
                  onChange={(e) => {
                    handleSelectChange("assignees", e?.value);
                  }}
                  value={selectedFields?.["assignees"]}
                />
                <label className="text-sm float-left">{displayChanges.key_mapping?.assignees || "(Person Name/ Team Name)"}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <KanbanCardPreview
        show={showKanbanCardPreview}
        onHide={setShowKanbanCardPreview}
        displayData={displayChanges}
      />
    </>
  );
}

export default DisplayBuilderKanbanCardKeyMapping;
