import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "./createschedule.scss";
import {
  fetchInstallAppData,
  getLogicsByAppId,
  getRecordById,
  upsert_schedule_record,
} from "../../../services/appService";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import Loader from "../../dataset/Loader/Loader";
import Cron from "react-js-cron";
import "react-js-cron/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class Createschedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      field: {
        scheduleName: "",
        selectedApp: "",
        selectedLogic: "",
        parameters: "",
        noOfRetries: "0",
        maxRunTime: "30",
        selectedAppName: "",
        selectedLogicName: "",
        notification_email: "",
      },
      errors: {},
      activationStatus: true,
      notifyOnError: false,
      loading: true,
      loadingApplist: true,
      logicLoading: false,
      isSaving: false,
      cronString: "* * * * *",
      getAppsList: [],
      getAppLogicData: [],
      scheduleId: "",
    };
  }

  setToDefault = () => {
    this.setState({
      field: {
        scheduleName: "",
        selectedApp: "",
        selectedLogic: "",
        parameters: "",
        noOfRetries: "0",
        maxRunTime: "30",
        selectedAppName: "",
        selectedLogicName: "",
        notification_email: "",
      },
      activationStatus: true,
      notifyOnError: false,
      cronString: "* * * * *",
      scheduleId: "",
    });
  };

  changeValue = (e) => {
    let fields = this.state.field;

    if (e.target.name === "selectedApp") {
      fields["selectedAppName"] = e.target.options[e.target.selectedIndex].text;
      this.getAppLogicData(e.target.value);
    } else if (e.target.name === "selectedLogic") {
      fields["selectedLogicName"] =
        e.target.options[e.target.selectedIndex].text;
    }
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  };

  setCronString = (val) => {
    this.setState({
      cronString: val,
    });
  };

  componentDidMount() {
    this.mounted = true;
    this.getInstallData();
    this.setScheduleId();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getScheduleData() {
    this.setState({ loading: true });
    var obj = { id: this.state.scheduleId, dataset_name: "sys_schedule" };
    getRecordById(obj)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            field: {
              scheduleName: response.data.schedule_name,
              selectedApp: response.data.app_id,
              selectedLogic: response.data.logic_id,
              parameters: response.data.parameters,
              noOfRetries: response.data.no_of_retries,
              maxRunTime: response.data.max_run_time,
              selectedAppName: response.data.app_name,
              selectedLogicName: response.data.logic_name,
              notificationEmail: response.data.notification_email,
            },
            activationStatus: response.data.activation_status,
            notifyOnError: response.data.notify_on_error,
            loading: false,
            cronString: response.data.cron_string,
          });
          this.getAppLogicData(response.data.app_id);
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  }

  setScheduleId() {
    if (this.props.match.params.schedule_id !== undefined) {
      this.setState(
        {
          scheduleId: this.props.match.params.schedule_id,
        },
        () => {
          this.getScheduleData();
        }
      );
    }
  }

  getInstallData() {
    this.setState({ loadingApplist: true, getAppsList: [] });
    fetchInstallAppData()
      .then((response) => {
        if (this.mounted) {
          if (response.success === true) {
            this.setState({
              getAppsList: response.data,
            });
          } else {
            toast.error(response?.data?.message || response?.message);
          }
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => {
        this.setState({ loadingApplist: false });
      });
  }

  getAppLogicData(appId) {
    this.setState({ logicLoading: true });
    getLogicsByAppId(appId)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            getAppLogicData: response.data,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => {
        this.setState({ logicLoading: false });
      });
  }

  activationStatusHandler = () => {
    this.setState({ activationStatus: !this.state.activationStatus });
  };

  notifyOnErrorHandler = () => {
    this.setState({ notifyOnError: !this.state.notifyOnError });
  };

  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;

    if (!fields["scheduleName"]) {
      formIsValid = false;
      errors["scheduleName"] = "*Please enter schedule Name.";
    }

    if (!fields["selectedApp"]) {
      formIsValid = false;
      errors["selectedApp"] = "*Please Select App.";
    }

    if (!fields["selectedLogic"]) {
      formIsValid = false;
      errors["selectedLogic"] = "*Please Select Logic.";
    }

    if (!fields["noOfRetries"]) {
      formIsValid = false;
      errors["noOfRetries"] = "*Please enter No. Of Retries.";
    }

    if (!fields["maxRunTime"]) {
      formIsValid = false;
      errors["maxRunTime"] = "*Please enter Max Run Time in Minutes.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  addSchedule(scheduleData) {
    let obj = {};
    if (this.state.scheduleId !== "") {
      obj = {
        object_id: this.state.scheduleId,
        dataset_name: "sys_schedule",
        fields: scheduleData,
      };
    } else {
      obj = {
        dataset_name: "sys_schedule",
        fields: scheduleData,
      };
    }
    this.setState({
      isSaving: true,
    });
    upsert_schedule_record(obj)
      .then((response) => {
        if (response) {
          toast.success(response.message);
          this.setState({ loading: false, isSaving: false });
          if (this.state.scheduleId !== "") {
            this.props.history.goBack();
          }
          this.setToDefault();
          this.props.history.push("/schedule");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
        this.setState({ loading: false, isSaving: false });
      });
  }

  saveSchedule = (e) => {
    e.preventDefault();

    if (this.validateFieldForm()) {
      let obj = {
        schedule_name: this.state.field.scheduleName,
        cron_string: this.state.cronString,
        activation_status: this.state.activationStatus,
        app_id: this.state.field.selectedApp,
        logic_id: this.state.field.selectedLogic,
        parameters: this.state.field.parameters,
        no_of_retries: parseInt(this.state.field.noOfRetries),
        notify_on_error: this.state.notifyOnError,
        max_run_time: parseInt(this.state.field.maxRunTime),
        app_name: this.state.field.selectedAppName,
        logic_name: this.state.field.selectedLogicName,
        notification_email: this.state.field.notificationEmail,
      };
      this.addSchedule(obj);
    }
  };

  render() {
    return (
      <div>
        <div className="d-help py-2">
          <h5>
            {this.state.scheduleId !== ""
              ? "Update Schedule"
              : "Create Schedule"}
          </h5>
          <button
            type="button"
            className="back-btn m-0"
            onClick={(e) => {
              e.preventDefault();
              this.props?.history?.goBack();
            }}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>
        <div className="border border-secondary border-opacity-25 p-3 bg-white mb-2">
          <form onSubmit={this.saveSchedule}>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Schedule Name
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  name="scheduleName"
                  value={this.state.field.scheduleName}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.scheduleName}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Define Schedule
              </label>
              <div className="col-md-9 col-sm-9 cronStringContainer">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="m-0 title">
                        <span className="cronStringText">
                          {this.state.cronString}
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Cron
                        value={this.state.cronString}
                        setValue={this.setCronString}
                        clearButton={false}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 col-6 text-nowrap required">
                Activation Status
              </label>
              <div className="col-md-9 col-sm-9 col-6 d-flex align-items-center">
                <FontAwesomeIcon
                  icon={
                    this.state.activationStatus
                      ? "fa fa-toggle-on"
                      : "fa fa-toggle-off"
                  }
                  role="button"
                  className={`togglebtn toggleFromBtnSize ${
                    this.state.activationStatus
                      ? " text-success"
                      : " text-danger"
                  }`}
                  onClick={this.activationStatusHandler}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Select App
              </label>
              <div className="col-md-9 col-sm-9 ">
                <Form.Select
                  className="form-control"
                  name="selectedApp"
                  value={this.state.field.selectedApp}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                >
                  <option value={""} disabled>
                    {this.state.loadingApplist ? "Loading.." : "--Select--"}
                  </option>
                  {this.state.getAppsList.map((app, index) => {
                    return (
                      <option value={app._id.$oid} key={index}>
                        {app.app_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <div className="errorMsg text-danger">
                  {this.state.errors.selectedApp}
                </div>{" "}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Select Logic
              </label>
              <div className="col-md-9 col-sm-9 ">
                <Form.Select
                  className="form-control"
                  name="selectedLogic"
                  value={this.state.field.selectedLogic}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                >
                  <option value={""} disabled>
                    {this.state.logicLoading ? "Loading.." : "--Select--"}
                  </option>
                  {this.state.getAppLogicData.map((logic, index) => {
                    return (
                      <option value={logic._id.$oid} key={index}>
                        {logic.function_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <div className="errorMsg text-danger">
                  {this.state.errors.selectedLogic}
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3">
                Parameters
              </label>
              <div className="col-md-9 col-sm-9 ">
                <textarea
                  className="form-control jsonParameter"
                  placeholder="Enter Parameters(JSON)"
                  name="parameters"
                  value={this.state.field.parameters}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                ></textarea>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                No. of Retries
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  min="0"
                  type="number"
                  className="form-control"
                  placeholder="Enter Retries"
                  name="noOfRetries"
                  value={this.state.field.noOfRetries}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.noOfRetries}
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 col-6 text-nowrap required">
                Notify On Error ?
              </label>
              <div className="col-md-9 col-sm-9 col-6 ">
                <FontAwesomeIcon
                  icon={
                    this.state.notifyOnError
                      ? "fa fa-toggle-on"
                      : "fa fa-toggle-off"
                  }
                  role="button"
                  className={`togglebtn toggleFormBtnSize ${
                    this.state.notifyOnError ? " text-success" : " text-danger"
                  }`}
                  onClick={this.notifyOnErrorHandler}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Notify errors on this email
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter email to notify in case of any error"
                  name="notificationEmail"
                  value={this.state.field.notificationEmail}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.notificationEmail}
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Max Runtime(minutes)
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  min="0"
                  pattern="[0-9]*"
                  type="number"
                  className="form-control"
                  placeholder="Enter max runtime in minutes"
                  name="maxRunTime"
                  value={this.state.field.maxRunTime}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.maxRunTime}
                </div>
              </div>
            </div>

            <div className="text-end">
              {this.state.scheduleId !== "" ? (
                <button
                  type="button"
                  className="btn btn-danger btn-md  m-0 me-2"
                  disabled={this.state.isSaving}
                  onClick={this.props.history.goBack}
                >
                  Cancel
                </button>
              ) : null}
              <button
                type="submit"
                className="custom-btn m-0"
                disabled={this.state.isSaving}
              >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />&nbsp;&nbsp;
                {this.state.isSaving ? (
                  <>
                    <div className="spinner-border spinner-border-sm text-light me-1" />
                    Saving
                  </>
                ) : (
                  <>{this.state.scheduleId !== "" ? "Update" : "Save"}</>
                )}&nbsp;&nbsp;
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Createschedule;
