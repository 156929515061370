import React, { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { createNewTenant } from "../../services/appService";
import "./Tenant.scss";
import logo from "../../images/Logo-10.png";
import backgroungImg from "../../images/bg_img.png";
import ReactFileReader from "react-file-reader";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class Tenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: {
        companyname: "",
        website: "",
        firstName: "",
        lastName: "",
        contact: "",
        store_plan: "",
        number_of_store: 0,
        number_of_product: 0,
      },
      fileImage: "",
      banngerFileImage: "",
      errors: {},
      file: "",
      fileBanner: "",
      subscription: [
        { name: "Freemium Plan", value: "freemium_plan" },
        { name: "Business Plan", value: "business_plan" },
        { name: "Enterprise Plan", value: "enterprise_plan" },
      ],
    };
  }

  routeToDashboard() {
    this.props.history.push(`/app-dashboard`);
  }

  changeValue(event) {
    let fields = this.state.field;
    fields[event.target.name] = event.target.value;
    if (event.target.name === "store_plan") {
      if (event.target.value === "freemium_plan") {
        this.setState({
          field: {
            ...this.state.field,
            number_of_store: 1,
            number_of_product: 25,
          },
        });
      }
      if (event.target.value === "business_plan") {
        this.setState({
          field: {
            ...this.state.field,
            number_of_store: 5,
            number_of_product: 100,
          },
        });
      }
      if (event.target.value === "enterprise_plan") {
        this.setState({
          field: {
            ...this.state.field,
            number_of_store: "",
            number_of_product: "",
          },
        });
      }
    }
    this.setState({
      fields,
    });
  }

  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;
    var companyNameRegex = /^[A-Za-z]*$/;

    if (!fields["companyname"]) {
      formIsValid = false;
      errors["companyname"] = "*Please enter company name.";
    } else if (!companyNameRegex.test(fields["companyname"])) {
      formIsValid = false;
      errors["companyname"] = "*Only alphabets are allowed.";
    }
    if (!fields["website"]) {
      formIsValid = false;
      errors["website"] = "*Please enter website link";
    }
    if (!fields["contact"]) {
      formIsValid = false;
      errors["contact"] = "*Please enter contact number";
    }
    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "*Please enter first name";
    }

    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "*Please enter last Name";
    }

    if (this.state.fileImage.length <= 0) {
      formIsValid = false;
      errors["logo"] = "*Please upload  a logo";
    }
    if (!fields["store_plan"]) {
      formIsValid = false;
      errors["store_plan"] = "*Please select subscription type.";
    }
    if (!fields["number_of_store"]) {
      formIsValid = false;
      errors["number_of_store"] = "*Please enter number of store.";
    }
    if (!fields["number_of_product"]) {
      formIsValid = false;
      errors["number_of_product"] = "*Please enter number of product.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleFiles = (files) => {
    this.setState({
      fileImage: files[0],
      file: URL.createObjectURL(files[0]),
    });
  };

  handleBannerFiles = (files) => {
    this.setState({
      banngerFileImage: files[0],
      fileBanner: URL.createObjectURL(files[0]),
    });
  };

  submitTenant(event) {
    event.preventDefault();
    const id = uuidv4();
    if (this.validateFieldForm()) {
      const formData = new FormData();

      if (this.state.banngerFileImage !== "") {
        formData.append("banner_file", this.state.banngerFileImage);
      }
      formData.append("file", this.state.fileImage);
      formData.append("tenant_name", this.state.field.companyname);
      formData.append("tenant_website", this.state.field.website);
      formData.append("tenant_contact", this.state.field.contact);
      formData.append("first_name", this.state.field.firstName);
      formData.append("last_name", this.state.field.lastName);
      formData.append("tenant_id", id);
      formData.append("store_plan", this.state.field.store_plan);
      formData.append("number_of_store", this.state.field.number_of_store);
      formData.append("number_of_product", this.state.field.number_of_product);

      createNewTenant(formData)
        .then((response) => {
          if (response.success === true) {
            if (response.message === "Tenant created successfully") {
              this.routeToDashboard();
            }
            if (response.message === "Tenant already exist") {
              toast.error(response?.data?.message || response?.message);
            }
          } else {
            toast.error(response?.data?.message || response?.message);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        });
    }
  }

  render() {
    return (
      <div className="tenant_main">
        <div className="col-md-12">
          <div className="row bg-white">
            <div className="col-md-7 p-0 bg-white d-sm-none d-md-block d-lg-block d-none">
              <img
                src={backgroungImg}
                alt="bg"
                className="img-fluid backimg"
              ></img>
            </div>
            <div className="col-md-5 right-side-col">
              <div className="tenant_main_content sm-border-0 p-sm-0">
                <div className="x_panel border-0">
                  <div className="logo logo d-flex justify-content-center">
                    <img className="flashcorpLogo" src={logo} alt="logo"></img>
                  </div>
                  <div className="x_content">
                    <form className="form-horizontal form-label-left">
                      <div className="form-group row ">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          Company
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="text"
                            name="companyname"
                            className="form-control"
                            placeholder="Enter Company name"
                            value={this.state.field.companyname}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.companyname}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          Website link
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="text"
                            name="website"
                            className="form-control"
                            placeholder="Enter Website link"
                            value={this.state.field.website}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.website}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          First Name
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            placeholder="Enter First Name"
                            value={this.state.field.firstName}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.firstName}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          Last Name
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            placeholder="Enter Last Name"
                            value={this.state.field.lastName}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.lastName}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          Subscription Type
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <Form.Select
                            className="form-control"
                            name="store_plan"
                            value={this.state.field.store_plan}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          >
                            <option
                              value=""
                              disabled={this.defaultDisabled ? true : false}
                            >
                              Select
                            </option>
                            {this.state.subscription.map((type, index) => {
                              return (
                                <option value={type.value} key={index}>
                                  {type.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <div className="errorMsg text-danger">
                            {this.state.errors.store_plan}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          Store
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Number of Store"
                            name="number_of_store"
                            value={this.state.field.number_of_store}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.number_of_store}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          Product
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Number of Product"
                            name="number_of_product"
                            value={this.state.field.number_of_product}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.number_of_product}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required ">
                          Contact
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <input
                            type="number"
                            name="contact"
                            className="form-control"
                            placeholder="Enter Contact Number"
                            value={this.state.field.contact}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.contact}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4 required">
                          Upload Logo
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <ReactFileReader
                            handleFiles={this.handleFiles}
                            multipleFiles={false}
                          >
                            <>
                              <button
                                type="button"
                                className="btn btn-primary btn-md"
                              >
                                Upload
                              </button>
                              <span>
                                {this.state.fileImage ? (
                                  <img
                                    src={this.state.file}
                                    alt="logo-img"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      marginLeft: "20px",
                                    }}
                                  />
                                ) : null}
                              </span>
                            </>
                          </ReactFileReader>
                          <div className="errorMsg text-danger">
                            {this.state.errors.logo}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-4 col-sm-4">
                          Upload Background
                        </label>
                        <div className="col-md-8 col-sm-8 ">
                          <ReactFileReader
                            handleFiles={this.handleBannerFiles}
                            multipleFiles={false}
                          >
                            <>
                              <button
                                type="button"
                                className="btn btn-primary btn-md"
                              >
                                Upload
                              </button>
                              <span>
                                {this.state.banngerFileImage ? (
                                  <img
                                    src={this.state.fileBanner}
                                    alt="logo-img"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      marginLeft: "20px",
                                    }}
                                  />
                                ) : null}
                              </span>
                            </>
                          </ReactFileReader>
                          <div className="errorMsg text-danger">
                            {this.state.errors.banner}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-md-9 col-sm-12  offset-md-3">
                          <button
                            type="button"
                            className="btn btn-success btn-md tensub"
                            onClick={(event) => this.submitTenant(event)}
                          >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />&nbsp;&nbsp;
                            Submit&nbsp;&nbsp;
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger btn-md tensub"
                            onClick={() => {
                              this.props.history.goBack();
                            }}
                          >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-left" />&nbsp;&nbsp;
                            Back&nbsp;&nbsp;
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="footer_title">
                <p className="m-0 ps-5 ps-sm-0">
                  Copyright &copy; {new Date().getFullYear()} Flashcorp.All
                  Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tenant;
