import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { fetchAllDataSets } from "../../services/datasetService";
import { toast } from "react-toastify";
import Loader from "./Loader/Loader";
import Pagination from "react-js-pagination";
import moment from "moment";
import { Link } from "react-router-dom";
import "./datasetDataList.scss";

const DatasetDataList = () => {
  const history = useHistory();

  // State management
  const [dataSetList, setDataSetList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folders, setFolders] = useState([]);

  // Helper function to sort folders
  const sortFolders = (a, b) => {
    if (a === "Default") return -1;
    if (b === "Default") return 1;
    return a.localeCompare(b);
  };

  // Fetch datasets from API
  const fetchDataSetList = () => {
    const obj = {
      SortCriteria: [{ sort_field: "name", sort_type: "asc" }],
    };

    setIsLoading(true);
    fetchAllDataSets(obj)
      .then((response) => {
        if (response.success) {
          const folderSet = new Set();

          response.data.forEach(dataset => {
            const folderName = dataset.folder_name || "Default";
            folderSet.add(folderName);
          });

          const folderArray = Array.from(folderSet).sort(sortFolders);
          
          setFolders(folderArray);
          setDataSetList(response.data);
          setTotalRecordCount(response.total_count);
        }
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || 
                          error.response?.message || 
                          error.message || 
                          "Failed to load datasets";
        toast.error(errorMessage);
        setDataSetList([]);
        setFolders([]);
        setTotalRecordCount(0);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchDataSetList();
  }, []);

  // Folder card component
  const FolderCard = ({ folder, count, onClick }) => (
    <div className="col-md-2 mb-4">
      <div className="folder-card p-3 text-center cursor-pointer" onClick={onClick}>
        <FontAwesomeIcon icon="fa fa-folder" size="3x" className="text-warning mb-2" />
        <div className="folder-name"><h5>{folder}</h5></div>
        <div className="folder-count text-muted small">{count} items</div>
      </div>
    </div>
  );

  // Handle folder click
  const handleFolderClick = (folderName) => {
    setSelectedFolder(folderName);
    setActivePage(1);
  };

  // Handle back to folders
  const handleBackToFolders = () => {
    setSelectedFolder(null);
  };

  // Get filtered datasets memoized
  const filteredDatasets = useMemo(() => {
    if (selectedFolder === "Default") return dataSetList.filter(dataset => (!dataset.folder_name || dataset.folder_name === "Default" || dataset.folder_name===""));
    if (selectedFolder) return dataSetList.filter(d => d.folder_name === selectedFolder);
    return [];
  }, [selectedFolder, dataSetList]);

  // Pagination calculations
  const indexOfLastRecord = activePage * 20;
  const indexOfFirstRecord = indexOfLastRecord - 20;
  const displayData = filteredDatasets.slice(indexOfFirstRecord, indexOfLastRecord);

  // Render dataset table row
  const renderTableRow = (dataSet, index) => (
    <tr key={index}>
      <td>{indexOfFirstRecord + index + 1}</td>
      <td>{dataSet.name}</td>
      <td>{dataSet.description}</td>
      <td>{dataSet.author}</td>
      <td>{dataSet.created_date ? moment(dataSet.created_date).format("L") : null}</td>
      <td>{dataSet.updated_date ? moment(dataSet.updated_date).format("L") : null}</td>
      <td>
        <div className="action-icons text-start">
          <Link to={`dataset/${dataSet._id.$oid}`}>
            <FontAwesomeIcon
              icon={"fa fa-pen-to-square"}
              className="me-2"
              title="Edit"
              size="lg"
            />
          </Link>
        </div>
      </td>
    </tr>
  );

  // Render folders view
 // Render folders view
const renderFolders = () => (
  <div className="folder-view">
    {folders.length === 0 ? (
      <div className="text-center py-5">
        <FontAwesomeIcon icon="fa fa-folder-open" size="3x" className="text-muted mb-3" />
        <h5>No folders available</h5>
        <p>Create a dataset to get started</p>
        <Link to={`dataset`}>
          <button
            type="button"
            className="add-btn mt-3 text-nowrap"
          >
            &nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-plus" />
            <span className="ms-1">&nbsp;&nbsp;Add Dataset&nbsp;&nbsp;</span>
          </button>
        </Link>
      </div>
    ) : (
      <div className="row">
        {folders.map((folder) => (
          <FolderCard 
            key={folder}
            folder={folder}
            count={
              folder === "Default" 
                ? dataSetList.filter(dataset => (!dataset.folder_name || dataset.folder_name === "Default" || dataset.folder_name==="")).length 
                : dataSetList.filter(d => d.folder_name === folder).length
            }
            onClick={() => handleFolderClick(folder)}
          />
        ))}
      </div>
    )}
  </div>
);

  // Render datasets table view
  const renderDatasetsTable = () => (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h6>
          <button 
            onClick={handleBackToFolders}
            className="btn btn-sm btn-outline-secondary me-2"
          >
            <FontAwesomeIcon icon={"fa fa-arrow-left"} />
          </button>
          Datasets in {selectedFolder}
        </h6>
        <Link to={`dataset`}>
          <button
            type="button"
            className="add-btn ms-2 my-auto text-nowrap me-0"
          >
            &nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-plus" />
            <span className="ms-1">&nbsp;&nbsp;Add Dataset&nbsp;&nbsp;</span>
          </button>
        </Link>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered mb-0">
          <thead>
            <tr>
              <th className="text-nowrap">Sr. No.</th>
              <th className="text-nowrap">Dataset Name</th>
              <th>Description</th>
              <th>Author</th>
              <th className="text-nowrap">Created Date</th>
              <th className="text-nowrap">Last Updated Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{displayData.map(renderTableRow)}</tbody>
        </table>
      </div>

      <div className="dynamic_pagination justify-content-between">
        {filteredDatasets.length > 0 && (
          <div className="my-2">
            Showing {(activePage - 1) * 20 + 1} to{" "}
            {Math.min(activePage * 20, filteredDatasets.length)}{" "}
            of {filteredDatasets.length} entries
          </div>
        )}
        {filteredDatasets.length > 20 && (
          <Pagination
            pageRangeDisplayed={3}
            activePage={activePage}
            itemsCountPerPage={20}
            totalItemsCount={filteredDatasets.length}
            onChange={setActivePage}
          />
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className="d-help py-2 flex-wrap">
        <h5>Dataset Field Editor</h5>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {!selectedFolder ? renderFolders() : renderDatasetsTable()}
        </div>
      )}
    </div>
  );
};

export default DatasetDataList;