import React, { useState, useContext } from "react";
import { Dropdown, ButtonGroup, Table } from "react-bootstrap";
import CustomNodeModel from "./CustomNode/CustomNodeModel";
import NodeConfig from "./CustomNode/NodeConfig";
import * as API from "../../../../services/API";
import { NodeContext } from "../../../../contexts/NodeContext";
import Loader from "../../../dataset/Loader/Loader";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function GlobalFlowMenu(props) {
  const nodeContext = useContext(NodeContext);
  const [show, setShow] = useState(false);
  const [activeNode, setActiveNode] = useState();
  const [creating, setCreating] = useState(false);
  const [loading, SetLoading] = useState(false);
  const toggleShow = () => setShow(!show);
  const [isOpen, setIsOpen] = useState(true);

  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  // Create CustomNodeModel from JSON data,
  // whether from menu item or global flow variable
  function nodeFromData(data) {
    const info = { ...data, is_global: true };
    const config = info.options;
    delete info.options;
    if (!info.option_types) {
      info.option_types = lookupOptionTypes(info.node_key);
    }
    const node = new CustomNodeModel(info, config);
    return node;
  }

  // Look up option types from appropriate menu item.
  // The option types aren't included in the global flow
  // serialization from the server.
  function lookupOptionTypes(nodeKey) {
    const keyMatches = props.menuItems.filter((d) => d.node_key === nodeKey);
    if (!keyMatches.length) return {};
    return keyMatches[0].option_types || {};
  }

  const handleEdit = (data, create = false) => {
    setCreating(create);
    const node = nodeFromData(data);
    setActiveNode(node);
    setShow(true);
  };

  const handleSubmit = (data, flowData, logic_id) => {
    const node = activeNode;
    if (
      node.options.node_key === "FormNode" ||
      node.options.node_key === "IntegerNode" ||
      node.options.node_key === "StringNode"
    ) {
      nodeContext.setFlag(false);
    }
    if (
      node.options.node_key === "FormNode" &&
      node.options.node_type === "flow_control"
    ) {
      data.default_value = data.form_data.dataset_column;
      data.var_name = `${data.form_data.dataset_column}_${data.form_data.dataset}`;
    } else {
      data.form_data = {};
    }
    if (creating) {
      node.config = data;
      SetLoading(true);
      API.addNode(node, logic_id)
        .then((response) => {
          SetLoading(false);
          callRedisAPI();
          props.onUpdate();
          if (response.message) {
            nodeContext.setFlag(true);
          }
          if (response.error) {
            toast.error(response.error);
          }
        })
        .catch((err) => {
          if (err) {
            toast.error(err.response?.data?.message || err.response?.message || err.message);
          }
          SetLoading(false);
        });
    } else {
      SetLoading(true);
      API.updateNode(node, data, flowData, props.logic_id)
        .then((response) => {
          SetLoading(false);
          callRedisAPI();
          props.onUpdate();
          nodeContext.setFlag(true);
          toast.success(response.message);
        })
        .catch((err) => {
          SetLoading(false);
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        });
    }
  };

  const callRedisAPI = () => {
    let objData = {
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
    };
    API.callVPApiRedis(objData, props.logic_id)
      .then((response) => { })
      .catch((err) => { });
  };

  const handleDelete = (data) => {
    const msg = "Are you sure you want to delete the global flow variable?";
    if (window.confirm(msg)) {
      const node = nodeFromData(data);
      API.deleteNode(node, props.logic_id)
        .then(() => {
          props.onUpdate();
          nodeContext.setFlag(true);
        })
        .catch((err) => { });
    }
  };

  return (
    <div className="GlobalFlowMenu m-0 p-2">
      <h6 className="d-flex justify-content-between">
        Flow Variables
        <Dropdown size="xs">
            <Dropdown.Toggle
              split
              className="add-btn"
              size="xs"
              id="dropdown-split-basic"
            >
              <span className="pe-2"><FontAwesomeIcon icon="fa fa-solid fa-plus" />&nbsp;Add</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {props.menuItems.map((node, i) => (
                <Dropdown.Item
                  key={node.key || i}
                  onClick={() => handleEdit(node, true)}
                >
                  {node.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        <div className="float-right" onClick={props.onToggle}>
          {!props.isOpen && (
            <FontAwesomeIcon fontSize="20" size="lg" icon="fa fa-solid fa-caret-down" />
          )}
          {props.isOpen && (
            <FontAwesomeIcon fontSize="20" size="lg" icon="fa fa-solid fa-caret-up" />
          )}
        </div>
      </h6>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          {props.isOpen && (
            <>
              <div className="table-responsive node-submenu variables-submenu tableTheme1">
                <Table size="sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Value</th>
                      <th action="true">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.nodes.map((node) => (
                      <tr key={node.id}>
                        <td>{node.options.var_name}</td>
                        <td>{node.name}</td>
                        <td className="text-primary">
                          {node.options.default_value}
                        </td>
                        <td action="true" className="d-flex justify-content-evenly">
                          <FontAwesomeIcon
                            icon={"fa fa-pen-to-square"}
                            className="me-2"
                            onClick={() => handleEdit(node, false)}
                            title="Edit"
                            size="lg"
                            role="button"
                          />
                          <FontAwesomeIcon
                            icon={"fa fa-trash"}
                            role="button"
                            onClick={() => handleDelete(node)}
                            title="Delete"
                            size="lg"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </React.Fragment>
      )}
      <NodeConfig
        node={activeNode}
        show={show}
        toggleShow={toggleShow}
        onSubmit={handleSubmit}
        logic_id={props.logic_id}
      />
    </div>
  );
}
