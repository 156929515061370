import React, { Component } from "react";
import fcLogo from "../../images/FLashLogo.png";
import "./Login.scss";
import {
  changePassword,
  getTenantDetail,
  resetPassWord,
} from "../../services/appService";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {checkImageExists, getIconImage} from "../../services/utils";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      hidden: true,
      confirmPassword: "",
      newPassword: "",
      disableButton: false,
      errors: {},
      tenantName: "",
      logoImage: "",
      loader: false,
    };
  }

  componentDidMount() {
    let query = this.props.location.search;
    let name = new URLSearchParams(query).get("tenant_name");
    this.setState({ tenantName: name });
    this.getDetailsOfTenantRecord(name);
  }

  getDetailsOfTenantRecord = (tenantName) => {
    this.setState({
      loader: true,
    });
    getTenantDetail(tenantName)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            logoImage: response.data[0]?.data_file,
          });
        }
      })
      .finally(() => {
        this.setState({ loader: false });
      });
  };

  changeValue = (e) => {
    let tempState = this.state;
    tempState[e.target.name] = e.target.value;
    this.setState({ tempState });
  };

  sendEmail = (event) => {
    event.preventDefault();
    this.setState({ disableButton: true });
    const obj = {
      user_email: this.state.email,
      tenant_name: this.state.tenantName,
    };
    changePassword(obj)
      .then((response) => {
        toast.success("Password reset email has been sent to the provided email address. Please check your email.");
        this.setState({ disableButton: false });
      })
      .catch((err) => {
        if (err.response || err.message) {
          toast.error("Email address does not exist!");
          this.setState({ disableButton: false });
        }
      });
  };

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  validateData() {
    let errors = {};
    let validateForm = true;
    if (this.state.confirmPassword !== this.state.newPassword) {
      errors["confirmPassword"] =
        "*Those passwords didn't match. Please try again.";
      validateForm = false;
    }
    this.setState({
      errors: errors,
    });
    return validateForm;
  }

  resetPassWord = (event) => {
    event.preventDefault();
    const body = {
      new_password: this.state.newPassword,
      confirm_new_password: this.state.confirmPassword,
    };
    if (this.validateData()) {
      this.setState({ disableButton: true });
      resetPassWord(body)
        .then((response) => {
          toast.success(response.data);
          this.setState({ disableButton: false });
          this.props.history.push(
            `/login?tenant_name=${this.state.tenantName}`
          );
        })
        .catch((err) => {
          if (err.response || err.message) {
            toast.error(err.response?.data?.message || err.response?.message || err.message);
            this.setState({ disableButton: false });
          }
        });
    }
  };

  render() {
    let resetPassWordUrl = false;
    if (this.props.location.pathname === "/reset_password") {
      resetPassWordUrl = true;
    }
    return (
      <div className="login_main">
        <div className="col-md-12">
          <div className="row forgotPasswordRow bodyBackground">
            <div className="forgotPasswordRow">
              <div>
                <div className="bg-white d-flex justify-content-center logoContainer">
                  {this.state.loader ? (
                    <div className="shimmer shimmerLogo"></div>
                  ) : checkImageExists(this.state.logoImage) ? (
                    <img
                      src={getIconImage(this.state.logoImage)}
                      alt="logo"
                      className="img-fluid"
                      height={200}
                      width={200}
                    />
                  ) : (
                    <img
                      src={fcLogo}
                      alt="bg"
                      className="img-fluid"
                      height={200}
                      width={200}
                    ></img>
                  )}
                </div>
                <div className="x_panel border-0">
                  <div className="x_content">
                    <form className="form-horizontal form-label-left">
                      {resetPassWordUrl === false ? (
                        <div>
                          <div className="form-group row ">
                            <div className="col-12">
                              <input
                                autoComplete="on"
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter Email Id"
                                value={this.state.email}
                                onChange={(e) => {
                                  this.changeValue(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div>&nbsp;</div>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-success btn-md"
                                onClick={(event) => this.sendEmail(event)}
                                disabled={this.state.disableButton}
                              >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-envelope" />
                                &nbsp;&nbsp;Send Email&nbsp;&nbsp;
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary btn-md"
                                onClick={() => {
                                  this.props.history.goBack();
                                }}
                              >
                                &nbsp;&nbsp;<FontAwesomeIcon
                                  icon="fa fa-arrow-left"
                                  className="me-1"
                                />
                                &nbsp;&nbsp;Back&nbsp;&nbsp;
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="form-group row ">
                            <label className="control-label col-md-3 col-sm-3 ">
                              New Password
                            </label>
                            <div className="col-md-9 col-sm-9 ">
                              <input
                                autoComplete="off"
                                type={this.state.hidden ? "password" : "text"}
                                name="newPassword"
                                className="form-control"
                                placeholder="Enter New Password"
                                value={this.state.newPassword}
                                onChange={(e) => {
                                  this.changeValue(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="control-label col-md-3 col-sm-3 ">
                              Confirm Password
                            </label>
                            <div className="col-md-9 col-sm-9 ">
                              <input
                                autoComplete="off"
                                type={this.state.hidden ? "password" : "text"}
                                name="confirmPassword"
                                className="form-control"
                                placeholder="Confirm Password"
                                value={this.state.confirmPassword}
                                onChange={(e) => {
                                  this.changeValue(e);
                                }}
                              />
                              <div className="errorMsg text-danger">
                                {this.state.errors.confirmPassword}
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-md-9 col-sm-9 offset-md-3">
                              <div
                                className="col-md-9 col-sm-9 buttonAlignment"
                                onClick={() => this.toggleShow()}
                              >
                                Show password?
                              </div>
                              <div className="col-md-3 col-sm-3 p-0">
                                <button
                                  type="submit"
                                  className="btn btn-success btn-md"
                                  onClick={(event) => this.resetPassWord(event)}
                                  disabled={this.state.disableButton}
                                >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-eraser" />
                                  &nbsp;&nbsp;Reset&nbsp;&nbsp;
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
