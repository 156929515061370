import React, { Component } from "react";
import { toast } from "react-toastify";
import ReactFileReader from "react-file-reader";
import { importProductList } from "../../../services/appService";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class ImportProductExcel extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
  }

  // get files from button
  handleFiles = (files) => {
    let hasError = false;
    if (files[0].name.indexOf(".csv") === -1) {
      hasError = true;
      toast.error("Please Enter CSV File Format");
    }
    if (!hasError) {
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("category_name", this.props.category_name);
      formData.append("category_id", this.props.category_id);
      this.uploadFile(formData);
      this.setState({ showModal: false });
    }
  };

  // call import api
  uploadFile = (formData) => {
    importProductList(this.props.store_id, formData)
      .then((response) => {
        // update table data from table component
        this.props.getTableDataImport();
      })
      .catch((error) => {
        this.props.getTableDataImport();
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      });
  };
  render() {
    const handleClose = () => {
      this.setState({ showModal: false });
    };
    const handleImport = () => {
      this.setState({ showModal: true });
    };
    return (
      <div>
        <button
          className="btn btn-primary btn-md me-2 d-flex align-items-center"
          onClick={handleImport}
        >
          <FontAwesomeIcon icon="fa-solid fa-file-import" />
          <span className="ms-1">Import</span>
        </button>

        <Modal show={this.state.showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="m-0">Confirm</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You can add{" "}
            {this.props.total_product_count - this.props.total_inserted_product}{" "}
            products. Do you want to proceed?
          </Modal.Body>
          <Modal.Footer>
            <ReactFileReader
              fileTypes={[".csv"]}
              handleFiles={this.handleFiles}
              multipleFiles={false}
            >
              <button
                type="button"
                className="btn btn-success btn-md m-0"
                // onClick={handleClose}
              >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-check" />
                &nbsp;&nbsp;Yes&nbsp;&nbsp;
              </button>
            </ReactFileReader>
            <button
              type="button"
              className="btn btn-success btn-md m-0"
              onClick={handleClose}
            >
              &nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-xmark" />
                &nbsp;&nbsp;No&nbsp;&nbsp;
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ImportProductExcel;
