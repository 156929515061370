import axios from "./axios";
import { serviceUrls } from "./serviceUrls";
let tenant_Id = JSON.parse(localStorage.getItem("tenantId"));

export const fetchAllDataSets = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchAllDataset, body, config);
};

export const getRecordsByTenantId = (
  getData,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    serviceUrls().getRecordsByTenantId +
      "/" +
      getData.dataset_id +
      "?is_parent=" +
      getData.is_parent +
      "&offset=" +
      getData.offset +
      "&limit=" +
      getData.limit
  );
};

export const saveFormDataSet = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().saveDataSet, body, config);
};

export const searchDatasetByName = (
  searchValue,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().searchDatasetByName +
      "?search_value=" +
      searchValue +
      "&tenant_id=" +
      (tenant_Id || JSON.parse(localStorage.getItem("tenantId"))) +
      ""
  );
};

export const saveMetaData = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().setMetadata, body, config);
};

export const getMetaData = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().getMetadata, body, config);
};

export const deleteMetaDataField = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.delete(
    serviceUrls().deleteMetadataField,
    { data: body },
    config
  );
};

export const addMetaDataField = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.patch(serviceUrls().addMetadataField, body, config);
};

export const saveAppResonseByJson = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().saveRecordByJsonId, body, config);
};

export const getUserDetailsByDatasetName = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().getUserDetailsByDatasetName,
    body,
    config
  );
};
