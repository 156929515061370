import Quill from "quill";

const BlockEmbed = Quill.import("blots/block/embed");

class TranscriptBlot extends BlockEmbed {
    static blotName = "transcript";
    static tagName = "details";

    static create(value) {
        let node = super.create();
        node.style.margin = "10px 0"; 
        node.style.padding = "10px"; 

        let summary = document.createElement("summary");
        summary.innerText = value?.summary || "Show Transcript";
        summary.style.cursor = "pointer";
        summary.style.fontWeight = "bold";
        summary.style.border = "1px solid #ccc"; 
        summary.style.padding = "5px 10px";
        summary.style.display = "inline-block";
        summary.style.listStyle = "none"; // Remove triangle inline

        let content = document.createElement("p");
        content.innerHTML = value?.text || "No transcript available.";

        node.appendChild(summary);
        node.appendChild(content);

        return node;
    }

    static value(node) {
        return {
            summary: node.querySelector("summary")?.innerText || "Show Transcript",
            text: node.querySelector("p")?.innerHTML || "",
        };
    }
}

Quill.register(TranscriptBlot);

export default TranscriptBlot;