import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
  fetchInstallAppData,
  getFlowsByAppId,
} from "../../../../services/appService";
import { toast } from "react-toastify";
import { transitionList } from "../../../common/model/Model";

const LinkingComponent = ({ value, onChange }) => {
  const linkingTypeList = transitionList;

  const [objectChange, setObjectChange] = useState({});
  const [appList, setAppList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [flowLoader, setFlowLoader] = useState(false);

  let linkingTypeListOption = linkingTypeList.map((type, index) => (
    <option key={index} value={type.value}>
      {type.name}
    </option>
  ));

  useEffect(() => {
    let { linkAppId, linkingType, linkPageId, linkingReference } = value;
    setObjectChange({ linkAppId, linkingType, linkPageId, linkingReference });
  }, [value]);

  useEffect(() => {
    getAllInstalledApps();
  }, []);

  useEffect(() => {
    if (objectChange?.linkAppId) {
      getFlowById(objectChange?.linkAppId);
    }
  }, [objectChange?.linkAppId]);

  const handleChange = (e) => {
    let objectData = { ...objectChange };
    let name = e.target.name;
    let value = e.target.value;
    if (name === "linkingType") {
      objectData["linkPageId"] = "";
      objectData["linkAppId"] = "";
    }
    objectData[name] = value;
    setObjectChange(objectData);
    onChange(objectData);
  };

  // fetch app details
  const getAllInstalledApps = () => {
    fetchInstallAppData()
      .then((response) => {
        if (response?.success) {
          setAppList(
            response.data?.map((data) => {
              return {
                label: data?.app_name,
                value: data?._id?.$oid,
              };
            })
          );
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  };

  // fetch flow Details
  const getFlowById = (appid) => {
    setPageList([]);
    setFlowLoader(true);
    getFlowsByAppId(appid)
      .then((response) => {
        if (response.data.length) {
          setPageList(
            response.data?.map((data) => {
              return {
                label: data?.function_name,
                value: data?._id?.$oid,
              };
            })
          );
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => {
        setFlowLoader(false);
      });
  };

  return (
    <div className="border rounded m-2">
      <h5 className="m-2 border-bottom">Linking</h5>

      <div className="form-group row">
        <label className="control-label col-md-3 col-sm-3">
          Link Reference
        </label>
        <div className="col-md-9 col-sm-9">
          <input
            type="text"
            name="linkingReference"
            className="form-control"
            value={objectChange?.linkingReference}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="control-label col-md-3 col-sm-3 ">Linking Type</label>
        <div className="col-md-9 col-sm-9">
          <Form.Select
            className="form-control"
            name="linkingType"
            value={objectChange?.linkingType}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            {linkingTypeListOption}
          </Form.Select>
        </div>
      </div>
      <div className="form-group row">
        <label className="control-label col-md-3 col-sm-3 ">App</label>
        <div className="col-md-9 col-sm-9">
          <Form.Select
            className="form-control"
            name="linkAppId"
            value={objectChange?.linkAppId || ""}
            onChange={handleChange}
          >
            <option defaultValue="" disabled value={""}>
              --Select--
            </option>
            {appList.map((app, index) => (
              <option key={index} value={app.value}>
                {app.label}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
      <div className="form-group row">
        <label className="control-label col-md-3 col-sm-3 ">Page</label>
        <div className="col-md-9 col-sm-9">
          <Form.Select
            className="form-control"
            name="linkPageId"
            value={objectChange?.linkPageId}
            onChange={handleChange}
          >
            <option value="">{flowLoader ? "Loading.." : "--Select--"}</option>
            {pageList.map((app, index) => (
              <option key={index} value={app.value}>
                {app.label}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
    </div>
  );
};

export default LinkingComponent;
