import React, { useEffect, useRef, useState, useContext } from "react";
import "./ReactUI.scss";
import { useHistory, useParams } from "react-router";
import { uploadAppSession } from "../../services/API";
import { getDynamicFlowData, getDynamicSingleFlowData } from "../../services/appService";
import { toast } from "react-toastify";
import Loader from "../dataset/Loader/Loader";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterFormContext } from "./contexts/FilterFormContext";
import DisplayComponent from "./components/DisplayComponent";
import { componentType } from "../common/model/Model";
import FormComponent from "./components/FormComponent";
import { getRecordDataById } from "../../services/tableConfigService";
import AppCategories from "../../components/react_engine/store/categories/Categories";
import { RoutesContext } from "../../RoutesContext";

const ReactUI = () => {
  const history = useHistory();
  const params = useParams();
  const [filterFormData, setFilterFormData] = useState({});
  const [appSessionId, setAppSessionId] = useState("");
  const [flowErrorState, setFlowErrorState] = useState(false);
  const [flowDataLoading, setFlowDataLoading] = useState(true);
  const [singleFlowDataLoading, setSingleFlowDataLoading] = useState(false);
  const [flowObjectList, setFlowObjectList] = useState([]);
  const [currentActiveFlowObject, setCurrentActiveFlowObject] = useState([]);
  const [appId, setAppId] = useState("");
  const { setCacheData } = useContext(RoutesContext);
  const userInfo = JSON.parse(localStorage.getItem("user-info"));

  const getRedirectToUrl = (firstFlow) => {
    if (firstFlow.data.page.page_type === componentType.storeComponent) {
      const store_id =
        firstFlow.data.page?.storeComponentData?.[0]?.value || "";
      return `/app_ui/${params.id}/${firstFlow?.data.flow_id}/${store_id}`;
    } else {
      return `/app_ui/${params.id}/${firstFlow?.data.flow_id}`;
    }
  };

  useEffect(() => {
    if (!params?.flowId && flowObjectList?.length) {
      let [firstFlow, ..._] = flowObjectList;
      history.replace(getRedirectToUrl(firstFlow));
    }else{
      if(params.flowId && params.flowId.length>0){
        if(!currentActiveFlowObject || currentActiveFlowObject?.data?.flow_id !== params?.flowId){
          setCurrentActiveFlowObject(flowObjectList?.find(
            (flowObject) => flowObject?.data.flow_id === params?.flowId
          ));
        }
      }
    }

    return () => {
      setCacheData({});
    };
  }, [flowObjectList]);

  useEffect(() => {
    if (appSessionId) {
      getAppData();
      getFlowData(appSessionId);
    }
  }, [appSessionId]);

  useEffect(() => {
    if (params?.id) {
      uploadAppSessionId();
      setAppId(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (params.flowId && params.flowId.length > 0) {
      if(currentActiveFlowObject && currentActiveFlowObject?.data?.flow_id === params?.flowId){
        return;
      }
      getSingleFlowData(appSessionId);
    }
  }, [params.flowId]);

  const uploadAppSessionId = () => {
    let body = {
      app_id: params?.id,
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
    };
    setFlowDataLoading(true);
    uploadAppSession(body)
      .then((response) => {
        setAppSessionId(response?.data);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
        setFlowDataLoading(false);
      });
  };

  const findAssignedPagePrivileges = (appId) => {
    let assignedPrivileges = [];
    if (userInfo?.privileges) {
      const { app_previleges } = userInfo?.privileges;
      if(!app_previleges){
        return assignedPrivileges;
      }
      for(let idx=0;idx<app_previleges.length;idx++){
        const app_privilege = app_previleges[idx];
        if(app_privilege[appId]){
          //app found
          //iterate over all pages
          for(let pageNo=0;pageNo<app_privilege[appId].pageList.length;pageNo++){
            const page=app_privilege[appId].pageList[pageNo];
            if(!page.componentList){
              assignedPrivileges.push(page.page_id);
              continue;
            }
            if(page.componentList.length === 0){
              assignedPrivileges.push(page.page_id);
            }else{
              if(page.componentList.some((obj) => obj?.componentButtonList?.length > 0)){
                assignedPrivileges.push(page.page_id);
              }
            }
          }
          break;
        }
      }
    }
    return assignedPrivileges;
  };

  const getFlowData = (appSessionId) => {
    setFlowDataLoading(true);
    getDynamicFlowData(params.id, appSessionId)
      .then((response) => {
        let permittedAppFlows = [];
        if(userInfo?.role_name === "Admin"){
          setFlowObjectList(response?.data);
          return;
        }
        //fetch permissions object
        const permittedPages = findAssignedPagePrivileges(params.id);
        for(let idx=0;idx<response?.data?.length;idx++){
          let appFlow = response?.data[idx];
          if(appFlow && appFlow.data?.page?.page_type === "display_component"){
            if(permittedPages.includes(appFlow.data?.page?.page_id)){
              permittedAppFlows.push(appFlow);
            }
          }else{
            permittedAppFlows.push(appFlow);
          }
        }

        setFlowObjectList(permittedAppFlows);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
        setFlowErrorState(true);
      })
      .finally(() => {
        setFlowDataLoading(false);
      });
  };

  const getSingleFlowData = (appSessionId) => {
    if(params.id && params.id.length > 0 && params.flowId && params.flowId.length>0 && appSessionId && appSessionId.length > 0){
      if(currentActiveFlowObject && currentActiveFlowObject?.data?.flow_id === params?.flowId){
        return;
      }
      setSingleFlowDataLoading(true);
      getDynamicSingleFlowData(params.id, params.flowId , appSessionId)
      .then((response) => {
        setCurrentActiveFlowObject(response?.data[0]);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
        setFlowErrorState(true);
      })
      .finally(() => {
        setSingleFlowDataLoading(false);
      });
    }
  };

  const getAppData = () => {
    let objData = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      dataset_name: "sys_installed_apps",
      id: params.id,
    };
    getRecordDataById(objData)
      .then((response) => {
        if (response.success === true && response.data !== null) {
          let subscriptionObj = response.data?.app_subscription;
          if (
            !subscriptionObj ||
            subscriptionObj.subscription_status === "active"
          ) {
          } else {
            toast.warn("Kindly subscribe the app to continue!");
            this.routeToDashboard();
          }
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  };

  const routeToDashboard = () => {
    history.push(`/app-dashboard`);
  };

  const checkBackTransition = () => {
    let page = currentActiveFlowObject.data.page;
    let transition = page?.pageTransition?.backButtonClick;
    if (transition?.transitionType === "appDashboard") {
      history.push("/app-dashboard");
    } else {
      history.goBack();
    }
  };

  return (
    <div className="react_ui">
      {flowDataLoading ? (
        <Loader />
      ) : flowObjectList.length > 0 ? (
        <div>
          <div className="d-flex col-12 justify-content-between align-items-center p-0">
            <FlowHeaderUiList
              flowList={flowObjectList}
              getRedirectToUrl={getRedirectToUrl}
            />
            <div>
              {!JSON.parse(localStorage.getItem("isRedirecting")) && (
                <button
                  className="back-btn text-nowrap"
                  onClick={checkBackTransition}
                >
                  <FontAwesomeIcon icon="fa fa-arrow-left" />
                  <span className="ms-2">Back</span>
                </button>
              )}
            </div>
          </div>
          <div className="clearfix" />
          
          {singleFlowDataLoading ? (
            <Loader />
          ) : (

            <FilterFormContext.Provider
              value={{
                filterFormData: filterFormData,
                setFilterFormData: setFilterFormData,
                appSessionId,
              }}
            >
              {currentActiveFlowObject?.data?.page?.page_type ===
                componentType.formComponent && (
                <div className="mt-2">
                  <FormComponent
                    key={params.flowId}
                    configObj={currentActiveFlowObject}
                    appSessionId={appSessionId}
                    isEdit={false}
                  />
                </div>
              )}
              {currentActiveFlowObject?.data?.page?.page_type ===
                componentType.displayComponent && (
                <div className="row pt-2 mx-1">
                  <DisplayComponent
                    appSessionId={appSessionId}
                    displayComponentData={currentActiveFlowObject}
                  />
                </div>
              )}
              {currentActiveFlowObject?.data?.page?.page_type ===
                componentType.storeComponent && (
                <div className="mt-2">
                  <AppCategories
                    appSessionId={appSessionId}
                    storeComponentData={currentActiveFlowObject}
                  />
                </div>
              )}
            </FilterFormContext.Provider>
          )}

        </div>
      ) : (
        <div className="p-3 ">
          {flowErrorState && <h4>Internal Issue... </h4>}
          {flowObjectList.length === 0 && !flowErrorState && (
            <h4>No Flow Available</h4>
          )}
          <button
            className="btn btn-danger btn-sm m-0 me-2"
            onClick={routeToDashboard}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default ReactUI;

export const FlowHeaderUiList = ({ flowList, getRedirectToUrl }) => {
  const params = useParams();
  return (
    <ul className="nav navbar-right panel_toolbox">
      {flowList
        ?.filter((header) => header.data?.hideFlow === false)
        .map((header, index) => {
          return (
            <NavLink
              key={index}
              activeClassName="tab_active"
              to={getRedirectToUrl(header)}
              className="collapse-link"
            >
              {header.data.function_name || ""}
            </NavLink>
          );
        })}
    </ul>
  );
};
