import React, { useEffect, useMemo, useState } from "react";
import DisplayBuilderKanbanLanes from "./DisplayBuilderKanbanLanes";
import DisplayBuilderKanbanCardKeyMapping from "./DisplayBuilderKanbanCardKeyMapping";
import Select from "react-select";
import { formPagesList } from "../../../../../services/appService";
import { getRecordDataById } from "../../../../../services/tableConfigService";
import { toast } from "react-toastify";

const DisplayBuilderKanban = (props) => {
  const { logicList } = props;
  const [displayChanges, setDisplayChanges] = useState({});
  const [formOptions, setFormOptions] = useState([]);
  
  useMemo(() => {
    getAllFormList();
    setDisplayChanges(props?.displayKanbanObj);
  }, []);

  useEffect(() => {
    props?.onChange(displayChanges);
  }, [displayChanges]);

  useEffect(() => {
    getDisplayFields();
  }, [displayChanges?.displayFormId]);

  function getDisplayFields() {
    const payloadForFetchingPageFields = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      dataset_name: "fc_app_pages",
      id: displayChanges?.displayFormId,
    };
    getRecordDataById(payloadForFetchingPageFields).then((res) => {
      const pageFieldsName = res.data.page_fields.map((field) => {
        return { label: field.display_name, value: field.name, is_hidden: field.is_hidden, data_type: field.data_type, show_key_to_table: field.show_key_to_table, status: field.status, tags: field.tags };
      });
      const data = { ...displayChanges };
      let name = "displayFields";
      let value = pageFieldsName;
      data[name] = value;
      setDisplayChanges(data);
    });
  }

  function getAllFormList() {
    let bodyObj = {
      FilterCriteria: [
        {
          filter_type: "text",
          type: "equal",
          filter: "form_component",
          filter_field: "page_type",
        },
      ],
      export: true,
    };
    formPagesList(bodyObj)
      .then((response) => {
        if (response?.success) {
          const formList = response?.data.map((form) => {
            return {
              label: form.function_name,
              value: form._id.$oid,
            };
          });
          setFormOptions(formList);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  }

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const kanbanLaneChange = (lane) => {
    if (lane?.length) {
      const data = { ...displayChanges };
      data["lanes"] = lane;
      setDisplayChanges(data);
    }
  };

  const searchableValueChange = (event, name) => {
    const data = { ...displayChanges };
    if (name === "form") {
      data["formId"] = event?.value || "";
      data["formName"] = event?.label || "";
      setDisplayChanges(data);
    }else if (name === "displayForm") {
      data["displayFormId"] = event?.value || "";
      data["displayFormName"] = event?.label || "";
      setDisplayChanges(data);
    }
  };

  const searchableValueChange2 = (event, key) => {
    const data = { ...displayChanges };
    let value = event?.label;
    data[key] = value || "";
    setDisplayChanges(data);
  };

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group ">
        <label className="form-label p-0 mt-2">Component Logic</label>
        <div>
          <Select
            classNamePrefix={"react-select"}
            placeholder="Select.."
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === displayChanges?.componentLogic
            )}
            onChange={(e) => { searchableValueChange2(e, "componentLogic"); }}
            isClearable
          />
          <label className="text-sm float-left">(Logic to fetch data for the cards to display)</label>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          ></input>
          <label className="text-sm float-left">(response variable name from your data fetch logic)</label>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Board Name</label>
        <div>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter Board Name"
            name="boardName"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.boardName}
          ></input>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Board Background Color</label>
        <div className="d-flex align-items-center">
          <input
            type="color"
            className="form-control form-control-color form-control-sm me-2"
            placeholder="Choose Color"
            name="boardBgColor"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.boardBgColor || "#ffffff"}
          />
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter color code"
            name="boardBgColor"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.boardBgColor || "#ffffff"}
          />
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Select Form</label>
        <div>
          <Select
            placeholder="Select..."
            options={formOptions}
            value={
              displayChanges?.formId
                ? {
                    label: displayChanges?.formName,
                    value: displayChanges?.formId,
                  }
                : ""
            }
            name="form"
            onChange={(e) => {
              searchableValueChange(e, "form");
            }}
            menuPosition="fixed"
            classNames={{
              control: (state) => 'searchableDropdownControl',
              menuPortal: () => 'searchableDropdownMenuPortal',
              singleValue: () => 'searchableDropdownValue',
              multiValue: () => 'searchableDropdownValue',
              menuList: () => 'searchableDropdownMenuList'
            }}
            isClearable
          />
          <label className="text-sm float-left">(Form for Add/Edit and Clone)</label>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Select Display Form</label>
        <div>
          <Select
            placeholder="Select..."
            options={formOptions}
            value={
              displayChanges?.displayFormId
                ? {
                    label: displayChanges?.displayFormName,
                    value: displayChanges?.displayFormId,
                  }
                : ""
            }
            name="displayForm"
            onChange={(e) => {
              searchableValueChange(e, "displayForm");
            }}
            menuPosition="fixed"
            classNames={{
              control: (state) => 'searchableDropdownControl',
              menuPortal: () => 'searchableDropdownMenuPortal',
              singleValue: () => 'searchableDropdownValue',
              multiValue: () => 'searchableDropdownValue',
              menuList: () => 'searchableDropdownMenuList'
            }}
            isClearable
          />
          <label className="text-sm float-left">(the structure of this form will be used to display more information about the card)</label>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Status Field Name in data
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.statusFieldName?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter status field name value"
            name="statusFieldName"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.statusFieldName || "status"}
          ></input>
        </div>
      </div>
      <DisplayBuilderKanbanCardKeyMapping
        displayChanges={displayChanges}
        setDisplayChanges={setDisplayChanges}
      />
      <div className="col-12">
        <div className="rounded p-2 border">
          <DisplayBuilderKanbanLanes
            lanes={displayChanges?.lanes}
            onChange={(lane) => kanbanLaneChange(lane)}
          />
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderKanban;
