import React, { useRef, useState, useEffect } from "react";
import { Col, Modal, Button, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import propTypes from "prop-types";
import * as _ from "lodash";
import * as API from "../../../../../services/API";
import "../../../../../styles/visual_programming/NodeConfig.css";
import DatasetField from "../../../../DatasetField/DatasetField";
import { NodeContext } from "../../../../../contexts/NodeContext";
import AdvanceParameter from "./NodeConfiguration/advanceQueryBuilder/AdvanceParameter";
import QueryBuilderComponent from "../../../../dataset_editor/dataset_structure/query_builder/QueryBuilderComponent";
import Editor from "@monaco-editor/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

export default class NodeConfig extends React.Component {
  static contextType = NodeContext;
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      data: {},
      flowData: {},
      flowNodes: [],
      logic_id: props.logic_id,
    };
    this.updateData = this.updateData.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getFlowNodes() {
    if (!this.props.node) return;

    API.getNode(this.props.node.options.id, this.state.logic_id)
      .then((node) => this.setState({ flowNodes: node.flow_variables }))
      .catch((err) => { });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) this.getFlowNodes();
  }

  componentDidMount() {
    const description = this.props?.node?.config?.description ?? "";
    const localKey =
      this.props?.node?.options?.option_replace &&
      Object.keys(this.props?.node?.options?.option_replace)[0];
    const localValue =
      this.props.node?.options?.option_replace &&
      this.props.node?.options?.option_replace[
      Object.keys(this.props.node?.options?.option_replace)[0]
      ];
    this.updateData(localKey, localValue);
    this.updateData("description", description);
  }

  // callback to update form data in state;
  // resulting state will be sent to node config callback
  updateData(key, value, flow = false) {
    if (flow) {
      this.setState((prevState) => ({
        ...prevState,
        flowData: {
          ...prevState.flowData,
          [key]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        // flowData: this.props?.node?.options?.option_replace,
      }));
    }
  }

  // confirm, fire delete callback, close modal
  handleDelete() {
    if (window.confirm("Are you sure you want to delete this node?")) {
      this.props.onDelete();
      this.props.toggleShow();
    }
  }

  // collect config data, fire submit callback, close modal
  handleSubmit(e) {
    if (!this.state.logic_id) {
      return;
    }
    e.preventDefault();
    // remove items from flow vars if null
    const flowData = { ...this.state.flowData };
    for (let key in flowData) {
      if (flowData[key] === null) delete flowData[key];
    }
    this.props.onSubmit(this.state.data, flowData, this.state.logic_id);
    this.props.toggleShow();
  }

  render() {
    if (!this.props.node) return null;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.toggleShow}
        centered
        dialogClassName="NodeConfig"
        onWheel={(e) => e.stopPropagation()}
      >
        <Form name="nodeConfigurationForm" onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>
              <b>{this.props.node.options.name}</b> Configuration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {_.map(this.props.node.configParams, (info, key) => (
              <OptionInput
                key={key}
                {...info}
                keyName={key}
                onChange={this.updateData}
                node={this.props.node}
                value={this.props.node.config[key]}
                flowValue={
                  this.props.node.options.option_replace
                    ? this.props.node.options.option_replace[key]
                    : null
                }
                flowNodes={this.state.flowNodes}
                disableFunc={(v) => this.setState({ disabled: v })}
                logic_id={this.state.logic_id}
                onSubmit={this.handleSubmit}
              />
            ))}
            <Form.Group>
              <Form.Label>Node Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="2"
                name="description"
                onChange={(e) => this.updateData("description", e.target.value)}
                defaultValue={this.props.node.config.description}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              disabled={this.props.disabled}
              type="submit"
            >
              Save
            </Button>
            <Button
              variant="secondary"
              type="button"
              onClick={this.props.toggleShow}
            >
              Cancel
            </Button>
            <Button variant="danger" type="button" onClick={this.handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

NodeConfig.propTypes = {
  show: propTypes.bool,
  toggleShow: propTypes.func,
  onDelete: propTypes.func,
  onSubmit: propTypes.func,
};

/**
 *  Wrapper component to render form groups in the node config form.
 */
function OptionInput(props) {
  const [isFlow, setIsFlow] = useState(props.flowValue ? true : false);
  const nodeOptions = props.node.options;
  let disableInput =
    nodeOptions.node_type === "flow_control" &&
      (nodeOptions.node_key === "StringNode" ||
        nodeOptions.node_key === "IntegerNode")
      ? false
      : true;

  const handleFlowCheck = (bool) => {
    // if un-checking, fire callback with null so no stale value is in `option_replace`
    if (!bool) props.onChange(props.keyName, null, true);
    setIsFlow(bool);
  };

  // fire callback to update `option_replace` with flow node info
  const handleFlowVariable = (value) => {
    props.onChange(props.keyName, value, true);
  };

  let inputComp;
  if (props.type === "file") {
    inputComp = <FileUploadInput {...props} />;
  } else if (props.type === "string") {
    inputComp = <SimpleInput {...props} type="text" />;
  } else if (props.type === "text") {
    inputComp = <SimpleInput {...props} type="textarea" />;
  } else if (props.type === "code") {
    inputComp = <CodeEditorWindow {...props} type="code" />;
  } else if (props.type === "int") {
    inputComp = <SimpleInput {...props} type="number" />;
  } else if (props.type === "boolean") {
    inputComp = <BooleanInput {...props} />;
  } else if (props.type === "select") {
    inputComp = <SelectInput {...props} />;
  } else if (props.type === "dataset_field") {
    inputComp = <DatasetField {...props} />;
  } else if (props.type === "query_builder") {
    inputComp = <QueryBuilderComponent {...props} />;
  } else if (props.type === "advance_select") {
    inputComp = <AdvanceParameter {...props} />;
  } else {
    return <></>;
  }

  const hideFlow =
    props.node.options.is_global ||
    props.type === "file" ||
    props.flowNodes.length === 0;
  return (
    <Form.Group>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      {props.docstring && <Form.Label>{props.docstring}</Form.Label>}
      <Row className="mb-2">
        <Col xs={hideFlow ? 12 : 6}>{inputComp}</Col>
        <Col xs={hideFlow ? 12 : 6}>
          {hideFlow ? null : (
            <FlowVariableOverride
              keyName={props.keyName}
              flowValue={props.flowValue || {}}
              flowNodes={props.flowNodes || []}
              checked={isFlow}
              onFlowCheck={handleFlowCheck}
              onChange={handleFlowVariable}
            />
          )}
        </Col>
      </Row>
    </Form.Group>
  );
}

function CodeEditorWindow(props) {
  const [value, setValue] = useState(props.value || "#write your python code below");
  const [showEditor, setShowEditor] = useState(false);
  const [output, setOutput] = useState("");

  const executeCode = () => {
    setOutput("Executing your code...");
    API.executeCode(value, props.logic_id)
    .then((response) => {
      if (response.success === true) {
        setOutput(response.data);
      }
    })
    .catch((err) => { 
      setOutput("Error executing the code. "+ err.message || err.__str__);
    });
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const { keyName, onChange, type } = props;

  // Whenever value changes, fire callback to update config form
  useEffect(() => {
    const formValue = type === "number" ? Number(value) : value;
    onChange(keyName, formValue);
  }, [value, keyName, onChange, type]);

  return (
    <>
      <Button variant="primary" onClick={() => setShowEditor(true)}>
        Open Code Editor
      </Button>

      <Modal show={showEditor} onHide={() => setShowEditor(false)} size="xl" fullscreen>
        <Modal.Header  className="position-relative p-1 px-2">
          <Modal.Title className="h6">Code Editor</Modal.Title>
          <Button
            variant="success"
            onClick={props.onSubmit}
            className="add-btn node-config-save-button"
          >
            <FontAwesomeIcon icon="fa-solid fa-floppy-disk" fontSize="20"/>&nbsp;&nbsp;Save &amp; Close
          </Button>
          <Button 
            variant="light" 
            className="close-btn node-config-close-button"
            onClick={() => setShowEditor(false)}
          >
            <FontAwesomeIcon icon="fa-solid fa-times" fontSize="18"/>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="8">
              <Editor
                height="85vh"
                language="python"
                theme="vs-dark"
                value={value}
                onChange={handleChange}
                options={{
                  automaticLayout: true,
                  wordWrap: "on",
                  minimap: { enabled: false },
                  fontSize: 14,
                  maxTokenizationLineLength: 999999999,
                  readOnly: false,
                }}
              />
            </Col>
            <Col xs="4">
              <Button className="btn btn-sm btn-primary" onClick={executeCode}>
                &nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-circle-play" />&nbsp;Run Code&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
              <pre style={{ background: "#1e1e1e", color: "#fff", padding: "10px", height: "calc(85vh - 34px)" }}>
                {output}
              </pre>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

function SimpleInput(props) {
  const [value, setValue] = useState(props.value);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const { keyName, onChange, type } = props;
  // whenever value changes, fire callback to update config form
  useEffect(() => {
    const formValue = type === "number" ? Number(value) : value;
    onChange(keyName, formValue);
  }, [value, keyName, onChange, type]);

  const extraProps =
    props.type === "textarea"
      ? { as: "textarea", rows: props.rows || 7 }
      : { type: props.type };
  return (
    <Form.Control
      {...extraProps}
      name={props.keyName}
      disabled={props.disabled}
      defaultValue={props.value}
      onChange={handleChange}
    />
  );
}

/**
 *  Component representing a file parameter.
 *  Uploads selected file to server upon selection, and passes
 *  the filename from the server response to the form callback.
 */
function FileUploadInput(props) {
  const input = useRef(null);
  const [fileName, setFileName] = useState(props.value || "");
  const [status, setStatus] = useState(props.value ? "ready" : "unconfigured");

  const { keyName, onChange } = props;
  // fire callback on mount to update node config state
  useEffect(() => {
    onChange(keyName, fileName);
  }, [fileName, keyName, onChange]);

  const uploadFile = async (file) => {
    props.disableFunc(true);
    setStatus("loading");
    const fd = new FormData();
    fd.append("file", file);
    fd.append("nodeId", props.node.options.id);
    API.uploadDataFile(fd, this.state.logic_id)
      .then((resp) => {
        setFileName(resp.filename);
        setStatus("ready");
        props.disableFunc(false);
        setStatus("ready");
      })
      .catch(() => {
        setStatus("failed");
      });
    input.current.value = null;
  };
  const onFileSelect = (e) => {
    e.preventDefault();
    if (!input.current.files) return;
    uploadFile(input.current.files[0]);
  };

  if (status === "loading") return <div>Uploading file...</div>;
  const btnText = status === "ready" ? "Choose Different File/s" : "Choose File/s";
  let content;
  if (status === "ready") {
    const rxp = new RegExp(props.node.options.id + "-");
    content = (
      <div>
        <b style={{ color: "green" }}>File loaded:</b>&nbsp;
        {fileName.replace(rxp, "")}
      </div>
    );
  } else if (status === "failed") {
    content = <div>Upload failed. Try a new file.</div>;
  }
  return (
    <>
      <input
        type="file"
        ref={input}
        onChange={onFileSelect}
        style={{ display: "none" }}
      />
      <input type="hidden" name={props.name} value={fileName} />
      <Button size="sm" onClick={() => input.current.click()}>
        {btnText}
      </Button>
      {content}
    </>
  );
}

function BooleanInput(props) {
  const [value, setValue] = useState(props.value);
  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  const { keyName, onChange } = props;
  // whenever value changes, fire callback to update config form
  useEffect(() => {
    onChange(keyName, value);
  }, [value, keyName, onChange]);

  return (
    <Form.Check
      type="checkbox"
      name={props.keyName}
      disabled={props.disabled}
      checked={value}
      onChange={handleChange}
    />
  );
}

function FlowVariableOverride(props) {
  const handleSelect = (event) => {
    const uuid = event.target.value;
    const flow = props.flowNodes.find((d) => d.node_id === uuid);
    const obj = {
      node_id: uuid,
      is_global: flow?.is_global,
    };
    props.onChange(obj);
  };
  const handleCheck = (event) => {
    props.onFlowCheck(event.target.checked);
  };

  useEffect(() => {
    const uuid = props?.flowValue?.node_id && props?.flowValue?.node_id;
    const flow = props.flowNodes.find((d) => d.node_id === uuid);
    const obj = {
      node_id: uuid,
      is_global: flow?.is_global,
    };
    props?.checked && props.onChange(obj);
  }, []);

  return (
    <Row>
      <Col xs="4">
        <Form.Group className="text-nowrap">
          <Form.Check
            type="checkbox"
            inline
            label="Use Variable"
            checked={props.checked}
            onChange={handleCheck}
          />
        </Form.Group>
      </Col>
      <Col xs="8">
        <Form.Group>
          {props.checked ? (
            <Form.Select
              size="sm"
              name={props.keyName}
              onChange={handleSelect}
              defaultValue={props.flowValue.node_id}
            >
              <option>--Select--</option>
              {props.flowNodes.map((fv) => (
                <option key={fv.node_id} value={fv.node_id}>
                  {fv.options ? fv.options.var_name : fv.option_values.var_name}
                </option>
              ))}
            </Form.Select>
          ) : null}
        </Form.Group>
      </Col>
    </Row>
  );
}

function SelectInput(props) {
  let uniqueOptions = new Set(props?.options);
  uniqueOptions = [...uniqueOptions];
  const [value, setValue] = useState(props.value || "");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const { keyName, onChange } = props;
  // whenever value changes, fire callback to update config form
  useEffect(() => {
    onChange(keyName, value);
  }, [value, keyName, onChange]);

  return (
    <Form.Select name={props.keyName} value={value} onChange={handleChange}>
      <option value={""} disabled>
        Please select
      </option>
      {uniqueOptions.map((opt) => (
        <option key={opt} value={opt}>
          {opt}
        </option>
      ))}
    </Form.Select>
  );
}
