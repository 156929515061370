import React, { useContext, useEffect, useState } from "react";
import { getRecordByIdForStepper } from "../../../services/appService";
import CustomForm from "./CustomForm";
import { FilterFormContext } from "../contexts/FilterFormContext";
import { useParams } from "react-router";
import { RoutesContext } from "../../../RoutesContext";
import { toast } from "react-toastify";
import { componentType } from "../../common/model/Model";
import { reactAppFormExecutionVP } from "../../../services/API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InlineForm = ({ appSessionId, objData }) => {
  const { filterFormData, setFilterFormData } = useContext(FilterFormContext);
  const { flowId } = useParams();
  const { cachedData } = useContext(RoutesContext);
  const [configObj, setConfigObj] = useState();
  const [columnWidthTable, setColumnWidthTable] = useState();
  const [submitResponseLoading, setSubmitResponseLoading] = useState(false);

  useEffect(() => {
    const component = objData?.page?.display_fields.find(
      (field) => field?.componentType === "Inline Form"
    );

    if (component) {
      const objectId = component?.inlineForm;
      setColumnWidthTable(component?.columenWidth);
      if (objectId) {
        getRecordByIdForStepper({ object_id: objectId }).then((response) => {
          setConfigObj(response);
        });
      }
    }

    if (cachedData?.[flowId] && component?.isCacheEnabled) {
      setFilterFormData({ ...cachedData[flowId] });
    } else {
      setFilterFormData();
    }
  }, [flowId, objData]);

  const addSubmitForm = async (isDraft) => {
    let objectBody = {
      app_session_id: appSessionId,
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      data: {
        page_fields: [
          configObj?.data?.page_fields.map((field) => ({
            is_required: field.is_required,
            display_name: field?.display_name,
            name: field?.name,
          })),
        ],
        formData: [{ ...filterFormData, srno: 0, isDraft }],
        logic_to_send: [configObj?.data?.logic_name_value],
      },
      page_type: componentType.formComponent,
      store_output_key: configObj?.data?.form_output_key,
    };
  
    try {
      setSubmitResponseLoading(true);
      const response = await reactAppFormExecutionVP(objectBody);
      if (isDraft === "Yes") {
        toast.warning("Saved as draft");
      } else {
        toast.success(response?.message);
        setFilterFormData({});
        // onFormSubmit?.();
      }
      return response;
    } catch (error) {
      if (isDraft !== "Yes") {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      }
      throw error;
    } finally {
      setSubmitResponseLoading(false);
    }
  };

  return (
    <div className={`col-md-${columnWidthTable ? columnWidthTable : 12}`}>
      <div className="main-content h-100">
      <CustomForm
          formConfigData={configObj?.data}
          pageFields={configObj?.data?.page_fields}
          formGroupData={configObj?.data?.formGroupData || []}
          appSessionId={appSessionId}
          formData={filterFormData}
          setFormData={(data) => setFilterFormData({ ...data })}
          onSubmitClick={addSubmitForm}
          submitButtonText={"Submit"}
          isResponseLoading={submitResponseLoading}
          submitButtonIcon={<FontAwesomeIcon icon="fa fa-check-double" />}
          formTitle={configObj?.data?.form_disclaimer}
        />
      </div>
    </div>
  );
};

export default InlineForm;
