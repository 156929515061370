import { DefaultLinkFactory } from '@projectstorm/react-diagrams';
import VPLinkModel from './VPLinkModel';

export default class VPLinkFactory extends DefaultLinkFactory {

    constructor(logic_id) {
        super('custom-edge');
        this.logic_id = logic_id;
    }

    generateModel() {
        return new VPLinkModel(this.logic_id);
    }
}
