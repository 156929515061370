import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as API from "../../../services/API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HierarchyContext } from "./HierarchyTableContext";
import { inputTypeKey } from "../../common/model/Model";

const HierarchyInLineEdit = ({
  rowStateData,
  setRowStateData,
  field,
  rowEditState,
  initialStateData,
}) => {
  const [editState, setEditState] = useState(false);
  const [changeValue, setChangeValue] = useState(rowStateData?.[field?.name]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const { appSessionId, reloadData, displayConfig } =
    useContext(HierarchyContext);

  useEffect(() => {
    setChangeValue(rowStateData?.[field?.name]);
  }, [rowStateData?.[field?.name]]);

  useEffect(() => {
    if (editState) {
      setChangeValue(rowStateData?.[field?.name]);
      inputRef?.current?.focus();
      inputRef?.current?.select();
    }
  }, [editState]);

  useEffect(() => {
    if (rowEditState) {
      setEditState(false);
    }
  }, [rowEditState]);

  let dataType;
  if (field.data_type === "number") {
    dataType = "number";
  } else if (field.data_type === "string") {
    dataType = "text";
  } else if (field.data_type === "dateTime") {
    dataType = "date";
  } else if (field.data_type === "time") {
    dataType = "time";
  }

  const onSave = (value, logic, reference, isClear) => {
    let obj = { ...rowStateData, ...{ [field.name]: value } };
    submitData(obj, logic, reference, isClear);
  };

  const submitData = (updatedData, logic, reference, isClear) => {
    let data = {
      app_session_id: appSessionId,
      logic_name: logic,
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      reference_name: reference,
      data: [updatedData],
    };

    if (reference && logic) {
      // Api Calling
      setIsLoading(true);
      API.executeAppForActions(data)
        .then((response) => {
          if (response) {
            toast.success(response.message);
            setRowStateData(updatedData);
            setEditState(false);
            reloadData();
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || err.response?.message || err.message);
          if (initialStateData?.[field.name] === undefined) {
            delete updatedData[field.name];
          } else {
            updatedData[field.name] = initialStateData?.[field.name];
          }
          setChangeValue(initialStateData?.[field?.name]);
          setRowStateData(updatedData);
          setEditState(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (isClear === "clear") {
        if (initialStateData?.[field.name] === undefined) {
          delete updatedData[field.name];
        } else {
          updatedData[field.name] = initialStateData?.[field.name];
        }
      }
      setRowStateData(updatedData);
    }
  };

  let isParentEditable = initialStateData?.sub_hierarchy_childs?.length === 0;

  if (displayConfig?.parentInlineEdit === "yes") {
    isParentEditable = true;
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (changeValue !== rowStateData?.[field?.name]) {
          onSave(
            changeValue,
            field?.inline_edit_logic,
            field?.inline_edit_reference
          );
        }
      }}
    >
      <div className="d-help inLineEdit">
        {dataType ? (
          <>
            <span
              className="w-100 text-end"
              title={field?.inline_edit_mode === "yes" ? "Click To Change" : ""}
              onClick={() => {
                if (field?.inline_edit_mode === "yes") {
                  setEditState(true);
                }
              }}
            >
              {editState ? (
                <input
                  required
                  ref={inputRef}
                  type={dataType}
                  className="w-100 form-control form-control-sm inLineMinWidth"
                  value={changeValue}
                  onChange={(e) => {
                    let value;
                    if (dataType === "number" && e.target.value) {
                      value = Number(e.target.value);
                    } else {
                      value = e.target.value;
                    }
                    setChangeValue(value);
                  }}
                  onBlur={() => {
                    if (changeValue === rowStateData?.[field?.name]) {
                      setEditState(false);
                    }
                  }}
                />
              ) : rowStateData?.[field?.name] &&
                (!isNaN(rowStateData?.[field?.name]) ||
                  !rowStateData?.[field?.name]?.includes("None")) ? (
                <span>
                  {field?.data_type === inputTypeKey.numberTextBox
                    ? field?.displayCurrencyFormat
                      ? rowStateData?.[field?.name]?.toLocaleString(
                          field?.displayCurrencyFormat
                        )
                      : rowStateData?.[field?.name]
                    : rowStateData?.[field?.name]}
                </span>
              ) : (
                "-"
              )}
            </span>
            <span className="d-flex">
              {field?.inline_edit_mode === "yes" &&
              isParentEditable &&
              !displayConfig?.hideActions ? (
                <>
                  {changeValue !== rowStateData?.[field?.name] && !isLoading ? (
                    <button
                      type="submit"
                      className="editIcon ms-2"
                      onClick={() => {
                        if (changeValue) {
                          onSave(
                            changeValue,
                            field?.inline_edit_logic,
                            field?.inline_edit_reference
                          );
                        }
                      }}
                    >
                      <FontAwesomeIcon icon="fa fa-check" aria-hidden="true" />
                    </button>
                  ) : null}

                  {changeValue !== initialStateData?.[field?.name] &&
                  !isLoading ? (
                    <span className="editIcon ms-2">
                      <FontAwesomeIcon
                        icon="fa fa-times"
                        aria-hidden="true"
                        onClick={() => {
                          setChangeValue(initialStateData?.[field?.name]);
                          setEditState(false);
                        }}
                      />
                    </span>
                  ) : null}

                  <span className={isLoading ? "" : "editIconContainer"}>
                    {isLoading ? (
                      <span className="ms-2">
                        <div className="spinner-border spinner-border-sm text-dark"></div>
                      </span>
                    ) : (
                      changeValue === initialStateData?.[field?.name] && (
                        <span
                          className="editIcon border p-1 ms-2 pointer"
                          onClick={() => {
                            setEditState(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon="fa fa-pencil"
                            aria-hidden="true"
                          />
                        </span>
                      )
                    )}
                  </span>
                </>
              ) : null}
            </span>
          </>
        ) : (
          <span>{changeValue?.toLocaleString("en-IN") || "-"}</span>
        )}
      </div>
    </form>
  );
};

export default HierarchyInLineEdit;
