import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./AddDetails.scss";
import ReactSlider from "react-slider";
import {
  getPagesByAppId,
  getLogicsByAppId,
  importFieldsDataset,
} from "../../../../services/appService";
import Select from "react-select";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import {
  componentType,
  conversionTypeList,
  displayFormatList,
  fieldDataTyeList,
  inputTypeKey,
} from "../../../common/model/Model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import AddTags from "../addTags/AddTags";
import LinkingComponent from "./LinkingComponent";
import { IconDropdown } from "../display_builder/IconDropdown";
import { defaultFieldLayout, layoutTypes } from "../../../../services/utils";

export class AddDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasetId: "",
      field: {
        name: "",
        display_name: "",
        data_type: "string",
        fieldLayout: defaultFieldLayout,
        entireRow: false,
        is_required: false,
        editor_type: "HTML Editor",
        default_value: "",
        length: 255,
        is_unique: false,
        is_key: false,
        is_hidden: false,
        enter_values: "",
        key_to_send: "",
        key_to_show: "",
        componentType: "",
        dateTimeFormat: "%Y-%m-%d",
        show_key_to_table: "yes",
        show_on_actions: "no",
        edit_mode: "yes",
        line_item_value: "",
        logic_to_call: "",
        summary_logic_to_call: "",
        depends_on: [],
        depends_on_multiple: [],
        htmlCode1: "",
        checked_text: "",
        unchecked_text: "",
        show_on_summary: "no",
        textColor: "Light",
        inline_edit_mode: "no",
        inline_edit_reference: "",
        inline_edit_reference_checked: "",
        inline_edit_reference_unChecked: "",
        inline_edit_logic: "",
        inline_edit_logic_checked: "",
        inline_edit_logic_unChecked: "",
        is_multi: "no",
        dependentReference: "",
        summaryInputRefName: "",
        autoFill: "",
        autoFillLogic: "",
        initialDisable: "",
        visibleWhenField: "",
        visibleWhenValue: "",
        autoFillReference: "",
        addFormData: "",
        status: false,
        tags: [],
        icons: "",
        checkList: {},
        calculatedFieldText: "",
        calculationReference: "",
        summaryOutputRefName: "",
        calculationEditReference: "",
        logic_for_calculation: "",
        numberSlider: {},
        numberTextBox: {},
      },
      notToShowDataSet: true,
      valueList: [
        { name: "true", value: true },
        { name: "false", value: false },
      ],
      editorTypeList: [
        { name: "HTML Editor", value: "HTML Editor" },
        { name: "Text Editor", value: "Text Editor" },
      ],
      dateTimeFormatList: [
        { name: "DD-MM-YYYY", value: "%d-%m-%Y" },
        { name: "MM-DD-YYYY", value: "%m-%d-%Y" },
        { name: "YYYY-MM-DD", value: "%Y-%m-%d" },
        { name: "DD-MM-YY", value: "%d-%m-%y" },
        { name: "MM-DD-YY", value: "%m-%d-%y" },
        { name: "YY-MM-DD", value: "%y-%m-%d" },
        { name: "DD/MM/YYYY", value: "%d/%m/%Y" },
        { name: "MM/DD/YYYY", value: "%m/%d/%Y" },
        { name: "YYYY/MM/DD", value: "%Y/%m/%d" },
        { name: "DD/MM/YY", value: "%d/%m/%y" },
        { name: "MM/DD/YY", value: "%m/%d/%y" },
        { name: "YY/MM/DD", value: "%y/%m/%d" },
      ],
      showKeyToTable: [
        { name: "yes", value: "yes" },
        { name: "no", value: "no" },
      ],
      textColor: [
        { name: "Dark", value: "Dark" },
        { name: "Light", value: "Light" },
      ],
      inlineEditMode: [
        { name: "no", value: "no" },
        { name: "yes", value: "yes" },
      ],
      errors: {},
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
      getPageData: [],
      getLogicData: [],
      appId: "",
      notToDataset: false,
      dynamicDropdownMultiSelectOptions: [],
      dynamicDropdownMultiSelectValue: "",
      datasetNameList: [],      
    };
  }
  handleChangeSlider = (value) => {
    this.setState({ value });
  };

  componentWillMount() {
    this.getCurrentDataSetRecord();
    if (this.props.formFieldData) {
      this.props.onRef(this);
    }
    this.setState(
      {
        appId: JSON.parse(localStorage.getItem("app_id")),
      },
      () => {
        this.getDataSetName();
        this.getPageListData();
        this.getLogicListData();
        if (this.props.sendFieldArray && this.props.sendFieldArray.length) {
          this.dynamicDependsOnData(this.props.sendFieldArray);
        }
        if (this.props.sendFields && this.props.sendFields.length) {
          this.dynamicDependsOnData(this.props.sendFields);
        }
      }
    );
    if (this.props.match.path === "/dataset/:id") {
      this.setState(
        {
          notToShowDataSet: false,
        },
        () => {
          this.setDataTypeList([
            "Line Items",
            "Dynamic Dependent Multiselect dropdowns",
          ]);
        }
      );
    }
    if (this.props.match.path === "/app_page/page_editor/:id") {
      this.setDataTypeList(["Object", "List of Objects"]);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        componentType: nextProps.pageTypeDetails,
      },
      () => {
        if (nextProps.sendFieldArray && nextProps.sendFieldArray.length) {
          this.dynamicDependsOnData(nextProps.sendFieldArray);
        }
        if (nextProps.sendFields && nextProps.sendFields.length) {
          this.dynamicDependsOnData(nextProps.sendFields);
        }
      }
    );
  }

  dynamicDependsOnData(value) {
    let fieldData = value.map((data) => {
      return {
        value: data.name,
        label: data.display_name,
      };
    });
    this.setState({
      dynamicDropdownMultiSelectOptions: fieldData,
    });
  }

  setDataTypeList(unlist_dataype_list) {
    let return_dataTypeList = this.state.dataTypeList?.filter((data) => {
      return !unlist_dataype_list.some((subValue) => {
        return data.name == subValue;
      });
    });
    this.setState({
      dataTypeList: return_dataTypeList,
    });
  }

  getCurrentDataSetRecord() {
    let datasetId = this.props.match.params.id;
    this.setState(
      {
        datasetId: datasetId,
      },
      () => {
        if (this.props.changeDataFlag === true) {
          this.setState({
            field: {
              ...this.props.sendData,
              name: this.props.sendData.name,
              display_name: this.props.sendData.display_name,
              data_type: this.props.sendData.data_type,
              fieldLayout: this.props.sendData.fieldLayout,
              entireRow:  this.props.sendData.entireRow,
              is_required: this.props.sendData.is_required,
              editor_type: this.props.sendData.editor_type,
              default_value: this.props.sendData.default_value,
              length: Number(this.props.sendData.length || 255),
              is_unique: this.props.sendData.is_unique,
              is_key: this.props.sendData.is_key,
              is_hidden: this.props.sendData.is_hidden,
              enter_values: this.props.sendData.enter_values,
              dateTimeFormat: this.props.sendData.dateTimeFormat,
              key_to_send: this.props.sendData.key_to_send,
              key_to_show: this.props.sendData.key_to_show,
              show_key_to_table: this.props.sendData.show_key_to_table,
              show_on_actions: this.props.sendData.show_on_actions,
              edit_mode: this.props.sendData.edit_mode,
              line_item_value: this.props.sendData.line_item_value,
              logic_to_call: this.props.sendData.logic_to_call,
              summary_logic_to_call: this.props.sendData.summary_logic_to_call,
              logic_dependent_field: this.props.sendData.logic_dependent_field,
              depends_on: this.props.sendData.depends_on,
              htmlCode1: this.props.sendData.htmlCode1,
              checked_text: this.props.sendData.checked_text,
              unchecked_text: this.props.sendData.unchecked_text,
              show_on_summary: this.props.sendData.show_on_summary,
              textColor: this.props.sendData?.textColor,
              inline_edit_mode: this.props.sendData.inline_edit_mode || "no",
              inline_edit_reference:
                this.props.sendData.inline_edit_reference || "",
              inline_edit_reference_checked:
                this.props.sendData.inline_edit_reference_checked || "",
              inline_edit_reference_unChecked:
                this.props.sendData.inline_edit_reference_unChecked || "",
              inline_edit_logic: this.props.sendData.inline_edit_logic,
              inline_edit_logic_checked:
                this.props.sendData.inline_edit_logic_checked,
              inline_edit_logic_unChecked:
                this.props.sendData.inline_edit_logic_unChecked,

              is_multi: this.props.sendData?.is_multi || "no",
              dependentReference: this.props.sendData?.dependentReference || "",
              summaryInputRefName:  this.props.sendData?.summaryInputRefName || "",
              autoFill: this.props.sendData?.autoFill || "",
              autoFillLogic: this.props.sendData?.autoFillLogic || "",
              initialDisable: this.props.sendData?.initialDisable || "",
              visibleWhenField: this.props.sendData?.visibleWhenField || "",
              visibleWhenValue: this.props.sendData?.visibleWhenValue || "",
              autoFillReference: this.props.sendData?.autoFillReference || "",
              addFormData: this.props.sendData?.addFormData || "",
              status: this.props.sendData?.status,
              tags: this.props.sendData?.tags || [],
              icons: this.props.sendData?.icons || {},
              checkList: this.props.sendData?.checkList || {},
              calculatedFieldText: "",
              depends_on_multiple: this.props.sendData.depends_on_multiple,
              calculationReference: this.props.sendData?.calculationReference,
              summaryOutputRefName: this.props.sendData?.summaryOutputRefName,
              calculationEditReference:
                this.props.sendData?.calculationEditReference,
              logic_for_calculation: this.props.sendData?.logic_for_calculation,
              numberSlider: this.props.sendData?.numberSlider || {},
            },
          });
        }
      }
    );
  }

  getPageListData() {
    getPagesByAppId(this.state.appId)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            getPageData: response.data,
          });
        }
      })
      .catch((err) => toast.error(err.response?.data?.message || err.response?.message || err.message));
  }

  getLogicListData() {
    getLogicsByAppId(this.state.appId)
      .then((response) => {
        if (response.success === true) {
          this.setState({
            getLogicData: response.data,
          });
        }
      })
      .catch((err) => toast.error(err.response?.data?.message || err.response?.message || err.message));
  }

  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;
    var namePattern = new RegExp(/^[A-Za-z_0-9]+(?:\s+[A-Za-z_0-9]+)*$/i);
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "*Please enter name.";
    } else if (!namePattern.test(fields["name"])) {
      formIsValid = false;
      errors["name"] = "Please Enter Valid Name";
    }
    if (!fields["display_name"]) {
      formIsValid = false;
      errors["display_name"] = "*Please enter display name.";
    }

    if (fields["data_type"] === "toggle_button") {
      if (fields["checked_text"] || fields["unchecked_text"]) {
        if (!(fields["checked_text"].trim().length > 0)) {
          formIsValid = false;
          errors["checked_text"] = "*Please enter Text.";
        }
        if (!(fields["unchecked_text"].trim().length > 0)) {
          formIsValid = false;
          errors["unchecked_text"] = "*Please enter Text.";
        }
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  sendDataToSort() {
    if (this.validateFieldForm()) {
      this.props.onSelectLanguage(this.state.field, this.props.sendIndex);
    }
  }

  sendDataToTable() {
    if (this.validateFieldForm()) {
    }
  }

  changeValue = (event) => {
    let fields = { ...this.state.field };
    if (event.target.name === "name") {
      fields[event.target.name] = event.target.value.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, '');
    } else if (event.target.name === "data_type") {
      if (event.target.value === "toggle_button") {
        fields["default_value"] = "true";
      } else {
        fields["default_value"] = "";
        fields["unchecked_text"] = "";
        fields["checked_text"] = "";
      }
      fields[event.target.name] = event.target.value;
    } else if (event.target.name === "status") {
      fields[event.target.name] = event.target.checked;

      if (!event.target.checked) {
        fields["tags"] = [];
      }
    } else if (event.target.name === "entireRow") {
      fields[event.target.name] = event.target.checked;
    } else if (event.target.name === "length") {
      fields[event.target.name] = Number(event.target.value || 255);
    } else {
      if (event.target.value === "true" || event.target.value === "false") {
        fields[event.target.name] = this.state.valueList.find(
          (val) => val.name === event.target.value
        )?.value;
      } else {
        fields[event.target.name] = event.target.value;
      }
    }
    this.setState({
      field: fields,
    });
  };

  handleCheckList(event) {
    let updatedData = { ...this.state.field };
    updatedData.checkList[event.target.name] = event.target.value;
    this.setState({
      field: updatedData,
    });
  }

  handleInputChange = (event) => {
    let updatedData = { ...this.state.field };

    updatedData.numberSlider["count"] = 0;
    updatedData.numberSlider[event.target.name] = parseInt(event.target.value);
    
    this.setState({
      field: updatedData,
    });
  };

  handleInputChangeNumberTextBox = (event) => {
    let updatedData = { ...this.state.field };

    updatedData.numberTextBox[event.target.name] = parseInt(event.target.value);
    
    this.setState({
      field: updatedData,
    });
  };

  iconChange = (e) => {
    const data = { ...this.state.field };
    data["icons"] = e;
    this.setState({
      field: data,
    });
  };

  editorChangeHandler = (e) => {
    const rows = this.state.field;
    rows["htmlCode1"] = e;
    this.setState({
      rows,
    });
  };

  submitFormFieldData = (event) => {
    event.preventDefault();
    if (this.validateFieldForm()) {
      let objData = {
        tenant_id: this.state.tenant_Id,
        dataset_id: this.state.datasetId,
        field: {
          ...this.state.field,
          name: this.state.field.name,
          display_name: this.state.field.display_name,
          data_type: this.state.field.data_type,
          fieldLayout: this.state.field.fieldLayout,
          entireRow:  this.state.field.entireRow,
          is_required: Boolean(this.state.field.is_required),
          editor_type: this.state.field.editor_type,
          default_value: this.state.field.default_value,
          length: Number(this.state.field.length || 255),
          is_key: Boolean(this.state.field.is_key),
          is_unique: Boolean(this.state.field.is_unique),
          values: {},
          is_hidden: Boolean(this.state.field.is_hidden),
          enter_values: this.state.field.enter_values || "",
          key_to_send: this.state.field.key_to_send || "",
          key_to_show: this.state.field.key_to_show || "",
          dateTimeFormat: this.state.field.dateTimeFormat,
          show_on_actions: this.state.field.show_on_actions,
          edit_mode: this.state.field.edit_mode,
          line_item_value: this.state.field.line_item_value,
          logic_to_call: this.state.field.logic_to_call,
          summary_logic_to_call: this.state.field.summary_logic_to_call,
          logic_dependent_field: this.state.field.logic_dependent_field,
          depends_on: this.state.field.depends_on,
          htmlCode1: this.state.field.htmlCode1,
          show_key_to_table: this.state.field.show_key_to_table,
          checked_text: this.state.field.checked_text || "",
          unchecked_text: this.state.field.unchecked_text || "",
          show_on_summary: this.state.field.show_on_summary || "no",
          textColor: this.state.field?.textColor || "Light",
          inline_edit_mode: this.state.field?.inline_edit_mode || "no",
          inline_edit_reference: this.state.field?.inline_edit_reference || "",
          inline_edit_reference_checked:
            this.state.field?.inline_edit_reference_checked || "",
          inline_edit_reference_unChecked:
            this.state.field?.inline_edit_reference_unChecked || "",
          inline_edit_logic: this.state.field?.inline_edit_logic,
          inline_edit_logic_checked:
            this.state.field?.inline_edit_logic_checked,
          inline_edit_logic_unChecked:
            this.state.field?.inline_edit_logic_unChecked,
          autoFill: this.state.field?.autoFill,
          autoFillLogic: this.state.field?.autoFillLogic,
          initialDisable: this.state.field?.initialDisable,
          visibleWhenField: this.state.field?.visibleWhenField,
          visibleWhenValue: this.state.field?.visibleWhenValue,
          autoFillReference: this.state.field?.autoFillReference,
          addFormData: this.state.field?.addFormData,
          status: this.state.field?.status,
          tags: this.state.field?.tags || [],
          icons: this.state.field?.icons || {},
          checkList: this.state.field?.checkList || {},
          calculatedFieldText: "",
          depends_on_multiple: this.state.field?.depends_on_multiple || [],
          calculationReference: this.state.field?.calculationReference,
          summaryOutputRefName: this.state.field?.summaryOutputRefName,
          calculationEditReference: this.state.field?.calculationEditReference,
          logic_for_calculation: this.state.field?.logic_for_calculation,
          numberSlider: this.state.field?.numberSlider,
        },
      };
      this.returnDataToParent(objData);
    }
  };

  returnDataToParent = (objData) => {
    this.props.formFieldData(objData);
    this.setState({
      field: {
        ...this.state.field,
        name: "",
        display_name: "",
      },
    });
  };

  handleChange = (selectedValue) => {
    let field = this.state.field;
    field["depends_on"] = selectedValue;
    this.setState({ field });
  };

  handleMultipleChange = (selectedValue) => {
    let field = this.state.field;
    field["depends_on_multiple"] = selectedValue;
    this.setState({ field });
  };

  handleMultiKeyChange = (changeObject, key) => {
    if (key) {
      this.setState({
        field: { ...this.state.field, [key]: changeObject },
      });
    } else {
      this.setState({
        field: { ...this.state.field, ...changeObject },
      });
    }
  };

  getDataSetName = () => {
    importFieldsDataset().then((response) => {
      this.setState({ datasetNameList: response.data || [] });
    });
  };

  render() {
    let listDatatypes = fieldDataTyeList.map((data, index) => (
      <option key={index} value={data.value}>
        {data.name}
      </option>
    ));

    let listEditortypes = this.state.editorTypeList.map((data, index) => (
      <option key={index} value={data.value}>
        {data.name}
      </option>
    ));

    let layoutTypeList = layoutTypes.map((data, index) => (
      <option key={index} value={data}>
        {data}
      </option>
    ));

    let listValue = this.state.valueList.map((data, index) => (
      <option key={index} value={data.value}>
        {data.name}
      </option>
    ));

    let dateList = this.state.dateTimeFormatList.map((data, index) => (
      <option key={index} value={data.value}>
        {data.name}
      </option>
    ));

    let keyList = this.state.showKeyToTable.map((data, index) => (
      <option key={index} value={data.value}>
        {data.name}
      </option>
    ));

    let btnColorTheme = this.state.textColor.map((data, index) => (
      <option key={index} value={data.value}>
        {data.name}
      </option>
    ));

    let inLineEdit = this.state.inlineEditMode.map((data, index) => (
      <option key={index} value={data.value}>
        {data.name}
      </option>
    ));

    let pageList = this.state.getPageData?.map((data, index) => (
      <option key={index} value={data.function_name}>
        {data.function_name}
      </option>
    ));

    let formPageListWithId = this.state.getPageData
      ?.filter((page) => page?.page_type === componentType.formComponent)
      ?.map((data, index) => (
        <option key={index} value={data._id?.$oid}>
          {data.function_name}
        </option>
      ));

    let logicList = this.state.getLogicData?.map((data, index) => (
      <option key={index} value={data.function_name}>
        {data.function_name}
      </option>
    ));

    let displayFormatListOption = displayFormatList?.map((data, index) => (
      <option key={index} value={data.value}>
        {data.label}
      </option>
    ));

    return (
      <div className="addField">
        {this.state.componentType !== "Display Component" &&
        this.state.componentType !== "Store Component" ? (
          <div>
            {this.state.datasetId || !this.props.addPageUrl ? (
              <div className="mb-2 border bg-white">
                {!this.props.changeDataFlag ? (
                  <div className="clearfix m-2 py-2">
                    <label className="text-start text-left float-left h5">Add Field</label>
                    {this.props.match.path === "/app_page/page_editor/:id" ? (
                      <div className="text-end float-right">
                        {!this.props.changeDataFlag ? (
                          <button
                            className="add-btn mx-2"
                            onClick={(event) => this.submitFormFieldData(event)}
                          >
                            Add Field
                          </button>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <form
                  className="form-horizontal form-label-left py-2"
                  onSubmit={this.submitFormFieldData}
                >
                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 required">
                      Name
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={this.state.field.name}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      />
                      <div className="errorMsg text-danger">
                        {this.state.errors.name}
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 required">
                      Display Name
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <input
                        type="text"
                        name="display_name"
                        className="form-control"
                        value={this.state.field.display_name}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      />
                      <div className="errorMsg text-danger">
                        {this.state.errors.display_name}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3">
                      Help Text
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <input
                        type="text"
                        name="helpText"
                        className="form-control"
                        value={this.state.field.helpText}
                        onChange={this.changeValue}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3">
                      Select Datatype
                    </label>

                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        name="data_type"
                        className="form-control"
                        value={this.state.field.data_type}
                        onChange={this.changeValue}
                      >
                        {listDatatypes}
                      </Form.Select>
                    </div>
                  </div>

                  {[
                    inputTypeKey.largeText,
                  ].includes(this.state.field.data_type) && (
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">
                        Select Editor Type
                      </label>
                      <div className="col-md-9 col-sm-9 ">
                        <Form.Select
                          name="editor_type"
                          className="form-control"
                          value={this.state.field.editor_type}
                          onChange={this.changeValue}
                        >
                          {listEditortypes}
                        </Form.Select>
                      </div>
                    </div>
                  )}
                  

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Field Required?
                    </label>

                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        className="form-control"
                        name="is_required"
                        value={this.state.field.is_required}
                        onChange={this.changeValue}
                      >
                        {listValue}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3">
                      Select Field Layout
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        name="fieldLayout"
                        className="form-control"
                        value={this.state.field.fieldLayout}
                        onChange={this.changeValue}
                      >
                        {layoutTypeList}
                      </Form.Select>
                      <br/>
                      <div className="">
                        <input
                            type="checkbox"
                            name="entireRow"
                            checked={this.state.field?.entireRow}
                            onChange={(e) => this.changeValue(e)}
                            className="form-check-input mx-2"
                          />
                        Occupy entire row?
                      </div>
                    </div>
                  </div>

                  {[inputTypeKey.textBox].includes(
                    this.state.field.data_type
                  ) && (
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">
                        Convert Value
                      </label>

                      <div className="col-md-9 col-sm-9 ">
                        <Form.Select
                          name="convertValue"
                          className="form-control"
                          value={this.state.field.convertValue}
                          onChange={this.changeValue}
                        >
                          <option value="">--Select--</option>
                          {conversionTypeList.map((data, index) => (
                            <option key={index} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>
                  )}

                  {this.state.field.data_type === inputTypeKey.checkList && (
                    <div className="border m-2 p-2">
                      <h5 className="pb-2 border-bottom">CheckList</h5>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">
                          Title
                        </label>
                        <div className="col-md-9 col-sm-9 ">
                          <input
                            type="text"
                            name="checkList_title"
                            className="form-control"
                            value={this.state.field?.checkList?.checkList_title}
                            onChange={(e) => {
                              this.handleCheckList(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">
                          Logic Name
                        </label>
                        <div className="col-md-9 col-sm-9 ">
                          <input
                            type="text"
                            name="checkList_logic_name"
                            className="form-control"
                            value={
                              this.state.field?.checkList?.checkList_logic_name
                            }
                            onChange={(e) => {
                              this.handleCheckList(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.field.data_type === inputTypeKey.numberSlider && (
                    <>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Minimum Value</label>
                      <div className="col-md-9 col-sm-9">
                        <input
                          type="text"
                          name="minValue"
                          className="form-control"
                          value={this.state.field?.numberSlider?.minValue}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Maximum Value</label>
                      <div className="col-md-9 col-sm-9">
                        <input
                          type="text"
                          name="maxValue"
                          className="form-control"
                          value={this.state.field?.numberSlider?.maxValue}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">Increment Value</label>
                      <div className="col-md-9 col-sm-9">
                        <input
                          type="text"
                          name="incrementValue"
                          className="form-control"
                          value={this.state.field?.numberSlider?.incrementValue}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    </>
                  )}

                  {(this.state.field.data_type ===
                    inputTypeKey.searchableDropdown || this.state.field.data_type ===
                    inputTypeKey.dynamicDependentDropdown) && (
                    <>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">
                          Multiple Select
                        </label>
                        <div className="col-md-9 col-sm-9 ">
                          <Form.Select
                            name="is_multi"
                            className="form-control"
                            value={this.state.field.is_multi}
                            onChange={this.changeValue}
                          >
                            {keyList}
                          </Form.Select>
                        </div>
                      </div>
                    </>
                  )}

                  {[
                    inputTypeKey.searchableDropdown,
                    inputTypeKey.inlineTableForm,
                    inputTypeKey.textBox,
                  ].includes(this.state.field.data_type) && (
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">
                        Select Form
                      </label>
                      <div className="col-md-9 col-sm-9 ">
                        <Form.Select
                          className="form-control"
                          name="addFormData"
                          value={this.state.field.addFormData}
                          onChange={this.changeValue}
                        >
                          <option value="">--Select--</option>
                          {formPageListWithId}
                        </Form.Select>
                      </div>
                    </div>
                  )}

                  {[
                    inputTypeKey.textBox,
                    inputTypeKey.searchableDropdown,
                  ].includes(this.state.field.data_type) && (
                    <>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">
                          Auto Fill
                        </label>
                        <div className="col-md-9 col-sm-9 ">
                          <Form.Select
                            name="autoFill"
                            className="form-control"
                            value={this.state.field.autoFill}
                            onChange={this.changeValue}
                          >
                            {inLineEdit}
                          </Form.Select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">
                          Auto Fill Logic
                        </label>
                        <div className="col-md-9 col-sm-9 ">
                          <Form.Select
                            className="form-control"
                            name="autoFillLogic"
                            value={this.state.field.autoFillLogic}
                            onChange={this.changeValue}
                          >
                            <option value="">--Select--</option>
                            {logicList}
                          </Form.Select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">
                          Auto Fill Reference
                        </label>
                        <div className="col-md-9 col-sm-9 ">
                          <input
                            type="text"
                            className="form-control"
                            name="autoFillReference"
                            value={this.state.field.autoFillReference}
                            onChange={this.changeValue}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3">
                      Initial Disable
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        name="initialDisable"
                        className="form-control"
                        value={this.state.field.initialDisable}
                        onChange={this.changeValue}
                      >
                        {inLineEdit}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3">
                      Enable When
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <div className="input-group mb-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Field Name"
                          name="visibleWhenField"
                          value={this.state.field.visibleWhenField}
                          onChange={this.changeValue}
                        />
                        <span className="input-group-text">=</span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Field Value"
                          name="visibleWhenValue"
                          value={this.state.field.visibleWhenValue}
                          onChange={this.changeValue}
                        />
                      </div>
                    </div>
                  </div>

                  {/* label */}
                  {this.state.field.data_type === "custom_section" &&
                  this.state.notToShowDataSet ? (
                    <div className="form-group row">
                      <div className="col-md-12 col-sm-12 col-12 ">
                        <ReactQuill
                          modules={this.state.modules}
                          id="htmlCode1"
                          name="htmlCode1"
                          value={this.state.field.htmlCode1}
                          theme="snow"
                          onChange={(e) => this.editorChangeHandler(e)}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* ends */}

                  {/* line items */}
                  {this.state.field.data_type === "lineItems" &&
                  this.state.notToShowDataSet ? (
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3 required">
                        Select Form To Link
                      </label>

                      <div className="col-md-9 col-sm-9 ">
                        <Form.Select
                          className="form-control"
                          name="line_item_value"
                          value={this.state.field.line_item_value}
                          onChange={this.changeValue}
                        >
                          <option value="">--Select--</option>
                          {pageList}
                        </Form.Select>
                      </div>
                    </div>
                  ) : null}
                  {/* ends */}

                  {[
                    inputTypeKey.searchableDropdown,
                    inputTypeKey.calculatedField,
                    inputTypeKey.textBox,
                  ].includes(this.state.field.data_type) &&
                  this.state.notToShowDataSet ? (
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">
                        Select Logic To Link
                      </label>

                      <div className="col-md-9 col-sm-9 ">
                        <Form.Select
                          className="form-control"
                          name="logic_to_call"
                          value={this.state.field.logic_to_call}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              this.setState({
                                field: {
                                  ...this.state.field,
                                  key_to_send: "",
                                  key_to_show: "",
                                },
                              });
                            }
                            this.changeValue(e);
                          }}
                        >
                          <option value="">--Select--</option>
                          {logicList}
                        </Form.Select>
                      </div>
                    </div>
                    ) : null}
                    
                  {[
                    inputTypeKey.calculatedField,
                  ].includes(this.state.field.data_type) &&
                  this.state.notToShowDataSet ? (
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">
                        Select Summary Logic To Link
                      </label>

                      <div className="col-md-9 col-sm-9 ">
                        <Form.Select
                          className="form-control"
                          name="summary_logic_to_call"
                          value={this.state.field.summary_logic_to_call}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              this.setState({
                                field: {
                                  ...this.state.field,
                                  key_to_send: "",
                                  key_to_show: "",
                                },
                              });
                            }
                            this.changeValue(e);
                          }}
                        >
                          <option value="">--Select--</option>
                          {logicList}
                        </Form.Select>
                      </div>
                    </div>
                  ) : null}

                  {this.state.field.data_type ===
                    inputTypeKey.dynamicDependentDropdown &&
                    this.state.notToShowDataSet && (
                      <>
                        <div className="form-group row">
                          <label className="control-label col-md-3 col-sm-3">
                            Depends On Database
                          </label>
                          <div className="col-md-9 col-sm-9 ">
                            <Form.Select
                              className="form-control"
                              name="dependentDataset"
                              onChange={this.changeValue}
                              value={this.state.field.dependentDataset}
                            >
                              <option value="">--Select--</option>
                              {this.state.datasetNameList.map((data) => (
                                <option value={data.dataset_name}>
                                  {data.dataset_name}
                                </option>
                              ))}
                            </Form.Select>

                            {/* <Select
                              options={this.state.dynamicDropdownMultiSelectOptions.filter(
                                (values) =>
                                  values.label !== this.state.field.display_name
                              )}
                              value={this.state.field.depends_on}
                              onChange={this.handleChange}
                              isObject={false}
                              isClearable
                            /> */}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="control-label col-md-3 col-sm-3">
                            Depends On Field
                          </label>
                          <div className="col-md-9 col-sm-9 ">
                            <Select
                              options={this.state.dynamicDropdownMultiSelectOptions.filter(
                                (values) =>
                                  values.label !== this.state.field.display_name
                              )}
                              value={this.state.field.depends_on}
                              onChange={this.handleChange}
                              isObject={false}
                              isClearable
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="control-label col-md-3 col-sm-3">
                            Select Logic To Link
                            {this.state.field?.depends_on?.value
                              ? " (Child)"
                              : " (Parent)"}
                          </label>

                          <div className="col-md-9 col-sm-9 ">
                            <Form.Select
                              className="form-control"
                              name="logic_to_call"
                              value={this.state.field.logic_to_call}
                              onChange={(e) => {
                                if (e.target.value === "") {
                                  this.setState({
                                    field: {
                                      ...this.state.field,
                                      key_to_send: "",
                                      key_to_show: "",
                                    },
                                  });
                                }
                                this.changeValue(e);
                              }}
                            >
                              <option value="">--Select--</option>
                              {logicList}
                            </Form.Select>
                          </div>
                        </div>
                      </>
                    )}

                  {[
                    inputTypeKey.dynamicDependentDropdown,
                    inputTypeKey.calculatedField,
                  ].includes(this.state.field.data_type) && (
                    <div className="form-group row mt-2">
                      <label className="control-label col-md-3 col-sm-3">
                        Reference name
                      </label>

                      <div className="col-md-9 col-sm-9 mt-2">
                        <input
                          type="text"
                          name="dependentReference"
                          className="form-control"
                          value={this.state.field.dependentReference}
                          onChange={(e) => {
                            this.changeValue(e);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {[
                    inputTypeKey.dynamicDependentDropdown,
                    inputTypeKey.calculatedField,
                  ].includes(this.state.field.data_type) && (
                    <div className="form-group row mt-2">
                      <label className="control-label col-md-3 col-sm-3">
                        Calculation Reference name
                      </label>

                      <div className="col-md-9 col-sm-9 mt-2">
                        <input
                          type="text"
                          name="calculationReference"
                          className="form-control"
                          value={this.state.field.calculationReference}
                          onChange={(e) => {
                            this.changeValue(e);
                          }}
                        />
                      </div>
                    </div>
                      )}
                    
                  {[
                    inputTypeKey.calculatedField,
                  ].includes(this.state.field.data_type) && (
                    <div className="form-group row mt-2">
                      <label className="control-label col-md-3 col-sm-3">
                        Summary Input Ref name
                      </label>

                      <div className="col-md-9 col-sm-9 mt-2">
                        <input
                          type="text"
                          name="summaryInputRefName"
                          className="form-control"
                          value={this.state.field.summaryInputRefName}
                          onChange={(e) => {
                            this.changeValue(e);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {[
                    inputTypeKey.calculatedField,
                  ].includes(this.state.field.data_type) && (
                    <div className="form-group row mt-2">
                      <label className="control-label col-md-3 col-sm-3">
                        Summary Output Ref name
                      </label>

                      <div className="col-md-9 col-sm-9 mt-2">
                        <input
                          type="text"
                          name="summaryOutputRefName"
                          className="form-control"
                          value={this.state.field.summaryOutputRefName}
                          onChange={(e) => {
                            this.changeValue(e);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {this.state.field.data_type ===
                    inputTypeKey.calculatedField &&
                    this.state.notToShowDataSet && (
                      <>
                        <div className="form-group row">
                          <label className="control-label col-md-3 col-sm-3">
                            Depends On Fields
                          </label>
                          <div className="col-md-9 col-sm-9 ">
                            <Select
                              options={this.state.dynamicDropdownMultiSelectOptions.filter(
                                (values) =>
                                  values.label !== this.state.field.display_name
                              )}
                              value={this.state.field.depends_on_multiple}
                              onChange={this.handleMultipleChange}
                              isObject={false}
                              isClearable
                              isMulti={true}
                            />
                          </div>
                        </div>

                        <div className="form-group row mt-2">
                          <label className="control-label col-md-3 col-sm-3">
                            Calculation Edit Reference name
                          </label>

                          <div className="col-md-9 col-sm-9 mt-2">
                            <input
                              type="text"
                              name="calculationEditReference"
                              className="form-control"
                              value={this.state.field.calculationEditReference}
                              onChange={(e) => {
                                this.changeValue(e);
                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="control-label col-md-3 col-sm-3">
                            Select Edit Logic For Calculation
                          </label>
                          <div className="col-md-9 col-sm-9 ">
                            <Form.Select
                              className="form-control"
                              name="logic_for_calculation"
                              value={this.state.field.logic_for_calculation}
                              onChange={(e) => {
                                if (e.target.value === "") {
                                  this.setState({
                                    field: {
                                      ...this.state.field,
                                      key_to_send: "",
                                      key_to_show: "",
                                    },
                                  });
                                }
                                this.changeValue(e);
                              }}
                            >
                              <option value="">--Select--</option>
                              {logicList}
                            </Form.Select>
                          </div>
                        </div>

                        <div className="form-group row mt-2">
                          <label className="control-label col-md-3 col-sm-3">
                            Label Detail Text
                          </label>

                          <div className="col-md-9 col-sm-9 mt-2">
                            <input
                              type="text"
                              name="calculatedFieldText"
                              className="form-control"
                              value={this.state.field.calculatedFieldText}
                              onChange={(e) => {
                                this.changeValue(e);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}

                  {this.state.field.data_type === "boolean" ||
                  this.state.field.data_type === "string[]" ||
                  this.state.field.data_type ===
                    inputTypeKey.searchableDropdown ||
                  this.state.field.data_type === "radio_button" ? (
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3">
                        Enter Value
                      </label>

                      <div className="col-md-9 col-sm-9 ">
                        <input
                          type="text"
                          name="enter_values"
                          className="form-control"
                          value={this.state.field.enter_values}
                          onChange={(e) => {
                            this.changeValue(e);
                          }}
                        />
                        {/* <div className="errorMsg text-danger">
                            {this.state.errors.enter_values}
                          </div> */}
                      </div>
                    </div>
                  ) : null}

                  {this.state.field.data_type === "string[]" ||
                  this.state.field.logic_to_call ? (
                    <>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">
                          Enter Key To Send
                        </label>

                        <div className="col-md-9 col-sm-9 ">
                          <input
                            type="text"
                            name="key_to_send"
                            className="form-control"
                            value={this.state.field.key_to_send}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3">
                          Enter Key To Show
                        </label>

                        <div className="col-md-9 col-sm-9 ">
                          <input
                            type="text"
                            name="key_to_show"
                            className="form-control"
                            value={this.state.field.key_to_show}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  {this.state.field.data_type === "dateTime" ? (
                    <div className="form-group row">
                      <label className="control-label col-md-3 col-sm-3 required">
                        Date Format
                      </label>

                      <div className="col-md-9 col-sm-9 ">
                        {" "}
                        <Form.Select
                          className="form-control"
                          name="dateTimeFormat"
                          value={this.state.field.dateTimeFormat}
                          onChange={this.changeValue}
                        >
                          {dateList}
                        </Form.Select>
                      </div>
                    </div>
                  ) : null}

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Default Value
                    </label>
                    {this.state.field.data_type === "toggle_button" ? (
                      <>
                        <div className="col-md-9 col-sm-9 ">
                          <Form.Select
                            className="form-control mb-2"
                            name="default_value"
                            value={this.state.field.default_value}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          >
                            <option value="true">Checked</option>
                            <option value="false">Unchecked</option>
                          </Form.Select>
                          <div className="form-group row p-0 m-0">
                            <div className="col-md-6 col-6 col-sm-6 ps-0">
                              <input
                                type="text"
                                name="checked_text"
                                className="form-control"
                                placeholder="Checked Text"
                                maxLength={30}
                                value={this.state.field.checked_text}
                                onChange={(e) => {
                                  this.changeValue(e);
                                }}
                              />
                              <div className="errorMsg text-danger">
                                {this.state.errors.checked_text}
                              </div>
                            </div>
                            <div className="col-md-6 col-6 col-sm-6 pe-0">
                              <input
                                type="text"
                                name="unchecked_text"
                                className="form-control"
                                placeholder="Unchecked Text"
                                maxLength={30}
                                value={this.state.field.unchecked_text}
                                onChange={(e) => {
                                  this.changeValue(e);
                                }}
                              />
                              <div className="errorMsg text-danger">
                                {this.state.errors.unchecked_text}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-md-9 col-sm-9 ">
                        <input
                          type="text"
                          name="default_value"
                          className="form-control"
                          value={this.state.field.default_value}
                          onChange={(e) => {
                            this.changeValue(e);
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Length
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <input
                        type="number"
                        name="length"
                        min="0"
                        className="form-control"
                        value={this.state.field.length}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      />
                      <div className="errorMsg text-danger">
                        {this.state.errors.length}
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Unique
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        className="form-control"
                        name="is_unique"
                        value={this.state.field.is_unique}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      >
                        {listValue}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Key
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        className="form-control"
                        name="is_key"
                        value={this.state.field.is_key}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      >
                        {listValue}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Hidden
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        className="form-control"
                        name="is_hidden"
                        value={this.state.field.is_hidden}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      >
                        {listValue}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Show Field To Table
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        className="form-control"
                        name="show_key_to_table"
                        value={this.state.field.show_key_to_table}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      >
                        {keyList}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Show on actions
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        className="form-control"
                        name="show_on_actions"
                        value={this.state.field.show_on_actions}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      >
                        {keyList}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Edit Mode
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        className="form-control"
                        name="edit_mode"
                        value={this.state.field.edit_mode}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      >
                        {keyList}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Inline Edit Mode
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        className="form-control"
                        name="inline_edit_mode"
                        value={this.state.field?.inline_edit_mode}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      >
                        {inLineEdit}
                      </Form.Select>
                    </div>
                  </div>

                  {this.state.field?.inline_edit_mode === "yes" ? (
                    <>
                      {this.state.field.data_type === "toggle_button" ? (
                        <>
                          <div className="form-group row">
                            <label className="control-label col-md-3 col-sm-3 ">
                              Inline Edit Logic (Checked)
                            </label>
                            <div className="col-md-9 col-sm-9 ">
                              <Form.Select
                                className="form-control"
                                name="inline_edit_logic_checked"
                                value={
                                  this.state.field?.inline_edit_logic_checked
                                }
                                onChange={(e) => {
                                  this.changeValue(e);
                                }}
                              >
                                <option value="">--Select--</option>
                                {logicList}
                              </Form.Select>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="control-label col-md-3 col-sm-3 ">
                              Inline Edit Reference (Checked)
                            </label>
                            <div className="col-md-9 col-sm-9 ">
                              <input
                                type="text"
                                name="inline_edit_reference_checked"
                                className="form-control"
                                value={
                                  this.state.field.inline_edit_reference_checked
                                }
                                onChange={(e) => {
                                  this.changeValue(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="control-label col-md-3 col-sm-3 ">
                              Inline Edit Logic (UnChecked)
                            </label>
                            <div className="col-md-9 col-sm-9 ">
                              <Form.Select
                                className="form-control"
                                name="inline_edit_logic_unChecked"
                                value={
                                  this.state.field?.inline_edit_logic_unChecked
                                }
                                onChange={(e) => {
                                  this.changeValue(e);
                                }}
                              >
                                <option value="">--Select--</option>
                                {logicList}
                              </Form.Select>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="control-label col-md-3 col-sm-3 ">
                              Inline Edit Reference (UnChecked)
                            </label>
                            <div className="col-md-9 col-sm-9 ">
                              <input
                                type="text"
                                name="inline_edit_reference_unChecked"
                                className="form-control"
                                value={
                                  this.state.field
                                    .inline_edit_reference_unChecked
                                }
                                onChange={(e) => {
                                  this.changeValue(e);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="form-group row">
                            <label className="control-label col-md-3 col-sm-3 ">
                              Inline Edit Logic
                            </label>
                            <div className="col-md-9 col-sm-9 ">
                              <Form.Select
                                className="form-control"
                                name="inline_edit_logic"
                                value={this.state.field?.inline_edit_logic}
                                onChange={(e) => {
                                  this.changeValue(e);
                                }}
                              >
                                <option value="">--Select--</option>
                                {logicList}
                              </Form.Select>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="control-label col-md-3 col-sm-3 ">
                              Inline Edit Reference
                            </label>
                            <div className="col-md-9 col-sm-9 ">
                              <input
                                type="text"
                                name="inline_edit_reference"
                                className="form-control"
                                value={this.state.field.inline_edit_reference}
                                onChange={(e) => {
                                  this.changeValue(e);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Show Field On Summary
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        className="form-control"
                        name="show_on_summary"
                        value={this.state.field.show_on_summary || ""}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      >
                        {keyList}
                      </Form.Select>
                    </div>
                  </div>

                  {inputTypeKey.numberTextBox ===
                    this.state.field.data_type && (
                    <>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3 ">
                          Display Currency Format
                        </label>
                        <div className="col-md-9 col-sm-9 ">
                          <Form.Select
                            className="form-control"
                            name="displayCurrencyFormat"
                            value={this.state.field.displayCurrencyFormat || ""}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          >
                            <option value="">--Select--</option>
                            {displayFormatListOption}
                          </Form.Select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3 ">
                          Prefix Value 
                        </label>
                        <div className="col-md-9 col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="prefixValue"
                            value={this.state.field.prefixValue || ""}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3 ">
                          Suffix Value 
                        </label>
                        <div className="col-md-9 col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="suffixValue"
                            value={this.state.field.suffixValue || ""}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Status
                    </label>
                    <div className="col-md-9 col-sm-9 d-flex align-items-center">
                      <input
                        type="checkbox"
                        name="status"
                        checked={this.state.field?.status}
                        onChange={(e) => this.changeValue(e)}
                        className="form-check-input mx-2"
                        />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Text Colour
                    </label>
                    <div className="col-md-9 col-sm-9 ">
                      <Form.Select
                        className="form-control"
                        name="textColor"
                        value={this.state.field?.textColor}
                        onChange={(e) => {
                          this.changeValue(e);
                        }}
                      >
                        {btnColorTheme}
                      </Form.Select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="control-label col-md-3 col-sm-3 ">
                      Icon
                    </label>{" "}
                    <div className="col-md-9 col-sm-9 ">
                      <IconDropdown
                        value={this.state.field?.icons || ""}
                        onChange={(e) => {
                          this.iconChange(e);
                        }}
                      ></IconDropdown>
                    </div>
                  </div>

                  {this.state?.field?.status && (
                    <AddTags
                      value={this.state.field?.tags}
                      onChange={(obj) => this.handleMultiKeyChange(obj, "tags")}
                    />
                  )}

                  <LinkingComponent
                    onChange={this.handleMultiKeyChange}
                    value={this.state.field}
                  />

                  {this.props.changeDataFlag ? (
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-md btn-success"
                        onClick={(e) => this.sendDataToSort()}
                      >
                        <FontAwesomeIcon icon="fa-regular fa-circle-check" />
                        <span className="ms-1">Save Changes</span>
                      </button>
                    </div>
                  ) : null}
                  {this.props.match.path === "/app_page/page_editor/:id" ? (
                    <div className="text-end">
                      {!this.props.changeDataFlag ? (
                        <button
                          className="add-btn mx-2"
                          onClick={(event) => this.submitFormFieldData(event)}
                        >
                          Add Field
                        </button>
                      ) : null}
                    </div>
                  ) : null}
                </form>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(AddDetails);
