import React, { useContext, useEffect, useState } from "react";
import { HierarchyContext } from "./HierarchyTableContext";
import { executeAppForTempStorage } from "../../../services/API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HierarchyInLineEdit from "./HierarchyInLineEdit";
import { toast } from "react-toastify";

const HierarchyTableRow = ({
  rowData,
  childList,
  setToggleRow,
  toggleRow,
  paddingPx,
  path,
  parentIds,
}) => {
  const [rowStateData, setRowStateData] = useState({});

  useEffect(() => {
    setRowStateData(rowData);
  }, [rowData]);

  const { appSessionId, displayConfig, setHierarchyModalObject } =
    useContext(HierarchyContext);

  const temporaryStorage = (hierarchyData) => {
    let data = {
      data: hierarchyData,
      reference_name: displayConfig?.editTemporaryReference,
      app_session_id: appSessionId,
    };
    executeAppForTempStorage(data)
      .then()
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  };

  return (
    <tr className="rootHierarchyTable">
      {displayConfig?.page_field?.map((field, index) => {
        if (field.name === displayConfig?.hierarchyColumnKey) {
          return (
            <td key={index}>
              <span
                className="hierarchyParent"
                style={{ paddingLeft: paddingPx + "px" }}
              >
                <div className="d-flex justify-content-between">
                  <div
                    className={`w-100 flex-grow-1 ${
                      childList ? "pointer" : ""
                    }`}
                    onClick={() => setToggleRow(!toggleRow)}
                  >
                    <span>
                      <span className="me-1">
                        {childList ? (
                          toggleRow ? (
                            <FontAwesomeIcon icon="fa fa-caret-down" />
                          ) : (
                            <FontAwesomeIcon icon="fa fa-caret-right" />
                          )
                        ) : (
                          <span
                            style={
                              childList === 0 && paddingPx === 0
                                ? { padding: "4.5px" }
                                : {}
                            }
                          ></span>
                        )}
                      </span>
                      <span title={path}>
                        {rowStateData?.[field.name] || "-"}
                      </span>
                    </span>
                  </div>
                  <div className="hierarchyIconColor d-flex align-items-center">
                    {!displayConfig?.hideActions && (
                      <>
                        <span
                          title="Add Category"
                          className="ms-2 pointer fileIcon"
                          onClick={() => {
                            setHierarchyModalObject({
                              modalData: rowStateData,
                              showModal: true,
                              hierarchyPath: path,
                              modalState: "Add",
                              parentIds: parentIds,
                              selectedHierarchy: rowStateData?.hierarchy_id,
                              setToggleRow: setToggleRow,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon="fa fa-plus" />
                        </span>
                        <span
                          title="Rename"
                          className="ms-2 pointer fileIcon"
                          onClick={() => {
                            setHierarchyModalObject({
                              modalData: rowStateData,
                              showModal: true,
                              hierarchyPath: path,
                              modalState: "Update",
                              selectedHierarchy: rowStateData?.hierarchy_id,
                            });
                            temporaryStorage(rowStateData);
                          }}
                        >
                          <FontAwesomeIcon icon="fa fa-pencil" />
                        </span>
                        <span
                          title="Delete"
                          className="ms-2 pointer fileIcon"
                          onClick={() => {
                            setHierarchyModalObject({
                              modalData: rowStateData,
                              showModal: true,
                              hierarchyPath: path,
                              modalState: "Delete",
                              selectedHierarchy: rowStateData?.hierarchy_id,
                            });
                            temporaryStorage(rowStateData);
                          }}
                        >
                          <FontAwesomeIcon icon="fa fa-trash" />
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </span>
            </td>
          );
        } else {
          return (
            <td key={index}>
              <HierarchyInLineEdit
                rowStateData={rowStateData}
                setRowStateData={setRowStateData}
                field={field}
                initialStateData={rowData}
              />
            </td>
          );
        }
      })}
    </tr>
  );
};

export default HierarchyTableRow;
