import React, { useEffect, useRef, useState } from "react";
import Loader from "../dataset/Loader/Loader";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import {
  getApiSpecification,
  getApiSpecificationForGet,
} from "../../services/appService";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ApiSpecification = ({ field }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("");
  const [apiKeyInApiSpecification, setapiKeyInApiSpecification] = useState("");
  const [body, setBody] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [method, setMethod] = useState({
    selectedMethodId: "",
    selectedMethodName: "",
  });
  const target = useRef(null);
  const headerTarget = useRef(null);
  const bodyTarget = useRef(null);
  const [isBodyShown, setIsBodyShown] = useState(true);
  const [headerCopyTextShow, setHeaderCopyTextShow] = useState(false);
  const [bodyCopyTextShow, setBodyCopyTextShow] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(url);
    url && setShow(!show);
    setTimeout(() => {
      setShow(show);
    }, 3000);
  };

  const handleCopyHeader = () => {
    navigator.clipboard.writeText(apiKeyInApiSpecification);
    apiKeyInApiSpecification && setHeaderCopyTextShow(!headerCopyTextShow);
    setTimeout(() => {
      setHeaderCopyTextShow(headerCopyTextShow);
    }, 3000);
  };

  const handleCopyBody = () => {
    navigator.clipboard.writeText(
      `{"body" : ${body}, "tenant_name" : ${tenantName}}`
    );
    body && setBodyCopyTextShow(!bodyCopyTextShow);
    setTimeout(() => {
      setBodyCopyTextShow(bodyCopyTextShow);
    }, 3000);
  };

  const changeValue = (e) => {
    let text = e.target.options[e.target.selectedIndex].text;
    let value = e.target.value;
    setMethod({
      ...method,
      selectedMethodId: value,
      selectedMethodName: text,
    });
    setIsLoading(true);
    if (e.target.value === "post") {
      setIsBodyShown(true);
      getApiSpecification({
        api_key: field.apiKey,
        logic_name: field.selectedLogicName,
      })
        .then((response) => {
          setIsLoading(false);
          setUrl(response.url);
          setapiKeyInApiSpecification(response.api_key);
          setBody(JSON.stringify(response.body));
          setTenantName(JSON.stringify(response.tenant_name));
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        });
    }
    if (e.target.value === "get") {
      setIsBodyShown(false);
      getApiSpecificationForGet(field.apiKey, field.selectedLogicName)
        .then((response) => {
          setIsLoading(true);
          if (response.success) {
            setIsLoading(false);
            setUrl(response.url);
            setapiKeyInApiSpecification(response.api_key);
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        });
    }
  };

  return (
    <div className="col-12 pb-4 bg-white my-3 p-0 border border-secondary-subtle border-opacity-25">
      <div className="p-2">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="my-3 d-flex col-12 align-items-center">
              <label
                htmlFor="method"
                className="form-label col-lg-3 col-md-3 col-4"
              >
                Method
              </label>
              <div className="d-flex flex-column col-lg-9 col-md-9 col-8 m-0 p-0">
                <Form.Select
                  className="form-control"
                  name="method"
                  value={method.selectedMethodId}
                  onChange={(e) => changeValue(e)}
                >
                  <option disabled value={""}>
                    Select
                  </option>
                  <option value="post">Post</option>
                  <option value="get">Get</option>
                </Form.Select>
              </div>
            </div>

            <div className="form-group d-flex align-items-center my-2">
              <div className="col-12 d-flex align-items-center m-0 p-0">
                <label className="form-label col-lg-3 col-md-3 col-4">
                  URL
                </label>
                <div className="col-lg-9 col-md-9 col-8 p-0 d-flex">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="URL"
                      name="url"
                      value={url}
                      readOnly
                    />
                    <button
                      className="btn btn-secondary m-0"
                      type="button"
                      id="copy-url-button"
                      onClick={handleCopyUrl}
                    >
                      <FontAwesomeIcon icon={`fa fa-copy`} ref={target} />
                      <Overlay
                        target={target.current}
                        show={show}
                        placement="top"
                      >
                        {(props) => (
                          <Tooltip id="overlay-for-url-copy" {...props}>
                            Copied
                          </Tooltip>
                        )}
                      </Overlay>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group d-flex align-items-center my-2">
              <div className="col-12 d-flex align-items-center m-0 p-0">
                <label className="form-label col-lg-3 col-md-3 col-4">
                  Header
                </label>
                <div className="col-lg-9 col-md-9 col-8 m-0 p-0">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Header"
                      name="header"
                      value={`x-api-key : ${apiKeyInApiSpecification}`}
                      readOnly
                    />
                    <button
                      className="btn btn-secondary m-0"
                      type="button"
                      id="copy-header-button"
                      onClick={handleCopyHeader}
                    >
                      <FontAwesomeIcon icon={`fa fa-copy`} ref={headerTarget} />
                      <Overlay
                        target={headerTarget.current}
                        show={headerCopyTextShow}
                        placement="top"
                      >
                        {(props) => (
                          <Tooltip id="overlay-for-header-copy" {...props}>
                            Copied
                          </Tooltip>
                        )}
                      </Overlay>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isBodyShown ? (
              <div className="form-group d-flex align-items-center my-2">
                <div className="col-12 d-flex align-items-center m-0 p-0">
                  <label className="form-label col-lg-3 col-md-3 col-4">
                    Body
                  </label>
                  <div className="col-lg-9 col-md-9 col-8 m-0 p-0">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Body"
                        name="body"
                        value={`{"body" : ${body}, "tenant_name" : ${tenantName}}`}
                        readOnly
                      />
                      <button
                        className="btn btn-secondary m-0"
                        type="button"
                        id="copy-body-button"
                        onClick={handleCopyBody}
                      >
                        <FontAwesomeIcon icon={`fa fa-copy`} ref={bodyTarget} />
                        <Overlay
                          target={bodyTarget.current}
                          show={bodyCopyTextShow}
                          placement="top"
                        >
                          {(props) => (
                            <Tooltip id="overlay-for-body-copy" {...props}>
                              Copied
                            </Tooltip>
                          )}
                        </Overlay>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default ApiSpecification;
