import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const DisplayBuilderKanbanLanes = (props) => {
  const [kanbanLanes, setKanbanLanes] = useState(props?.lanes);

  const defaultLane = {
    title: "",
    id: uuidv4(),
    style: {
      backgroundColor: "#f1f2f4",
      color: "#000000",
    },
  };

  useMemo(() => {
    if (props?.lanes) {
      setKanbanLanes(props?.lanes);
    } else {
      setKanbanLanes([defaultLane]);
    }
  }, []);

  useEffect(() => {
    if (kanbanLanes?.length) {
      props?.onChange(kanbanLanes);
    }
  }, [kanbanLanes]);

  const changeValue = (event, index) => {
    let laneList = [...kanbanLanes];
    let name = event.target.name;
    let value = event.target.value;
    laneList[index][name] = value;
    if (name === "laneBgColor") {
      laneList[index]["style"]["backgroundColor"] = value;
    }
    if (name === "laneColor") {
      laneList[index]["style"]["color"] = value;
    }
    setKanbanLanes(laneList);
  };

  // add and remove kanban lane
  const addNewLane = () => {
    let laneList = [...kanbanLanes];
    laneList = [...laneList, defaultLane];
    setKanbanLanes(laneList);
  };

  const removeLane = (index) => {
    let laneList = [...kanbanLanes];
    laneList = laneList.filter((_, i) => i !== index);
    setKanbanLanes(laneList);
  };

  return (
    <div>
      <h3>Kanban Lane List</h3>
      {kanbanLanes?.map((lane, index) => {
        return (
          <div
            className="d-flex justify-content-between mt-2 border p-2 rounded actionButtonContainer"
            key={lane?.id}
          >
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="col-md-4 col-sm-6 d-flex m-2 align-items-center">
                  <label className="form-label col-md-4 col-sm-4 p-0 mt-2">
                    Lane Name
                  </label>
                  <div className="col-md-8 col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Enter Lane Name"
                      name="title"
                      onChange={(e) => {
                        changeValue(e, index);
                      }}
                      value={lane.title}
                    ></input>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 d-flex m-2 align-items-center">
                  <label className="form-label col-md-6 col-sm-6 p-0 mt-2">
                    Lane Background Color
                  </label>
                  <div className="col-md-6 col-sm-6 d-flex align-items-center">
                    <input
                      type="color"
                      className="form-control form-control-color form-control-sm me-2"
                      name="laneBgColor"
                      onChange={(e) => {
                        changeValue(e, index);
                      }}
                      value={
                        lane?.laneBgColor || defaultLane.style.backgroundColor
                      }
                    />
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Enter color code"
                      name="laneBgColor"
                      onChange={(e) => {
                        changeValue(e, index);
                      }}
                      value={
                        lane?.laneBgColor || defaultLane.style.backgroundColor
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 d-flex m-2 align-items-center">
                  <label className="form-label col-md-6 col-sm-6 p-0 mt-2">
                    Lane Header Color
                  </label>
                  <div className="col-md-6 col-sm-6 d-flex align-items-center">
                    <input
                      type="color"
                      className="form-control form-control-color form-control-sm me-2"
                      name="laneColor"
                      onChange={(e) => {
                        changeValue(e, index);
                      }}
                      value={lane?.laneColor || defaultLane.style.color}
                    />
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Enter color code"
                      name="laneColor"
                      onChange={(e) => {
                        changeValue(e, index);
                      }}
                      value={lane?.laneColor || defaultLane.style.color}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-2 d-flex justify-content-center flex-wrap align-items-center flex-column">
              <FontAwesomeIcon
                icon={"fa fa-plus-circle"}
                className="pointer text-success add_remove_size m-1"
                aria-hidden="true"
                onClick={addNewLane}
                title="Add Lane"
              />
              {kanbanLanes?.length > 1 && (
                <FontAwesomeIcon
                  icon={"fa fa-minus-circle"}
                  className="pointer text-danger add_remove_size m-1"
                  aria-hidden="true"
                  onClick={() => removeLane(index)}
                  title="Remove Lane"
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DisplayBuilderKanbanLanes;