import React, { Component } from "react";
import { toast } from "react-toastify";
import "./AppList.scss";
import Loader from "../../dataset/Loader/Loader";
import { Link } from "react-router-dom";
import {
  fetachAllApps,
  installNewApp,
  uninstallNewApp,
} from "../../../services/appService";
import { Pagination } from "../../dataset/pagination/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class AppList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      isWrite: false,

      getInstallApplicationHeader: [
        "Sr No",
        "App Name",
        "Version",
        "Created By",
        "Updated By",
        "Logo",
        "Status",
        "Actions",
      ],
      getInstallAppsRecord: [],
      totalRecordCount: 0,
      currentPage: 1,
      dataPerPage: 20,
      loading: true,
      errorMsg: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.getAppsData();
    this.getPrivilages();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getPrivilages = () => {
    if (this.state.userInfo.privileges) {
      const { app_previleges, data_previleges, standard_privileges } =
        this.state.userInfo.privileges;
      standard_privileges.forEach((data, index) => {
        if (data["Available Apps"]) {
          this.setState({ isWrite: data["Available Apps"]?.write });
        }
      });
    } else {
      this.setState({ isWrite: true });
    }
  };
  receiveCurrentPageNumber = (langValue) => {
    this.setState({ currentPage: langValue });
  };

  getAppsData() {
    this.setState({ loading: true });
    fetachAllApps()
      .then((response) => {
        if (this.mounted) {
          if (response.success === true) {
            this.setState({
              getInstallAppsRecord: response.data,
              totalRecordCount: response.total_count,
              loading: false,
            });
          } else {
            toast.error(response?.data?.message || response?.message);
          }
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: true, loading: false }, () => {
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        });
      });
  }

  installData(currentData) {
    let objInstall = {
      app_id: [currentData.srno],
    };
    installNewApp(objInstall)
      .then((response) => {
        if (response.success === true) {
          toast.success("App Installed Successfully");
          this.getAppsData();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  }

  uninstallData(currentData) {
    let objUninstall = {
      app_id: [currentData.srno],
    };
    uninstallNewApp(objUninstall)
      .then((response) => {
        if (response.success === true) {
          toast.success("App Uninstalled Successfully");
          this.getAppsData();
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  }

  render() {
    const { currentPage, dataPerPage } = this.state;
    const indexOfLastRecord = currentPage * dataPerPage;
    const indexOfFirstRecord = indexOfLastRecord - dataPerPage;
    const currentData = this.state.getInstallAppsRecord.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    const setInstallAppsHeaderData = this.state.isWrite
      ? this.state.getInstallApplicationHeader.map((header, index) => (
          <th className="text-nowrap" key={index}>
            {header}
          </th>
        ))
      : this.state.getInstallApplicationHeader
          .filter((header) => header !== "Actions")
          .map((header, index) => (
            <th className="text-nowrap" key={index}>
              {header}
            </th>
          ));

    const setInstallAppsRecordsData = currentData.map((data, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{data.app_name}</td>
        <td>{data.app_version}</td>
        <td>{data.created_by}</td>
        <td>{data.updated_by}</td>
        <td>
          {data.data_file ? (
            <div className="appLogo">
              <img className="img-fluid" alt="logo" src={data.data_file}></img>
            </div>
          ) : null}
        </td>
        <td>{data.status}</td>
        {this.state.isWrite ? (
          <td className="text-nowrap">
            <div className="d-flex align-items-center">
              {data.installed === "yes" ? (
                <>
                  <div className="installbtn">
                    <button
                      type="button"
                      className="uninstall-btn me-3"
                      onClick={(event) => this.uninstallData(data)}
                    >
                      Uninstall
                    </button>
                  </div>
                </>
              ) : (
                <div className="installbtn">
                  <button
                    type="button"
                    className="install-btn me-3 px-3"
                    onClick={(event) => this.installData(data)}
                  >
                    &nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-cloud-arrow-down" />
                    &nbsp;&nbsp;Install&nbsp;&nbsp;
                  </button>
                </div>
              )}
              <div className="action-icons">
                <Link to={`create_app/${data._id.$oid}`}>
                  <FontAwesomeIcon
                    icon={"fa fa-pen-to-square"}
                    className="me-2"
                    role="button"
                    size="lg"
                  />
                </Link>
              </div>
            </div>
          </td>
        ) : null}
      </tr>
    ));
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div>
        <div className="d-help py-2 flex-wrap">
          <h5 className="m-0">Available Apps</h5>

          <div className="d-flex">
            <div className="input-group input-group-sm mb-0 me-2">
              <span className="input-group-text" id="basic-addon1">
                <FontAwesomeIcon icon={"fa fa-search"} />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Search By Name"
              />
            </div>
            <div className="d-flex">
              {this.state.isWrite ? (
                <Link to={"create-app"}>
                  <button
                    type="button"
                    className="btn btn-success btn-md m-0 text-nowrap"
                  >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                    &nbsp;&nbsp;Create New App&nbsp;&nbsp;
                  </button>
                </Link>
              ) : null}

              <button
                type="button"
                className="my-auto btn btn-secondary btn-sm ms-2 me-0 text-nowrap"
                onClick={() => this.props?.history?.goBack()}
              >
                <FontAwesomeIcon icon="fa fa-arrow-left" />
                <span className="ms-1">Back</span>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="card-box">
              <div
                id="datatable-checkbox_wrapper"
                className="dataTables_wrapper container-fluid dt-bootstrap no-footer p-0"
              >
                <div className="row">
                  <div className="col-md-12 col-sm-12 set_height">
                    <div className="table-responsive table-hover">
                      <table className="table  table-bordered bulk_action appsTable no-footer w-100 m-0">
                        <thead>
                          <tr className="head-row">
                            {setInstallAppsHeaderData}
                          </tr>
                        </thead>
                        <tbody>{setInstallAppsRecordsData}</tbody>
                      </table>
                      {this.state.errorMsg === true ? (
                        <p className="no_table_records">Internal issue ...</p>
                      ) : this.state.errorMsg === false &&
                        this.state.totalRecordCount === 0 ? (
                        <p className="no_table_records">
                          No matching records found
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                {this.state.totalRecordCount >= 1 ? (
                  <Pagination
                    sendRowDataLength={this.state.getInstallAppsRecord.length}
                    onSelectLanguage={this.receiveCurrentPageNumber}
                  ></Pagination>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppList;
