import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchInstallAppData,
  getFlowsByAppId,
} from "../../../../services/appService";
import { displayTransition } from "../../../common/model/Model";
import { IconDropdown } from "../display_builder/IconDropdown";
import { Form } from "react-bootstrap";

function DisplayTransition({ value, onChange, transitionTitle }) {
  let {
    transitionActionTitle,
    transitionAppId,
    transitionPageId,
    transitionSelectedIcon,
    transitionType,
  } = value;

  let changeData = {
    transitionActionTitle,
    transitionAppId,
    transitionPageId,
    transitionSelectedIcon,
    transitionType,
  };

  const [appList, setAppList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [objectChange, setObjectChange] = useState(changeData || {});

  const [flowLoader, setFlowLoader] = useState(false);
  const [appLoader, setAppLoader] = useState(false);

  useEffect(() => {
    getAllInstalledApps();
    setObjectChange(changeData);
  }, []);

  useEffect(() => {
    if (objectChange?.transitionAppId) {
      getFlowById(objectChange?.transitionAppId);
    }
  }, [objectChange?.transitionAppId]);

  // fetch app details
  const getAllInstalledApps = () => {
    setAppLoader(true);
    fetchInstallAppData()
      .then((response) => {
        if (response.data.length) {
          setAppList(response.data);
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => {
        setAppLoader(false);
      });
  };

  // fetch flow Details
  const getFlowById = (appid) => {
    setFlowLoader(true);
    setPageList([]);
    getFlowsByAppId(appid)
      .then((response) => {
        if (response.data.length) {
          setPageList(response.data);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => {
        setFlowLoader(false);
      });
  };

  const handleChange = (e) => {
    let objectData = { ...objectChange };
    let name = e.target.name;
    let value = e.target.value;
    objectData[name] = value;
    setObjectChange(objectData);
    onChange(objectData);
  };

  const handleIconChange = (e) => {
    let objectData = { ...objectChange };
    objectData["transitionSelectedIcon"] = e;
    setObjectChange(objectData);
    onChange(objectData);
  };

  const displayTransitionTypeList = [
    { name: "Go To Page", value: displayTransition.goToPage },
    { name: "Go To Another App", value: displayTransition.goToAnotherApp },
  ];

  // transitions dropdown
  const displayTransitionList = displayTransitionTypeList.map((data, index) => (
    <option key={index} value={data.value}>
      {data.name}
    </option>
  ));

  //   app list dropdown
  const displayAppList = appList.map((app) => (
    <option key={app._id.$oid} value={app._id.$oid}>
      {app.app_name}
    </option>
  ));
  //   flowList dropdown
  const displayFlowList = pageList.map((page) => (
    <option key={page._id.$oid} value={page._id.$oid}>
      {page.function_name}
    </option>
  ));
  return (
    <>
      <h5>{transitionTitle}</h5>
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <label className="col-form-label">Transition Type</label>
          <Form.Select
            className="form-control form-select-sm"
            value={objectChange?.transitionType}
            onChange={handleChange}
            name="transitionType"
          >
            <option value="">Please select</option>
            {displayTransitionList}
          </Form.Select>
        </div>
        <div className="col-md-4 col-sm-6">
          <label className="col-form-label ">Select App</label>
          <Form.Select
            className="form-control form-select-sm"
            value={objectChange?.transitionAppId}
            onChange={handleChange}
            name="transitionAppId"
          >
            <option value="">{appLoader ? "Loading.." : "Select"}</option>
            {displayAppList}
          </Form.Select>
        </div>

        {objectChange?.transitionAppId && (
          <div className="col-md-4 col-sm-6">
            <div className="col-form-label">Select Page</div>
            <Form.Select
              className="form-control form-select-sm"
              value={objectChange?.transitionPageId}
              onChange={handleChange}
              name="transitionPageId"
            >
              <option value="">{flowLoader ? "Loading.." : "Select"}</option>
              {displayFlowList}
            </Form.Select>
          </div>
        )}

        {transitionTitle === "Display Transition" ? (
          <>
            <div className="col-md-4 col-sm-6">
              <div className="col-form-label">Action Title</div>
              <input
                className="form-control form-control-sm"
                value={objectChange?.transitionActionTitle}
                onChange={handleChange}
                name="transitionActionTitle"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="col-form-label">Icon</div>
              <IconDropdown
                value={objectChange?.transitionSelectedIcon}
                onChange={handleIconChange}
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default DisplayTransition;
