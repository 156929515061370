import React, { useEffect, useMemo, useState } from "react";
import { formPagesList } from "../../../../services/appService";
import Select from "react-select";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";


const filterFormPositions = [
  {
    label: "top",
    value: "top",
  },
  {
    label: "left",
    value: "left",
  },
  {
    label: "right",
    value: "right",
  },
];

const DisplayBuilderStepperForm = (props) => {
  const [displayChanges, setDisplayChanges] = useState(
    props?.displayFilterFormObj
  );
  const [columnWidthList, setcolumnWidthList] = useState(
    props?.columnWidthList
  );
  const [selectedFilterForm, setSelectedFilterForm] = useState([]);
  const [filterFormListOptions, setFilterFormListOptions] = useState([]);
  const initialFormPosition = props?.displayFilterFormObj?.formPosition
    ? filterFormPositions.find(
        (form) =>
          form.value === props?.displayFilterFormObj?.formPosition && form
      )
    : null;
  const [formPosition, setFormPosition] = useState(initialFormPosition);
  const [formPositionOptions] = useState(filterFormPositions);

  useMemo(() => {
    getAllFormList();
    setDisplayChanges(displayChanges);
  }, []);

  useEffect(() => {
    props.onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const handleSelectChange = (name, selectedOpt) => {
    const selectedValues = selectedOpt ? selectedOpt.map((opt) => opt.value) : [];
    const selectedLabels = selectedOpt ? selectedOpt.map((opt) => opt.label) : [];
  
    if (name === "stepperForm") {
      setSelectedFilterForm(selectedOpt || []);
    }
  
    // Update both stepperForm and stepperFormName
    setDisplayChanges((prevState) => ({
      ...prevState,
      stepperForm: selectedValues,
      stepperFormName: selectedLabels,
    }));
  };
  
  

  function getAllFormList() {
    let bodyObj = {
      FilterCriteria: [
        {
          filter_type: "text",
          type: "equal",
          filter: "form_component",
          filter_field: "page_type",
        },
      ],
      export: true,
    };
    formPagesList(bodyObj)
      .then((response) => {
        if (response?.success) {
          const filterFormList = response?.data.map((form) => {
            return {
              label: form.function_name,
              value: form._id.$oid,
            };
          });
          setFilterFormListOptions(filterFormList);
          setSelectedFilterForm(
            filterFormList.filter((form) => {
              return displayChanges?.stepperForm?.includes(form.value);
            })
          );
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  }

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Form Header</label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.formHeader?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter Form Header"
            name="formHeader"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.formHeader}
          ></input>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2" htmlFor="filterForm">
          Select Form/s
        </label>
        <div>
          <Select
            options={filterFormListOptions}
            isClearable
            isMulti
            inputId="stepperForm"
            name="stepperForm"
            value={selectedFilterForm}
            onChange={(e) => {
              handleSelectChange("stepperForm", e);
            }}
            menuPosition="fixed"
            classNames={{
              control: (state) => 'searchableDropdownControl',
              menuPortal: () => 'searchableDropdownMenuPortal',
              singleValue: () => 'searchableDropdownValue',
              multiValue: () => 'searchableDropdownValue',
              menuList: () => 'searchableDropdownMenuList'
            }}
          />
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Step Labels</label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.formSteps?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter , separated labels for the steps"
            name="formSteps"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.formSteps}
          ></input>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Column Width</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.columenWidth}
            onChange={(e) => {
              changeValue(e);
            }}
            name="columenWidth"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>
    </>
  );
};


export default DisplayBuilderStepperForm;