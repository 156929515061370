import React, { useEffect, useMemo, useState } from "react";
import { IconDropdown } from "./IconDropdown";
import ReactFileReader from "react-file-reader";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import Select from "react-select";
import {
  autoReloadComponentDelaysList,
  displayFormatList,
} from "../../../common/model/Model";

const DisplayBuilderCard = ({
  logicList,
  displayBuilder,
  columnWidthList,
  onChange,
  selectFormList,
}) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);
  useEffect(() => {
    setDisplayChanges((prev) => {
      const updatedState = { ...prev, ...displayBuilder };
      updatedState.refValue = updatedState.refValue || "response"; 
      return JSON.stringify(updatedState) !== JSON.stringify(prev) ? updatedState : prev;
    });
  }, []);

  useEffect(() => {
    onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const iconChange = (e) => {
    const data = { ...displayChanges };
    data["selectedIcon"] = e;
    setDisplayChanges(data);
  };

  // get files from button
  const handleFiles = (files) => {
    let data = { ...displayChanges };
    if (files.fileList[0].size >= 500000) {
      toast.error("File size must be less than 500kb.");
    } else {
      data.uploadFile = files.base64;
      setDisplayChanges(data);
    }
  };

  const searchableValueChange = (event, key) => {
    const data = { ...displayChanges };
    let value = event?.label;
    data[key] = value || "";
    setDisplayChanges(data);
  };

  const checkBoxChangeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.checked;
    data[name] = value;
    setDisplayChanges(data);
  };

  const displayFormatListOption = useMemo(() => {
    return displayFormatList?.map((data, index) => (
      <option key={index} value={data.value}>
        {data.label}
      </option>
    ));
  }, []);

  const autoReloadDelays = useMemo(() => 
  autoReloadComponentDelaysList?.map((data, index) => (
    <option key={index} value={data.value}>
      {data.label}
    </option>
  )),[]);

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm`}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12">
        <label className="form-label p-0 mt-2">Component Logic</label>
        <div>
          <Select
            placeholder="Select.."
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === displayChanges?.componentLogic
            )}
            onChange={(e) => searchableValueChange(e, "componentLogic")}
            isClearable
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Column Width</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges.card}
            onChange={(e) => {
              changeValue(e);
            }}
            name="card"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Select Form Page</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.formId || ""}
            onChange={(e) => {
              changeValue(e);
            }}
            name="formId"
          >
            <option value="">Select</option>
            {selectFormList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Counter Key</label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="data attribute"
            name="dataAttribute"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.dataAttribute}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Display Keys</label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Enter Keys"
            name="displayKeys"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.displayKeys}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Display Attribute
        </label>
        <div>
          <input
            type="text"
            className={`form-control form-control-sm`}
            placeholder="Display Attribute"
            name="displayAttribute"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.displayAttribute}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Icon</label>
        <div>
          <IconDropdown
            value={displayChanges?.selectedIcon || ""}
            onChange={(e) => {
              iconChange(e);
            }}
          ></IconDropdown>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Icon Color</label>
        <div>
          <input
            type="color"
            placeholder="Display Attribute"
            name="iconColor"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.iconColor || "#000000"}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Upload File</label>
        <div>
          <div className="d-inline-block">
            <ReactFileReader
              fileTypes={[".jpg", ".png", ".gif"]}
              handleFiles={(e) => handleFiles(e)}
              multipleFiles={false}
              base64={true}
            >
              <button className="btn btn-primary btn-md">Upload File</button>
              {displayChanges.uploadFile ? (
                <span>
                  <img
                    src={displayChanges.uploadFile}
                    alt="file-img"
                    height={"40"}
                    width={"40"}
                  />
                </span>
              ) : null}
            </ReactFileReader>
          </div>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Display Currency Format</label>
        <div className="col-md-9 col-sm-9 ">
          <Form.Select
            className="form-control"
            name="displayCurrencyFormat"
            value={displayChanges.displayCurrencyFormat || ""}
            onChange={(e) => {
              changeValue(e);
            }}
          >
            <option value="">--Select--</option>
            {displayFormatListOption}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <div className="col-md-9 col-sm-9 ">
          <label className="form-label p-0 mt-2">
            <input
              className="me-2"
              type="checkbox"
              onChange={(e) => {
                checkBoxChangeValue(e);
              }}
              name="autoReloadComponent"
              checked={displayChanges?.autoReloadComponent || false}
            />
            Auto reload
          </label>
        </div>
        {displayChanges.autoReloadComponent && (
          <div className="col-md-9 col-sm-9 ">
            <label className="form-label p-0 mt-2">Select delay</label>
            <Form.Select
              className="form-control"
              name="autoReloadDelay"
              value={displayChanges.autoReloadDelay}
              onChange={(e) => {
                changeValue(e);
              }}
            >
              <option value="">--Select--</option>
              {autoReloadDelays}
            </Form.Select>
          </div>
        )}
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <div className="d-flex">
          <input
            className="me-2"
            type="checkbox"
            onChange={(e) => {
              checkBoxChangeValue(e);
            }}
            name="isCacheEnabled"
            checked={displayChanges?.isCacheEnabled}
          />
          <label className="form-label p-0 mt-2">Enable Cache</label>
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderCard;
