import React from "react";
import { Modal } from "react-bootstrap";
import {
  assigneeReporterEvaluator,
  backgroundEvaluator,
} from "./kanbanCardUtils";

function KanbanCardDetailedView({
  boardData,
  card,
  displayData,
  hideDetailedKanbanCardModal,
  lane,
  showDetailedKanbanCard,
}) {
  if (!card || !displayData?.displayFields) {
    return null;
  }

  // Filter visible fields and chunk into pairs for 4-column layout
  const visibleFields = displayData.displayFields.filter(
    (field) => field.is_hidden === false && card.hasOwnProperty(field.value)
  );

  // Function to chunk array into pairs
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const fieldPairs = chunkArray(visibleFields, 2);

  // Function to safely render HTML or text
  const renderContent = (content) => {
    if (Array.isArray(content)) {
      return content.map((item, index) => {
        const isAssigneeOrReporter = visibleFields.some(
          (f) =>
            (f.value === "assignees" || f.value === "reporter") &&
            card[f.value] === content
        );

        if (isAssigneeOrReporter) {
          const name = assigneeReporterEvaluator(item);
          const bgParam =
            boardData?.laneOrder?.indexOf(lane?.id) + index || index;
          const backgroundColor = backgroundEvaluator(bgParam, item);
          return (
            <div
              key={index}
              className={`bg-${backgroundColor} text-white rounded-circle d-flex align-items-center justify-content-center me-2 kanban-card-circle`}
              title={`${item}`}
            >
              {name}
            </div>
          );
        }
        return (
          <div
            key={index}
            className="bg-dark-subtle text-dark rounded p-1 px-2 m-1"
          >
            {item}
          </div>
        );
      });
    }

    // If string contains HTML tags, render as HTML, otherwise as text
    if (typeof content === "string" && /<[^>]+>/.test(content)) {
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }
    return <div>{content}</div>;
  };

  return (
    <Modal
      show={showDetailedKanbanCard}
      onHide={hideDetailedKanbanCardModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {card[displayData?.key_mapping?.label] || "Card Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid px-4">
          {fieldPairs.map((pair, rowIndex) => (
            <div
              className="row mb-3 align-items-center text-break"
              key={rowIndex}
            >
              {pair.map((field, colIndex) => (
                <React.Fragment key={field.value}>
                  <div className="col-2 text-start fw-bold pe-2">
                    {field.label}:
                  </div>
                  <div className="col-4">
                    {renderContent(card[field.value])}
                  </div>
                  {/* Add empty columns if this is the last item in an odd-length array */}
                  {pair.length === 1 && colIndex === 0 && (
                    <>
                      <div className="col-2"></div>
                      <div className="col-4"></div>
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* Add footer content if needed */}
      </Modal.Footer>
    </Modal>
  );
}

export default KanbanCardDetailedView;