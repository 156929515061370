import { React, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../header/Header.scss";
import dummyImg from "../../../../images/dummy-header-img.png";
import "./StandardLayout.scss";
import { Button, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TenantInformation from "../../TenantInformation";
import {
  addToCartList,
  getCartConfigDetails,
} from "../../../../services/appService";
import { toast } from "react-toastify";
import { RoutesContext } from "../../../../RoutesContext";
import SearchBar from "../../../search/SearchBar";
import {checkImageExists, getIconImage} from "../../../../services/utils";

function StandardLayoutHeader() {
  const { routesData } = useContext(RoutesContext);
  const [viewCartList, setViewCartList] = useState([]);
  const [logoImage] = useState(JSON.parse(localStorage.getItem("logoImage")));
  const user = JSON.parse(localStorage.getItem("user-info"))
    ? JSON.parse(localStorage.getItem("user-info"))
    : "";
  const query_data = JSON.parse(localStorage.getItem("query_param"));
  const isAdmin = sessionStorage.getItem("admin-info");
  const isRedirecting = JSON.parse(localStorage.getItem("isRedirecting"));
  const history = useHistory();
  const currentTheme = localStorage.getItem("currentTheme");
  const setCurrentTheme = (themeName) => {
    let html = document.getElementsByTagName("html")[0];
    if (
      themeName &&
      themeName !== "" &&
      typeof themeName != "undefined" &&
      themeName !== "undefined"
    ) {
      html.className = themeName;
      localStorage.setItem("currentTheme", themeName);
    }
  };
  const switchTheme = () => {
    let html = document.getElementsByTagName("html")[0];
    if (html.className === "default") {
      setCurrentTheme("dark");
    } else {
      setCurrentTheme("default");
    }
  };
  setCurrentTheme(currentTheme);

  const loggingOut = (e) => {
    if (isAdmin) {
      sessionStorage.clear();
      localStorage.clear();
      history.push("/dmadmin?tenant_name=flashcorp");
    } else {
      localStorage.clear();
      history.push(`/login?tenant_name=${query_data}`);
    }
  };

  useEffect(() => {
    getAllCartItems();
  }, [routesData?.cartChange]);

  const getAllCartItems = () => {
    addToCartList()
      .then((response) => {
        if (response?.success) {
          let cartData = response?.data;
          setViewCartList(cartData?.product_data || []);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      });
  };

  let userName = user?.Name?.split(" ");
  let firstnameInitial = userName?.[0].charAt(0).toUpperCase();
  let lastName = userName?.[1];
  let lastnameInitial = lastName?.charAt(0).toUpperCase();

  return (
    <div className={`top_nav header`}>
      <div className="nav_menu m-0">
        <nav
          className={`standard-nav standard-navbar-nav ${
            isRedirecting && "d-none"
          }`}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="m-0 p-0">
              <div className="navbar nav_title">
                <div className="fc-logo">
                  {checkImageExists(logoImage) ? (
                    <img src={getIconImage(logoImage)} alt="FC"/>
                  ) : (
                    <TenantInformation />
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center header-dropdown">
              <SearchBar />
              <Link
                to={"/cartlist"}
                className="companyProfileHeaderLink mx-2 position-relative p-1"
                title="Cart List"
              >
                <FontAwesomeIcon
                  icon={"shopping-cart"}
                  className="companyProfileHeaderIcon"
                />
                {viewCartList?.length ? (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {viewCartList?.length}
                  </span>
                ) : null}
              </Link>
              <Button
                onClick={switchTheme}
                className="switchThemeHeaderLink p-1"
                variant="link"
                size="lg"
                title="Switch Theme"
              >
                <FontAwesomeIcon
                  icon={"fa-solid fa-palette"}
                  className="switchThemeHeaderIcon"
                />
              </Button>
              <NavDropdown
                className="me-4 userProfileHeaderDropdown"
                title={
                  user.profile_image ? (
                    <img
                      title={`${userName?.[0]} ${userName?.[1]}`}
                      src={
                        user.profile_image === undefined ||
                        user.profile_image === ""
                          ? dummyImg
                          : user.profile_image
                      }
                      alt="Profile"
                      className="user_logo"
                    />
                  ) : (
                    <div
                      className="user_logo"
                      title={`${userName?.[0]} ${userName?.[1]}`}
                    >
                      <div className="user_logo_text">
                        {firstnameInitial}
                        {lastnameInitial}
                      </div>
                    </div>
                  )
                }
                id="nav-dropdown"
              >
                <NavDropdown.Item eventKey="4.1">
                  <Link
                    className="dropdown-item helpButton"
                    to={`/user_profile`}
                  >
                    <FontAwesomeIcon icon={"fa fa-user"} />
                    <span className="ms-2">User Profile</span>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="4.2">
                  <Link className="dropdown-item helpButton" to={"/help_page"}>
                    <FontAwesomeIcon icon={"fa fa-handshake"} />
                    <span className="ms-2">Help</span>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="4.3">
                  <button
                    className="dropdown-item"
                    onClick={(e) => loggingOut()}
                  >
                    <FontAwesomeIcon icon={"fa fa-sign-out"} />
                    <span className="ms-2">Log Out</span>
                  </button>
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default StandardLayoutHeader;
