import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

const DisplayBuilderEchart = ({
  logicList,
  displayBuilder,
  columnWidthList,
  onChange,
}) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);
  const [typeOfEchart, setTypeOfEhart] = useState();  
  useEffect(() => {
    setDisplayChanges((prev) => {
      const updatedState = { ...prev, ...displayBuilder };
      updatedState.refValue = updatedState.refValue || "response"; 
      return JSON.stringify(updatedState) !== JSON.stringify(prev) ? updatedState : prev;
    });
  }, []);

  useEffect(() => {
    onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const searchableValueChange = (event, key) => {
    const data = { ...displayChanges };
    let value = event?.label;
    data[key] = value || "";
    setDisplayChanges(data);
  };
  const echartTypeChange = (event, key) => {
    const data = { ...displayChanges };
    let value = event;
    data[key] = value || "";
    setDisplayChanges(data);
  };
  const checkBoxChangeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.checked;
    data[name] = value;
    setDisplayChanges(data);
  };

  const optionsOfEcharts = [
    { label: "Bar", value: "bar" },
    { label: "Stacked Bar", value: "stackedBar" },
    { label: "Pie", value: "pie" },
    { label: "Radar", value: "radar" },
    { label: "Doughnut", value: "doughnut" },
    { label: "Line", value: "line" },
    { label: "Smooth Line", value: "line" },
    { label: "Area Line", value: "line" },
    { label: "Step Line", value: "stepLine" },
    { label: "Stacked Line", value: "stackedLine" },
    { label: "Stacked Area Line", value: "stackedLine" },
    { label: "Stacked Horizontal Bar", value: "stackedHorizontalBar" },
    {
      label: "Horizontal Bar with Negative",
      value: "HorizontalBarwithNegative",
    },
    { label: "Polar", value: "polar" },
    { label: "Log Axis", value: "logAxis" },
    { label: "Line Style", value: "lineStyle" },
  ];

  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Title</label>
        <div>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Enter value"
            name="echartTitle"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.echartTitle}
          ></input>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">Select Type</label>
        <div>
          <Select
            placeholder="Select.."
            options={optionsOfEcharts}
            value={displayChanges?.echartType}
            onChange={(e) => echartTypeChange(e, "echartType")}
            isClearable
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control form-control-sm `}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12">
        <label className="form-label p-0 mt-2">Component Logic</label>
        <div>
          <Select
            classNamePrefix={"react-select"}
            placeholder="Select.."
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === displayChanges?.componentLogic
            )}
            onChange={(e) => searchableValueChange(e, "componentLogic")}
            isClearable
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Column Width</label>
        <div>
          <Form.Select
            size="sm"
            className="form-control"
            value={displayChanges?.columenWidth}
            onChange={(e) => {
              changeValue(e);
            }}
            name="columenWidth"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <div className="d-flex">
          <input
            className="me-2"
            type="checkbox"
            onChange={(e) => {
              checkBoxChangeValue(e);
            }}
            name="isCacheEnabled"
            checked={displayChanges?.isCacheEnabled}
          />
          <label className="form-label p-0 mt-2">Enable Cache</label>
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderEchart;
