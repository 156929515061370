import Select from "react-select";
import React, { useEffect, useState } from "react";
import DisplayTransition from "../../dataset_editor/dataset_structure/display_transition/DisplayTransition";
import { v4 as uuID } from "uuid";
import {
  actionTypes,
  actionTypesList,
  fileType,
  fileTypeList,
} from "../../common/model/Model";
import { Collapse, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchAllDataSets } from "../../../services/datasetService";
import { toast } from "react-toastify";
import { IconDropdown } from "../../dataset_editor/dataset_structure/display_builder/IconDropdown";

const ActionButtonList = (props) => {
  const [actionButtonList, setActionButtonList] = useState(props.actionList);
  const [datasets, setDatasets] = useState([]);
  const [actionCollapse, setActionCollapse] = useState(false);
  const buttonPlacementOn = ["Table", "Header"];
  const buttonPrivilege = ["Yes", "No"];
  const initialButtons = ["Update", "View", "Clone"];
  const actionTypesListData = actionTypesList;

  useEffect(() => {
    setActionButtonList(props.actionList);
    getDatasetList();
  }, []);

  useEffect(() => {
    if (actionButtonList?.length) {
      props?.onChange(actionButtonList);
    }
  }, [actionButtonList]);

  const buttonPlacementList = buttonPlacementOn.map((data, index) => (
    <option key={index} value={data}>
      {data}
    </option>
  ));

  const updateViewPrivilege = buttonPrivilege.map((data, index) => (
    <option key={index} value={data}>
      {data}
    </option>
  ));

  const actionTypeList = actionTypesListData.map((actionType, index) => (
    <option key={index} value={actionType.value}>
      {actionType.label}
    </option>
  ));

  const changeValue = (event, index) => {
    let name = event.target.name;
    let value = event.target.value;
    if(name === "actionName" && (value === "View" || value === "Update" || value === "Clone")){
      toast.error("Please do not use system reserved names: 'View','Update' or 'Clone'");
      return;
    }
    let actionList = [...actionButtonList];
    actionList[index][name] = value;
    setActionButtonList(actionList);
  };

  const rootActionTypeChange = (e, index) => {
    let value = e.target.value;
    if (
      [
        actionTypes.fileUpload,
        actionTypes.downloadLink,
        actionTypes.downloadXml,
        actionTypes.addForm,
        actionTypes.export,
        actionTypes.import,
        actionTypes.importDataset,
      ].includes(value)
    ) {
      onChangeAnotherValue(index, {
        transitionAppId: "",
        transitionPageId: "",
        transitionType: "",
        buttonPlacement: "Header",
        actionType: value,
      });
    } else if ([actionTypes.popUp].includes(value)) {
      onChangeAnotherValue(index, {
        transitionAppId: "",
        transitionPageId: "",
        transitionType: "",
        buttonPlacement: "Table",
        actionType: value,
      });
    } else {
      changeValue(e, index);
    }
  };

  const onChangeAnotherValue = (index, changeObj) => {
    let actionList = [...actionButtonList];
    actionList[index] = {
      ...actionList[index],
      ...changeObj,
    };
    setActionButtonList(actionList);
  };

  const searchableValueChange = (event, index) => {
    let actionList = [...actionButtonList];
    actionList[index]["logicNameId"] = event?.value || "";
    actionList[index]["logicNameValue"] = event?.label || "";
    setActionButtonList(actionList);
  };

  const onDatasetSelectHandler = (event, index) => {
    let actionList = [...actionButtonList];
    actionList[index]["datasetId"] = event?.value || "";
    actionList[index]["datasetName"] = event?.label || "";
    setActionButtonList(actionList);
  };

  const searchableSelectChange = (changeValue, key, index) => {
    let actionList = [...actionButtonList];
    actionList[index][key] = changeValue;
    setActionButtonList(actionList);
  };

  // add new table action
  const addNewAction = () => {
    let obj = [
      {
        actionName: "",
        referenceValue: "",
        logicNameId: "",
        logicNameValue: "",
        buttonPlacement: "Table",
        buttonColor: "#000000",
        actionType: actionTypes.button,
        action_id: uuID(),
      },
    ];
    let actionList = [...actionButtonList];
    actionList = [...actionList, ...obj];
    setActionButtonList(actionList);
  };

  const removeAction = (index) => {
    let actionList = [...actionButtonList];
    actionList = actionList.filter((_, i) => i !== index);
    setActionButtonList(actionList);
  };

  let formOptionList = props?.formsList?.map((form) => {
    return {
      label: form?.function_name,
      value: form?._id?.$oid,
    };
  });

  const getDatasetList = () => {
    let obj = {
      SortCriteria: [
        {
          sort_field: "name",
          sort_type: "asc",
        },
      ],
    };
    fetchAllDataSets(obj)
      .then((response) => {
        if (response.success === true) {
          let datasetObj = response?.data?.map((item) => ({
            label: item.name,
            value: item._id.$oid,
          }));
          setDatasets(datasetObj);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  };

  return (
    <div>
      <div className="d-help border-bottom p-2">
        <div className="me-2 fw-semibold fs-6">
          Action List
          {actionButtonList?.length > 0 && ` (${actionButtonList?.length})`}
        </div>
        <div>
          <FontAwesomeIcon
            icon={"fa fa-plus-circle"}
            className="pointer text-success add_remove_size mx-2"
            aria-hidden="true"
            onClick={() => addNewAction()}
            title="Add New Action Button"
          />

          <FontAwesomeIcon
            onClick={() => setActionCollapse(!actionCollapse)}
            icon={"fa fa-angle-down"}
            className={`iconTransition ${actionCollapse ? "arrow-icon-up" : "arrow-icon-down"
              }`}
          />
        </div>
      </div>

      <Collapse in={actionCollapse} className="px-2">
        <div id="action-collapse">
          {actionButtonList?.map((action, index) => (
            <div
              className="my-2 border rounded actionButtonContainer position-relative overflow-hidden"
              key={action?.action_id}
            >
              <div className="row actionList p-2">
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                  <label className="form-label p-0">Action Name</label>
                  <div className="d-flex">
                    <Form.Control
                      className="flex-grow-1"
                      size="sm"
                      type="text"
                      name="actionName"
                      value={action.actionName}
                      placeholder="Enter Action Name"
                      onChange={(e) => {
                        changeValue(e, index)
                      }}
                      disabled={action.actionName === "View" || action.actionName === "Update" || action.actionName === "Clone"}
                    />
                    {
                      (action.actionName !== "View" && action.actionName !== "Update" && action.actionName !== "Clone") &&
                      <div className="d-flex">
                        <input
                          className="form-control form-control-color form-control-sm"
                          onChange={(e) => {
                            changeValue(e, index);
                          }}
                          type="color"
                          name="buttonColor"
                          value={action.buttonColor}
                        />
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          name="buttonColorHex"
                          value={action.buttonColor}
                          placeholder="Enter Hex Code"
                          onChange={(e) => {
                            let hexValue = e.target.value;
                            let updatedAction = [...actionButtonList];
                              updatedAction[index].buttonColor = hexValue;
                              setActionButtonList(updatedAction);      
                          }}
                        />
                      </div>
                    }
                  </div>
                </div>

                {!initialButtons.includes(action.actionName) ? (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                    <label className="form-label p-0">Action Type</label>
                    <div>
                      <Form.Select
                        size="sm"
                        className="form-control"
                        value={action?.actionType || actionTypes.popUp}
                        onChange={(e) => rootActionTypeChange(e, index)}
                        name="actionType"
                      >
                        <option value={""} disabled>
                          --Select--
                        </option>
                        {actionTypeList}
                      </Form.Select>
                    </div>
                  </div>
                ) : null}

                {[actionTypes.import].includes(action.actionType) && (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                    <label className="form-label p-0 text-nowrap">
                      File Type
                    </label>
                    <div>
                      <Form.Select
                        size="sm"
                        className="form-control"
                        onChange={(e) => {
                          changeValue(e, index);
                        }}
                        name="fileType"
                        value={action.fileType}
                      >
                        {fileTypeList.map((filetype, index) => {
                          return (
                            <option value={filetype.value}>
                              {filetype.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </div>
                )}

                {[fileType.xls, fileType.xlsx].includes(action.fileType) &&
                  [actionTypes.import].includes(action.actionType) && (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0 text-nowrap">
                        Sheet Name
                      </label>
                      <div>
                        <input
                          onChange={(e) => {
                            changeValue(e, index);
                          }}
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Enter Sheet,Sheet1,Sheet2..."
                          name="xlsxSheetName"
                          value={action.xlsxSheetName}
                        />
                      </div>
                    </div>
                  )}

                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                  <label className="form-label p-0">Select Logic</label>
                  <div>
                    <Select
                      menuPosition="fixed"
                      classNamePrefix={"react-select"}
                      placeholder="Select.."
                      options={props?.appLogicList}
                      value={
                        action?.logicNameValue
                          ? {
                            label: action?.logicNameValue,
                            value: action?.logicNameId,
                          }
                          : ""
                      }
                      onChange={(e) => {
                        searchableValueChange(e, index);
                      }}
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                  <label className="form-label p-0">Reference Value</label>
                  <div>
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Enter Reference Value"
                      name="referenceValue"
                      onChange={(e) => {
                        changeValue(e, index);
                      }}
                      value={action.referenceValue || "request"}
                    />
                  </div>
                </div>

                {(action?.actionType === actionTypes.timeLineView || action?.actionType === actionTypes.importDataset) && (
                  <>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0">
                        Select Dataset Name
                      </label>
                      <div>
                        <Select
                          menuPosition="fixed"
                          classNamePrefix={"react-select"}
                          placeholder="Select.."
                          options={datasets}
                          value={
                            action?.datasetName
                              ? {
                                label: action?.datasetName,
                                value: action?.datasetId,
                              }
                              : ""
                          }
                          onChange={(e) => {
                            onDatasetSelectHandler(e, index);
                          }}
                          isClearable
                        />
                      </div>
                    </div>
                  </>
                )}
                {(action?.actionType === actionTypes.timeLineView &&
                  <>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0">Timeline Heading</label>
                      <div>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Enter Timeline Name"
                          name="timeLineName"
                          onChange={(e) => {
                            changeValue(e, index);
                          }}
                          value={action.timeLineName}
                        />
                      </div>
                    </div>
                  </>
                )}

                {([actionTypes.addForm, actionTypes.export].includes(
                  action.actionType
                ) ||
                  action.actionName === "Update" || action.actionName === "Clone") && (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0">Select Form Page</label>
                      <div>
                        <Select
                          menuPosition="fixed"
                          classNamePrefix={"react-select"}
                          placeholder="Select.."
                          options={formOptionList}
                          value={
                            action?.selectedForm?.value
                              ? action?.selectedForm
                              : ""
                          }
                          onChange={(e) => {
                            searchableSelectChange(e, "selectedForm", index);
                          }}
                          isClearable
                        />
                      </div>
                    </div>
                  )}
                {action.actionName === "Update" ? (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                    <label className="form-label p-0 text-nowrap">
                      Show Comments Box
                    </label>
                    <div>
                      <Form.Select
                        size="sm"
                        className="form-control"
                        onChange={(e) => {
                          changeValue(e, index);
                        }}
                        name="showCommentBox"
                        value={action.showCommentBox}
                      >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                      </Form.Select>
                    </div>
                  </div>
                ) : null}

                {action?.actionType === actionTypes.button ||
                  action?.actionType === actionTypes.link ||
                  action?.actionType === undefined ? (
                  !initialButtons.includes(action.actionName) ? (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                      <label className="form-label p-0">Button Placement</label>
                      <div>
                        <Form.Select
                          size="sm"
                          className="form-control"
                          value={action.buttonPlacement}
                          onChange={(e) => {
                            changeValue(e, index);
                          }}
                          name="buttonPlacement"
                        >
                          {buttonPlacementList}
                        </Form.Select>
                      </div>
                    </div>
                  ) : null
                ) : null}
                {!initialButtons.includes(action.actionName) && (
                  <>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 form-group">
                      <label className="form-label p-0 textOverFlowEllipsis w-100 mb-0">
                        Reload Components On Action
                      </label>
                      <div>
                        <input
                          onChange={(e) => {
                            changeValue(e, index);
                          }}
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Enter refName,refNameOne,refNameTwo"
                          name="onActionReloadComponent"
                          value={action.onActionReloadComponent}
                        />
                      </div>
                    </div>
                    {action.buttonPlacement === "Table" && (
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 form-group">
                        <label className="form-label p-0">
                          Button Text Key
                        </label>
                        <div>
                          <input
                            onChange={(e) => {
                              changeValue(e, index);
                            }}
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Enter Key"
                            name="buttonTextKey"
                            value={action.buttonTextKey}
                          />
                        </div>
                      </div>
                    )}

                    {[actionTypes.import].includes(action.actionType) && (
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                        <label className="form-label p-0 text-nowrap textOverFlowEllipsis w-100">
                          Reference Variable For Incorrect Data
                        </label>
                        <div>
                          <input
                            onChange={(e) => {
                              changeValue(e, index);
                            }}
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Reference Variable for incorrect data"
                            name="incorrect_data_ref_var"
                            value={action.incorrect_data_ref_var}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                      <label className="form-label">Button Icon</label>
                      <IconDropdown
                        value={action.buttonIcon}
                        onChange={(e) => {
                          searchableSelectChange(e, "buttonIcon", index);
                        }}
                      />
                    </div>
                  </>
                )}

                {initialButtons.includes(action.actionName) && (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                    <label className="form-label p-0">View on Table</label>
                    <div>
                      <Form.Select
                        size="sm"
                        className="form-control"
                        onChange={(e) => {
                          changeValue(e, index);
                        }}
                        name={
                          action.actionName === "Update" || action.actionName === "Clone"
                            ? "updateOnTable"
                            : "viewOnTable"
                        }
                        value={
                          action.actionName === "Update" || action.actionName === "Clone"
                            ? action.updateOnTable
                            : action.viewOnTable
                        }
                      >
                        <option value="" disabled>
                          --Select--
                        </option>
                        {updateViewPrivilege}
                      </Form.Select>
                    </div>
                  </div>
                )}

                {action.actionName === "View" && (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                    <label className="form-label p-0 text-nowrap">
                      Show Comments History
                    </label>
                    <div>
                      <Form.Select
                        size="sm"
                        className="form-control"
                        onChange={(e) => {
                          changeValue(e, index);
                        }}
                        name="showComments"
                        value={action.showComments || "yes"}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </div>
                  </div>
                )}

                {[
                  actionTypes.link,
                  actionTypes.button,
                  actionTypes.popUp,
                ].includes(action?.actionType) ||
                  action?.actionType === undefined ? (
                  !initialButtons.includes(action.actionName) ? (
                    <div className="col-md-12">
                      <div className="p-2 border rounded">
                        <DisplayTransition
                          value={action}
                          onChange={(e) => {
                            onChangeAnotherValue(index, e);
                          }}
                          transitionTitle={`${action.actionType} Transition`}
                        />
                      </div>
                    </div>
                  ) : null
                ) : null}
              </div>

              {!initialButtons.includes(action.actionName) && (
                <div
                  className="iconTopRight border"
                  role="button"
                  onClick={() => removeAction(index)}
                >
                  <FontAwesomeIcon
                    icon={"fa fa-minus"}
                    className="pointer text-white add_remove_size"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default ActionButtonList;
