import React, { useEffect, useState, useRef, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reactAppExecutionVP, executeAppForTempStorage } from "../../../services/API";
import AppLoader from "../../dataset/Loader/AppLoader";
import { formatCurrency } from "../../../services/utils";
import { RoutesContext } from "../../../RoutesContext";
import { toast } from "react-toastify";
import "./cardGroup.scss";

function CardGroup({
  appSessionId,
  displayField,
  reloadComponents,
  objData,
  clearFilterApply,
  tempStorageCall,
  setTempStorageCall,
  filterExecution,
}) {
  const {
    component_id,
    refValue,
    isCacheEnabled = false,
    displayCurrencyFormat,
    autoReloadDelay,
    iconColor,
    selectedIcons, // Changed from selectedIcon to selectedIcons
    dataAttribute,
    displayKeys,
    submitVariableName,
  } = displayField;

  console.log(displayField, "displayField--in CardGroup->>>");

  const abortController = new AbortController();
  const signal = abortController.signal;
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const sliderRef = useRef(null);
  const { cachedData, setCacheData } = useContext(RoutesContext);

  useEffect(() => {
    if (autoReloadDelay) {
      const intervalId = setInterval(() => fetchCardGroupData(), autoReloadDelay);
      return () => clearInterval(intervalId);
    }
  }, [autoReloadDelay]);

  useEffect(() => {
    if (reloadComponents?.includes(refValue)) {
      fetchCardGroupData();
    }
  }, [reloadComponents]);

  useEffect(() => {
    if (cachedData?.[component_id] && !tempStorageCall && isCacheEnabled) {
      setCardsData(cachedData[component_id]);
      return;
    }
    fetchCardGroupData();
    return () => abortController.abort();
  }, [tempStorageCall]);

  const fetchCardGroupData = async () => {
    const executeAppObject = {
      data: {
        logic: objData?.logic?.length > 0 ? objData.logic : [displayField?.componentLogic],
      },
      referenceValue: [{ refValue }],
      app_session_id: appSessionId,
    };

    setLoading(true);
    try {
      const response = await reactAppExecutionVP(executeAppObject, signal);
      const groupData = response.data.find((data) => Object.keys(data)[0] === refValue)?.[refValue];
      const cardArray = groupData?.value || [];
      const finalData = Array.isArray(cardArray) ? cardArray : [];
      setCardsData(finalData);
      if (isCacheEnabled) {
        let data = cachedData || {};
        data[component_id] = finalData;
        setCacheData(data);
      }
      setTempStorageCall(false);
      clearFilterApply?.();
    } catch (err) {
      console.error("Error fetching card group data:", err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (label, index) => {
    setSelectedCard(index);

    const payloadForTempStorage = {
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      data: label,
      reference_name: submitVariableName,
      app_session_id: appSessionId,
    };

    setLoading(true);
    executeAppForTempStorage(payloadForTempStorage)
      .then((response) => {
        if (response.status === 200) {
          filterExecution?.();
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const scrollLeft = () => {
    sliderRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  const scrollRight = () => {
    sliderRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };

  const renderCard = (cardData, index) => {
    const value = cardData[dataAttribute] || 0;
    const label = cardData[displayKeys] || "Card";
    const formattedValue = displayCurrencyFormat
      ? formatCurrency(value, displayCurrencyFormat)
      : Number.isInteger(value) ? value : value.toFixed(2);

    // Assign icon based on index, looping through selectedIcons if necessary
    const iconValue = selectedIcons?.length > 0
      ? selectedIcons[index % selectedIcons.length] // Loop through icons if fewer than cards
      : null;

    return (
      <div
        className={`card-item ${selectedCard === index ? "selected" : ""}`}
        key={index}
        onClick={() => handleCardClick(label, index)}
        style={{ cursor: "pointer" }}
      >
        <div className="card-content">
          {iconValue && (
            <FontAwesomeIcon
              icon={iconValue} // Use the string value directly
              style={{ color: iconColor }}
              className="card-icon"
            />
          )}
          <div className="card-info">
            <span className="card-value">{formattedValue}</span>
            <p className="card-label">{label}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card-group-container position-relative">
      {loading && <AppLoader />}
      <button
        className="nav-arrow left-arrow"
        onClick={scrollLeft}
        title="Scroll Left"
      >
        <FontAwesomeIcon icon="chevron-left" />
      </button>
      <div className="cards-slider" ref={sliderRef}>
        {cardsData.length > 0 ? (
          cardsData.map((card, index) => renderCard(card, index))
        ) : (
          <div className="no-data">No cards available</div>
        )}
      </div>
      <button
        className="nav-arrow right-arrow"
        onClick={scrollRight}
        title="Scroll Right"
      >
        <FontAwesomeIcon icon="chevron-right" />
      </button>
      <button
        className="reload-button"
        onClick={fetchCardGroupData}
        title="Refresh"
      >
        <FontAwesomeIcon icon="refresh" size="xs" />
      </button>
    </div>
  );
}

export default CardGroup;