import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Add all the icon sets to the library
library.add(fas, far);

export function IconDropdown({ onChange, value, name, isMulti = false }) {
  const [selectedIcon, setSelectedIcon] = useState(value);

  useEffect(() => {
    setSelectedIcon(value);
  }, [value]);

  const iconNames_fas = Object.keys(library.definitions.fas).map((key) => key);
  const iconNames_far = Object.keys(library.definitions.far).map((key) => key);

  const iconNameList = [...iconNames_fas, ...iconNames_far];
  const iconNameListOption = iconNameList.map((icon) => ({
    label: icon
      ?.split("-")
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      ?.join(" "),
    value: icon,
  }));

  const formatOptionLabel = ({ value, label }) => (
    <div className="d-flex my-1 align-items-center px-1" title={label}>
      <FontAwesomeIcon icon={value} className="me-2" />
      <span className="textOverFlowEllipsis">{label}</span>
    </div>
  );

  const CustomOption = ({ innerProps, label, value }) => (
    <div {...innerProps}>
      <div className="d-flex my-1 align-items-center px-2" title={label}>
        <FontAwesomeIcon icon={value} className="me-2" />
        <span className="textOverFlowEllipsis">{label}</span>
      </div>
    </div>
  );

  // Adjust value format based on isMulti
  const selectedValue = isMulti
    ? iconNameListOption.filter((option) =>
        Array.isArray(value) ? value.includes(option.value) : false
      )
    : iconNameListOption.find((option) => option.value === value) || null;

  const handleChange = (selected, action) => {
    if (isMulti) {
      // For multi-select, return an array of values or empty array if cleared
      const newValue = selected ? selected.map((opt) => opt.value) : [];
      onChange(newValue, action);
      setSelectedIcon(newValue);
    } else {
      // For single-select, return the single value or empty string if cleared
      const newValue = selected ? selected.value : "";
      onChange(newValue, action);
      setSelectedIcon(newValue);
    }
  };

  return (
    <div style={{ minWidth: "30%" }}>
      <Select
        components={{
          Option: CustomOption,
        }}
        formatOptionLabel={formatOptionLabel}
        classNamePrefix={"react-select"}
        name={name}
        menuPosition="fixed"
        classNames={{
          control: () => "searchableDropdownControl",
          menuPortal: () => "searchableDropdownMenuPortal",
          singleValue: () => "searchableDropdownValue",
          multiValue: () => "searchableDropdownValue",
          menuList: () => "searchableDropdownMenuList",
        }}
        options={iconNameListOption}
        value={selectedValue}
        isClearable
        isMulti={isMulti}
        onChange={handleChange}
      />
    </div>
  );
}