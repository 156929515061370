import React, { useEffect, useState } from "react";
import Loader from "../../dataset/Loader/Loader";
import dummyImage from "../../../images/dummy-image.jpg";
import {
  sendEmailToSubAdmin,
  viewWishlist,
} from "../../../services/appService";
import { useParams } from "react-router";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ShareproductList(props) {
  const { tenant_Id, wishlist_Id } = useParams();
  const [productList, setProductList] = useState([]);
  const [hidePriceInformation, setHidePriceInformation] = useState(true);
  const [wishlistName, setWishlistName] = useState("");
  const [loader, setLoader] = useState([]);
  const [getQuoteModal, setGetQuoteModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [details, setDetails] = useState({ name: "", number: "" });
  const [error, setError] = useState({});
  const appId = JSON.parse(localStorage.getItem("store_app_Id"));
  const setViewDetails = props.history.location.pathname.includes(
    "share_wishlist_product_list"
  );

  const routeToProductDetails = (productDetails) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (setViewDetails) {
      props.history.push(
        `/share_product/${productDetails._id.$oid}/${tenant_Id}`
      );
    } else {
      props.history.push(
        `/app_product_view/${productDetails._id.$oid}?id=${wishlist_Id}`
      );
    }
  };

  useEffect(() => {
    fetchProductList();
  }, []);
  // fetch Product List
  const fetchProductList = () => {
    setLoader(true);
    let bodyData = {
      tenant_Id: tenant_Id,
      wishlist_Id: wishlist_Id,
    };
    viewWishlist(bodyData)
      .then((response) => {
        setLoader(false);
        if (response.data) {
          setHidePriceInformation(response?.hidePriceInformation === "yes");
          setProductList(response.data);
          setWishlistName(response.wishlist_name);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const routeToParentStore = () => {
    props.history.push(
      `/app_category_list/${appId}/${productList?.[0].store_id}`
    );
  };

  const validateFormField = () => {
    let formIsValid = true;
    let error = {};
    if (details.number === "") {
      formIsValid = false;
      error["numberError"] = "Please Enter Your Number";
    }

    if (details.name === "") {
      formIsValid = false;
      error["nameError"] = "Please Enter Your Name";
    }
    setError(error);
    return formIsValid;
  };

  const sendEmail = () => {
    if (validateFormField()) {
      let sendEmailBody = {
        user_detail: {
          name: details.name,
          contact: details.number,
          email: details.email || "",
        },
      };
      sendEmailToSubAdmin(sendEmailBody, productId)
        .then((res) => {
          if (res.success) {
            toast.success(res.message);
            setGetQuoteModal(false);
            setDetails({ name: "", number: "" });
          }
        })
        .catch((err) => {
          setDetails({ name: "", number: "" });
          toast.error(err.response?.data?.message || err.response?.message || err.message);
        });
    }
  };

  const handleGetQuote = (product_id) => {
    setGetQuoteModal(true);
    setProductId(product_id);
  };

  const handleDetails = (e) => {
    const data = { ...details };
    data[e.target.name] = e.target.value;
    setDetails(data);
  };

  const hideModal = () => {
    setGetQuoteModal(false);
    setError({});
    setDetails({ name: "", number: "" });
  };

  return (
    <div className="share-category-wrap-screen">
      <div className="col-md-12 col-sm-12 pb-2 pt-3 sharedHeight">
        {loader ? (
          <Loader />
        ) : (
          <div className="catergory-wrap-screen">
            <div className="d-help">
              <div className="d-flex">
                {setViewDetails ? null : (
                  <div className="bread-crum d-help me-2">
                    <p
                      className="m-0 me-2 text-underline cursor font-13"
                      onClick={() => routeToParentStore()}
                    >
                      Product Categories
                    </p>
                    <span>/</span>
                    <p
                      className="m-0 ms-2 text-underline cursor font-13"
                      onClick={() => props.history.goBack()}
                    >
                      Wishlist
                    </p>
                    <span className="ms-2">/</span>
                    <h6 className="m-0 ms-2 font-13 text-capitalize font-13">
                      {wishlistName}
                    </h6>
                  </div>
                )}
              </div>
              <div className="buttton-wrapper">
                <button
                  className="btn btn-secondary btn-md"
                  onClick={() => props.history.goBack()}
                >
                  <FontAwesomeIcon icon="fa fa-arrow-left" className="me-1" />
                  Back
                </button>
              </div>
            </div>
            <div className="product-data mt-4 p-3">
              <div className="row">
                {productList.length > 0 ? (
                  productList?.map((data, index) =>
                    data.product_status === true ? (
                      <div className="col-md-12 product-wrapper" key={index}>
                        <div className="row align-items-center">
                          <div className="col-md-3 col-12 dynamic-border">
                            <div className="img-wrapper">
                              <img
                                src={
                                  data.product_images?.length
                                    ? data.product_images[0]
                                    : dummyImage
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-8 col-11 offset-1">
                            <div className="content-wrapper text-capitalize">
                              <div className="top-content">
                                <h4 className="fw-bold responsive-header">
                                  {data.product_name}
                                </h4>
                                <h6>
                                  {data.product_short_description.length > 92
                                    ? data.product_short_description.slice(
                                        0,
                                        95
                                      ) + "..."
                                    : data.product_short_description}
                                </h6>
                              </div>
                              <div className="middle-content my-3 d-flex flex-wrap">
                                {data.product_tag?.length > 0 ? (
                                  data.product_tag.map((tags, index) => (
                                    <div className="custom-tag mb-2">
                                      <FontAwesomeIcon
                                        icon={"fa fa-tag"}
                                        className="me-2"
                                      />
                                      <span>{tags.text}</span>
                                    </div>
                                  ))
                                ) : (
                                  <div className="custom-tag">
                                    <FontAwesomeIcon
                                      icon={"fa fa-tag"}
                                      className="me-2"
                                    />
                                    <span>No Tags</span>
                                  </div>
                                )}
                              </div>
                              <div className="bottom-content d-help">
                                <div className="price text-dark font-14 d-help">
                                  {/* <p className="text-warning m-0 me-4 text-sm">
                                    25% off
                                  </p> */}
                                  <p className="fw-bold m-0">
                                    {data.get_quote ? (
                                      <button
                                        className="btn btn-light border btn-md fw-bold"
                                        onClick={() =>
                                          handleGetQuote(data._id.$oid)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={"fa fa-envelope-o"}
                                          className="me-2"
                                          aria-hidden="true"
                                        />
                                        Get Quote
                                      </button>
                                    ) : (
                                      <span>
                                        {!hidePriceInformation && (
                                          <>
                                            &#x20b9;&nbsp;
                                            {data?.price?.toLocaleString(
                                              "en-IN"
                                            )}
                                          </>
                                        )}
                                      </span>
                                    )}
                                  </p>
                                </div>
                                <div className="">
                                  <button
                                    type="button"
                                    className="btn btn-md btn-secondary fw-bold"
                                    onClick={() => {
                                      routeToProductDetails(data);
                                    }}
                                  >
                                    View Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )
                ) : (
                  <p className="ps-2 font-weigh-bold font-14">
                    No Products Added To Wishlist
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal show={getQuoteModal} onHide={() => hideModal()} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Enter Details</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3 required">
              Name
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
                name="name"
                value={details?.name}
                onChange={handleDetails}
              />
              {error.nameError && (
                <div className="errorMsg text-danger">{error.nameError}</div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3 required">
              Phone No.
            </label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="number"
                className="form-control"
                placeholder="Enter Your Phone Number"
                name="number"
                value={details?.phoneNumber}
                onChange={handleDetails}
              />
              <div className="errorMsg text-danger">{error.numberError}</div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-3 col-sm-3">Email</label>
            <div className="col-md-9 col-sm-9 ">
              <input
                type="email"
                className="form-control"
                placeholder="Enter Your Email"
                name="email"
                value={details?.email}
                onChange={handleDetails}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button className="btn btn-primary btn-md me-0" onClick={sendEmail}>
              Submit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ShareproductList;
