import React, { Component } from "react";
import "./SubscriptionList.scss";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import {
  getAlertSubscription,
  getTenantDetails,
} from "../../services/appService";
import Loader from "../dataset/Loader/Loader";
import { toast } from "react-toastify";
import { message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenantDetails: [],
      loading: true,
      errorMsg: false,
      totalDataCount: 0,
      currentPage: 1,
      dataPerPage: 10,
    };
  }

  receiveCurrentPageNumber = (langValue) => {
    this.setState({ currentPage: langValue });
  };

  componentDidMount() {
    this.getTenentData();
  }

  sendNotification = (tenant_id) => {
    getAlertSubscription({ id: tenant_id })
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message);
        } else if (response.success === false) {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      });
  };

  getTenentData = () => {
    getTenantDetails()
      .then((response) => {
        if (response.success === true) {
          this.setState({
            tenantDetails: response.data,
            loading: false,
            totalDataCount: response.total_count,
          });
        } else if (response.success === false) {
          toast.error(response?.data?.message || response?.message);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { currentPage, dataPerPage } = this.state;
    const indexOfLastRecord = currentPage * dataPerPage;
    const indexOfFirstRecord = indexOfLastRecord - dataPerPage;
    const tenantData = this.state.tenantDetails.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    return (
      <div className="col-md-12 col-sm-12 mt-3 bg-white">
        <div className="d-help p-2">
          <h5>Subscription List</h5>
        </div>
        <div className="">
          <div className="col-md-12 col-sm-12 ">
            <div className="card-box">
              <div
                id="datatable-checkbox_wrapper"
                className="dataTables_wrapper container dt-bootstrap no-footer p-0"
              >
                <div className="table-responsive table-hover">
                  {this.state.loading ? (
                    <Loader />
                  ) : (
                    <table
                      
                      className="table  table-bordered "
                    >
                      <thead>
                        <tr className="head-row">
                          <th className="text-nowrap">Sr.No</th>
                          <th className="text-nowrap">Tenant Name</th>
                          <th className="text-nowrap">Tenant Id</th>
                          <th className="text-nowrap">Start Date</th>
                          <th className="text-nowrap">End Date</th>
                          <th className="text-nowrap">Status</th>
                          <th className="text-nowrap">Actions</th>
                        </tr>
                      </thead>
                      {tenantData.map((tenant, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{tenant.tenant_name}</td>
                            <td>{tenant.tenant_id}</td>

                            <td className="text-center">
                              {tenant.subscription_start_date
                                ? tenant.subscription_start_date
                                : "---"}
                            </td>
                            <td className="text-center">
                              {tenant.subscription_end_date
                                ? tenant.subscription_end_date
                                : "---"}
                            </td>
                            <td className="text-center">
                              {tenant.subscription_status
                                ? tenant.subscription_status
                                : "---"}
                            </td>
                            <td className="text-center">
                              <Link
                                to={`subscriptionform/${tenant._id.$oid}/${tenant.tenant_id}/${tenant.tenant_name}`}
                              >
                                <FontAwesomeIcon
                                  icon={"fa fa-pen-to-square"}
                                  className="me-2 actionicon"
                                />
                              </Link>
                              <FontAwesomeIcon
                                icon={"fa fa-solid fa-bell"}
                                className="bell_icon"
                                onClick={() =>
                                  this.sendNotification(tenant.tenant_id)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  )}
                  {this.state.errorMsg === true ? (
                    <p className="no_table_records">Internal issue ...</p>
                  ) : this.state.errorMsg === false &&
                    this.state.totalRecordCount === 0 ? (
                    <p className="no_table_records">
                      No matching records found
                    </p>
                  ) : null}
                </div>

                {this.state.totalDataCount > 10 ? (
                  <div className="dynamic_pagination my-2">
                    <Pagination
                      pageRangeDisplayed={3}
                      activePage={this.state.currentPage}
                      itemsCountPerPage={10}
                      totalItemsCount={this.state.totalDataCount}
                      onChange={this.receiveCurrentPageNumber.bind(this)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
