import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";

const CustomNumberBox = (props) => {
  const { value, onChange, readonly } = props;

  return (
    <InputGroup>
      <InputGroup.Text>{props.prefixValue || "#"}</InputGroup.Text>   {/* Prefix as island */}
      <FormControl
        type="number"
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        disabled={readonly}
      />
      <InputGroup.Text>{props.suffixValue || ""}</InputGroup.Text> {/* Suffix as island */}
    </InputGroup>
  );
};

export default CustomNumberBox;
