import React, { Component } from "react";
import "../../sideMenu/SideMenu.scss";
import { Link } from "react-router-dom";
import { fetchInstallAppData } from "../../../../services/appService";
import { toast } from "react-toastify";
import "./StandardLayout.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class StandardLayoutSidemenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getDataSetSubMenu: [],
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      logoImage: JSON.parse(localStorage.getItem("logoImage")),
      loading: false,
      getAppsRecord: [],
      showAppObj: {},
      appId: "",
      showComponents: this.initialPrivilege(),
      defaultDashboard: "",
    };
  }

  componentDidMount() {
    this.getPrivilages();
    this.getInstallData();
    this.clearDefaultDashboardFromLocalStorage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.getAppsRecord !== this.state.getAppsRecord) {
      this.getPrivilages();
    }
  }

  getInstallData() {
    this.setState({ loading: true });
    fetchInstallAppData()
      .then((response) => {
        if (response.success === true) {
          this.setState({
            getAppsRecord: response.data,
            loading: false,
          });
        } else {
          this.setState(
            {
              getAppsRecord: [],
              loading: false,
            },
            () => {
              toast.error(response?.data?.message || response?.message);
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ loading: false, errorMsg: true }, () =>
          toast.error(err.response?.data?.message || err.response?.message || err.message)
        );
      });
  }

  getPrivilages = () => {
    if (this.state.userInfo?.privileges) {
      let { app_previleges, data_previleges, standard_privileges } =
        this.state.userInfo.privileges;
      let showAppObj = this.state.showAppObj;

      this.state.getAppsRecord?.forEach((data, index) => {
        showAppObj[data._id.$oid] = {
          showApp: false,
        };

        app_previleges?.forEach((app_previlege, index) => {
          let previlegeAppKey = "";
          let previlegeAppVal = {};
          Object.keys(app_previlege)?.forEach((row_key) => {
            previlegeAppKey = row_key;
          });
          Object.values(app_previlege)?.forEach((row_val) => {
            previlegeAppVal = {
              showApp: row_val.read || row_val.write,
            };
          });
          if (previlegeAppKey === data._id.$oid) {
            showAppObj[data._id.$oid] = previlegeAppVal;
          }
        });
        this.setState({ showAppObj });
      });
    }
  };

  initialPrivilege() {
    let show = [
      "Roles",
      "Privileges",
      "AddUser",
      "Datasets",
      "Dataset  Editor",
      "My Apps",
      "Available Apps",
      "Create Apps",
      "Create Schedules",
      "Schedules",
      "History / Log",
      "List of Api",
      "Logs",
    ];
    let showComponents = {};
    show.forEach((menu) => {
      showComponents[menu] = {
        read: false,
        write: false,
        readwrite: false,
      };
    });
    showComponents["apps"] = false;
    showComponents["schedule"] = false;
    showComponents["API"] = false;

    return showComponents;
  }

  clearDefaultDashboardFromLocalStorage() {
    let newUserInfoObj = {
      ...this.state.userInfo,
      default_dashboard: "",
    };
    this.setState(
      {
        userInfo: newUserInfoObj,
      },
      () => {
        localStorage.setItem("user-info", JSON.stringify(this.state.userInfo));
      }
    );
  }

  render() {
    let isUserAdmin = false;
    if (this.state.userInfo != null) {
      if (this.state.userInfo.role_name.toLowerCase() === "admin") {
        isUserAdmin = true;
      }
    }
    const isAdmin = sessionStorage.getItem("admin-info");
    const isRedirecting = JSON.parse(localStorage.getItem("isRedirecting"));
    let appData = [];
    this.state.getAppsRecord?.forEach((data, index) =>
      isUserAdmin ||
      (Object.keys(this.state.showAppObj).length > 0 &&
        this.state.showAppObj[data._id.$oid]?.showApp)
        ? appData.push(data)
        : null
    );
    return (
      <div
        className={`col-md-3 left_col ${
          isRedirecting && "d-none"
        } standard_main_div`}
      >
        <div className="left_col scroll-view position-fixed">
          <div className="menu_section standard_menu_section bg-secondary sticky-top">
            <ul className="standard_nav standard_side_menu nav side-menu">
              <li onClick={() => this.clearDefaultDashboardFromLocalStorage}>
                <Link
                  className="allAppsStandardLayout"
                  to="/app-dashboard"
                  title="All Apps"
                >
                  <FontAwesomeIcon
                    icon={"fa fa-th"}
                    className="sidemenuIcon"
                    size="2xl"
                  />
                </Link>
              </li>
            </ul>
          </div>
          <div id="sidebar-menu" className="p-0 hidden-print main_menu">
            <div className="menu_section">
              <React.Fragment>
                {appData.map(
                  (app, index) =>
                    app.show_app_in_menu === "true" && (
                      <Link key={index} to={`/app_ui/${app._id.$oid}`}>
                        <li className="my-4 d-flex align-items-center flex-column">
                          <img
                            className="standard-app-logo-img-sidemenu"
                            src={app.data_file}
                          />
                          <span className="text-white text-center text-sm">
                            {app.app_name}
                          </span>
                        </li>
                      </Link>
                    )
                )}
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StandardLayoutSidemenu;
