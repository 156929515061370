import React, { useState, useEffect } from "react";
import {
  addNewApi,
  editApi,
  fetchInstallAppData,
  generateAuthKey,
  getApiById,
  getLogicsByAppId,
  getRoleData,
  getUserEmails,
} from "../../services/appService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "../dataset/Loader/Loader";
import ApiSpecification from "./ApiSpecification";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";

const AddNewApi = (props) => {
  const [emailList, setEmailList] = useState([]);
  const [installedAppList, setInstalledAppList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [logicList, setLogicList] = useState([]);
  const [field, setField] = useState({
    apiName: "",
    apiOwner: "",
    select_type: "",
    apiDescription: "",
    selectedAppId: "",
    selectedAppName: "",
    selectedLogicId: "",
    selectedLogicName: "",
    apiKey: "",
  });
  const [errors, setErrors] = useState({});
  const [apiId, setApiId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [
    apiSpecificationButtonVisibility,
    setApiSpecificationButtonVisibility,
  ] = useState(false);
  const [showApiSpecification, setShowApiSpecification] = useState(false);

  useEffect(() => {
    setIdForApi();
    getEmails();
    getAllInstalledApps();
    fetchRolesData();
  }, []);

  useEffect(() => {
    apiId && getApiDataById();
  }, [apiId]);

  const handleShowApiSpecification = (e) => {
    e.preventDefault();
    if (e.target.value) {
      setShowApiSpecification(!showApiSpecification);
    }
    setShowApiSpecification(!showApiSpecification);
  };

  const getEmails = () => {
    setIsLoading(true);
    getUserEmails()
      .then((response) => {
        if (response.success === true) {
          setIsLoading(false);
          setEmailList(response.data);
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
        setIsLoading(false);
      });
  };

  const generateAuth = () => {
    setApiSpecificationButtonVisibility(false);
    generateAuthKey()
      .then((response) => {
        if (response.success === true) {
          setField({ ...field, apiKey: response.api_key });
          setErrors({ ...errors, apiKey: "" });
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
        setIsLoading(false);
      });
  };

  const getAllInstalledApps = () => {
    setIsLoading(true);
    fetchInstallAppData()
      .then((response) => {
        if (response.success === true) {
          setIsLoading(false);
          setInstalledAppList(response.data);
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
        setIsLoading(false);
      });
  };

  const getLogicsForApps = (appId) => {
    getLogicsByAppId(appId)
      .then((response) => {
        if (response.success === true) {
          setLogicList(response.data);
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
        setIsLoading(false);
      });
  };

  const changeValue = (e) => {
    let text = e.target.options[e.target.selectedIndex].text;
    let value = e.target.value;
    setApiSpecificationButtonVisibility(false);
    setShowApiSpecification(false);
    if (e.target.name === "selectedAppName") {
      setLogicList([]);
      setField({ ...field, selectedAppId: value, selectedAppName: text });
      setErrors({ ...errors, selectedAppName: "" });
      getLogicsForApps(e.target.value);
    } else if (e.target.name === "selectedLogicName") {
      setField({ ...field, selectedLogicId: value, selectedLogicName: text });
      setErrors({ ...errors, selectedLogicName: "" });
    } else if (e.target.name === "api_owner") {
      setField({ ...field, apiOwner: value });
      setErrors({ ...errors, apiOwner: "" });
    } else {
      setField({ ...field, select_type: value, apiOwner: "" });
      setErrors({ ...errors, select_type: "" });
    }
  };

  const handleKeyDown = (e) => {
    if (field.apiName === "" && e.code === "Space") {
      e.preventDefault();
    }
  };

  const handleApiDescriptionKeyDown = (e) => {
    if (field.apiDescription === "" && e.code === "Space") {
      e.preventDefault();
    }
  };

  const handleApiNameInput = (e) => {
    setApiSpecificationButtonVisibility(false);
    setField({ ...field, apiName: e.target.value });
    setErrors({ ...errors, apiName: "" });
  };

  const handleApiDescriptionInput = (e) => {
    setApiSpecificationButtonVisibility(false);
    setField({ ...field, apiDescription: e.target.value });
    setErrors({ ...errors, apiDescription: "" });
  };

  const handleAddNewApiSubmit = () => {
    let bodyObj = {
      api_name: field.apiName,
      api_owner: field.apiOwner,
      select_type: field.select_type,
      api_description: field.apiDescription,
      logic_id: field.selectedLogicId,
      api_key: field.apiKey,
      app_id: field.selectedAppId,
      app_name: field.selectedAppName,
      logic_name: field.selectedLogicName,
    };
    let editBodyObj = {
      api_name: field.apiName,
      api_owner: field.apiOwner,
      select_type: field.select_type,
      api_description: field.apiDescription,
      logic_id: field.selectedLogicId,
      api_key: field.apiKey,
      app_id: field.selectedAppId,
      app_name: field.selectedAppName,
      logic_name: field.selectedLogicName,
      object_id: apiId,
    };
    apiId === ""
      ? addNewApi(bodyObj)
          .then((response) => {
            setIsLoading(true);
            if (response.success === true) {
              setIsLoading(false);
              toast.success("Added api successfully");
              setApiSpecificationButtonVisibility(true);
            } else {
              toast.error(response?.data?.message || response?.message);
            }
          })
          .catch((err) => {
            toast.error(err.response?.data?.message || err.response?.message || err.message);
            setIsLoading(false);
          })
      : editApi(editBodyObj)
          .then((response) => {
            setIsLoading(true);
            if (response.success === true) {
              setIsLoading(false);
              toast.success("Edited api successfully");
              setApiSpecificationButtonVisibility(true);
            } else {
              toast.error(response?.data?.message || response?.message);
            }
          })
          .catch((err) => {
            toast.error(err.response?.data?.message || err.response?.message || err.message);
            setIsLoading(false);
          });
  };

  const validateFieldForm = () => {
    let fields = field;
    let errors = {};
    let formIsValid = true;

    if (!fields.apiName) {
      formIsValid = false;
      errors["apiName"] = "*Please enter API name.";
    }

    if (!fields.apiOwner) {
      formIsValid = false;
      errors["apiOwner"] = "*Please Select API owner.";
    }

    if (!fields.select_type) {
      formIsValid = false;
      errors["select_type"] = "*Please Select Type.";
    }

    if (!fields.apiDescription) {
      formIsValid = false;
      errors["apiDescription"] = "*Please enter API description.";
    }

    if (!fields.selectedAppName) {
      formIsValid = false;
      errors["selectedAppName"] = "*Please select an applicaton.";
    }

    if (!fields.selectedLogicName) {
      formIsValid = false;
      errors["selectedLogicName"] =
        "*Please select a logic. To select logic, first select an application.";
    }

    if (!fields.apiKey) {
      formIsValid = false;
      errors["apiKey"] = "*Please generate auth key.";
    }

    setErrors(errors);
    return formIsValid;
  };

  const saveApi = (e) => {
    e.preventDefault();

    if (validateFieldForm()) {
      handleAddNewApiSubmit();
    }
  };

  const getApiDataById = () => {
    setIsLoading(true);
    getApiById(apiId)
      .then((response) => {
        if (response.success === true) {
          setField({
            apiName: response.data.api_name,
            apiOwner: response.data.api_owner,
            select_type: response.data.select_type,
            apiDescription: response.data.api_description,
            selectedAppId: response.data.app_id,
            selectedAppName: response.data.app_name,
            selectedLogicId: response.data.logic_id,
            selectedLogicName: response.data.logic_name,
            apiKey: response.data.api_key,
          });
          getLogicsForApps(response.data.app_id);
          setIsLoading(false);
          setApiSpecificationButtonVisibility(true);
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
        setIsLoading(false);
      });
  };

  const setIdForApi = () => {
    setApiId([props.location.pathname.split("/")[2]].toString());
  };

  const fetchRolesData = () => {
    getRoleData()
      .then((response) => {
        if (response.success === true) {
          setRoleList(response.data);
        }
      })
      .catch((err) => {});
  };

  return (
    <div>
      <div className="d-help py-2">
        <h5 className="m-0">{apiId === "" ? "Add new API" : "Edit API"}</h5>
        <Link to="/list-of-api">
          <button type="submit" className="back-btn m-0">
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </Link>
      </div>
      <div className="p-2 bg-white border border-secondary-subtle border-opacity-25">
        {isLoading ? (
          <Loader />
        ) : (
          <form onSubmit={saveApi}>
            <div className="my-3 d-flex col-12 align-items-center">
              <label
                htmlFor="apiName"
                className="form-label col-lg-3 col-md-3 col-4 required"
              >
                API Name
              </label>
              <div className="d-flex flex-column col-lg-9 col-md-9 col-8 m-0 p-0">
                <input
                  type="text"
                  className="form-control"
                  id="apiName"
                  placeholder="Enter API name"
                  value={field.apiName}
                  onChange={(e) => handleApiNameInput(e)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  disabled={apiId}
                />
                <div className="errorMsg text-danger">{errors.apiName}</div>
              </div>
            </div>

            <div className="my-3 d-flex col-12 align-items-center">
              <label className="form-label col-lg-3 col-md-3 col-4 required">
                Select Type
              </label>
              <div className="d-flex flex-column col-lg-9 col-md-9 col-8 m-0 p-0">
                <Form.Select
                  className="form-control"
                  value={field.select_type}
                  onChange={changeValue}
                  name="select_type"
                >
                  <option disabled value={""}>
                    Select
                  </option>
                  <option value={"user"}>User</option>
                  <option value={"role"}>Role</option>
                </Form.Select>
                <div className="errorMsg text-danger">{errors.select_type}</div>
              </div>
            </div>

            <div className="my-3 d-flex col-12 align-items-center">
              <label
                htmlFor="apiOwner"
                className="form-label col-lg-3 col-md-3 col-4 required"
              >
                API Owner
              </label>
              <div className="d-flex flex-column col-lg-9 col-md-9 col-8 m-0 p-0">
                <Form.Select
                  className="form-control"
                  value={field.apiOwner}
                  onChange={changeValue}
                  name="api_owner"
                >
                  <option disabled value={""}>
                    Select
                  </option>
                  {field.select_type === "user" &&
                    emailList.map((userEmail) => {
                      return (
                        <option key={userEmail.id} value={userEmail.display}>
                          {userEmail.display}
                        </option>
                      );
                    })}
                  {field.select_type === "role" &&
                    roleList.map((role) => {
                      return (
                        <option key={role._id.$oid} value={role._id.$oid}>
                          {role.role_name}
                        </option>
                      );
                    })}
                </Form.Select>
                <div className="errorMsg text-danger">{errors.apiOwner}</div>
              </div>
            </div>
            <div className="my-3 d-flex col-12 align-items-center">
              <label
                htmlFor="apiOwner"
                className="form-label col-lg-3 col-md-3 col-4 required"
              >
                API Description
              </label>
              <div className="d-flex flex-column col-lg-9 col-md-9 col-8 m-0 p-0">
                <textarea
                  className="form-control"
                  id="apiDescription"
                  rows="3"
                  value={field.apiDescription}
                  onChange={(e) => handleApiDescriptionInput(e)}
                  onKeyDown={(e) => handleApiDescriptionKeyDown(e)}
                  disabled={apiId}
                />
                <div className="errorMsg text-danger">
                  {errors.apiDescription}
                </div>
              </div>
            </div>
            <div className="my-3 d-flex col-12 align-items-center">
              <label
                htmlFor="selectApp"
                className="form-label col-lg-3 col-md-3 col-4 required"
              >
                Select Application
              </label>
              <div className="d-flex flex-column col-lg-9 col-md-9 col-8 m-0 p-0">
                <Form.Select
                  className="form-control"
                  onChange={(e) => changeValue(e)}
                  name="selectedAppName"
                  value={field.selectedAppId}
                >
                  <option disabled value={""}>
                    Select
                  </option>
                  {installedAppList.map((app, index) => {
                    return (
                      <option key={index} value={app._id.$oid}>
                        {app.app_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <div className="errorMsg text-danger">
                  {errors.selectedAppName}
                </div>
              </div>
            </div>
            <div className="my-3 d-flex col-12 align-items-center">
              <label
                htmlFor="selectLogic"
                className="form-label col-lg-3 col-md-3 col-4 required"
              >
                Select Logic
              </label>
              <div className="d-flex flex-column col-lg-9 col-md-9 col-8 m-0 p-0">
                <Form.Select
                  className="form-control"
                  name="selectedLogicName"
                  onChange={(e) => changeValue(e)}
                  value={field.selectedLogicId}
                >
                  <option disabled value={""}>
                    Select
                  </option>

                  {logicList.map((logic, index) => {
                    return (
                      <option key={index} value={logic._id.$oid}>
                        {logic.function_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <div className="errorMsg text-danger">
                  {errors.selectedLogicName}
                </div>
              </div>
            </div>
            <div className="my-3 d-flex col-12 align-items-center">
              <label
                htmlFor="apiKeys"
                className="form-label col-lg-3 col-md-3 col-4 required"
              >
                API Key
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="apiKey"
                  placeholder="API Key"
                  value={field.apiKey}
                  disabled
                />
                <div className="errorMsg text-danger">{errors.apiKey}</div>
                <button
                  type="button"
                  className="btn btn-secondary m-0"
                  onClick={generateAuth}
                  disabled={apiId}
                >
                  Generate Key
                </button>
              </div>
            </div>
            <div className="d-help flex-wrap">
              {apiSpecificationButtonVisibility ? (
                <button
                  className="btn btn-primary m-0"
                  type="button"
                  onClick={handleShowApiSpecification}
                  disabled={!apiSpecificationButtonVisibility}
                >
                  API specification
                </button>
              ) : (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      Submit Api To see Api specification
                    </Tooltip>
                  }
                  rootClose={!apiSpecificationButtonVisibility}
                  onHide={apiSpecificationButtonVisibility}
                  trigger={["hover", "focus", "click"]}
                >
                  <span>
                    <button
                      className="btn btn-primary m-0"
                      type="button"
                      onClick={handleShowApiSpecification}
                      disabled={!apiSpecificationButtonVisibility}
                    >
                      API specification
                    </button>
                  </span>
                </OverlayTrigger>
              )}
              <button className="custom-btn m-0" type="submit">
                Submit
              </button>
            </div>
          </form>
        )}
      </div>
      {showApiSpecification && <ApiSpecification field={field} />}
    </div>
  );
};

export default AddNewApi;
