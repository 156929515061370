import React, { Component } from "react";
import { toast } from "react-toastify";
import { newLogin } from "../../services/appService";
import "./AdminLogin.scss";
import fcLogo from "../../images/flashcorp.png";
import backgroungImg from "../../images/bg_img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: {
        email: "",
        password: "",
      },
      errors: {},
      hidden: true,
    };
    this.toggleShow = this.toggleShow.bind(this);
  }

  componentDidMount() {
    this.redirectLogin();
  }

  redirectLogin() {
    const isAuthenticated =
      JSON.parse(
        sessionStorage.getItem("admin-info")
      )?.role_name?.toLowerCase() === "admin";
    if (isAuthenticated) {
      this.routeToDashboard();
    }
  }

  routeToDashboard() {
    this.props.history.push(`/clone-tenant`);
  }

  changeValue(event) {
    let fields = this.state.field;
    fields[event.target.name] = event.target.value;
    this.setState({
      fields,
    });
  }
  // validate form date
  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter email.";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter password";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  submitLogin(event) {
    let query = this.props.location.search;
    let name = new URLSearchParams(query).get("tenant_name");
    event.preventDefault();
    if (this.validateFieldForm()) {
      const formData = new FormData();

      formData.append("email", this.state.field.email);
      formData.append("password", this.state.field.password);
      formData.append("org_name", name);

      newLogin(formData)
        .then((response) => {
          if (response.success === true) {
            sessionStorage.setItem("admin-info", JSON.stringify(response.data));
            localStorage.setItem(
              "tenantId",
              JSON.stringify(response.data.tenant_id)
            );
            localStorage.setItem("query_param", JSON.stringify(name));
            localStorage.setItem(
              "role",
              JSON.stringify(response.data.role_name)
            );
            localStorage.setItem(
              "userid",
              JSON.stringify(response.data.user_id)
            );
            localStorage.setItem(
              "session_id",
              JSON.stringify(response.data.sessionid)
            );
            this.routeToDashboard();
          }
        })
        .catch((err) => {
          if (err.response || err.message) {
            toast.error(err.response?.data?.message || err.response?.message || err.message);
          }
        });
    }
  }
  render() {
    return (
      <div className="login_main">
        <div className="col-md-12">
          <div className="row bodyBackground">
            <div className="col-md-7 p-0">
              <img
                src={backgroungImg}
                alt="background"
                className="img-fluid backimg"
              ></img>
            </div>
            <div className="col-md-5 right-side-col">
              <div className="login_main_content">
                <div>
                  <img src={fcLogo} alt="logo" className="img-logo" />
                </div>
                <div className="x_panel border-0">
                  <div className="x_content">
                    <form className="form-horizontal form-label-left">
                      <div className="form-group row ">
                        <label className="control-label col-md-3 col-sm-3 ">
                          Username
                        </label>
                        <div className="col-md-9 col-sm-9 ">
                          <input
                            type="email"
                            autoComplete="on"
                            name="email"
                            className="form-control"
                            placeholder="Enter Username"
                            value={this.state.field.email}
                            onChange={(e) => {
                              this.changeValue(e);
                            }}
                          />
                          <div className="errorMsg text-danger">
                            {this.state.errors.email}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="control-label col-md-3 col-sm-3 ">
                          Password
                        </label>
                        <div className="col-md-9 col-sm-9">
                          <div className="input-group input-group-sm">
                            <input
                              name="password"
                              autoComplete="on"
                              type={this.state.hidden ? "password" : "text"}
                              className="form-control"
                              placeholder="Enter Password"
                              value={this.state.field.password}
                              onChange={(e) => {
                                this.changeValue(e);
                              }}
                            />
                            <span className="input-group-text border-0 border-bottom bg-white">
                              <FontAwesomeIcon
                                role="button"
                                icon={
                                  this.state.hidden
                                    ? "fa fa-eye-slash"
                                    : "fa fa-eye"
                                }
                                onClick={this.toggleShow}
                              />
                            </span>
                          </div>
                          <div className="errorMsg text-danger">
                            {this.state.errors.password}
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-md-9 col-sm-9  offset-md-3">
                          <button
                            type="submit"
                            className="btn btn-success btn-md"
                            onClick={(event) => this.submitLogin(event)}
                          >&nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-solid fa-right-to-bracket" />&nbsp;&nbsp;
                            Login&nbsp;&nbsp;
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="footer_title">
                <p className="m-0">
                  Copyright &copy; {new Date().getFullYear()} Flashcorp.All
                  Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminLogin;
