import React, { useContext, useEffect, useState } from "react";
import { getRecordByIdForStepper } from "../../../../services/appService";
import CustomForm from "../../components/CustomForm";
import { FilterFormContext } from "../../contexts/FilterFormContext";
import { useParams } from "react-router";
import { RoutesContext } from "../../../../RoutesContext";
import "./stepperForm.scss";
import { toast } from "react-toastify";
import { componentType } from "../../../common/model/Model";
import { reactAppFormExecutionVP } from "../../../../services/API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";

const StepperForm = ({ appSessionId, objData }) => {
  const { filterFormData, setFilterFormData } = useContext(FilterFormContext);
  const [stepperFormName, setStepperFormName] = useState([]);
  const [stepperFormMap, setStepperFormMap] = useState({});
  const [columenWidth, setcolumenWidth] = useState({});
  const [activeStepperForm, setActiveStepperForm] = useState(null);
  const [configObj, setConfigObj] = useState();
  const [formHeader, setFormHeader] = useState("");
  const [formSteps, setFormSteps] = useState("");
  const [stepStatus, setStepStatus] = useState({}); // Tracks step status ('saved', 'submitted', 'drafted')
  const { flowId } = useParams();
  const { cachedData, setCacheData } = useContext(RoutesContext);
  const [submitResponseLoading, setSubmitResponseLoading] = useState(false);

  useEffect(() => {
    const component = objData?.page?.display_fields.filter(
      (field) => field?.componentType === "Stepper Form"
    );
    if (component?.length) {
      setFormHeader(component[0].formHeader || "");
      setFormSteps(component[0].formSteps || "");
      const columenWidth = component[0]?.columenWidth || 12;
      const names = component[0]?.stepperFormName || [];
      const userDefinedNames = (component[0].formSteps || "").split(",");
      for(var idx=0; idx<names.length; idx++){
        if(userDefinedNames.length>=idx+1 && userDefinedNames[idx]!==""){
          names[idx]=userDefinedNames[idx]
        }
      }
      const formMap = {};
      names.forEach((name, index) => {
        formMap[name] = component[0]?.stepperForm[index];
      });
      setStepperFormName(names);
      setStepperFormMap(formMap);
      setcolumenWidth(columenWidth);
      if (names.length > 0) {
        const defaultName = names[0];
        handleStepperFormClick(defaultName, formMap[defaultName]);
      }
    }

    let data = localStorage.getItem(flowId);
    if (data && data!=null && data!=="") {
      data = JSON.parse(data);
      setFilterFormData({ ...data});
      const status_data = JSON.parse(localStorage.getItem(flowId+"_status"));
      if(status_data.active){
        setActiveStepperForm(status_data.active);
      }
      if(status_data.status){
        setStepStatus({...status_data.status});
      }
    } else {
      initializeFormData();
    }
  }, [flowId, objData]);

  const initializeFormData = () => {
    const uuid = uuidv4();
    const initialData = {"sys_unique_id":uuid}
    setFilterFormData({ ...initialData}); // Default initialization
  }

  const handleStepperFormClick = (name, objectId) => {
    setActiveStepperForm(name);
    getRecordByIdForStepper({ object_id: objectId }).then((response) => {
      setConfigObj(response);
    });
  };

  const addSubmitForm = async (is_Draft) => {
    const stepNumber = stepperFormName.indexOf(activeStepperForm) + 1;
    const stepName = activeStepperForm;
    let objectBody = {
      app_session_id: appSessionId,
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      data: {
        page_fields: [
          configObj?.data?.page_fields.map((field) => ({
          is_required: field.is_required,
          display_name: field?.display_name,
          name: field?.name,
        })),
        ],
        formData: [
          {
            ...filterFormData,
            is_Draft,
            step_number: stepNumber,
            step_name: stepName,
          },
        ],
        logic_to_send: [configObj?.data?.logic_name_value],
      },
      page_type: componentType.formComponent,
      store_output_key: configObj?.data?.form_output_key,
    };
  
    try {
      setSubmitResponseLoading(true);
      const response = await reactAppFormExecutionVP(objectBody);
      toast.success(response?.message);
      setStepStatus((prevStatus) => ({
        ...prevStatus,
        [activeStepperForm]: "submitted",
      }));

      if(isLastStep){
        localStorage.removeItem(flowId);
        localStorage.removeItem(flowId+"_status");
        initializeFormData();
        setStepStatus({});
        const nextName = stepperFormName[0];
        handleStepperFormClick(nextName, stepperFormMap[nextName]);
      }
      return response;
    } catch (error) {
      toast.error(error.response?.data?.message || error.response?.message || error.message);
      throw error;
    } finally {
      setSubmitResponseLoading(false);
    }
  };
  
  const handleSaveDraftClick = async () => {
    localStorage.setItem(flowId,JSON.stringify(filterFormData));
    toast.warning("Saved as draft");
    setStepStatus((prevStatus) => ({
      ...prevStatus,
      [activeStepperForm]: "drafted",
    }));
    localStorage.setItem(flowId+"_status",JSON.stringify({
      "active":activeStepperForm,
      "status":{...stepStatus,[activeStepperForm]: "drafted"}
    }));
    /*
    try {
      await addSubmitForm("Yes");
    } catch (error) {
      console.error("Error while saving draft:", error);
    }*/
  };
  
  
  const handleNextButtonClick = async () => {
    try {
      const response = await addSubmitForm("No");
      if (response && response.message === "Success") {
        const currentIndex = stepperFormName.indexOf(activeStepperForm);
        if (currentIndex !== -1 && currentIndex < stepperFormName.length - 1) {
          const nextName = stepperFormName[currentIndex + 1];
          handleStepperFormClick(nextName, stepperFormMap[nextName]);
        }
        if(!isLastStep){
          localStorage.setItem(flowId,JSON.stringify(filterFormData));
          localStorage.setItem(flowId+"_status",JSON.stringify({
            "active":activeStepperForm,
            "status":{...stepStatus,[activeStepperForm]: "submitted"}
          }));
        }
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };
  

  const isLastStep = stepperFormName.indexOf(activeStepperForm) === stepperFormName.length - 1;

  return (
    <div className={`col-md-${columenWidth ? columenWidth : 12} px-0`}>
      <div className="row main-content h-100 mx-1 bg-white">
        <div className="d-flex justify-content-between align-items-center pb-2 mt-1 border-bottom">
          <h5 className="table-heading fw-bold">{formHeader}</h5>
        </div>
        <div className="col-md-3 my-4">
          <div className="border p-3">
            {stepperFormName?.map((name, index) => {
              const isActive = activeStepperForm === name;
              const status = stepStatus[name];
              const currentIndex = stepperFormName.indexOf(activeStepperForm);
              const isPreviousStep = index < currentIndex;
              
              return (
                <div
                  key={name}
                  onClick={() => {
                    if (isActive || status || isPreviousStep) {
                      handleStepperFormClick(name, stepperFormMap[name]);
                    }
                  }}
                  className="stepper-name"
                >
                  <div
                    className={`stepper-circle ${
                      status === "submitted"
                        ? "completed"
                        : status === "saved" || status === "drafted"
                        ? "saved"
                        : isActive
                        ? "active"
                        : "inactive"
                    }`}
                  ></div>
                  <span
                    className={`stepper-label ${
                      isActive || status || isPreviousStep ? "" : "inactive"
                    }`}
                  >
                    {name}
                    {
                      isActive ? (
                        <>&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-caret-right" /></>
                      ) : (
                        <></>
                      )
                    }
                  </span>
                </div>
              );
            })}
            <div className="button-container">
              <button className="btn save-draft btn-sm" onClick={handleSaveDraftClick}>
                Save Draft
              </button>
              <button className="btn nextb btn-sm" onClick={handleNextButtonClick}>
                {isLastStep ? (
                  <>&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />&nbsp;&nbsp;Submit&nbsp;</>
                ): (
                  <>&nbsp;&nbsp;&nbsp;&nbsp;Next <FontAwesomeIcon icon="fa-solid fa-arrow-right" />&nbsp;&nbsp;&nbsp;&nbsp;</>
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-9 border my-4">
          <div className="main-content bg-white py-4 px-4 mb-2 col-md-12">
            <CustomForm
              pageFields={configObj?.data?.page_fields}
              formGroupData={configObj?.data?.formGroupData || []}
              appSessionId={appSessionId}
              formData={filterFormData}
              setFormData={(data) => setFilterFormData({ ...data})} // Ensure `srno` is preserved
              onSubmitClick={addSubmitForm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepperForm;