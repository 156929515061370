import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  fetchAttributeDetails,
  storeAttributeDetails,
} from "../../../../services/appService";
import Loader from "../../../dataset/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class CrudAttribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributeId: this.props.match.params.attributeId
        ? this.props.match.params.attributeId
        : "",
      fields: { attribute_name: "", attribute_description: "" },
      errors: {},
      editAttributeFlag:
        this.props.match.path === "/add-attibuteSet/:storeId" ? false : true,
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
      loading: false,
      storeId: this.props.match.params.storeId,
    };
  }

  componentDidMount() {
    if (this.state.editAttributeFlag) {
      this.editDetails();
    }
  }

  changeFormValue(event) {
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;
    this.setState({
      fields,
    });
  }

  // validate form fields
  validateFieldForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["attribute_name"]) {
      formIsValid = false;
      errors["attribute_name"] = "*Please enter attribute name";
    }
    if (!fields["attribute_description"]) {
      formIsValid = false;
      errors["attribute_description"] = "*Please enter attribute description";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  editDetails() {
    this.setState(
      {
        loading: true,
      },
      () => {
        fetchAttributeDetails(this.state.attributeId)
          .then((response) => {
            this.setState(
              {
                loading: false,
              },
              () => {
                if (response.data) {
                  let fieldsProperty = { ...this.state.fields };
                  fieldsProperty.attribute_name = response.data.attribute_name;
                  fieldsProperty.attribute_description =
                    response.data.attribute_description;
                  this.setState({
                    fields: fieldsProperty,
                  });
                }
              }
            );
          })
          .catch((response) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  }

  saveAttributeSetData() {
    if (this.validateFieldForm()) {
      let storeDetails = { ...this.state.fields };
      storeDetails["store_id"] = this.state.storeId;
      this.setState(
        {
          loading: true,
          fields: storeDetails,
        },
        () => {
          let objData = {
            tenant_id: this.state.tenant_Id,
            dataset_name: "attributes",
            fields: this.state.fields,
          };
          if (this.state.editAttributeFlag) {
            objData["object_id"] = this.props.match.params.attributeId;
          }
          storeAttributeDetails(objData)
            .then((response) => {
              if (response.data) {
                this.setState(
                  {
                    loading: false,
                  },
                  () => {
                    this.props.history.push(
                      `/custom-attribute-fields/${this.state.storeId}/${response.object_id}`
                    );
                  }
                );
              }
            })
            .catch((err) => {
              if (err) {
                toast.error(err.response?.data?.message || err.response?.message || err.message);
              }
              this.setState({
                loading: false,
              });
            });
        }
      );
    }
  }

  // backButton
  backToCategoryListings() {
    this.props.history.push(`/attributes_set/${this.state.storeId}`);
  }

  render() {
    return this.state.loading ? (
      <Loader></Loader>
    ) : (
      <div className={`col-md-12 pb-4 catlog-main`}>
        <div className="button-wrapper w-100 d-help my-3">
          <h5 className="mb-0">
            {this.state.editAttributeFlag ? "Edit" : "Add"} Attribute Set
          </h5>
          <button
            type="button"
            className="btn btn-sm btn-secondary m-0 "
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>
        <div className="main-catlog bg-white p-2 mt-3 border">
          <div className="store-list col-md-12">
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Attribute Name
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Attribute Name"
                  name="attribute_name"
                  value={this.state.fields.attribute_name}
                  onChange={(e) => {
                    this.changeFormValue(e);
                  }}
                />
                <div className="errorMsg text-danger mt-2">
                  {this.state.errors.attribute_name}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Attribute Description
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Attribute Description"
                  name="attribute_description"
                  value={this.state.fields.attribute_description}
                  onChange={(e) => {
                    this.changeFormValue(e);
                  }}
                />
                <div className="errorMsg text-danger mt-2">
                  {this.state.errors.attribute_description}
                </div>
              </div>
            </div>
            <div className="d-help justify-content-end">
              <button
                className="btn btn-success btn-md m-0"
                onClick={() => {
                  this.saveAttributeSetData();
                }}
              >
                <span className="me-1">&nbsp;&nbsp;Next&nbsp;&nbsp;</span>
                <FontAwesomeIcon icon="fa fa-arrow-right" />&nbsp;&nbsp;
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CrudAttribute;
