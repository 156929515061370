import React, { useContext, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddUpdateKanbanCard from "./AddUpdateKanbanCard";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  assigneeReporterEvaluator,
  backgroundEvaluator,
  filterFormDataGetter,
} from "./kanbanCardUtils";
import KanbanCardDetailedView from "./KanbanCardDetailedView";
import CustomModal from "../../application/customModal/CustomModal";
import { FilterFormContext } from "../contexts/FilterFormContext";
import { reactAppFormExecutionVP } from "../../../services/API";
import { toast } from "react-toastify";
import Badge from 'react-bootstrap/Badge';

const NumberCircle = ({ number }) => (
  <Badge
    bg="secondary"
    className="d-inline-flex align-items-center justify-content-center"
    style={{
      height: '30px',
      fontSize: '14px',
      borderRadius: '0px',
      opacity: 0.75,
    }}
  >
    <FontAwesomeIcon icon="fa-solid fa-hashtag" size="sm"/>{number}
  </Badge>
);

const KanbanCard = ({
  srno,
  fetchBoardData,
  card,
  index,
  lane,
  boardData,
  setBoardData,
  displayData,
  objData,
  appSessionId,
  submitFormPayload,
}) => {
  const { filterFormData } = useContext(FilterFormContext);

  const [isCloningCard, setIsCloningCard] = useState(false);
  const [isUpdatingCard, setIsUpdatingCard] = useState(false);
  const [isDeletingCard, setIsDeletingCard] = useState(false);
  const [showDetailedKanbanCard, setShowDetailedKanbanCard] = useState(false);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);

  const deleteKanbanCard = async (card) => {
    try {
      setIsDeletingCard(false);
      setShowDeleteLoader(true);

      const cardKey = card.srno.toString();
      delete boardData.cards[cardKey];

      const newCardIdsArray = lane?.cardIds?.filter((id) => id !== cardKey);

      const newBoardData = {
        ...boardData,
        lanes: {
          ...boardData.lanes,
          [lane.id]: {
            ...boardData.lanes?.[lane.id],
            cardIds: newCardIdsArray,
          },
        },
        cards: boardData.cards,
      };

      setBoardData(newBoardData);

      const newSubmitFormPayload = {
        ...submitFormPayload,
        data: {
          ...submitFormPayload.data,
          formData: [
            {
              srno: card.srno,
              is_Deleted: "yes",
            },
          ],
        },
      };

      const res = await reactAppFormExecutionVP(newSubmitFormPayload, "from KanbanCard Component.");

      if (res.status !== 200) {
        throw new Error(`Failed to delete card: ${res.status} - ${res.data?.message || "Unknown error"}`);
      }

      fetchBoardData();
      setShowDeleteLoader(false);
      toast.success(`Card "${card.name}" deleted successfully`);
    } catch (error) {
      console.error("Error in deleteKanbanCard:", error);
      setBoardData(boardData); // Revert UI on failure
      setShowDeleteLoader(false);
      toast.error(error.response?.data?.message || error.response?.message || error.message);
    }
  };

  // Function to safely render HTML or text
  const renderContent = (content) => {
    if (Array.isArray(content)) {
      return content.map((item, index) => (
        <div
          key={index}
          className="m-1 bg-dark-subtle text-dark rounded p-1 px-2"
        >
          {typeof item === "string" && /<[^>]+>/.test(item) ? (
            <span dangerouslySetInnerHTML={{ __html: item }} />
          ) : (
            item
          )}
        </div>
      ));
    }

    // If string contains HTML tags, render as HTML, otherwise as text
    if (typeof content === "string" && /<[^>]+>/.test(content)) {
      return <span dangerouslySetInnerHTML={{ __html: content }} />;
    }
    return <span>{content}</span>;
  };

  return (
    <Draggable key={card.srno.toString()} draggableId={card.srno.toString()} index={index}>
      {(provided) => (
        <div
          className="col-md-12 kanban-card mb-2 rounded"
          key={card.srno.toString()}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className="py-2 m-2"
            onClick={(e) => {
              setShowDetailedKanbanCard(true);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className="kanban-card-inner-border-bottom pb-2">
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex align-items-center m-1"
                  title={card?.[displayData?.key_mapping?.label]}
                >
                  <span className="fw-bold">
                    {renderContent(
                      String(card?.[displayData?.key_mapping?.label] || "").slice(0, 50)
                    )}
                  </span>
                </div>

                <div
                  className="kanban-card-action-dropdown d-flex align-items-center justify-content-center"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault(); // Prevents focus and scroll jumps
                    e.stopPropagation();
                  }}
                >
                  <DropdownButton
                    align="end"
                    drop="end"
                    as="span"
                    variant="light"
                    size="sm"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    id="kanban-card-action-button"
                    className="kanban-dropdown-small"
                    title={
                      <FontAwesomeIcon
                        icon={"fa fa-ellipsis"}
                        title="Actions"
                      />
                    }
                  >
                    <Dropdown.Item
                      as="span"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsUpdatingCard(true);
                      }}
                    >
                      <div className="kanban-edit-card kanban-dropdown-item">
                        <span
                          className="text-success ms-1 cursorPointer small-icon"
                          title="Edit"
                        >
                          <FontAwesomeIcon icon={"fa fa-pencil"} />
                        </span>
                        <span className="ms-1 small-text">Edit</span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="span"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsCloningCard(true);
                        setIsUpdatingCard(true);
                      }}
                    >
                      <div className="kanban-edit-card kanban-dropdown-item">
                        <span
                          className="text-success ms-1 cursorPointer small-icon"
                          title="Clone"
                        >
                          <FontAwesomeIcon icon={"fa fa-solid fa-clone"} />
                        </span>
                        <span className="ms-1 small-text">Clone</span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="span"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsDeletingCard(true);
                      }}
                    >
                      <div className="kanban-delete-card kanban-dropdown-item">
                        <span
                          className="text-danger ms-1 cursorPointer small-icon"
                          title="Delete"
                        >
                          <FontAwesomeIcon icon={"fa fa-trash"} />
                        </span>
                        <span className="ms-1 small-text">Delete</span>
                      </div>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </div>

            {card?.[displayData?.key_mapping?.title] && (
              <div className="kanban-card-title fw-semibold m-1">
                {renderContent(
                  String(card?.[displayData?.key_mapping?.title] || "").slice(0, 100)
                )}
              </div>
            )}

            {card?.[displayData?.key_mapping?.description] && (
              <div className="kanban-card-description m-1">
                {renderContent(
                  card?.[displayData?.key_mapping?.description]?.length > 200
                    ? `${card?.[displayData?.key_mapping?.description]?.slice(0, 200)}...`
                    : card?.[displayData?.key_mapping?.description]
                )}
              </div>
            )}

            {(card?.[displayData?.key_mapping?.from_section] ||
              card?.[displayData?.key_mapping?.to_section]) && (
              <div className="from-to d-flex align-items-center justify-content-between m-1 flex-wrap">
                {card?.[displayData?.key_mapping?.from_section] && (
                  <Badge pill={true} bg="secondary">{renderContent(card?.[displayData?.key_mapping?.from_section])}</Badge>
                )}
                {card?.[displayData?.key_mapping?.to_section] && (
                  <Badge pill={true} bg="info">{renderContent(card?.[displayData?.key_mapping?.to_section])}</Badge>
                )}
              </div>
            )}

            {card?.[displayData?.key_mapping?.moreInfo] && (
              <div className="kanban-card-description m-1">
                {renderContent(card?.[displayData?.key_mapping?.moreInfo])}
              </div>
            )}

            <div className="kanban-card-tags">
              {card?.[displayData?.key_mapping?.tags]?.length ? (
                <>
                  {(Array.isArray(card?.[displayData?.key_mapping?.tags])
                    ? card?.[displayData?.key_mapping?.tags]
                    : [card?.[displayData?.key_mapping?.tags] || ""])
                    .map((tag) => (
                      <div
                        className="m-1 text-success p-1 px-2 float-right"
                        key={tag}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-check" />&nbsp;{renderContent(tag)}
                      </div>
                    ))}
                </>
              ) : null}

              {card?.[displayData?.key_mapping?.errorTags]?.length ? (
                <>
                  {(Array.isArray(card?.[displayData?.key_mapping?.errorTags])
                    ? card?.[displayData?.key_mapping?.errorTags]
                    : [card?.[displayData?.key_mapping?.errorTags] || ""])
                    .map((tag) => (
                      <div
                        className="m-1 bg-red-subtle text-danger p-1 px-2 float-right"
                        key={tag}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />&nbsp;{renderContent(tag)}
                      </div>
                    ))}
                </>
              ) : null}
            </div>

            <div className="kanban-card-inner-border-top pt-2">
              <span className="text-start float-left col-4">
                {card?.[displayData?.key_mapping?.idField] && (
                  <NumberCircle number={card?.[displayData?.key_mapping?.idField]} />
                )}
              </span>

              {Array.isArray(card?.[displayData?.key_mapping?.assignees]) ? (
                  <div className="kanban-card-assignees text-end  float-right">
                    {card?.[displayData?.key_mapping?.assignees]?.map(
                      (assignee, assigneeIndex) => {
                        const assigneeName = assigneeReporterEvaluator(assignee);
                        const bgParam =
                          (boardData?.laneOrder?.indexOf(lane?.id) ?? 0) +
                          assigneeIndex +
                          index;
                        const backgroundColor = backgroundEvaluator(bgParam, assignee);
                        return (
                          <div
                            className={`bg-${backgroundColor} text-white rounded-circle kanban-card-assignee p-1 ${
                              card?.assignees?.length > 6 ? "my-1" : ""
                            }`}
                            title={`Assigned To: ${assignee}`}
                            key={assigneeIndex}
                          >
                            <div className="d-flex align-items-center justify-content-center mx-2 kanban-card-assignee-name">
                              {assigneeName}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
              ) : card?.[displayData?.key_mapping?.assignees] ? ( // handle non-array scenario
                (() => {
                  const assignee = card?.[displayData?.key_mapping?.assignees];
                  const assigneeName = assigneeReporterEvaluator(assignee);
                  const bgParam =
                    (boardData?.laneOrder?.indexOf(lane?.id) ?? 0) + index;
                  const backgroundColor = backgroundEvaluator(bgParam, assignee);
                  return (
                    <span className="kanban-card-assignees text-end  float-right col-4">
                      <div
                        className={`bg-${backgroundColor} text-white rounded-circle kanban-card-assignee p-1 ${
                          card?.assignees?.length > 6 ? "my-1" : ""
                        }`}
                        title={`Assigned To: ${assignee}`}
                      >
                        <div className="d-flex align-items-center justify-content-center mx-2 kanban-card-assignee-name">
                          {assigneeName}
                        </div>
                      </div>
                    </span>
                  );
                })()
              ) : null}
            </div>
          </div>
          <CustomModal
            modalState={isDeletingCard}
            modalTitle="Are you sure"
            modalDiscription="You want to delete this card?"
            hideModal={() => setIsDeletingCard(false)}
            onDeleteClick={() => deleteKanbanCard(card)}
            isLoading={showDeleteLoader}
          />
          {isUpdatingCard && (
            <AddUpdateKanbanCard
              fetchBoardData={fetchBoardData}
              setIsUpdatingCard={setIsUpdatingCard}
              setIsCloningCard={setIsCloningCard}
              isCloningCard={isCloningCard}
              card={card}
              lane={lane}
              boardData={boardData}
              setBoardData={setBoardData}
              displayData={displayData}
              objData={objData}
              appSessionId={appSessionId}
              submitFormPayload={submitFormPayload}
            />
          )}
          <KanbanCardDetailedView
            key={card.srno.toString()}
            boardData={boardData}
            card={card}
            displayData={displayData}
            hideDetailedKanbanCardModal={() => {
              setShowDetailedKanbanCard(false);
            }}
            lane={lane}
            showDetailedKanbanCard={showDetailedKanbanCard}
          />
        </div>
      )}
    </Draggable>
  );
};

export default KanbanCard;