import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  addProduct,
  getProductDetails,
  fetchTagsByStoreId,
  updateTagsByStoreId,
  updateProductDetails,
  fetchCategoryByStoreDetails,
} from "../../../../services/appService";
import "./ProductType.scss";
import { WithContext as ReactTags } from "react-tag-input";
import Loader from "../../../dataset/Loader/Loader";
import { useLocation } from "react-router";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function StandardProduct(props) {
  const productId = props.sendProductId ? props.sendProductId : "";
  const category_id = props.categoryId ? props.categoryId : "";
  const productObjectJson = {
    product_name: "",
    product_description: "",
    product_short_description: "",
    product_type: "",
    categories: [],
    sku_id: "",
    get_quote: false,
    price: "",
    max_price: "",
    available_quantity: "",
    is_available: true,
    product_images: "",
    size: "",
    supplier_name: "",
    brand_name: "",
    uom: "",
    minimum_unit_value: 1,
    unit_of_pricing: "",
    gst_percentage: 18,
    additional_charges: 0,
    is_gst_applicable: "Yes",
    additional_info: "",
  };

  let product_type_options = [
    { label: "Packaged Product", value: "packaged_product" },
    { label: "Subscription", value: "subscription" },
    { label: "Downloadable file", value: "downlodable_file" },
    { label: "Bundled Product", value: "bundled_product" },
    { label: "Grouped Product", value: "grouped_product" },
    { label: "Customizable Product", value: "customizable_product" },
    { label: "Rental Service", value: "rental_service" },
    { label: "Valuable Product", value: "valuable_product" },
    { label: "Location", value: "location" },
  ];
  const storeId = props.storeId;
  const tenant_id = JSON.parse(localStorage.getItem("tenantId"));
  const [productFields, setProductFields] = useState(productObjectJson);
  const [errors, setErrors] = useState({});
  const [enableProduct, setEnableProduct] = useState(true);
  const [tags, setTags] = React.useState([]);
  const [categories, setCategories] = useState([]);
  const [suggestions, setSuggestions] = React.useState([]);
  const [disable, setDisable] = useState(true);
  const [isResponseLoading, setisResponseLoading] = useState(false);
  const { search } = useLocation();
  const categoryListType = new URLSearchParams(search).get(
    "category_list_type"
  );

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  useEffect(() => {
    if (productId?.length) {
      callProductDetails();
    }
  }, [productId]);

  useEffect(() => {
    fetchTagsData();
    fetchCategoryByStore();
  }, []);

  // fetch and assign category data
  useEffect(() => {
    const filteredData = categories.filter(
      (data, index) => data.value == category_id
    );
    setProductFields((productFields) => ({
      ...productFields,
      ["categories"]: filteredData,
    }));
  }, [categories]);

  // fetch tags data
  const fetchTagsData = () => {
    setDisable(false);
    fetchTagsByStoreId(storeId)
      .then((response) => {
        if (response.data.length) {
          assignDataToSuggestions(response.data);
        }
      })
      .catch(() => {});
  };

  // fetch category data
  const fetchCategoryByStore = () => {
    fetchCategoryByStoreDetails(storeId)
      .then((response) => {
        let optionData = [];
        if (response.data && response.data.length) {
          response.data.map((data, index) => {
            let objData = {
              value: data._id.$oid,
              label: data.category_name,
            };
            optionData.push(objData);
          });
        }
        setCategories(optionData);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || error.response?.message || error.message);
      });
  };

  const assignDataToSuggestions = (data) => {
    let assignValue = [];
    data.map((value, index) => {
      let obj = {
        id: value,
        text: value,
      };
      assignValue.push(obj);
    });
    setSuggestions(assignValue);
  };

  const callProductDetails = () => {
    setDisable(true);
    getProductDetails(productId)
      .then((response) => {
        if (response.data) {
          let obj = {
            ...response.data,
            get_quote: response?.data?.get_quote || false,
          };
          props.receiveProductName(response.data.product_name);
          setProductFields(obj);
          setTags(response?.data?.product_tag || []);
          setEnableProduct(response.data.product_status);
        }
      })
      .catch((error) => {
        if (error) {
          toast.error(error.response?.data?.message || error.response?.message || error.message);
        }
      })
      .finally(() => {
        setDisable(false);
      });
  };

  const validateFieldForm = () => {
    let fields = productFields;
    let errors = {};
    let formIsValid = true;

    if (!fields.product_name) {
      formIsValid = false;
      errors["product_name"] = "*Please enter product name";
    }

    if (!fields.product_description) {
      formIsValid = false;
      errors["product_description"] = "*Please enter product description";
    }

    if (!fields.sku_id) {
      formIsValid = false;
      errors["sku_id"] = "*Please enter sku Id";
    }
    if (!fields.get_quote) {
      if (!fields.price) {
        formIsValid = false;
        errors["price"] = "*Please Enter Minimum Price";
      }
      if (!fields.max_price) {
        formIsValid = false;
        errors["max_price"] = "*Please Enter Maximum Price";
      }
    }
    if (!fields.categories.length) {
      formIsValid = false;
      errors["categories"] = "*Please select category";
    }

    if (fields.product_type === "location") {
      var latlangPattern = new RegExp(/^-?[0-9]{1,3}(?:\.[0-9]{1,10})?$/);
      if (!fields.latitude) {
        formIsValid = false;
        errors["latitude"] = "*Please enter latitude";
      } else if (!latlangPattern.test(fields["latitude"])) {
        formIsValid = false;
        errors["latitude"] = "*Please Enter Valid Latitude";
      }
      if (!fields.longitude) {
        formIsValid = false;
        errors["longitude"] = "*Please enter longitude";
      } else if (!latlangPattern.test(fields["longitude"])) {
        formIsValid = false;
        errors["longitude"] = "*Please Enter Valid Longitude";
      }
    }
    setErrors(errors);
    return formIsValid;
  };

  const changeProductDetails = (e) => {
    if (e.target.name === "is_available") {
      setProductFields((productFields) => ({
        ...productFields,
        [e.target.name]: e.target.checked,
      }));
    } else if (
      e.target.name === "price" ||
      e.target.name === "max_price" ||
      e.target.name === "additional_charges"
    ) {
      setProductFields((productFields) => ({
        ...productFields,
        [e.target.name]: parseFloat(e.target.value),
      }));
    } else {
      setProductFields((productFields) => ({
        ...productFields,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const submitDataToDisplay = () => {
    if (validateFieldForm()) {
      setisResponseLoading(true);
      let obj = {
        store_id: storeId,
        product_status: enableProduct,
        product_tag: tags,
      };

      if (productFields?.get_quote === true) {
        obj["max_price"] = "";
        obj["price"] = "";
      }
      let bodyObj = {
        fields: { ...productFields, ...obj },
      };
      if (productId?.length) {
        bodyObj["object_id"] = productId;
        updateProductDetails(bodyObj)
          .then((response) => {
            if (response.success) {
              toast.success(response.message);
              props.returnObjectId(productId);
            }
          })
          .catch((error) => {
            toast.error(error.response?.data?.message || error.response?.message || error.message);
          })
          .finally(() => {
            setisResponseLoading(false);
          });
      } else {
        addProduct(bodyObj)
          .then((response) => {
            if (response.success) {
              toast.success(response.message);
              props.returnObjectId(response.object_id);
            }
          })
          .catch((error) => {
            toast.error(error.response?.data?.message || error.response?.message || error.message);
          })
          .finally(() => {
            setisResponseLoading(false);
          });
      }
    }
  };

  const handleChange = (selectedOption) => {
    setProductFields((productFields) => ({
      ...productFields,
      ["categories"]: selectedOption,
    }));
  };

  let productTypeList = product_type_options?.map((data, index) => (
    <option key={index} value={data.value}>
      {data.label}
    </option>
  ));

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
    let objData = {
      tenant_id: tenant_id,
      fields: { tags: [tag.text], store_id: storeId },
    };
    updateTagsByStoreId(objData)
      .then((response) => {
        fetchTagsData();
      })
      .catch(() => {});
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleQuoate = (enableQuote) => {
    let data = {
      ...productFields,
    };
    data.get_quote = enableQuote;
    setProductFields(data);
  };

  const handleTagClick = (index) => {};
  return disable ? (
    <div className="p-3">
      <div className="text-center">
        <div className="spinner-border"></div>
      </div>
    </div>
  ) : (
    <div className="mt-3 add-standard-product">
      <div className="form">
        <div className="form-group row">
          <div className="col-md-6 mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Product Status
              </label>
              <div className="col-md-8 col-sm-8">
                <FontAwesomeIcon
                  icon={
                    enableProduct === true
                      ? "fa fa-toggle-on"
                      : "fa fa-toggle-off"
                  }
                  role="button"
                  disabled={true}
                  className={`stauspointer togglebtn toggleFromBtnSize ${
                    enableProduct ? "text-success" : "text-danger"
                  }`}
                  aria-hidden="true"
                  name="enable_category"
                  onClick={(e) => setEnableProduct(!enableProduct)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4 required">
                Product Name
              </label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter product name"
                  name="product_name"
                  value={productFields.product_name}
                  onChange={changeProductDetails}
                />

                <span className="mt-2 text-danger">{errors.product_name}</span>
              </div>
            </div>
          </div>

          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4 required centerd">
                Product Description
              </label>
              <div className="col-md-8 col-sm-8">
                <div className="mb-2">
                  <span>**Kindly put your HTML block here**</span>
                </div>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter product description"
                  name="product_description"
                  value={productFields.product_description}
                  onChange={changeProductDetails}
                />
                <span className="mt-2 text-danger">
                  {errors.product_description}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Product Short Description
              </label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter product short description"
                  name="product_short_description"
                  value={productFields.product_short_description}
                  onChange={changeProductDetails}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4 centerd">
                Enter Product Tags
              </label>
              <div className="col-md-8 col-sm-8">
                <ReactTags
                  inline
                  tags={tags}
                  allowDeleteFromEmptyInput={false}
                  suggestions={suggestions}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  inputFieldPosition="bottom"
                  autoComplete
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Select Product Types
              </label>
              <div className="col-md-8 col-sm-8">
                <Form.Select
                  className="form-control"
                  name="product_type"
                  value={productFields.product_type}
                  onChange={changeProductDetails}
                >
                  <option>Please select value</option>
                  {categoryListType === "list_type"
                    ? productTypeList.filter(
                        (data) => data.props?.children !== "Location"
                      )
                    : productTypeList}
                </Form.Select>
              </div>
            </div>
          </div>

          {productFields.product_type === "location" && (
            <>
              <div className="col-md-6 form-group mb-4">
                <div className="row">
                  <label className="col-form-label col-md-4 col-sm-4 required">
                    Latitude
                  </label>
                  <div className="col-md-8 col-sm-8">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="0.000000"
                      name="latitude"
                      value={productFields.latitude}
                      onChange={changeProductDetails}
                    />
                    <span className="mt-2 text-danger">{errors.latitude}</span>
                  </div>
                </div>
              </div>

              <div className="col-md-6 form-group mb-4">
                <div className="row">
                  <label className="col-form-label col-md-4 col-sm-4 required">
                    Longitude
                  </label>
                  <div className="col-md-8 col-sm-8">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="0.000000"
                      name="longitude"
                      value={productFields.longitude}
                      onChange={changeProductDetails}
                    />
                    <span className="mt-2 text-danger">{errors.longitude}</span>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4 required">
                Select Categories
              </label>
              <div className="col-md-8 col-sm-8">
                <Select
                  isMulti={true}
                  options={categories}
                  name="categories"
                  value={productFields.categories}
                  onChange={handleChange}
                ></Select>
                <span className="mt-2 text-danger">{errors.categories}</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4 required">
                Sku Id
              </label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter sku id"
                  name="sku_id"
                  value={productFields.sku_id}
                  onChange={changeProductDetails}
                  readOnly={productId?.length > 0}
                  min={0}
                />
                <span className="mt-2 text-danger">{errors.sku_id}</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Get Quote
              </label>
              <div className="col-md-8 col-sm-8">
                <FontAwesomeIcon
                  icon={
                    productFields?.get_quote === true
                      ? "fa fa-toggle-on"
                      : "fa fa-toggle-off"
                  }
                  role="button"
                  disabled={true}
                  className={`stauspointer togglebtn toggleFromBtnSize ${
                    productFields.get_quote ? "text-success" : "text-danger"
                  }`}
                  aria-hidden="true"
                  name="enable_quote"
                  onClick={() =>
                    handleQuoate(
                      productFields?.get_quote === true ? false : true
                    )
                  }
                />
              </div>
            </div>
          </div>
          {productFields?.get_quote === false && (
            <div className="col-md-6 form-group mb-4">
              <div className="row">
                <label className="col-form-label col-md-4 col-sm-4 required">
                  Price
                </label>
                <div className="col-md-4 col-sm-4">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Min price"
                    name="price"
                    value={parseFloat(productFields.price)}
                    onChange={changeProductDetails}
                  />
                  <span className="mt-2 text-danger">{errors.price}</span>
                </div>
                <div className="col-md-4 col-sm-4">
                  <input
                    type="number"
                    min={0}
                    className="form-control"
                    placeholder="Enter Max price"
                    name="max_price"
                    value={parseFloat(productFields.max_price)}
                    onChange={changeProductDetails}
                  />
                  <span className="mt-2 text-danger">{errors.max_price}</span>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Available Quantity
              </label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter available quantity"
                  name="available_quantity"
                  value={productFields.available_quantity}
                  onChange={changeProductDetails}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">Size</label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter size"
                  name="size"
                  value={productFields.size}
                  onChange={changeProductDetails}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Is Available
              </label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="checkbox"
                  placeholder="Is available"
                  name="is_available"
                  checked={productFields.is_available}
                  onChange={changeProductDetails}
                  className="custom-checkbox"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Unit Of Measurement
              </label>
              <div className="col-md-8 col-sm-8">
                <Form.Select
                  className="form-control"
                  value={productFields.uom}
                  name="uom"
                  onChange={changeProductDetails}
                >
                  <option value="">Please select value</option>
                  <option value="sq.feet"> Sq.Feet</option>
                  <option value="no_of_items">No Of Items</option>
                </Form.Select>
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Minimum Unit Value
              </label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter MUV"
                  name="minimum_unit_value"
                  value={productFields.minimum_unit_value}
                  onChange={changeProductDetails}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Supplier Name
              </label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter supplier name"
                  name="supplier_name"
                  value={productFields.supplier_name}
                  onChange={changeProductDetails}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Brand Name
              </label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter brand name"
                  name="brand_name"
                  value={productFields.brand_name}
                  onChange={changeProductDetails}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Unit Of Pricing
              </label>
              <div className="col-md-8 col-sm-8">
                <Form.Select
                  className="form-control"
                  value={productFields.unit_of_pricing}
                  name="unit_of_pricing"
                  onChange={changeProductDetails}
                >
                  <option value="">Please select value</option>
                  <option value="rateperhr">Rate/Hr</option>
                  <option value="ratepertwelvehr">Rate/12Hr</option>
                  <option value="rateperday">Rate/Day</option>
                  <option value="ratepermonth">Rate/Month</option>
                </Form.Select>
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Is GST Applicable
              </label>
              <div className="col-md-8 col-sm-8">
                <Form.Select
                  className="form-control"
                  value={productFields.is_gst_applicable}
                  name="is_gst_applicable"
                  onChange={changeProductDetails}
                >
                  <option value="">Please select value</option>
                  <option value="yes"> Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4">
                Additional Charges
              </label>
              <div className="col-md-8 col-sm-8">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Additional Charges"
                  name="additional_charges"
                  value={parseFloat(productFields.additional_charges)}
                  onChange={changeProductDetails}
                />
              </div>
            </div>
          </div>
          {productFields.is_gst_applicable === "yes" && (
            <div className="col-md-6 form-group mb-4">
              <div className="row">
                <label className="col-form-label col-md-4 col-sm-4">
                  GST(%)
                </label>
                <div className="col-md-8 col-sm-8">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="18"
                    name="gst_percentage"
                    value={productFields.gst_percentage}
                    onChange={changeProductDetails}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="col-md-6 form-group mb-4">
            <div className="row">
              <label className="col-form-label col-md-4 col-sm-4 centerd">
                Additional Information
              </label>
              <div className="col-md-8 col-sm-8">
                <div className="mb-2">
                  <span>**Kindly put your HTML block here**</span>
                </div>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter product additional information"
                  name="additional_info"
                  value={productFields.additional_info}
                  onChange={changeProductDetails}
                />
                {/* <span className="mt-2 text-danger">
                  {errors.product_description}
                </span> */}
              </div>
            </div>
          </div>
        </div>

        <div className="text-end">
          <button
            type="button"
            className="btn btn-success btn-md me-0"
            onClick={submitDataToDisplay}
            disabled={isResponseLoading}
          >
            {isResponseLoading && (
              <div className="spinner-border text-light btnLoader me-1 spinner-border-sm"></div>
            )}
            &nbsp;&nbsp;Next&nbsp;&nbsp;
            <FontAwesomeIcon icon="fa fa-arrow-right" className="ms-1" />&nbsp;&nbsp;
          </button>
        </div>
      </div>
    </div>
  );
}

export default StandardProduct;
