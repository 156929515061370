import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  getFlowsByAppId,
  fetchInstallAppData,
} from "../../../../services/appService";
import { Form } from "react-bootstrap";

function PageTransition({ value = {}, pageTransitionType, onChange }) {
  const onChangeObject = (objectValue, key) => {
    onChange({ ...value, [key]: objectValue });
  };

  return (
    <div className="bg-white border border-secondary-subtle border-opacity-25 col-md-12 mb-4">
      <div className="clearfix mx-2 py-2">
        <h5 className="m-0">Page Transition</h5>
        {pageTransitionType === "Form Component" && (
          <PageTransitionTypeComponent
            PageTransitionTitle={"Submitted Successfully"}
            value={value.submitSuccess}
            onChange={(obj) => onChangeObject(obj, "submitSuccess")}
          />
        )}
        {pageTransitionType === "Display Component" && (
          <PageTransitionTypeComponent
            PageTransitionTitle={"Back Button Click"}
            value={value.backButtonClick}
            onChange={(obj) => onChangeObject(obj, "backButtonClick")}
          />
        )}
      </div>
    </div>
  );
}

export default PageTransition;

export const PageTransitionTypeComponent = ({
  PageTransitionTitle,
  value,
  onChange,
}) => {
  const [changeObject, setChangeObject] = useState(value || {});
  const [pageList, setPageList] = useState([]);
  const [appList, setAppList] = useState([]);
  const [flowLoader, setFlowLoader] = useState(false);
  const appId = JSON.parse(localStorage.getItem("app_id"));

  const PageTransitionTypeList = [
    { label: "App Dashboard", value: "appDashboard" },
    { label: "Another Page", value: "anotherPage" },
    { label: "Another App", value: "anotherApp" },
  ];

  useEffect(() => {
    getFlowById(changeObject?.transitionApp);
  }, [changeObject?.transitionApp]);

  useEffect(() => {
    getAllInstalledApps();
  }, []);

  // getFlowById
  useEffect(() => {
    setChangeObject(value);
  }, [value]);

  // fetch page list of app
  const getFlowById = (appId) => {
    setFlowLoader(true);
    getFlowsByAppId(appId)
      .then((response) => {
        if (response.data.length) {
          setPageList(response.data);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      })
      .finally(() => setFlowLoader(false));
  };

  // fetch app list details
  const getAllInstalledApps = () => {
    fetchInstallAppData()
      .then((response) => {
        if (response.data.length) {
          setAppList(response.data);
        } else {
          toast.error(response?.data?.message || response?.message);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.response?.message || err.message);
      });
  };

  const handleChange = (e) => {
    let objectData = { ...changeObject };
    let name = e.target.name;
    let value = e.target.value;
    if (name === "transitionType") {
      objectData = {};
    }
    if (name === "transitionType" && value === "anotherPage") {
      objectData = {};
      objectData["transitionApp"] = appId;
    }
    objectData[name] = value;
    setChangeObject(objectData);
    onChange(objectData);
  };

  // transitions type dropdown
  const PageTransitionTypeDropdown = PageTransitionTypeList.map(
    (data, index) => (
      <option key={index} value={data.value}>
        {data.label}
      </option>
    )
  );

  // page list dropdown
  const pageListData = pageList.map((data, index) => (
    <option key={data + index} value={data._id.$oid}>
      {data.function_name}
    </option>
  ));

  // app List dropdown
  const appListData = appList.map((data, index) => (
    <option key={data + index} value={data._id.$oid}>
      {data.app_name}
    </option>
  ));
  return (
    <div className="border m-2 p-2">
      <h6>{PageTransitionTitle}</h6>
      <div className="row">
        <div className="col-md-4 col-sm-12 form-group">
          <Form.Select
            size="sm"
            className="form-control"
            name="transitionType"
            value={changeObject?.transitionType}
            onChange={handleChange}
          >
            <option value="">Select Transition</option>
            {PageTransitionTypeDropdown}
          </Form.Select>
        </div>
        {changeObject?.transitionType === "anotherApp" && (
          <div className="col-md-4 col-sm-12 form-group">
            <Form.Select
              size="sm"
              className="form-control"
              name="transitionApp"
              value={changeObject?.transitionApp}
              onChange={handleChange}
            >
              <option value="">Select App</option>
              {appListData}
            </Form.Select>
          </div>
        )}
        {changeObject?.transitionApp?.length > 0 && (
          <div className="col-md-4 col-sm-12">
            <Form.Select
              size="sm"
              className="form-control"
              name="transitionPage"
              value={changeObject?.transitionPage}
              onChange={handleChange}
            >
              <option value="">
                {flowLoader ? "Loading.." : "Select Page"}
              </option>
              {pageListData}
            </Form.Select>
          </div>
        )}
      </div>
    </div>
  );
};
