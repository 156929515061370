import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

const DisplayBuilderCalender = ({
  displayBuilder,
  columnWidthList,
  onChange,
  logicList,
}) => {
  const [displayChanges, setDisplayChanges] = useState(displayBuilder);
    
    useEffect(() => {
      setDisplayChanges((prev) => {
        const updatedState = { ...prev, ...displayBuilder };
        updatedState.refValue = updatedState.refValue || "response"; 
        return JSON.stringify(updatedState) !== JSON.stringify(prev) ? updatedState : prev;
      });
    }, []);

  useEffect(() => {
    onChange(displayChanges);
  }, [displayChanges]);

  const changeValue = (e) => {
    const data = { ...displayChanges };
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    setDisplayChanges(data);
  };

  const changeLogicValue = (e) => {
    const data = { ...displayChanges };
    data["selectedLogic"] = e;
    setDisplayChanges(data);
  };

  const searchableValueChange = (event, key) => {
    const data = { ...displayChanges };
    let value = event?.label;
    data[key] = value || "";
    setDisplayChanges(data);
  };
  
  return (
    <>
      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2 required">
          Logic Variable Name
        </label>
        <div>
          <input
            type="text"
            className={`${
              displayChanges?.refValue?.length <= 0 ? "border-red" : ""
            } form-control`}
            placeholder="Enter value"
            name="refValue"
            onChange={(e) => {
              changeValue(e);
            }}
            value={displayChanges?.refValue}
          ></input>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12">
        <label className="form-label p-0 mt-2">Component Logic</label>
        <div>
          <Select
            placeholder="Select.."
            options={logicList}
            value={logicList?.find(
              (logic) => logic.label === displayChanges?.componentLogic
            )}
            onChange={(e) => searchableValueChange(e, "componentLogic")}
            isClearable
          />
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Column Width</label>
        <div>
          <Form.Select
            className="form-control"
            value={displayChanges?.columenWidth}
            onChange={(e) => {
              changeValue(e);
            }}
            name="columenWidth"
          >
            {columnWidthList}
          </Form.Select>
        </div>
      </div>

      <div className="col-md-4 col-sm-6 col-12 form-group">
        <label className="form-label p-0 mt-2">Select Logic</label>
        <div>
          <Select
            options={logicList}
            value={displayChanges?.selectedLogic}
            onChange={(e) => {
              changeLogicValue(e);
            }}
            isClearable
            menuPosition="fixed"
            classNames={{
              control: (state) => 'searchableDropdownControl',
              menuPortal: () => 'searchableDropdownMenuPortal',
              singleValue: () => 'searchableDropdownValue',
              multiValue: () => 'searchableDropdownValue',
              menuList: () => 'searchableDropdownMenuList'
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DisplayBuilderCalender;
