import { DefaultLinkModel } from "@projectstorm/react-diagrams";
import * as API from "../../../../../services/API";

export default class VPLinkModel extends DefaultLinkModel {
  constructor(logic_id) {
    super({
      type: "default",
      width: 2,
      color: "black",
    });
    this.logic_id = logic_id;
    this.registerListener({
      targetPortChanged: (event) => {
        API.addEdge(this, this.logic_id).catch(() => { });
      },
    });
  }

  getSVGPath() {
    if (this.isLastPositionDefault()) {
      return;
    }

    return super.getSVGPath();
  }

  isLastPositionDefault() {
    return this.getLastPoint().getX() === 0 && this.getLastPoint().getY() === 0;
  }

  /**
   * TODO: Notify backend the link has been removed
   */
  remove() {
    API.deleteEdge(this, this.logic_id)
      .then(() => { })
      .catch(() => { });
    super.remove();
  }
}
